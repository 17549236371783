import React from "react";
import {
  CORESelectAPI,
  CORESelectAPIProps,
  groupOptions,
} from "../../COREDesignSystem/Form/CORESelectAPI";

export const CreditTypeSelect: React.FC<
  Omit<
    CORESelectAPIProps<{
      // eslint-disable-next-line camelcase
      credit_type: string;
    }>,
    "transform" | "endpoint"
  >
> = ({ onChange, ...props }) => (
  <CORESelectAPI
    transform={(data) =>
      groupOptions(data, "credit_type", "credit_type", "credit_type")
    }
    endpoint={"distinctCreditTypes"}
    onChange={(value, option) => {
      onChange && onChange(value ? value : null, option);
    }}
    {...props}
  />
);
