import React, { useState, useEffect } from "react";
import "./LivePrice.less";
import { TradableProductTreeSelect } from "../../../../shared/select/TradableProductTree";
import { LivePriceTable } from "./LivePriceTable";
import { Col, Row } from "antd";
import { COREHeading } from "../../../../COREDesignSystem/Typography/COREHeading";
import { COREDivider } from "../../../../COREDesignSystem/Layout/COREDivider";
import { generateTestId } from "../../../../shared/testids/testids";

export const LivePrice = ({ props }) => {
  const [selectedProducts, setSelectedProducts] = useState(props.props);

  useEffect(() => {
    setSelectedProducts(props.props);
  }, [props.props]);

  const setProducts = (widget) => {
    setSelectedProducts(widget);
    props.setProps({ widget, i: props.name });
  };

  return (
    <Row className={"widget-padding"}>
      <Col span={24} className={"live-price-overview"}>
        <COREHeading level={3} marginBottom={false}>
          {props.title ?? "Market Prices Watchlist"}
        </COREHeading>
      </Col>
      <COREDivider space={"md"} type={"horizontal"}></COREDivider>
      <Col span={24}>
        {!props.disableTree && (
          <Row>
            <Col span={24} className={"tradable-product-select"}>
              <TradableProductTreeSelect
                tradeType={"firm"}
                multiple={true}
                onChange={setProducts}
                onlyCurrent={true}
                initialValue={props.props}
                testID={generateTestId("tradetracker", "live-price")}
              />
            </Col>
          </Row>
        )}
      </Col>
      <Col span={24}>
        <LivePriceTable
          tradableProducts={selectedProducts === "" ? [] : selectedProducts}
          codes={props.codes}
        />
      </Col>
    </Row>
  );
};
