/* eslint-disable */
import React, { ReactNode, useState } from "react";
import {
  useDeepCompareEffect,
  useLocalStorage,
  useStateWithHistory,
} from "react-use";
import { Col, Collapse, Popconfirm, Row, Spin } from "antd";
import { useEventListener } from "../../shared/globals";
import {
  CaretUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import "../../shared/deprecatedTables/DEPRECATEDCORETable.less";
import { COREDataSheet } from "../../shared/dataSheet/COREDataSheet";
import ReactDataSheet from "react-datasheet";
import "../../dependencies/react-datasheet/react-datasheet.less";
import { Merge } from "../../shared/TypeScriptHelpers";
import { TableProps } from "antd/lib/table/Table";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../shared/testids/testids";
import "./COREDataTable.less";
import { ColumnType } from "antd/lib/table";
import { ColumnGroupType } from "antd/es/table/interface";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { Key } from "antd/lib/table/interface";
import { UseStateHistoryReturn } from "react-use/lib/useStateWithHistory";
import { COREPagination } from "../Navigation/COREPagination";
import { COREDivider } from "../Layout/COREDivider";
import { RenderEmptyHandler } from "antd/lib/config-provider";
import { COREButton } from "../Action/COREButton";
import classNames from "classnames";

type Grid<RecordType> = {
  tableType?: string;
  setGridLog: UseStateHistoryReturn<Grid<RecordType>>[1];
  onSave: CORETableExternal<RecordType>["onSave"];
  gridLog: UseStateHistoryReturn<Grid<RecordType>>[0];
  group?: CORETableExternal<RecordType>["group"];
};
const { Panel }: { Panel: typeof CollapsePanel } = Collapse;
export type ExpandedRowKeys<RecordType> = Exclude<
  COREDataTableProps<RecordType>["expandedRowKeys"],
  undefined
>[number];

interface DataTableColumnType<T extends ReactDataSheet.Cell<T, V>, V = string>
  extends ColumnType<T> {
  editable?: boolean;
  readOnly?: boolean;
  escKeyPress?: React.KeyboardEventHandler<HTMLElement>;
  setEscKeyPress?: (value: boolean) => void;
  key?: string;
  className?: string;
  render?: (text: string, record: T) => React.ReactNode;
  index?: number;
  dataIndex?: string;
  sourceIndex?: string;
  value?: string;
  groupIndex?: boolean;
  dataEditor?: ReactDataSheet.DataEditor<T, V>;
  valueViewer?: ReactDataSheet.DataSheetProps<T, V>["valueViewer"];
}

type GridColumnType<RecordType> =
  | ColumnGroupType<RecordType>
  | ColumnType<RecordType>;

interface GridElement extends ReactDataSheet.Cell<GridElement> {
  value: string | number | null;
}

export interface CORESortedColumn {
  [key: string]: {
    columnKey: string;
    field: string;
    order: string;
  };
}

type CORETableExternal<RecordType> = {
  columns: ColumnsType<RecordType>;
  showUndo?: boolean;
  editMode?: boolean;
  selectCellMode?: boolean;
  deleteMode?: boolean;
  copyDuplicateMode?: boolean;
  deleteTitle?: string;
  deleteColumnClassName?: string;
  onDuplicate?: (arg: RecordType) => void;
  onDelete?: (arg: RecordType) => void;
  onSave?: (arg: RecordType, index?: number | string) => void;
  onPaginationChange?: (current: number, size: number) => void;
  showSizeChanger?: boolean;
  group?: (arg: RecordType) => RecordType[];
  searchedColumn?: RecordType;
  handleSearch?:
    | null
    | ((
        arg1: RecordType,
        arg2: RecordType,
        arg3: RecordType,
        arg4: RecordType
      ) => void);
  handleReset?: null | ((arg1: RecordType, arg2: RecordType) => void);
  handleSort?: null | ((arg: RecordType) => void);
  sortedColumn?: CORESortedColumn[];
  loading?: boolean;
  tableType?: string;
  showLastUpdate?: boolean;
  lastUpdate?: moment.Moment;
  sticky?: boolean;
  headerColSpan?: number;
  tableHeight?: number;
  testID: TestID;
  onSelectRow?: (selectedRow: RecordType, key: React.Key) => void;
  selectedRowKey?: React.Key;
  empty?: ReactNode;
  emptyGroupTable?: RenderEmptyHandler;
  hoverable?: boolean;
};

export type COREDataTableProps<RecordType> = Merge<
  TableProps<RecordType>,
  CORETableExternal<RecordType>
>;

type RenderDataSheetProps<RecordType> = Merge<
  COREDataTableProps<RecordType>,
  {
    cellRenderer?: ReactDataSheet.CellRenderer<GridElement>;
    onCellChange?: ReactDataSheet.CellsChangedHandler<RecordType>;
    data?: Grid<RecordType>;
    column?: boolean;
    groupData?: Grid<RecordType>;
    groupDataColumn?: Grid<RecordType>;
  }
>;

const DataSheetPagination = ({
  loading,
  pagination,
  onPageChange,
  testID,
  showSizeChanger,
}) => {
  const [, setUserPagination]: [
    number | undefined,
    (
      value:
        | ((prevState: number | undefined) => number | undefined)
        | number
        | undefined
    ) => void,
    () => void
  ] = useLocalStorage("pagination", 10);

  if (loading || !pagination) return null;

  const {
    current,
    total,
    pageSize,
    hideOnSinglePage,
  }: {
    current: number;
    total: number;
    pageSize: number;
    hideOnSinglePage: boolean;
  } = pagination;

  return (
    <>
      <COREDivider />
      <Row justify="end">
        <COREPagination
          onChange={onPageChange}
          onShowSizeChange={(currPage, size) => {
            setUserPagination(size);
            return onPageChange;
          }}
          defaultCurrent={current}
          total={total}
          hideOnSinglePage={hideOnSinglePage}
          pageSize={pageSize ? pageSize : 10}
          testID={testID}
          showSizeChanger={showSizeChanger}
        />
      </Row>
    </>
  );
};

const ReactDataSheetBlock = <RecordType extends object>({
  onCellChange,
  cellRenderer,
  groupData,
  groupDataColumn,
  group,
  tableType,
  defaultExpandedRowKeys = [],
  columns,
  handleSearch,
  handleReset,
  handleSort,
  searchedColumn,
  sortedColumn,
  sticky,
  headerColSpan,
  tableHeight,
  onSelectRow,
  selectedRowKey,
  editMode,
  selectCellMode,
  testID,
  empty,
  emptyGroupTable,
  hoverable,
}: RenderDataSheetProps<RecordType>) => {
  const [curExpand, setCurExpand] = useState<
    ExpandedRowKeys<RecordType>[] | string | string[]
  >(defaultExpandedRowKeys);
  if (group) {
    return (
      <>
        {sticky && (
          <div className={classNames("core-data-table", "sticky")}>
            <div className={"sticky-header-table"}>
              <COREDataSheet
                data={[]}
                columns={columns}
                onCellsChanged={(changes) => {
                  // @ts-ignore
                  onCellChange && onCellChange(changes, column);
                }}
                valueRenderer={(cell) => cell.value}
                cellRenderer={cellRenderer}
                handleSearch={handleSearch}
                handleReset={handleReset}
                handleSort={handleSort}
                sortedColumn={sortedColumn}
                searchedColumn={searchedColumn}
                headerColSpan={headerColSpan}
                onSelectRow={onSelectRow}
                selectedRowKey={selectedRowKey}
                editMode={editMode}
                selectCellMode={selectCellMode}
                empty={empty}
                hoverable={hoverable}
              />
            </div>
          </div>
        )}
        <div
          className={sticky ? "core-data-table-overflow" : ""}
          style={sticky && tableHeight ? { maxHeight: tableHeight } : {}}
        >
          {/*  For Column Headers*/}
          {!sticky && (
            <div className={"core-datasheet-header-col"}>
              <RenderDataSheet
                // @ts-ignore
                data={[groupDataColumn]}
                column={true}
                cellRenderer={cellRenderer}
                tableType={tableType}
                headerColSpan={headerColSpan}
                hoverable={hoverable}
              />
            </div>
          )}
          {/*Actual table*/}
          {groupData && Array.isArray(groupData) && groupData.length !== 0 ? (
            <div className={"core-datasheet-group"}>
              <Collapse
                defaultActiveKey={curExpand}
                expandIcon={({ isActive, header }) => (
                  <TestIDWrapper
                    testID={generateTestId(
                      "table",
                      `group-header-${header?.toString()}`
                    )}
                  >
                    <CaretUpOutlined
                      className={"group-arrow-icon"}
                      rotate={isActive ? 180 : 0}
                    />
                  </TestIDWrapper>
                )}
                className={"core-datasheet-group-collapse"}
                onChange={(e) => {
                  setCurExpand(e);
                }}
              >
                {groupData.map(({ key, name, data, extra, id }) => (
                  <Panel header={name} key={key} extra={extra}>
                    <TestIDWrapper
                      testID={generateTestId("table", `group-body-${name}`)}
                    >
                      {data.length !== 0 ? (
                        <RenderDataSheet
                          data={data}
                          cellRenderer={cellRenderer}
                          onCellChange={onCellChange}
                          tableType={tableType}
                          headerColSpan={headerColSpan}
                          onSelectRow={onSelectRow}
                          selectedRowKey={selectedRowKey}
                          columns={[]}
                          testID={testID}
                        />
                      ) : (
                        <Row
                          justify="center"
                          className={"core-data-table-empty-block"}
                        >
                          <Col span={24}>
                            {emptyGroupTable && emptyGroupTable(id)}
                          </Col>
                        </Row>
                      )}
                    </TestIDWrapper>
                  </Panel>
                ))}
              </Collapse>
            </div>
          ) : (
            <Row justify="center" className={"core-data-table-empty-block"}>
              <Col span={24}>{empty}</Col>
            </Row>
          )}
        </div>
      </>
    );
  }

  return (
    <RenderDataSheet
      data={groupData}
      cellRenderer={cellRenderer}
      onCellChange={onCellChange}
      columns={columns}
      handleSearch={handleSearch}
      handleReset={handleReset}
      handleSort={handleSort}
      sortedColumn={sortedColumn}
      searchedColumn={searchedColumn}
      sticky={sticky}
      tableType={tableType}
      headerColSpan={headerColSpan}
      tableHeight={tableHeight}
      onSelectRow={onSelectRow}
      selectedRowKey={selectedRowKey}
      editMode={editMode}
      selectCellMode={selectCellMode}
      testID={testID}
      empty={empty}
      hoverable={hoverable}
    />
  );
};

const RenderDataSheet = <RecordType extends object>({
  data,
  group,
  column,
  columns,
  cellRenderer,
  onCellChange,
  tableType,
  handleSearch,
  handleReset,
  handleSort,
  sortedColumn,
  searchedColumn,
  sticky,
  headerColSpan,
  tableHeight,
  onSelectRow,
  selectedRowKey,
  editMode,
  selectCellMode,
  empty,
  testID,
  hoverable,
}: RenderDataSheetProps<RecordType>) => {
  const className = classNames(
    "table-overflow",
    "core-data-table",
    { "datasheet-grouping": group },
    { sticky: sticky }
  );
  if (columns) {
    return (
      <div className={className}>
        {sticky && (
          <div className={"sticky-header-table"}>
            <COREDataSheet
              data={[]}
              columns={columns}
              onCellsChanged={(changes) => {
                // @ts-ignore
                onCellChange && onCellChange(changes, column);
              }}
              valueRenderer={(cell) => cell.value}
              cellRenderer={cellRenderer}
              handleSearch={handleSearch}
              handleReset={handleReset}
              handleSort={handleSort}
              sortedColumn={sortedColumn}
              searchedColumn={searchedColumn}
              headerColSpan={headerColSpan}
              onSelectRow={onSelectRow}
              selectedRowKey={selectedRowKey}
              editMode={editMode}
              selectCellMode={selectCellMode}
              empty={empty}
              testID={testID}
              hoverable={hoverable}
            />
          </div>
        )}
        <div
          className={classNames({ "core-data-table-overflow": sticky })}
          style={sticky && tableHeight ? { height: tableHeight } : {}}
        >
          <COREDataSheet
            data={data}
            columns={columns}
            onCellsChanged={(changes) => {
              // @ts-ignore
              onCellChange && onCellChange(changes, column);
            }}
            valueRenderer={(cell) => cell.value}
            cellRenderer={cellRenderer}
            handleSearch={handleSearch}
            handleReset={handleReset}
            handleSort={handleSort}
            sortedColumn={sortedColumn}
            searchedColumn={searchedColumn}
            headerColSpan={headerColSpan}
            onSelectRow={onSelectRow}
            selectedRowKey={selectedRowKey}
            editMode={editMode}
            selectCellMode={selectCellMode}
            empty={empty}
            testID={testID}
            hoverable={hoverable}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <COREDataSheet
        // @ts-ignore
        data={data}
        onCellsChanged={(changes) => {
          // @ts-ignore
          onCellChange && onCellChange(changes, column);
        }}
        valueRenderer={(cell) => cell.value}
        cellRenderer={cellRenderer}
        onSelectRow={onSelectRow}
        selectedRowKey={selectedRowKey}
        editMode={editMode}
        selectCellMode={selectCellMode}
        columns={columns}
        handleReset={handleReset}
        handleSearch={handleSearch}
        handleSort={handleSort}
        sortedColumn={sortedColumn}
        searchedColumn={searchedColumn}
        headerColSpan={headerColSpan}
        empty={empty}
        testID={testID}
        hoverable={hoverable}
      />
    </div>
  );
};

const generateGridRowData: <T extends ReactDataSheet.Cell<T, V>, V = string>(
  columns: any,
  cell: any,
  groupIndex?: boolean,
  editMode?: boolean
) => any = <T extends ReactDataSheet.Cell<T, V>, V = string>(
  columns,
  cell,
  groupIndex = false,
  editMode = true
) =>
  columns.map(
    ({
      dataIndex,
      key,
      render,
      editRender,
      editable = editMode,
      className,
      width,
      escKeyPress,
      setEscKeyPress,
    }) => {
      const dKey: Key | null | undefined = cell.hasOwnProperty(key)
        ? key
        : null;
      const index: number = cell.hasOwnProperty(dataIndex) ? dataIndex : dKey;
      const rawValue: string = cell.hasOwnProperty(index) ? cell[index] : null;

      const cellProperties: DataTableColumnType<T, V> = {
        value: rawValue,
        index,
        readOnly: !editable,
        className,
        width,
        sourceIndex: cell.sourceIndex,
        groupIndex: groupIndex,
      };
      if (editRender) {
        cellProperties.dataEditor = ({
          value: rawCellValue,
          row,
          col,
          onChange,
          onCommit,
          onRevert,
          onKeyDown,
        }) => {
          const editResult =
            editRender(
              rawCellValue,
              cell,
              index,
              onChange,
              onCommit,
              onRevert,
              onKeyDown,
              row,
              col,
              escKeyPress
            ) ?? null;

          if (escKeyPress) {
            onRevert();
            setEscKeyPress(false);
          }
          return editResult;
        };
      }
      if (render) {
        cellProperties.valueViewer = ({ value }) =>
          render(rawValue, cell, index) ?? value;
      }

      return cellProperties;
    }
  );

const generateGridColumn = (columns: any) =>
  columns.map(({ title, width, className }) => ({
    value: title,
    readOnly: true,
    width,
    className,
  }));

const generategroupData = (columns, dataSource, group, editMode): any => {
  if (dataSource === undefined) return [];
  let convertCol: ColumnsType = [];
  columns.forEach((c) => {
    if (c.hasOwnProperty("children")) {
      c.children.forEach((chi) => {
        convertCol.push(chi);
      });
    } else {
      convertCol.push(c);
    }
  });
  if (group) {
    const dataSourceGroup = group(dataSource);
    return dataSourceGroup.map(({ name, children, key, extra, id }, i) => ({
      key: key,
      id: id,
      name,
      extra,
      data: children.map((cell) =>
        generateGridRowData(convertCol, cell, i, editMode)
      ),
    }));
  }
  return dataSource.map((cell) => generateGridRowData(convertCol, cell));
};

const DeleteColumn = ({
  copyDuplicateMode,
  action,
  record,
  deleteTitle,
}): JSX.Element => (
  <div style={{ display: "flex" }}>
    {copyDuplicateMode && (
      <COREButton
        icon={<CopyOutlined />}
        size={"sm"}
        onClick={() => action.onDuplicate(record)}
      />
    )}

    <Popconfirm
      placement="topRight"
      title={deleteTitle}
      onConfirm={() => {
        action.onDelete(record);
      }}
      okText="Yes"
      cancelText="No"
    >
      <COREButton danger icon={<DeleteOutlined />} size={"sm"} />
    </Popconfirm>
  </div>
);

const UndoComponent = ({ showUndo, grid }) => {
  const noHistory = grid.gridLogHistory.history === undefined;
  return showUndo ? (
    <div className={"datasheet-undo-block"}>
      <Popconfirm
        title="Are you sure you want to undo?"
        onConfirm={() => {
          const { position: curPos, history } = grid.gridLogHistory;
          const nextPos: number = curPos - 1;
          const nextHistory: any = history[nextPos];

          return (
            nextHistory &&
            nextHistory.forEach((newRow, i) => {
              const curRow: any = grid.gridLog[i];
              const isSame: boolean =
                JSON.stringify(newRow) === JSON.stringify(curRow);
              if (!isSame) {
                grid.onSave(newRow);
              }
            })
          );
        }}
        okText="Yes"
        cancelText="No"
        disabled={noHistory}
      >
        <COREButton danger disabled={noHistory} icon={<UndoOutlined />}>
          Undo
        </COREButton>
      </Popconfirm>
    </div>
  ) : null;
};

const LastUpdateTime = ({ showLastUpdate, children }) =>
  showLastUpdate ? <p className={"date-time"}>{children}</p> : null;

const savegroupData = <RecordType extends object>(
  newData: {
    cell: DataTableColumnType<RecordType> | null;
    row: number;
    col: number;
    value: string | null;
  }[],
  grid: Grid<RecordType>
) => {
  const prepareSaveData: { index?: number; data: RecordType }[] = [];

  const tmpUpdate = grid.gridLog;

  newData.forEach(({ cell, row, value }) => {
    const updateRow = grid.group ? cell?.sourceIndex : row;
    const { index } = cell ?? { index: undefined };
    // @ts-ignore
    const rowData = grid.gridLog[updateRow];

    if (rowData[index] !== value) {
      if (grid.tableType === "eod") {
        rowData[index].price = value;
      } else {
        rowData[index] = value;
      }
    }
    // @ts-ignore
    tmpUpdate[updateRow] = rowData;

    prepareSaveData.push({
      index,
      data: rowData,
    });
  });
  // @ts-ignore
  grid.setGridLog(() => [...tmpUpdate]);
  prepareSaveData.forEach(({ index, data }) => {
    if (data) {
      grid.onSave && grid.onSave(data, index);
    }
  });
};
export type COREDataSheetProps<RecordType> = Merge<
  Omit<COREDataTableProps<RecordType>, "columns">,
  {
    columns: Merge<
      COREDataTableProps<RecordType>["columns"][0],
      {
        editable?: boolean;
        dataIndex?: keyof RecordType;
        children?: COREDataSheetProps<RecordType>["columns"];
        editRender?: (
          value: RecordType[keyof RecordType],
          _cell: unknown,
          _index: unknown,
          onChange: (newValue: RecordType[keyof RecordType]) => void,
          onCommit: (newValue: RecordType[keyof RecordType]) => void
        ) => JSX.Element;
      }
    >[];
  }
>;

export const COREDataTable = <RecordType extends object>({
  columns: cs,
  editMode = false,
  deleteMode = false,
  selectCellMode = false,
  deleteTitle = "Are you sure you want to delete?",
  copyDuplicateMode = false,
  onDuplicate,
  onSave,
  onDelete,
  dataSource,
  group = undefined,
  pagination = false,
  onPaginationChange,
  showSizeChanger,
  scroll,
  defaultExpandedRowKeys,
  showUndo = false,
  searchedColumn,
  handleSearch,
  handleReset,
  handleSort,
  sortedColumn,
  loading = false,
  tableType,
  showLastUpdate,
  lastUpdate,
  footer,
  tableHeight,
  testID,
  onSelectRow,
  selectedRowKey,
  empty,
  emptyGroupTable,
  hoverable,
  ...props
}: COREDataTableProps<RecordType>) => {
  // Add event listener using our hook
  const [escKeyPress, setEscKeyPress]: any = useState(false);
  useEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setEscKeyPress(true);
    }
  });

  // this is needed as we don't want to modify the original columns array, as it will cause delete mode to be added for each rerender
  const gridColumns: GridColumnType<RecordType>[] = cs.map((c) => ({
    escKeyPress: escKeyPress,
    setEscKeyPress: setEscKeyPress,
    ...c,
  }));

  const [gridLog, setGridLog, gridLogHistory] = useStateWithHistory(dataSource);
  const groupDataColumn: Grid<RecordType> = generateGridColumn(gridColumns);

  // update the grid if the datasource prop changes ( from the API)
  useDeepCompareEffect(() => {
    setGridLog(() => dataSource);
  }, [dataSource, lastUpdate]);

  // ====== Delete mode ====== //
  if (deleteMode && gridColumns) {
    gridColumns.push({
      key: "delete",
      className: props?.deleteColumnClassName,
      // @ts-ignore
      editable: false,
      width: 50,
      render: (text, record) => {
        return (
          <DeleteColumn
            copyDuplicateMode={copyDuplicateMode}
            action={{
              onDelete,
              onDuplicate,
            }}
            record={record}
            deleteTitle={deleteTitle}
          />
        );
      },
    });
  }

  const gridProps: Grid<RecordType> = {
    tableType,
    group,
    // @ts-ignore
    gridLog,
    // @ts-ignore
    setGridLog,
    onSave,
  };

  return (
    <>
      <LastUpdateTime
        children={lastUpdate?.calendar()}
        showLastUpdate={showLastUpdate}
      />
      <UndoComponent
        showUndo={showUndo}
        grid={{
          gridLogHistory,
          gridLog,
        }}
      />

      <Spin spinning={loading} tip="Loading...">
        <ReactDataSheetBlock
          onCellChange={(changes) =>
            editMode && savegroupData(changes, gridProps)
          }
          groupDataColumn={groupDataColumn}
          groupData={generategroupData(gridColumns, gridLog, group, editMode)}
          group={group}
          tableType={tableType}
          defaultExpandedRowKeys={defaultExpandedRowKeys}
          columns={gridColumns}
          searchedColumn={searchedColumn}
          handleSearch={handleSearch}
          handleReset={handleReset}
          sortedColumn={sortedColumn}
          handleSort={handleSort}
          sticky={props.sticky}
          headerColSpan={props.headerColSpan}
          tableHeight={tableHeight}
          onSelectRow={onSelectRow}
          selectedRowKey={selectedRowKey}
          editMode={editMode}
          selectCellMode={selectCellMode}
          testID={testID}
          empty={empty}
          emptyGroupTable={emptyGroupTable}
          hoverable={hoverable}
        />
      </Spin>
      <DataSheetPagination
        loading={loading}
        pagination={pagination}
        onPageChange={onPaginationChange}
        testID={testID}
        showSizeChanger={showSizeChanger}
      />
    </>
  );
};
