import React, { FC } from "react";
import { CORENotification } from "./COREDesignSystem/Feedback/CORENotification";
import { COREButton } from "./COREDesignSystem/Action/COREButton";
import { COREIcon } from "./COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useCheckFEVersionHasChanged } from "./shared/useCheckFEVersionHasChanged";

export const ReloadPageNotification: FC = () => {
  const hasFEVersionChanged = useCheckFEVersionHasChanged();
  if (!hasFEVersionChanged) {
    return null;
  }
  return (
    <CORENotification
      description={
        "Reload the page to complete the installation and receive CORE Markets' latest features & enhancements."
      }
      message={"Update ready"}
      showIcon
      testID={"page-notification-component"}
      type={"info"}
    >
      <COREButton
        size={"sm"}
        type={"primary"}
        onClick={() => {
          window.location.reload();
        }}
        icon={
          <COREIcon icon={icon({ name: "rotate-right", style: "solid" })} />
        }
      >
        Reload
      </COREButton>
    </CORENotification>
  );
};
