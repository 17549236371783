import { Tooltip } from "antd";
import React from "react";
import { NumberFormat } from "../../../../shared/globals";

export function NumberTooltip(props) {
  if (!props.number) {
    return null;
  }

  return (
    <Tooltip
      title={` ${props.prefix || ""} ${props.number} ${props.suffix | ""}`}
    >
      {NumberFormat(props.number, props.places)}
    </Tooltip>
  );
}
