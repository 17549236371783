import React from "react";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { NumberFormat } from "../../../shared/globals";
import { fileBaseUrl } from "../../../shared/state/rest";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import {
  BrokerCallButton,
  COREButton,
} from "../../../COREDesignSystem/Action/COREButton";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREPagination } from "../../../COREDesignSystem/Navigation/COREPagination";
import { CORETag } from "../../../COREDesignSystem/Content/CORETag";
import {
  getOfferSummary,
  GreenOfferDetailsType,
  OfferSummaryType,
  ProjectViewPagination,
} from "./ProjectMarketplaceContainer";
import "./ViewProjectsAsCardsAndList.less";
import { CoBenefitsCol } from "./ViewProjectsAsList";
import DefaultImagePlaceholder from "../../../assets/img/certificate-project-default-banner.svg";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { generateTestId, TestID } from "../../../shared/testids/testids";
import { COREGridItem } from "../../../COREDesignSystem/Content/COREGridItem";
import { projectMarketplaceDetailsLink } from "./projectMarketplaceHelper";
import { COREDescriptionList } from "../../../COREDesignSystem/Content/COREDescriptionList";
import { COREDescriptionSection } from "../../../COREDesignSystem/Content/COREDescriptionSection";
import { keysToCamelCase } from "../../../shared/global";
import {
  BeZeroCarbonRatingTag,
  BeZeroCarbonRatingType,
} from "./BeZeroCarbonRating/BeZeroCarbonRatingTag";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

type ViewProjectsAsCardsProp = {
  allProjects: GreenOfferDetailsType[];
  carbonProjects: GreenOfferDetailsType[];
  onProjectPaginationChange: (val: number) => void;
  loading?: boolean;
  sync?: boolean;
  error?: boolean;
  pagination: ProjectViewPagination;
};

export const EmptyProjectsRow: React.FC<{ testID: TestID }> = ({ testID }) => (
  <Row justify="center" className={"view-as-card-empty-block"}>
    <Col span={24}>
      <COREEmpty
        description={"No projects found"}
        hint={
          "Expand your search to include more options. If you can't find what you are looking for, call our broker hotline and speak to our experts"
        }
        testID={testID}
      >
        <BrokerCallButton />
      </COREEmpty>
    </Col>
  </Row>
);

const Pricing: React.FC<{
  allProjects: GreenOfferDetailsType[];
  projectCertificateId?: number;
}> = ({ allProjects, projectCertificateId }) => {
  if (projectCertificateId !== undefined) {
    const projects = allProjects.filter(
      (p: GreenOfferDetailsType) =>
        p.certificate_project === projectCertificateId
    );
    const summary: OfferSummaryType = getOfferSummary(projects);
    if (summary.countOffers > 0) {
      return (
        <COREBody marginBottom={false}>
          {summary.countOffers > 1
            ? `${summary.countOffers} offers `
            : `${summary.countOffers} offer `}
          from ${NumberFormat(summary.minOffersPrice, 2)}
        </COREBody>
      );
    }
  }
  return <COREBody marginBottom={false}>Call for details</COREBody>;
};

const availableUnits = (
  allProjects: GreenOfferDetailsType[],
  projectCertificateId?: number
) => {
  if (projectCertificateId !== undefined) {
    const projects = allProjects.filter(
      (p: GreenOfferDetailsType) =>
        p.certificate_project === projectCertificateId
    );
    const summary: OfferSummaryType = getOfferSummary(projects);
    if (summary.countOffers > 0) {
      return NumberFormat(summary.totalOffersVolume);
    }
  }
  return "Call for details";
};

export const ViewProjectsAsCards: React.FC<ViewProjectsAsCardsProp> = ({
  allProjects,
  carbonProjects,
  pagination,
  onProjectPaginationChange,
}) => {
  const history = useHistory();
  const { currentPage, defaultPageSize, setDefaultPageSize } = pagination;
  const total = carbonProjects.length;
  const projectsWithPaginate = carbonProjects.slice(
    (currentPage - 1) * defaultPageSize,
    currentPage * defaultPageSize
  );

  if (carbonProjects.length === 0)
    return (
      <EmptyProjectsRow
        testID={"projectmarketplace-viewas-cards-empty-block"}
      />
    );

  return (
    <>
      <div className={"view-as-cards-block"}>
        <COREGridItem
          render={(p: GreenOfferDetailsType) => {
            const greenOfferDetail: GreenOfferDetailsType = keysToCamelCase(p);
            const descriptionList = [
              {
                label: "Location",
                value: (
                  <COREBody marginBottom={false}>
                    {greenOfferDetail.regionName ?? "-"}
                  </COREBody>
                ),
              },
              {
                label: "Method",
                value: (
                  <COREBody marginBottom={false}>
                    {greenOfferDetail.certificateProjectTypeName}
                  </COREBody>
                ),
              },
              {
                label: "Credit type",
                value: (
                  <COREBody marginBottom={false}>
                    {greenOfferDetail.projectCreditType}
                  </COREBody>
                ),
              },
              {
                label: "Co-benefits",
                value:
                  greenOfferDetail.unSdGoals &&
                  greenOfferDetail.unSdGoalColors ? (
                    <CoBenefitsCol
                      unSDGoals={greenOfferDetail.unSdGoals}
                      unSDGoalColors={greenOfferDetail.unSdGoalColors}
                      max={2}
                    />
                  ) : (
                    "-"
                  ),
              },
              {
                label: "Pricing",
                value: (
                  <Pricing
                    allProjects={allProjects}
                    projectCertificateId={greenOfferDetail.certificateProject}
                  />
                ),
              },
              {
                label: "Available Units",
                value: (
                  <COREBody marginBottom={false}>
                    {availableUnits(
                      allProjects,
                      greenOfferDetail.certificateProject
                    )}
                  </COREBody>
                ),
              },
            ];

            return (
              <CORECard
                className={"card"}
                testID={"projectmarketplace-view-projects-as-cards"}
                size="default"
                title={greenOfferDetail.name}
                coverUrl={{
                  ...(greenOfferDetail.projectImageFile && {
                    image: `${fileBaseUrl()}/${
                      greenOfferDetail.projectImageFile
                    }`,
                  }),
                  imagePlaceholder: DefaultImagePlaceholder,
                }}
                actions={[
                  <div
                    className={"card-button"}
                    key={`view-detail-${greenOfferDetail.certificateProject}-button`}
                  >
                    <COREButton
                      type={"primary"}
                      icon={
                        <COREIcon
                          icon={icon({
                            name: "circle-arrow-right",
                            style: "regular",
                          })}
                        />
                      }
                      onClick={() =>
                        greenOfferDetail.certificateProject &&
                        history.push(
                          projectMarketplaceDetailsLink(
                            greenOfferDetail.certificateProject
                          )
                        )
                      }
                      testID={"projectmarketplace-view-projects-button"}
                    >
                      View details
                    </COREButton>
                  </div>,
                ]}
              >
                <Row gutter={[0, 8]}>
                  <Col flex={"auto"}>
                    <CORETag
                      label={greenOfferDetail.productTypeName}
                      type="basic"
                    />
                  </Col>
                  <Col flex={"none"}>
                    {greenOfferDetail.bezeroRatings && (
                      <BeZeroCarbonRatingTag
                        className={"bezero-tag-float-right"}
                        rating={
                          greenOfferDetail.bezeroRatings
                            .rating as BeZeroCarbonRatingType
                        }
                        isOnWatch={greenOfferDetail.bezeroRatings.isOnWatch}
                      />
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={24}>
                    <COREBody className={`text-overflow-line-2`}>
                      {greenOfferDetail.description ?? "-"}
                    </COREBody>
                  </Col>
                </Row>
                <COREDescriptionList>
                  <COREDescriptionSection items={descriptionList} />
                </COREDescriptionList>
              </CORECard>
            );
          }}
          keyExtractor={(item: GreenOfferDetailsType) =>
            item.tradable_product + "-col"
          }
          horizontalMargin={24}
          verticalMargin={24}
          data={projectsWithPaginate ? projectsWithPaginate : []}
          testID={generateTestId(
            "projectmarketplace",
            "view-projects-as-cards-list"
          )}
        />
      </div>
      <COREDivider testID="storybook-COREDivider" />
      <Row justify="end">
        <COREPagination
          testID="page-pagination-component"
          current={currentPage}
          defaultPageSize={defaultPageSize}
          hideOnSinglePage={false}
          total={total}
          onChange={(page, size) => {
            onProjectPaginationChange(page);
            setDefaultPageSize(size);
          }}
          showSizeChanger={true}
        />
      </Row>
    </>
  );
};
