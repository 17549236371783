import React from "react";
import { Portfolio } from "../usePortfolio";
import moment from "moment";
import {
  AggregatesChartProps,
  Trace,
} from "../../tools/chartBuilder/AggregatesChartV2";
import { NonEmptyRange, strToRange } from "../../../shared/date/ranges";
import { momentFormat } from "../../../shared/date/DateFormatContext";
import { downloadFileName } from "../../../COREDesignSystem/Chart/COREChart";
import { COREAggregatesContainerChart } from "../../../COREDesignSystem/Chart/ChartHOC";
import { generateTestId } from "../../../shared/testids/testids";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";

export const aggPeriod = (
  trackingLevel: Portfolio["trackingLevel"]
): Trace["aggPeriod"] => {
  if (trackingLevel === "1 year") return "year";
  if (trackingLevel === "3 mons") return "quarter";
  if (trackingLevel === "1 mon") return "month";
  throw Error("unknown tracking level: " + trackingLevel);
};
export const combinedPortfolioRange: NonEmptyRange = {
  from: moment().subtract("1 year").startOf("year"),
  to: moment().add(2, "year").endOf("year"),
  bounds: "[]",
};
const getChartTraces = (portfolio: Portfolio | "combined"): Trace[] => {
  const portfolioFilter = portfolio === "combined" ? undefined : portfolio.id;
  const trackingLevel =
    portfolio === "combined" ? "1 year" : portfolio.trackingLevel;
  const range =
    portfolio === "combined"
      ? combinedPortfolioRange
      : strToRange(portfolio.activeRange);
  const normalisedRange = range?.to.isValid()
    ? range
    : { ...range, to: moment().add(3, "year").endOf("year") };
  const hovertemplate = {
    hovertemplate: "%{y:,.3f} tCO₂e",
  };

  return [
    {
      aggFunction: "sum",
      aggPeriod: aggPeriod(trackingLevel),
      dateRange: {
        isRelative: false,
        from: normalisedRange.from,
        to: normalisedRange.to,
        bounds: "[)",
      },
      type: "scatter",
      axis: {
        side: "left",
        title: "tCO₂e",
      },
      columnName: "gross_emissions",
      tableName: "portfolio_period_scopes",
      title: {
        text: "Gross Emissions",
      },
      name: "gross",
      selectedValue: JSON.stringify({
        portfolio: portfolioFilter,
      }),
      "line.color": "indigo100",
      "line.dash": "solid",
      ...hovertemplate,
    },
    {
      aggFunction: "sum",
      aggPeriod: aggPeriod(trackingLevel),
      dateRange: {
        isRelative: false,
        from: normalisedRange.from,
        to: normalisedRange.to,
        bounds: "[)",
      },
      type: "scatter",
      axis: {
        side: "left",
        title: "tCO₂e",
      },
      columnName: "net_emissions",
      tableName: "portfolio_period_scopes",
      title: {
        text: "Net Emissions",
      },
      name: "net",
      selectedValue: JSON.stringify({
        portfolio: portfolioFilter,
      }),
      "line.color": "green100",
      "line.dash": "solid",
      ...hovertemplate,
    },
    {
      aggFunction: "sum",
      aggPeriod: aggPeriod(trackingLevel),
      dateRange: {
        isRelative: false,
        from: normalisedRange.from,
        to: normalisedRange.to,
        bounds: "[)",
      },
      type: "scatter",
      axis: {
        side: "left",
        title: "tCO₂e",
      },
      columnName: "target_emissions",
      tableName: "portfolio_period_scopes",
      title: {
        text: "Target Emissions",
      },
      name: "target",
      selectedValue: JSON.stringify({
        portfolio: portfolioFilter,
      }),
      "line.color": "purple100",
      "line.dash": "dash",
      ...hovertemplate,
    },
    {
      aggFunction: "sum",
      aggPeriod: aggPeriod(trackingLevel),
      dateRange: {
        isRelative: false,
        from: normalisedRange.from,
        to: normalisedRange.to,
        bounds: "[)",
      },
      type: "bar",
      axis: {
        side: "left",
        title: "tCO₂e",
      },
      columnName: "initiative_impact",
      tableName: "portfolio_period_scopes",
      title: {
        text: "Initiatives",
      },
      convertDataTo: "negative",
      name: "initiative",
      selectedValue: JSON.stringify({
        portfolio: portfolioFilter,
      }),
      "line.color": "grey140",
      ...hovertemplate,
    },
    {
      aggFunction: "sum",
      aggPeriod: aggPeriod(trackingLevel),
      dateRange: {
        isRelative: false,
        from: normalisedRange.from,
        to: normalisedRange.to,
        bounds: "[)",
      },
      type: "bar",
      axis: {
        side: "left",
        title: "tCO₂e",
      },
      columnName: "offsets_assigned",
      tableName: "portfolio_period_scopes",
      title: {
        text: "Offsets",
      },
      convertDataTo: "negative",
      name: "offset",
      selectedValue: JSON.stringify({
        portfolio: portfolioFilter,
      }),
      "line.color": "grey100",
      ...hovertemplate,
    },
  ];
};

export const EmissionsSnapshot: React.FC<{
  portfolio: Portfolio;
}> = ({ portfolio }) => {
  const chartTraces: Trace[] = getChartTraces(portfolio);

  const currentDate: string = moment(Date.now()).format(
    momentFormat.csvDate.format
  );

  const extraLayoutConfig: AggregatesChartProps["layout"] = {
    barmode: "stack",
    filename: downloadFileName(
      `Emissions-snapshot-${portfolio?.name}-${currentDate}`
    ),
    xaxis: {
      title: {
        text: "Date",
      },
      domain: [0.075, 1],
      type: "category",
    },
  };

  return (
    <COREAggregatesContainerChart
      chartId={"Emissions snapshot"}
      chartTitle={"Emissions snapshot"}
      traces={chartTraces}
      chartFilter={{
        hasTodayBreak: false,
        todayDate: "",
      }}
      downloadCsv
      downloadPng={true}
      adjustHeightOfChart={true}
      enableEmptyIcon={true}
      legendFilter={true}
      roundCorner={true}
      testID={generateTestId("emissionsmanager", "emissions-snapshot")}
      layout={extraLayoutConfig}
      empty={
        <COREEmpty
          description={"No emissions data available"}
          hint={
            "There are no emissions records available for this portfolio. Edit this portfolio and add emissions data to populate this chart."
          }
          testID={generateTestId(
            "emissionsmanager",
            "emissions-snapshot-no-data"
          )}
        />
      }
    />
  );
};
