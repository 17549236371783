/* eslint-disable react/prop-types */
import React, { Dispatch, SetStateAction } from "react";
import { Card, Col, Empty, Layout, Row, Space } from "antd";
import { PriceTable, PriceTableProps } from "../../../shared/tables/PriceTable";
import { capitaliseEachWord } from "../../../shared/stringUtil";
import { useUserIsAdmin } from "../../../shared/state/user";
import "./LiveMarketScreen.less";
import { InFocusTable } from "./InFocusLiveMarketScreen";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../../shared/testids/testids";
import { COREPageWrapper } from "../../../COREDesignSystem/Layout/COREPageWrapper";
import { COREPageHeader } from "../../../COREDesignSystem/Layout/COREPageHeader";
import { CORESwitch } from "../../../COREDesignSystem/Form/CORESwitch";
import { useLocalStorage } from "react-use";
import { COREMenuItem } from "../../../COREDesignSystem/Navigation/COREMenuItem";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { InteractionMenuItem } from "../../../COREDesignSystem/Layout/COREAccordionItem";
import { COREDropdownMenu } from "../../../COREDesignSystem/Form/COREDropdownMenu";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";

const { Content } = Layout;

type AdminPageWrapperProps = {
  pageType: string;
  genericProductsOnly?: boolean;
  setGenericProducts: Dispatch<SetStateAction<boolean | undefined>>;
  showDraftProject?: boolean;
  setShowDraftProject: Dispatch<SetStateAction<boolean | undefined>>;
};

const TableGroup = (priceTableProps) => {
  const { tableType, products } = priceTableProps;
  const isAdmin: boolean = useUserIsAdmin();
  const cardClass = isAdmin
    ? `live-market-screen-${tableType}`
    : "live-market-screen-firm";

  return (
    <Row>
      {products.map((productName, ind) => {
        const tableTypeText: string = tableType
          ? ` - ${capitaliseEachWord(tableType)}`
          : "";
        return (
          <Col
            key={`${productName}${tableTypeText}-col-${ind}`}
            xs={24}
            xxl={12}
          >
            <TestIDWrapper
              testID={generateTestId(
                "marketprices",
                `LiveMarketScreen-PriceTable-${productName}-${tableType}`
              )}
            >
              <Card
                key={`${productName}${tableTypeText}-${ind}`}
                title={`${productName}${tableTypeText}`}
                className={cardClass}
              >
                <PriceTable
                  key={`${productName}${tableTypeText}-price-${ind}`}
                  name={productName}
                  {...priceTableProps}
                />
              </Card>
            </TestIDWrapper>
          </Col>
        );
      })}
    </Row>
  );
};

const ShowGenericProductsSwitch: React.FC<
  Pick<AdminPageWrapperProps, "genericProductsOnly" | "setGenericProducts"> & {
    testId: TestID;
  }
> = ({ genericProductsOnly, setGenericProducts, testId }) => (
  <Space size={8} direction={"horizontal"}>
    <CORESwitch
      id={"switchShowMap"}
      size="sm"
      checked={genericProductsOnly}
      onChange={(value) => {
        setGenericProducts(value);
        if (setGenericProducts) {
          setTimeout(() => {
            setGenericProducts(value);
          }, 200);
        }
      }}
      testID={generateTestId(
        "marketprices",
        `${testId}-only-generic-products-toggle`
      )}
    />
    <COREBody marginBottom={false} type="p3">
      Show generic products only
    </COREBody>
  </Space>
);

const ShowDraftProjectsSwitch: React.FC<
  Pick<
    AdminPageWrapperProps,
    "showDraftProject" | "genericProductsOnly" | "setShowDraftProject"
  > & { testId: TestID }
> = ({
  showDraftProject,
  genericProductsOnly,
  setShowDraftProject,
  testId,
}) => (
  <Space size={8} direction={"horizontal"}>
    <CORESwitch
      id={"switchShowMap"}
      size="sm"
      checked={showDraftProject && !genericProductsOnly}
      onChange={(value) => {
        setShowDraftProject && setShowDraftProject(value);
      }}
      testID={generateTestId("marketprices", `${testId}-draft-projects-toggle`)}
      disabled={genericProductsOnly}
    />
    <COREBody marginBottom={false} type="p3">
      Show draft projects
    </COREBody>
  </Space>
);

const AdminPageWrapper: React.FC<AdminPageWrapperProps> = ({
  pageType,
  children,
  genericProductsOnly,
  setGenericProducts,
  showDraftProject,
  setShowDraftProject,
}) => {
  if (pageType === "carbon") {
    const testId: TestID = generateTestId("marketprices", carbonPageWrapper);
    const menu: InteractionMenuItem[] = [
      {
        component: (
          <COREMenuItem
            testID={generateTestId(
              "marketprices",
              `${testId}-menu-item-only-generic-products`
            )}
            key={"switchShowMap"}
          >
            <ShowGenericProductsSwitch
              genericProductsOnly={genericProductsOnly}
              setGenericProducts={setGenericProducts}
              testId={testId}
            />
          </COREMenuItem>
        ),
        closeWhenClick: false,
      },
      {
        component: (
          <COREMenuItem
            testID={generateTestId(
              "marketprices",
              `${testId}-menu-item-draft-projects`
            )}
            key={"switchShowMap"}
            disabled={genericProductsOnly}
          >
            <ShowDraftProjectsSwitch
              showDraftProject={showDraftProject}
              genericProductsOnly={genericProductsOnly}
              setShowDraftProject={setShowDraftProject}
              testId={testId}
            />
          </COREMenuItem>
        ),
        closeWhenClick: false,
      },
    ];
    const breadcrumbs = [
      {
        title: "Market Prices",
        href: "/market-prices",
      },
      {
        title: "Carbon",
      },
    ];
    return (
      <COREPageWrapper
        breadcrumbs={breadcrumbs}
        testID={testId}
        header={
          <COREPageHeader
            breadcrumbs={breadcrumbs}
            extra={[
              <COREDropdownMenu
                key={`${testId}-dropdown`}
                testID={`${testId}-dropdown`}
                isDisabled={false}
                interactionsMenu={menu}
                placement={"bottomRight"}
              >
                <COREButton
                  testID={generateTestId(
                    "marketprices",
                    "carbon-toggle-menu-btn"
                  )}
                  icon={
                    <COREIcon icon={icon({ name: "bars", style: "regular" })} />
                  }
                  type={"primary"}
                  size={"lg"}
                />
              </COREDropdownMenu>,
            ]}
            extraResponsive={[
              {
                children: (
                  <ShowGenericProductsSwitch
                    genericProductsOnly={genericProductsOnly}
                    setGenericProducts={setGenericProducts}
                    testId={testId}
                  />
                ),
              },
              {
                children: (
                  <ShowDraftProjectsSwitch
                    showDraftProject={showDraftProject}
                    genericProductsOnly={genericProductsOnly}
                    setShowDraftProject={setShowDraftProject}
                    testId={testId}
                  />
                ),
              },
            ]}
            testID={`${testId}-header`}
            title={"Market Prices - Carbon"}
          />
        }
      >
        {children}
      </COREPageWrapper>
    );
  } else {
    return (
      <LiveMarketPageWrapper type={pageType}>{children}</LiveMarketPageWrapper>
    );
  }
};

const LiveMarketWidgetWrapper: React.FC<{
  type: string;
}> = ({ type, children }) => {
  return (
    <Row className={"widget-padding"}>
      <Col span={24} className={"live-price-overview"}>
        <COREHeading
          level={3}
          marginBottom={false}
          testID={generateTestId("marketprices", "widget-header")}
        >
          {`Market Prices - ${type}`}
        </COREHeading>
      </Col>
      <Col span={24}>
        <COREDivider space={"md"} type={"horizontal"}></COREDivider>
      </Col>
      <Col span={24}>{children}</Col>
    </Row>
  );
};

const LiveMarketPageWrapper: React.FC<{ type: string }> = ({
  type,
  children,
}) => {
  const breadcrumbs = [
    {
      title: "Market Prices",
      href: "/market-prices",
    },
    {
      title: "Environmental Certificates",
    },
  ];
  return (
    <div className={classNames("live-market-screen-page", "content-page")}>
      <COREPageWrapper
        breadcrumbs={breadcrumbs}
        testID={generateTestId(
          "marketprices",
          "user-live-market-widget-wrapper"
        )}
        header={
          <COREPageHeader
            breadcrumbs={breadcrumbs}
            testID={generateTestId(
              "marketprices",
              "user-live-market-widget-header"
            )}
            className={"text-capitalize"}
            title={`Market Prices - ${type}`}
          />
        }
      >
        {children}
      </COREPageWrapper>
    </div>
  );
};

export const LiveMarketWidget = ({
  products,
  type,
  showFirmAndIndicativeIfAdmin = false,
  disableInFocus = false,
}) => {
  return (
    <LiveMarketWidgetWrapper type={type}>
      <LiveMarketPrices
        products={products}
        type={type}
        showFirmAndIndicativeIfAdmin={showFirmAndIndicativeIfAdmin}
        disableInFocus={disableInFocus}
      />
    </LiveMarketWidgetWrapper>
  );
};

export const LiveMarketPage = ({
  products,
  type,
  showFirmAndIndicativeIfAdmin = false,
  disableInFocus = false,
  filtersOr = {},
}) => {
  const isAdmin: boolean = useUserIsAdmin();

  const [adminGenericProductsOnly, setGenericProducts] = useLocalStorage(
    "userGenericProductsOnly",
    true
  );
  const [showDraftProject, setShowDraftProject] = useLocalStorage(
    "userShowDraftProject",
    false
  );

  if (showFirmAndIndicativeIfAdmin && isAdmin) {
    return (
      <div className={`live-market-screen-page`}>
        <AdminPageWrapper
          pageType={type}
          genericProductsOnly={adminGenericProductsOnly}
          setGenericProducts={setGenericProducts}
          setShowDraftProject={setShowDraftProject}
          showDraftProject={showDraftProject}
        >
          <LiveMarketPrices
            products={products}
            type={type}
            showFirmAndIndicativeIfAdmin={showFirmAndIndicativeIfAdmin}
            disableInFocus={disableInFocus}
            adminGenericProductsOnly={adminGenericProductsOnly}
            showDraftProject={showDraftProject}
            filtersOr={filtersOr}
          />
        </AdminPageWrapper>
      </div>
    );
  }

  return (
    <LiveMarketPageWrapper type={type}>
      <LiveMarketPrices
        products={products}
        type={type}
        showFirmAndIndicativeIfAdmin={showFirmAndIndicativeIfAdmin}
        disableInFocus={disableInFocus}
        filtersOr={filtersOr}
      />
    </LiveMarketPageWrapper>
  );
};

const carbonPageWrapper = "carbon-page-wrapper";

const LiveMarketPrices = ({
  products,
  type,
  showFirmAndIndicativeIfAdmin = false,
  disableInFocus = false,
  adminGenericProductsOnly = true,
  showDraftProject = false,
  filtersOr = {},
}) => {
  const { features: featureList } = useFeatureFlags();
  const isAdmin: boolean = useUserIsAdmin();
  const currentPageTypeFeature =
    type === "carbon" ? "market-prices-carbon" : "market-prices-environmental";
  const checkFeature =
    featureList !== null && featureList.includes(currentPageTypeFeature);
  if (!checkFeature) return <Empty />;
  const priceTableProps: PriceTableProps = {
    products,
    type,
    theme: "w",
    disableInFocus,
    className: "no-tabs",
    testID: generateTestId("dashboard", "live-market-widget"),
    option: false,
    genericProductsOnly: true,
    showPublishedProjectOnly: false,
    filtersOr: filtersOr,
  };
  if (showFirmAndIndicativeIfAdmin && isAdmin) {
    priceTableProps.genericProductsOnly = adminGenericProductsOnly;
    priceTableProps.showPublishedProjectOnly = !adminGenericProductsOnly
      ? !showDraftProject
      : true;
    return (
      <div className={classNames("live-market-screen-page", "content-page")}>
        {!disableInFocus && <InFocusTable propsPriceTable={priceTableProps} />}
        <Content>
          <TableGroup
            key={"firm-group"}
            tableType={"firm"}
            {...priceTableProps}
          />
          <TableGroup
            key={"indicative-group"}
            tableType={"indicative"}
            {...priceTableProps}
          />
        </Content>
      </div>
    );
  }

  return (
    <>
      {!disableInFocus && <InFocusTable propsPriceTable={priceTableProps} />}
      <TableGroup {...priceTableProps} />
    </>
  );
};
