import React, { useState } from "react";
import { Col, Row, Space } from "antd";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./TradeTicker.less";
import { TestID, generateTestId } from "../../../shared/testids/testids";
import { useOpenClose } from "../../../shared/global";
import { COREModal } from "../../../COREDesignSystem/Overlay/COREModal";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { TradableProductTree } from "../../../shared/select/TradableProductTree";
import { TradeTickerFilterType } from "./TradeTickerFilter";
import { useDeepCompareEffect } from "react-use";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";

export const TradeTickerFilterModal: React.FC<{
  testID: TestID;
  isOpen: boolean;
  closer: ReturnType<typeof useOpenClose>["closer"];
  tradeTickerFilter: TradeTickerFilterType;
  onChangeFilter: (selectedFilter: TradeTickerFilterType) => void;
}> = ({ testID, isOpen, closer, tradeTickerFilter, onChangeFilter }) => {
  const [filter, setFilter] = useState<number[]>(
    tradeTickerFilter?.productType ?? []
  );
  const [onlyCurrent, setOnlyCurrent] = useState<boolean>(
    tradeTickerFilter.onlyCurrent ?? true
  );

  useDeepCompareEffect(() => {
    setFilter(tradeTickerFilter?.productType ?? []);
    setOnlyCurrent(tradeTickerFilter?.showAll ?? true);
  }, [tradeTickerFilter.productType]);

  const handleOnClear = () => {
    onChangeFilter({});
    setFilter([]);
    setOnlyCurrent(true);
    closer();
  };

  const handleOnCancel = () => {
    tradeTickerFilter?.productType && setFilter(tradeTickerFilter?.productType);
    setOnlyCurrent(true);
    closer();
  };

  const handleOnApply = () => {
    onChangeFilter({
      ...tradeTickerFilter,
      productType: filter,
      showAll: onlyCurrent,
    });
    closer();
  };

  const handleOnSwitch = (value: boolean) => {
    setFilter([]);
    setOnlyCurrent(value);
  };

  return (
    <COREModal
      testID={`${testID}-filter-modal`}
      type={"basic"}
      title={"Filter trades"}
      visible={isOpen}
      onCancel={handleOnCancel}
      destroyOnClose={true}
      className={"filter-modal"}
      footer={[
        <div className={"button"} key={"submit-button-block"}>
          <COREButton
            key="submit"
            size={"lg"}
            type="primary"
            testID={`${testID}-button-confirm-filter`}
            onClick={handleOnApply}
            icon={
              <COREIcon
                icon={icon({ name: "circle-check", style: "regular" })}
              />
            }
          >
            Apply
          </COREButton>
        </div>,
        <div className={"button"} key={"back-button-block"}>
          <COREButton
            key="back"
            size={"lg"}
            testID={`${testID}-button-cancel-filter`}
            onClick={handleOnCancel}
          >
            Cancel
          </COREButton>
        </div>,
      ]}
    >
      <Row>
        <Col flex="auto">
          <COREHeading
            level={4}
            marginBottom={false}
            testID={generateTestId("tradeticker", "filter-modal-heading")}
          >
            <Space size={8}>
              <COREIcon
                icon={icon({ name: "filter", style: "regular" })}
                className={"filter-icon"}
              />
              <span>Filter options</span>
            </Space>
          </COREHeading>
        </Col>
        <Col>
          <COREButton
            onClick={() => {
              handleOnClear();
            }}
            size={"sm"}
            icon={
              <COREIcon
                icon={icon({ name: "circle-xmark", style: "regular" })}
              />
            }
          >
            Clear all
          </COREButton>
        </Col>
      </Row>
      <COREDivider dashed={true} space={"sm"} />

      <TradableProductTree
        testID={generateTestId("tradeticker", "filter-by-product")}
        onlyCurrent={onlyCurrent}
        setOnlyCurrent={handleOnSwitch}
        showOnlyCurrentToggle={true}
        onChange={(i: number[]) => {
          setFilter(i);
        }}
        multiple={true}
        value={filter}
        search={true}
        tradeType={"firm"}
      />
    </COREModal>
  );
};
