import React from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { TestID } from "../../shared/testids/testids";
import { Merge } from "../../shared/TypeScriptHelpers";
import "./CORETypographyInput.less";

const { Text } = Typography;

export type CORETypographyInputProps = Merge<
  Pick<TextProps, "className" | "children">,
  {
    testID?: TestID;
    type: "default" | "label";
  }
>;

export const CORETypographyInput: React.FC<CORETypographyInputProps> = ({
  testID,
  type = "default",
  className,
  children,
}) => {
  return (
    <div className={"core-typography-input"}>
      <Text
        data-testid={testID}
        className={`${type} ${className ? className : ""}`}
      >
        {children}
      </Text>
    </div>
  );
};
