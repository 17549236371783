import React, { useEffect, useState } from "react";
import { OHLCProductType } from "../../../dashboards/widgets/marketPrices/OHLCPriceHistoryWidget";
import { COREError } from "../../../../COREDesignSystem/Content/COREError";
import {
  ContentWidgetProps,
  currentWidgetFilters,
  onSelectFilters,
} from "../../../dashboards/WidgetPanel";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import {
  PriceHistoryChart,
  PriceHistoryChartFilterValues,
} from "../PriceHistoryChart";

type OHLCPriceHistoryByProductTypeProps = ContentWidgetProps<
  OHLCProductType,
  PriceHistoryChartFilterValues
>;

export const OHLCPriceHistoryByProductType = ({
  widgetProps,
  widgetType: productType,
  testID,
  setOnDownloadPNG,
  setOnResetChart,
  setProgressPercent,
}: OHLCPriceHistoryByProductTypeProps) => {
  const defaultWidgetFilters = currentWidgetFilters(widgetProps)?.filters;
  const [widgetFilters, setWidgetFilters] = useState<
    PriceHistoryChartFilterValues | undefined
  >(defaultWidgetFilters ?? undefined);

  useEffect(() => {
    setWidgetFilters(defaultWidgetFilters);
  }, [defaultWidgetFilters]);

  if (!productType) return <COREError />;

  return (
    <PriceHistoryChart
      key={`${widgetProps.name}-${productType}`}
      localStorageKey={`${widgetProps.name}-${productType}`}
      isWidget
      empty={
        <COREEmpty
          description="No price history available"
          hint="Try reloading the page or expanding the chart controls to populate the chart."
          testID={`${testID}-empty-chart`}
        />
      }
      onWidgetFilter={(v) => {
        const filter = {
          ...v,
          productType: productType,
        } as PriceHistoryChartFilterValues;
        if (v?.method !== widgetFilters?.method) {
          setWidgetFilters({ ...filter, vintages: undefined });
          onSelectFilters(widgetProps, { ...filter, vintages: undefined });
        } else {
          setWidgetFilters(filter);
          onSelectFilters(widgetProps, filter);
        }
      }}
      productType={productType}
      widgetFilters={{
        productType: productType,
        method: "generic",
        zoomLevel: "1Y",
        ...widgetFilters,
      }}
      setOnDownloadPNG={setOnDownloadPNG}
      setOnResetChart={setOnResetChart}
      setProgressPercent={setProgressPercent}
    />
  );
};
