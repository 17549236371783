import { FeatureGroup, Popup, Marker } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { Icon, L, LatLngExpression } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import {
  convertLocation,
  COREMap,
  COREMapProps,
} from "../../../COREDesignSystem/Content/COREMap";
import { centralAustralia } from "../../../shared/global";
import "./ViewProjectsAsMap.less";
import "../../../COREDesignSystem/Content/COREMap.less";
import {
  getOfferSummary,
  GreenOfferDetailsType,
  OfferSummaryType,
} from "./ProjectMarketplaceContainer";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { CORETypographyInput } from "../../../COREDesignSystem/Typography/CORETypographyInput";
import { NumberFormat } from "../../../shared/globals";
import React, { useRef } from "react";
import { Merge } from "../../../shared/TypeScriptHelpers";
import { projectMarketplaceDetailsLink } from "./projectMarketplaceHelper";
import classNames from "classnames";

export type PopupElRefType = L.popup;

export type ViewProjectsAsMapProps = {
  carbonProjects: GreenOfferDetailsType[];
  allProjects: GreenOfferDetailsType[];
  className?: string;
  mapHeight: COREMapProps["mapHeight"];
  mapWidth: COREMapProps["mapWidth"];
};

export type ProjectWithLocationType = Merge<
  GreenOfferDetailsType,
  { area: number; location?: LatLngExpression }
>;

export type MarkerPopupGroupProps = {
  project: ProjectWithLocationType;
  popupElRef?: PopupElRefType;
  allProjects: GreenOfferDetailsType[];
};

const groupLocation = (projects) => {
  return projects.map((item) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!item["location"]) {
      const location = convertLocation(item["location"]);
      return {
        ...item,
        location: {
          lat: location.currentPos.lat,
          lng: location.currentPos.lng,
        },
        area: 50000,
      };
    } else {
      return null;
    }
  });
};

const Vintages: React.FC<{
  period?: string;
  countOffers: number;
  className?: string;
}> = ({ className, countOffers, period }) => {
  if (countOffers > 1) {
    return (
      <tr>
        <td>
          <COREBody marginBottom={false}>Vintages</COREBody>
        </td>
        <td>
          <COREBody marginBottom={false} className={className}>
            {NumberFormat(countOffers, 0)} available
          </COREBody>
        </td>
      </tr>
    );
  }
  return (
    <tr>
      <td>
        <COREBody marginBottom={false}>Vintage</COREBody>
      </td>
      <td>
        {period && (
          <COREBody marginBottom={false} className={className}>
            {period}
          </COREBody>
        )}
      </td>
    </tr>
  );
};

export const MarkerPopupGroup: React.FC<MarkerPopupGroupProps> = ({
  project,
  allProjects,
  popupElRef,
}) => {
  const history = useHistory();
  const position = project.location;
  const displayColumn = [
    { title: "Accreditation", column: "product_type_name" },
    { title: "Method", column: "certificate_project_type_name" },
    { title: "Status", column: "project_status" },
  ];
  const markerProps = {
    icon: new Icon({
      iconUrl: markerIconPng,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
    }),
  };

  const displayData = (data, columnName) => {
    return (data && data[columnName]) || "-";
  };

  const projects = allProjects.filter(
    (p: GreenOfferDetailsType) =>
      p.certificate_project === project.certificate_project
  );
  const offerSummary: OfferSummaryType = getOfferSummary(projects);

  return (
    <FeatureGroup pathOptions={{ color: "#2196F3" }}>
      <Popup ref={popupElRef}>
        <table>
          <tr>
            <th colSpan={2}>
              <CORETypographyInput type={"label"}>
                {project["name"]}
              </CORETypographyInput>
            </th>
          </tr>
          {displayColumn.map((item) => {
            return (
              <tr key={item.title}>
                <td>
                  <COREBody marginBottom={false}>{item.title} </COREBody>
                </td>
                <td>
                  <COREBody marginBottom={false}>
                    {displayData(project, item.column)}
                  </COREBody>
                </td>
              </tr>
            );
          })}
          <Vintages
            period={project.period}
            countOffers={offerSummary.countOffers}
          />
          <tr>
            <td>
              <COREBody marginBottom={false}>Availability </COREBody>
            </td>
            <td>
              {offerSummary.totalOffersVolume > 0 && (
                <COREBody marginBottom={false}>
                  {NumberFormat(offerSummary.totalOffersVolume, 0)} units
                </COREBody>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <COREBody marginBottom={false}>Offers from </COREBody>
            </td>
            <td>
              {offerSummary.minOffersPrice > 0 && (
                <COREBody marginBottom={false}>
                  ${NumberFormat(offerSummary.minOffersPrice, 2)}/unit
                </COREBody>
              )}
            </td>
          </tr>
        </table>
        {project.certificate_project && (
          <p
            className={"more-info"}
            onClick={() =>
              project.certificate_project &&
              history.push(
                projectMarketplaceDetailsLink(project.certificate_project)
              )
            }
          >
            MORE INFO
          </p>
        )}
      </Popup>
      <Marker position={position} {...markerProps} />
    </FeatureGroup>
  );
};

export const getFitBound = (projectsWithLocation) => {
  const boundsArr = projectsWithLocation?.map(({ location }) => {
    return [location.lat, location.lng];
  });

  if (boundsArr.length === 0) return { center: centralAustralia };

  return boundsArr.length === 1
    ? { center: boundsArr[0] }
    : { bounds: boundsArr };
};

export const ViewProjectsAsMap: React.FC<ViewProjectsAsMapProps> = ({
  carbonProjects,
  allProjects,
  className,
  mapHeight,
  mapWidth,
}) => {
  carbonProjects = carbonProjects.filter((p) => p.certificate_project !== null);
  const projectsWithLocation = groupLocation(carbonProjects).filter(
    (item) => item
  );

  const popupElRef = useRef<PopupElRefType>(null);
  if (popupElRef.current && popupElRef.current._close) {
    popupElRef.current._close();
  }

  return (
    <div className={classNames("project-view-map", className)}>
      <COREMap
        zoomLevel={4}
        mapHeight={mapHeight}
        mapWidth={mapWidth}
        readOnly={true}
        {...getFitBound(projectsWithLocation)}
      >
        {projectsWithLocation.map((item: ProjectWithLocationType) => {
          return (
            <MarkerPopupGroup
              key={item.certificate_project}
              project={item}
              allProjects={allProjects}
              popupElRef={popupElRef}
            />
          );
        })}
      </COREMap>
    </div>
  );
};
