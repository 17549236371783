import React, { ReactNode, useEffect } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import LeafletMap, { Icon, LatLngExpression } from "leaflet";
import "./COREMap.less";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { GestureHandling } from "leaflet-gesture-handling";
// import "leaflet-geosearch/dist/geosearch.css";
// import "leaflet/dist/leaflet.css";
import "leaflet.fullscreen/Control.FullScreen";
// import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "../../modules-css/geosearch.less";
import "../../modules-css/leaflet.less";
import "../../modules-css/Control.FullScreen.less";
import "../../modules-css/leaflet-gesture-handling.less";
import { mapPositionType } from "../../modules/brokerAdmin/certificateProjects/manageProjects/addEditProjects/AddProject";

export type COREMapProps = {
  centerLatitude?: number;
  centerLongtitude?: number;
  zoomLevel: number;
  mapHeight: string;
  mapWidth: string;
  readOnly?: boolean;
  setPosition?: ([lat, lng]: LatLngExpression) => void;
  position?: mapPositionType;
  onClick?: () => void;
  showSearch?: boolean;
  enableFullscreen?: boolean;
  children?: ReactNode;
};

type SearchControlProps = Pick<COREMapProps, "setPosition" | "onClick">;

export const convertLocation = (location) => {
  const lngLat: string[] = location
    ?.replace("(", "")
    .replace(")", "")
    .split(",");
  const longtitude: number = parseFloat(lngLat[0]);
  const latitude: number = parseFloat(lngLat[1]);
  return {
    currentPos: {
      lat: latitude,
      lng: longtitude,
    },
  };
};

const SearchControl: React.FC<SearchControlProps> = ({
  setPosition,
  onClick,
}) => {
  const handleMarker = (e) => {
    setPosition && setPosition([e.latlng.lat, e.latlng.lng]);
  };

  const map = useMap();
  useMapEvents({ click: handleMarker });
  const searchControl = GeoSearchControl({
    provider: new OpenStreetMapProvider(),
    animateZoom: true,
    showMarker: false,
    searchLabel: "Please enter your address",
    notFoundMessage: "Sorry, that address could not be found.",
    style: "bar",
    autoCompleteDelay: 0,
    marker: {
      icon: new Icon({
        iconUrl: markerIconPng,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
    },
  });

  useEffect(() => {
    map.addControl(searchControl);
    map.on("geosearch/showlocation", ({ location }) => {
      setPosition && setPosition([location.y, location.x]);
      onClick && onClick();
    });
    if (map.getContainer()) {
      map.getContainer().onclick = () => {
        onClick && onClick();
      };
    }

    return () => map.removeControl(searchControl);
  }, [map, onClick, searchControl, setPosition]);

  return null;
};

const GestureHandlingSetter: React.FC = () => {
  const map = useMap();
  map.gestureHandling?.enable();
  map.addHandler("gestureHandling", GestureHandling);
  return null;
};

export const COREMap: React.FC<COREMapProps> = ({
  centerLatitude,
  centerLongtitude,
  zoomLevel,
  mapHeight,
  mapWidth,
  readOnly = false,
  setPosition,
  position,
  onClick,
  showSearch = false,
  enableFullscreen = true,
  children,
  ...otherProps
}) => {
  const markerProps = {
    icon: new Icon({
      iconUrl: markerIconPng,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
    }),
  };

  otherProps = {
    ...otherProps,
    ...(centerLatitude &&
      centerLongtitude && { center: [centerLatitude, centerLongtitude] }),
    zoom: zoomLevel,
    scrollWheelZoom: true,
    fullscreenControl: enableFullscreen,
    fullscreenControlOptions: {
      position: "topleft",
    },
  };

  const setupMap = (map: LeafletMap) => {
    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });
    const container = document.getElementById("leafletmap-map-container");
    resizeObserver.observe(container!);
  };

  return (
    <div
      id="leafletmap"
      style={{ height: mapHeight, width: mapWidth }}
      className={"leaflet-main-block"}
    >
      <MapContainer
        id="leafletmap-map-container"
        whenCreated={setupMap}
        {...otherProps}
      >
        <GestureHandlingSetter />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {children}

        {showSearch && (
          <SearchControl setPosition={setPosition} onClick={onClick} />
        )}

        {position?.currentPos.lat && position?.currentPos.lng && (
          <Marker position={position?.currentPos} {...markerProps} />
        )}
      </MapContainer>
    </div>
  );
};
