import React, { ComponentProps, FC, useContext } from "react";
import { Col, DatePicker, Row } from "antd";
import moment, { Moment } from "moment";
import "./CORERangePicker.less";
import "./COREDatePicker.less";
import {
  DEPRECATEDDateFormatContextStandard,
  DEPRECATEDDateFormatMarket,
} from "../../shared/date/DateFormatContext";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { WidthSize } from "./COREInput";
import classNames from "classnames";
import { COREButton } from "../Action/COREButton";
import {
  Bounds,
  MaybeInfRange,
  NonEmptyRange,
  Range,
  convertRangeToClosedInterval,
  createRange,
} from "../../shared/date/ranges";
import { CORERadiobuttonGroup } from "./CORERadioButton";
import { CORESizeToAntSize, SizeDatePicker } from "./COREDatePicker";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CORETooltip } from "../Overlay/CORETooltip";

const { RangePicker } = DatePicker;
type RangePickerProps = ComponentProps<typeof RangePicker>;
export type RangeValue = Required<RangePickerProps["value"]>;

export type RelativeRangeValue =
  | {
      isRelative: true;
      from: number | undefined;
      to: number | undefined;
    }
  | ({
      isRelative: false;
    } & {
      from: Moment | undefined;
      to: Moment | undefined;
      bounds: Bounds;
    });

export const convertRelativeRangeToNonEmptyRange = (
  relativeRange: RelativeRangeValue
): NonEmptyRange => {
  const range = getDateFromRelativeDateRange(relativeRange);
  if (!range || !range[0] || !range[1])
    throw new Error("Relative range is undefined");
  if (relativeRange.isRelative) {
    return createRange(range[0], range[1], "[]");
  }

  return createRange(range[0], range[1], relativeRange.bounds);
};

export const getDateFromRelativeDateRange = (
  value: RelativeRangeValue
): RangeValue => {
  const { isRelative, from, to } = value;
  if (isRelative && typeof from == "number" && typeof to == "number") {
    const time1 = moment().startOf("day").add(from, "day");
    const time2 = moment().startOf("day").add(to, "day");
    return [time1, time2] as RangeValue;
  } else {
    return [moment(value.from), moment(value.to)] as RangeValue;
  }
};

const fromRangePickerValue = (
  isRelative: boolean,
  dates: RangeValue,
  currentBounds: Bounds | undefined
): RelativeRangeValue => {
  const [from, to] = dates || [undefined, undefined];
  let returnValue;
  if (from === undefined || to === undefined) {
    return {
      isRelative: isRelative,
      from: undefined,
      to: undefined,
      bounds: currentBounds ?? "[]",
    };
  } else {
    if (isRelative) {
      returnValue = {
        isRelative: isRelative,
        from: from?.diff(moment().startOf("day"), "day"),
        to: to?.diff(moment().startOf("day"), "day"),
      };
    } else {
      returnValue = {
        isRelative: isRelative,
        from: from,
        to: to,
        bounds: currentBounds ?? "[]",
      };
    }
  }
  return returnValue;
};

type CORERangePickerType = Omit<
  RangePickerProps,
  "defaultValue" | "value" | "onChange" | "size" | "disabled"
> & {
  testID: TestID;
  onChange?: (value: RelativeRangeValue) => void | Promise<void> | Function;
  extended?: boolean;
  relativeToggle?: boolean;
  defaultValue?: RelativeRangeValue;
  value?: RelativeRangeValue;
  widthSize?: WidthSize;
  size?: SizeDatePicker;
  disabled?: boolean;
};

const getNormalizedValue = (
  value?: RelativeRangeValue
): RelativeRangeValue | undefined => {
  if (!value || value.isRelative) return value;

  const newValue = convertRangeToClosedInterval({
    from: value?.from,
    to: value?.to,
    bounds: value.bounds,
  });

  if (newValue === "empty") return undefined;

  return {
    isRelative: value.isRelative,
    from: newValue.from,
    to: newValue.to,
    bounds: newValue.bounds,
  };
};

export const customizePickerIcon = (): {
  separator: RangePickerProps["separator"];
  prevIcon: RangePickerProps["prevIcon"];
  superPrevIcon: RangePickerProps["superPrevIcon"];
  nextIcon: RangePickerProps["nextIcon"];
  superNextIcon: RangePickerProps["superNextIcon"];
} => {
  return {
    separator: (
      <COREIcon icon={icon({ name: "arrows-left-right", style: "regular" })} />
    ),
    prevIcon: (
      <COREIcon icon={icon({ name: "angle-left", style: "regular" })} />
    ),
    superPrevIcon: (
      <COREIcon icon={icon({ name: "angles-left", style: "regular" })} />
    ),
    nextIcon: (
      <COREIcon icon={icon({ name: "angle-right", style: "regular" })} />
    ),
    superNextIcon: (
      <COREIcon icon={icon({ name: "angles-right", style: "regular" })} />
    ),
  };
};

export const CORERangePicker: FC<CORERangePickerType> = ({
  testID,
  allowClear = false,
  extended,
  onChange,
  defaultValue,
  value: v,
  relativeToggle = true,
  widthSize = "lg",
  className,
  dropdownClassName,
  size = "md",
  disabled,
  ...props
}) => {
  const vDefault = v ?? defaultValue;
  const value = getNormalizedValue(vDefault);

  const onChangeIfRequired = (updates) => {
    if (onChange) onChange(updates);
  };
  const momentFormatStandard = useContext(DEPRECATEDDateFormatContextStandard);
  const momentFormatDateMarket = useContext(DEPRECATEDDateFormatMarket);

  const rangePickerDefaultValue =
    defaultValue === undefined
      ? undefined
      : getDateFromRelativeDateRange(defaultValue);
  const rangePickerValue =
    value === undefined ? undefined : getDateFromRelativeDateRange(value);
  const isRelative = value?.isRelative ?? false;
  const currentBounds = value?.isRelative ? undefined : value?.bounds;

  function setDates(momentDates) {
    if (momentDates === null) {
      momentDates = [undefined, undefined];
    }
    onChangeIfRequired(
      fromRangePickerValue(isRelative, momentDates, currentBounds)
    );
  }

  function toggleRelative() {
    onChangeIfRequired(
      fromRangePickerValue(!isRelative, rangePickerValue, currentBounds)
    );
  }

  const ToolTipChild = () => {
    return relativeToggle ? (
      <COREButton
        onClick={toggleRelative}
        icon={
          isRelative ? (
            <COREIcon icon={icon({ name: "lock-open", style: "regular" })} />
          ) : (
            <COREIcon icon={icon({ name: "lock", style: "regular" })} />
          )
        }
        size={size}
        disabled={disabled}
      />
    ) : (
      <></>
    );
  };

  const defaultProps = {
    suffixIcon: (
      <>
        <div className={"default-suffix-icon"}>
          <COREIcon icon={icon({ name: "calendar", style: "regular" })} />
        </div>
        <div className={"solid-suffix-icon"}>
          <COREIcon icon={icon({ name: "calendar", style: "solid" })} />
        </div>
      </>
    ),
    clearIcon: (
      <COREIcon icon={icon({ name: "circle-xmark", style: "solid" })} />
    ),
    ...props,
    size: CORESizeToAntSize[size],
    className: classNames(
      className,
      "date-to-on-right",
      `input-width-${widthSize}`
    ),
    dropdownClassName: classNames(
      dropdownClassName,
      "core-datepicker-dropdown"
    ),
  };

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!value)
    defaultProps.className = classNames(defaultProps.className, "has-value");

  return (
    <>
      <TestIDWrapper testID={testID} className={"core-datepicker"}>
        <Row gutter={[8, 8]}>
          <Col>
            <RangePicker
              defaultValue={rangePickerDefaultValue}
              value={rangePickerValue}
              onChange={setDates}
              allowClear={allowClear}
              disabled={disabled}
              format={
                allowClear
                  ? momentFormatDateMarket.format
                  : momentFormatStandard.format
              }
              {...customizePickerIcon()}
              {...defaultProps}
            />
          </Col>
          {extended && (
            <Col>
              <ExtendedDateRangeButtons
                value={rangePickerValue}
                onChange={({ target: { value: interval } }) => {
                  setDates(getIntervalDates(interval));
                }}
                testID={testID}
                size={size}
                disabled={disabled}
              />
            </Col>
          )}

          {relativeToggle && (
            <Col>
              <CORETooltip
                title={isRelative ? "Relative date" : "Fixed date"}
                message={
                  isRelative
                    ? "This option rolls forward the chosen date range, keeping the end date selection as 'today'."
                    : "This option locks the range to the selected dates and wont change once set."
                }
                position={"top"}
                testID={`${testID}-relative-tooltip`}
                overlay
              >
                {ToolTipChild()}
              </CORETooltip>
            </Col>
          )}
        </Row>
      </TestIDWrapper>
    </>
  );
};

const getIntervalDates = (interval) => {
  const startDate =
    interval === "day"
      ? moment()
      : moment().subtract(1, interval).add(1, "day").startOf("day");
  const endDate = moment().startOf("day");
  return [startDate, endDate];
};

const values = [
  { value: "day", label: "D" },
  { value: "week", label: "W" },
  { value: "month", label: "M" },
  { value: "quarter", label: "Q" },
  { value: "year", label: "Y" },
];

const ExtendedDateRangeButtons: React.FC<{
  onChange: (target: any) => void;
  value: RangeValue;
  testID: TestID;
  size?: CORERangePickerType["size"];
  disabled?: CORERangePickerType["disabled"];
}> = ({ onChange, value, testID, size = "md", disabled }) => {
  const [startDate, endDate] = value || [];
  const radioValue = values.find((v) => {
    const [radioStartDate, radioEndDate] = getIntervalDates(v.value);
    return (
      radioStartDate.isSame(startDate, "day") &&
      radioEndDate.isSame(endDate, "day")
    );
  })?.value;

  return (
    <CORERadiobuttonGroup
      value={radioValue}
      onChange={onChange}
      buttonStyle={"solid"}
      optionType={"button"}
      size={CORESizeToAntSize[size]}
      testID={`${testID}-radio-button-group`}
      radioBtnValues={values.map((v) => {
        return {
          name: v.label,
          value: v.value,
          disabled: disabled,
          testID: `${testID}-radio-button-group-${v.value}`,
        };
      })}
    ></CORERadiobuttonGroup>
  );
};

export type DateLimit = {
  past: number | undefined;
  future: number | undefined;
};

export type CORERangePickerV2Props = Omit<
  RangePickerProps,
  "size" | "value" | "onChange"
> & {
  testID: TestID;
  size?: SizeDatePicker;
  dateLimit?: DateLimit;
  value?: Range;
  onChange?: (value?: Range | null) => void;
  widthSize?: WidthSize;
};

export const CORERangePickerV2: FC<CORERangePickerV2Props> = ({
  testID,
  allowClear = false,
  value,
  size = "md",
  onChange,
  dateLimit,
  className,
  dropdownClassName,
  widthSize,
  ...props
}) => {
  const momentFormatStandard = useContext(DEPRECATEDDateFormatContextStandard);
  const momentFormatDateMarket = useContext(DEPRECATEDDateFormatMarket);
  let inputValue: RangeValue = null;
  if (value) {
    const closedIntervalRange = convertRangeToClosedInterval(
      value
    ) as MaybeInfRange;
    inputValue = [
      closedIntervalRange?.from,
      closedIntervalRange?.to,
    ] as RangeValue;
  }
  const today = moment().startOf("day");
  const hasLimitDaysAgo = dateLimit && (dateLimit.past || dateLimit.past === 0);
  const hasLimitDaysAhead =
    dateLimit && (dateLimit.future || dateLimit.future === 0);
  const limitDaysAgo = today
    .clone()
    .subtract(dateLimit?.past, "days")
    .startOf("day");
  const limitDaysAhead = today
    .clone()
    .add(dateLimit?.future, "days")
    .startOf("day");
  const disabledDate = (current: Moment) => {
    const currentDay = current.startOf("day");
    switch (true) {
      case hasLimitDaysAgo && hasLimitDaysAhead:
        return currentDay < limitDaysAgo || currentDay > limitDaysAhead;

      case hasLimitDaysAgo:
        return currentDay < limitDaysAgo;

      case hasLimitDaysAhead:
        return currentDay > limitDaysAhead;

      default:
        return false;
    }
  };

  const defaultProps: RangePickerProps = {
    suffixIcon: (
      <>
        <div className={"default-suffix-icon"}>
          <COREIcon icon={icon({ name: "calendar", style: "regular" })} />
        </div>
        <div className={"solid-suffix-icon"}>
          <COREIcon icon={icon({ name: "calendar", style: "solid" })} />
        </div>
      </>
    ),
    clearIcon: (
      <COREIcon icon={icon({ name: "circle-xmark", style: "solid" })} />
    ),
    separator: (
      <COREIcon icon={icon({ name: "arrows-left-right", style: "regular" })} />
    ),
    size: CORESizeToAntSize[size],
    className: classNames(
      className,
      "date-to-on-right",
      `input-width-${widthSize}`
    ),
    dropdownClassName: classNames(
      dropdownClassName,
      "core-datepicker-dropdown"
    ),
  };

  return (
    <TestIDWrapper testID={testID} className={"core-datepicker"}>
      <RangePicker
        format={
          allowClear
            ? momentFormatDateMarket.format
            : momentFormatStandard.format
        }
        disabledDate={dateLimit !== undefined ? disabledDate : undefined}
        value={inputValue}
        allowClear={allowClear}
        onChange={(dates) => {
          const [start, end] = dates ?? [undefined, undefined];
          onChange &&
            onChange(
              start
                ? {
                    from: start as Moment,
                    to: end as Moment,
                    bounds: "[]",
                  }
                : null
            );
        }}
        {...defaultProps}
        {...props}
      />
    </TestIDWrapper>
  );
};
