import React from "react";
import { CFDLiveMarketScreen } from "./CFDLiveMarketScreen";
import { Empty } from "antd";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";

export const REHUBMarketWidget = () => {
  const { sync, loading, features: featureList } = useFeatureFlags();
  if (loading || sync) return <Empty />;
  const isMarketPriceFeature =
    featureList !== null && featureList.includes("market-prices-rehub");
  return isMarketPriceFeature ? (
    <CFDLiveMarketScreen type={"rehub"} disableInFocus={true} />
  ) : (
    <Empty />
  );
};
