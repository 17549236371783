import React from "react";
import { COREChart } from "../../../COREDesignSystem/Chart/COREChart";
import { Empty } from "antd";
import "./ChartBuilder.less";
import moment from "moment-timezone";
import ColorHash from "color-hash";
import { prepareChartColorV1 } from "../../../COREDesignSystem/Content/COREColour";
import { useChartBuilder } from "./useChartBuilderApi";
import sizeMe from "react-sizeme";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";
import { COREProgress } from "../../../COREDesignSystem/Feedback/COREProgress";
import { camelize } from "../../../shared/globals";

let colorHash = new ColorHash();
const defPositionStep = 0.08;
export const getUniqueAxisLabel = (rawData) => {
  let axisLeft = [];
  let axisRight = [];
  const leftSide = rawData.filter((i) => i.chartOptions.axisSide === "left");
  const rightSide = rawData.filter((i) => i.chartOptions.axisSide === "right");

  leftSide.forEach((item) => {
    const {
      data,
      chartOptions: { axisTitle, traceName },
    } = item;

    if (data !== undefined && data.length > 0) {
      const ind = axisLeft.findIndex((i) => i.axisTitle === axisTitle);
      if (ind === -1) {
        axisLeft.push({
          axisTitle,
          traceNames: [traceName],
        });
      } else {
        axisLeft[ind].traceNames.push(traceName);
      }
    }
  });

  rightSide.forEach((item) => {
    const {
      data,
      chartOptions: { axisTitle, traceName },
    } = item;

    if (data !== undefined && data.length > 0) {
      if (leftSide.length === 0 && axisLeft.length === 0) {
        axisLeft.push({
          axisTitle,
          traceNames: [traceName],
        });
      } else {
        const ind = axisRight.findIndex((i) => i.axisTitle === axisTitle);
        if (ind === -1) {
          axisRight.push({
            axisTitle,
            traceNames: [traceName],
          });
        } else {
          axisRight[ind].traceNames.push(traceName);
        }
      }
    }
  });
  return { left: [...new Set(axisLeft)], right: [...new Set(axisRight)] };
};

const getXDomain = (axisLabels) => {
  let leftDomain = null;
  let rightDomain = null;
  if (axisLabels) {
    let leftAxisLength = axisLabels.left.length;

    if (leftAxisLength === 0) {
      leftAxisLength = 1;
    }

    leftDomain = leftAxisLength * 0.075;
    rightDomain = 1 - axisLabels.right.length * 0.075;
  }

  return [leftDomain, rightDomain];
};
export const getChartAxis = (rawData, uniqueAxis) => {
  const xd = getXDomain(uniqueAxis);
  let yAxisArray = [];
  let lastLeft = 0;
  let lastRight = 0;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const generateYAxis = (items, axisSide) => {
    const { axisTitle, traceNames } = items;

    const axisIndex = yAxisArray.findIndex(
      (item) => item.title.text === axisTitle
    );
    if (axisIndex === -1) {
      const xPosition = axisSide === "left" ? xd[0] : xd[1];
      const sideItems = yAxisArray.filter((i) => i.side === axisSide).length;
      let newPs = xPosition;
      let anchor, overlaying;
      const newAxis =
        yAxisArray.length === 0 ? "yaxis" : `yaxis${yAxisArray.length + 1}`;
      if (yAxisArray.length === 0 && sideItems === 0) {
        if (axisSide === "left") {
          lastLeft = newPs;
          yAxisArray.push({
            axisKey: newAxis,
            traceName: [...traceNames],
            title: {
              text: axisTitle,
              standoff: 40,
            },
            tickformat: ",.2f",
            zeroline: false,
            rangemode: "tozero",
            autorange: true,
            showgrid: false,
            showline: true,
          });
        }
      } else {
        if (axisSide === "right" && sideItems === 0) {
          lastRight = newPs;
          yAxisArray.push({
            axisKey: newAxis,
            traceName: [...traceNames],
            title: {
              text: axisTitle,
              standoff: 10,
            },
            side: "right",
            overlaying: "y",
            tickformat: ",.2f",
            zeroline: true,
            rangemode: "tozero",
            autorange: true,
            showgrid: false,
            showline: true,
          });
        } else {
          if (axisSide === "left") {
            newPs = lastLeft - defPositionStep;
            anchor = "free";
            overlaying = "y";
            lastLeft = newPs;
          } else {
            newPs = lastRight + defPositionStep;
            anchor = "free";
            overlaying = "y";
            lastRight = newPs;
          }

          yAxisArray.push({
            axisKey: newAxis,
            traceName: [...traceNames],
            title: {
              text: axisTitle,
              standoff: 10,
            },
            side: axisSide,
            anchor: anchor,
            overlaying: overlaying,
            position: newPs,
            tickformat: ",.2f",
            zeroline: false,
            rangemode: "tozero",
            autorange: true,
            showgrid: false,
            showline: true,
          });
        }
      }
    }
  };

  uniqueAxis.left.forEach((item) => {
    generateYAxis(item, "left");
  });

  uniqueAxis.right.forEach((item) => {
    generateYAxis(item, "right");
  });

  return yAxisArray;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const generateChartData = (apiData, hasTodayBreak, todayDate, axisState) => {
  const chartData = [];
  if (apiData !== undefined) {
    const chartDataColor = prepareChartColorV1(apiData);
    if (chartDataColor !== undefined && chartDataColor.length > 0) {
      for (let key in chartDataColor) {
        if (
          !chartDataColor[key].hasOwnProperty("data") ||
          chartDataColor[key].data === undefined
        )
          continue;
        const {
          data,
          chartOptions: { traceName, treeName, "line.color": itemColor },
        } = chartDataColor[key];
        if (data === undefined) continue;
        let dataAxisKey = "y";
        const axisStateItem = axisState.find((item) => {
          return item.traceName.includes(traceName);
        });
        if (axisStateItem) {
          dataAxisKey = axisStateItem.axisKey.toString().replace("axis", "");
        }

        const [parent, child] = traceName.split(" ");
        const colour = itemColor
          ? itemColor
          : parent
          ? colorHash.hex(`${parent}${child}${treeName}`)
          : colorHash.hex(moment().valueOf().toString());
        const chartMode = data.length === 1 ? "markers" : "lines+points";
        const markerStyle = chartMode === "markers" ? { size: 10 } : {};
        if (hasTodayBreak) {
          const halfLeftData = data.filter(({ date }) => date <= todayDate);
          const hx = halfLeftData.map((d) => d.date);
          const hy = halfLeftData.map((d) => d.value);
          chartData.push({
            name: traceName,
            x: hx,
            y: hy,
            type: "scatter",
            mode: chartMode,
            line: { width: 2 },
            marker: { color: colour, ...markerStyle },
            yaxis: dataAxisKey,
            showlegend: true,
            hoverlabel: { namelength: -1 },
          });

          const halfRightData = data.filter(({ date }) => date >= todayDate);
          const x = halfRightData.map((d) => d.date);
          const y = halfRightData.map((d) => d.value);

          chartData.push({
            name: traceName,
            x: x,
            y: y,
            type: "scatter",
            mode: chartMode,
            line: { dash: "dot", width: 1.5 },
            showlegend: false,
            marker: { color: colour, ...markerStyle },
            yaxis: dataAxisKey,
            hovertemplate: "%{y:,.2f}",
            hoverlabel: { namelength: -1 },
          });
        } else {
          const x = data.map((d) => d.date);
          const y = data.map((d) => d.value);

          chartData.push({
            name: traceName,
            x: x,
            y: y,
            type: "scatter",
            mode: chartMode,
            marker: { color: colour, ...markerStyle },
            yaxis: dataAxisKey,
            showlegend: true,
            hovertemplate: "%{y:,.2f}",
            hoverlabel: { namelength: -1 },
          });
        }
      }
    }
  }
  return chartData;
};

const getTodayDate = (aggPeriod) =>
  aggPeriod === "none" || aggPeriod === "half-hour"
    ? moment().tz("Australia/Brisbane")
    : moment().tz("Australia/Brisbane").startOf("day");

const hasTodayDate = (aggPeriod, [from, to]) =>
  getTodayDate(aggPeriod).isBetween(from, to.add(1, "days"));

const variableCheck = (variable) => {
  if (variable && variable.includes("trades")) return "trades";
  return variable;
};

export const hasDownloadFeatureForTrees = (trees, featureList) => {
  return trees.every((tree) => {
    const treeName = tree.treeName;
    const variable = variableCheck(camelize(tree.variable));
    return featureList.some((feature) => {
      return treeName === "productTree" &&
        (variable === "brokerCurve" || variable === "trades")
        ? feature === `chart-builder-csv-${treeName}-${variable}`
        : feature === `chart-builder-csv-${treeName}`;
    });
  });
};

const emptyDataCheck = (data) => {
  if (data.length === 0) return true;
  return data.every((datalist) => {
    return (
      (datalist.x?.length === 0 && datalist.y?.length === 0) ||
      !datalist.y?.some((v) => v !== null)
    );
  });
};

export const AggregatesChart = sizeMe({ noPlaceholder: true })(
  ({
    chartId,
    size: { width },
    configTitle: chartTitle,
    empty,
    dateRange,
    aggPeriod,
    apiConfig,
  }) => {
    const { progress, data: apiData, loading } = useChartBuilder(apiConfig);
    const { sync: featuresSync, features: featureList } = useFeatureFlags();
    const trees = apiConfig.map(({ chartOptions: { treeName, variable } }) => ({
      treeName,
      variable,
    }));
    const downloadCSV =
      featuresSync && apiConfig
        ? hasDownloadFeatureForTrees(trees, featureList)
        : false;
    if (apiData === undefined) {
      return (
        <div className={"chart-builder-empty-chart"}>
          <Empty description={"No data for selection(s)."} />
        </div>
      );
    }

    const hasTodayBreak = hasTodayDate(aggPeriod, dateRange);
    const todayDate = getTodayDate(aggPeriod).format();
    const uniqueAxis = getUniqueAxisLabel(apiData);
    const axisState = getChartAxis(apiData, uniqueAxis);
    const chartData = generateChartData(
      apiData,
      hasTodayBreak,
      todayDate,
      axisState
    );
    const todaySplitShape =
      hasTodayBreak && chartData.length > 0
        ? {
            shapes: [
              {
                type: "line",
                xref: "x",
                yref: "paper",
                x0: todayDate,
                y0: 0,
                x1: todayDate,
                y1: 1,
                opacity: 1,
                line: { dash: "dot", width: 1, color: "#ff0000" },
              },
            ],
          }
        : {};

    let chartAxis = [];
    axisState.forEach((item) => {
      chartAxis[item.axisKey] = item;
    });

    const fontFamily = {
      family: 'Inter", sans-serif',
    };

    const chartLayout = {
      font: {
        family: fontFamily,
      },
      title: `<b>${chartTitle}</b>`,
      titlefont: {
        family: fontFamily,
        size: width > 600 ? 20 : 14,
      },
      margin: {
        t: 50,
      },
      yaxis: {
        font: {
          family: fontFamily,
        },
        zeroline: false,
        rangemode: "tozero",
        autorange: true,
        showgrid: false,
        showline: true,
      },
      xaxis: {
        font: {
          family: fontFamily,
        },
        zeroline: false,
        rangemode: "tozero",
        autorange: true,
        showgrid: false,
        showline: true,
        domain: getXDomain(uniqueAxis),
        title: {
          text: "Time Starting",
          standoff: 50,
        },
      },
      legend: {
        font: {
          family: fontFamily,
        },
        orientation: "h",
        x: 0,
        y: -0.4,
      },
      ...chartAxis,
      ...todaySplitShape,
    };

    if (emptyDataCheck(chartData)) return empty;

    return (
      <>
        <COREProgress hideOnEmpty={!loading} percent={progress.percent} />
        <COREChart
          chartId={chartId}
          downloadCsv={downloadCSV}
          data={chartData}
          layout={chartLayout}
          useResizeHandler={true}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
          empty={empty}
          showTimeButtons={false}
        />
      </>
    );
  }
);
