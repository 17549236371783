/* eslint-disable react/prop-types */
import React, { forwardRef, useState } from "react";
import { Alert, Checkbox, Empty } from "antd";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import {
  useAPIQuery,
  useAPIQueryPagination,
} from "../../../shared/customHoooks/useAPI";
import {
  generateTree,
  Key,
  CORETreeData,
} from "../../../COREDesignSystem/Form/CORETree";
import { sortByRegions } from "../../../shared/globals";
import { capitaliseEachWord } from "../../../shared/stringUtil";
import {
  cfdTreeType,
  COREAsyncTree,
  updateTreeData,
  useCOREAsyncTree,
} from "../../../COREDesignSystem/Form/COREAsyncTree";
import { Trace } from "./AggregatesChartV2";
import "../../../COREDesignSystem/Form/CORETree.less";

type TimeseriesElectricityProduct = {
  multiple: boolean;
  onlyCurrent: boolean;
  showOnlyCurrentToggle: boolean;
  onChange: (change: Trace[], value: Trace[], checkedKeys: string) => void;
  checkedKeys: string[];
  includeAgg?: boolean;
  optionType: "firm" | "option";
};

const fields = ["Broker Curve", "Trades (Price)", "Trades (Volume)"];

const setParam = (onlyCurrent) => {
  let params = {
    cfd_product: `not.is.null`, // eslint-disable-line camelcase,
    trade_type: `eq.firm`, // eslint-disable-line camelcase,
  };
  if (onlyCurrent) {
    params["is_active"] = `eq.true`;
  }
  return params;
};

const onLoadEvent = (filterTradeProduct, includeAgg) => {
  let cfdTree: cfdTreeType[] = generateTree(
    filterTradeProduct,
    ["name", "region", "vintage", "tenor", "field"],
    "value",
    includeAgg
  );

  return sortByRegions(cfdTree[0]?.children);
};

const ElectricityProductTree = ({
  shapeTypes,
  tradeProductData,
  onChange: parentOnChange,
  ref,
  includeAgg = false,
  ...otherProps
}) => {
  const [treeData, setTreeData] =
    useState<CORETreeData<cfdTreeType>>(shapeTypes);

  const onLoad = ({ key }: { key: Key }) => {
    const filterTradeProduct = tradeProductData.filter((d) => d.name === key);

    if (!filterTradeProduct.length) {
      return;
    }

    setTreeData((origin) => {
      const childrenNodeData = onLoadEvent(filterTradeProduct, includeAgg);
      return updateTreeData(origin, key, childrenNodeData);
    });
  };

  const cfdTreeState = useCOREAsyncTree(treeData, {
    onChange: parentOnChange,
    initialRootValues: undefined,
  });

  return (
    <COREAsyncTree
      onLoad={onLoad}
      ref={ref}
      {...cfdTreeState}
      {...otherProps}
    />
  );
};

const setupTopLevelData = (dataShapeTypes) => {
  const resultShapeTypes: cfdTreeType[] = dataShapeTypes.map((item) => {
    return {
      title: capitaliseEachWord(item.name),
      key: capitaliseEachWord(item.name),
      path: [capitaliseEachWord(item.name)],
      children: [],
      isLeaf: false,
      checkable: false,
    };
  });

  return resultShapeTypes;
};

const flatProduct = (tradeProductData) => {
  return tradeProductData.flatMap((cfdp) => {
    const node = {
      name: cfdp.isCap
        ? cfdp.cfdProduct.shape.type === "flat"
          ? "$300 Cap"
          : capitaliseEachWord(cfdp.cfdProduct.shape.type) + " cap"
        : capitaliseEachWord(cfdp.cfdProduct.shape.type),
      tenor: cfdp.cfdProduct.tenor.name,
      vintage: cfdp.vintage,
      region: cfdp.cfdProduct.shape.region.short_name,
      id: cfdp.id,
    };
    const allowFields =
      cfdp.isCap && cfdp.cfdProduct.shape.type === "flat"
        ? fields.filter((f) => f !== "Broker Curve")
        : fields;
    return allowFields.map((f) => ({
      ...node,
      field: f,
      value: {
        id: node.id,
        variable: f,
        traceName: `${node.name} ${node.region} ${node.vintage} ${node.tenor} ${f}`,
      },
    }));
  });
};

export const TimeseriesElectricityProductTree = React.memo(
  forwardRef(
    (
      {
        multiple = false,
        includeAgg = false,
        onlyCurrent: oc = true,
        showOnlyCurrentToggle = true,
        onChange,
        checkedKeys,
        ...otherProps
      }: TimeseriesElectricityProduct,
      ref
    ) => {
      const [onlyCurrent, setOnlyCurrent] = useState(oc);
      const params = setParam(onlyCurrent);
      const data = useAPIQuery("getProductForElectricityProductTree");
      const dataCfpd = useAPIQueryPagination(
        "tradable_products",
        { params },
        {
          perPage: 10000,
          shouldLoadAll: true,
        }
      );

      const isLoading = data.loading && !data.sync;
      if (isLoading) return <CORELoading size={"lg"} />;

      const resultShapeTypes = setupTopLevelData(data.data.data);
      const isLoadingCfpd = dataCfpd.loading && !dataCfpd.sync;
      if (isLoadingCfpd) return <CORELoading size={"lg"} />;

      const hasError = dataCfpd.error;
      if (hasError) {
        console.error(dataCfpd);
        return (
          <Alert type={"error"} message={"Error loading tradable products"} />
        );
      }
      const hasNoData =
        !dataCfpd.sync ||
        dataCfpd.data === undefined ||
        dataCfpd.data.length === 0;

      if (hasNoData) return <Empty />;

      const tradeProductData = dataCfpd?.data;
      const cfdProducts = flatProduct(tradeProductData);

      return (
        <>
          {showOnlyCurrentToggle && (
            <>
              <Checkbox
                checked={!onlyCurrent}
                onChange={(e) => setOnlyCurrent(!e.target.checked)}
                className={"show-all-btn"}
              >
                Show all
              </Checkbox>
              <br />
            </>
          )}
          <ElectricityProductTree
            shapeTypes={resultShapeTypes}
            tradeProductData={cfdProducts}
            multiple={multiple}
            includeAgg={includeAgg}
            checkedKeys={checkedKeys}
            onChange={onChange}
            ref={ref}
            {...otherProps}
          />
        </>
      );
    }
  )
);
