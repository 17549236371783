import React from "react";
import { COREBody, COREBodyProps } from "../Typography/COREBody";
import { Typography } from "antd";
import { Merge } from "../../shared/TypeScriptHelpers";
import { LinkProps } from "antd/lib/typography/Link";
import "./CORELink.less";

const { Link } = Typography;

type CORELinkProps = Merge<
  LinkProps,
  Pick<COREBodyProps, "type" | "testID" | "underline" | "inline">
>;

export const CORELink: React.FC<CORELinkProps> = ({
  testID,
  type,
  underline,
  inline,
  ...props
}) => {
  return (
    <COREBody
      className={"core-link"}
      type={type}
      strong
      underline={underline}
      testID={testID}
      marginBottom={false}
      inline={inline}
    >
      <Link {...props} />
    </COREBody>
  );
};
