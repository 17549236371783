import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  OnResize,
  SetSizeObject,
  defaultResizeValue,
} from "../../../shared/OnResize";
import "./ListCollapseHOC.less";

function useValueEffect<T>(
  defaultValue: T
): [T, (fn: () => Generator<T, void, unknown>) => void] {
  const [value, setValue] = useState<T>(defaultValue);
  const effect = useRef<Generator<T, void, unknown> | null>(null);
  const nextRef = useRef<(() => void) | null>(null);

  nextRef.current = () => {
    const newValue = effect.current!.next();
    if (newValue.done) {
      effect.current = null;

      return;
    }

    if (value === newValue.value) {
      nextRef.current!();
    } else {
      setValue(newValue.value);
    }
  };

  useLayoutEffect(() => {
    if (effect.current) {
      nextRef.current!();
    }
  });

  const queue = useCallback(
    (fn: () => Generator<T, void, unknown>) => {
      effect.current = fn();
      nextRef.current!();
    },
    [effect, nextRef]
  );

  return [value, queue];
}

export type ListCollapseParams = {
  items: ListCollapseItem[];
  hideItems?: ListCollapseItem[];
  filtered?: boolean;
};

export type ListCollapseItem = {
  key: string | number;
  input: React.ReactElement;
};

const defaultRenderChildren = (
  visibleItems: ListCollapseItem[],
  items: ListCollapseItem[],
  filtered?: boolean
) => (
  <>
    {visibleItems.map((item) => (
      <div className={"list-collapse-hoc-children"} key={item.key}>
        {item.input}
      </div>
    ))}
  </>
);

function computeVisibleItems(
  listItems: HTMLDivElement[],
  containerWidth: number,
  isShowingStack: boolean,
  stackPosition: "left" | "right"
): number {
  let calculatedWidth = 0;
  let newItemsNumber = 0;

  if (isShowingStack && stackPosition === "right") {
    calculatedWidth += listItems.pop()!.offsetWidth;
  } else if (isShowingStack && stackPosition === "left") {
    calculatedWidth += listItems.shift()!.offsetWidth;
    listItems.reverse();
  }

  for (const [index, listItem] of listItems.entries()) {
    if (
      calculatedWidth + listItem.offsetWidth <= containerWidth ||
      index === 0
    ) {
      calculatedWidth += listItem.offsetWidth;
      newItemsNumber += 1;
    } else {
      break;
    }
  }

  return newItemsNumber;
}

export const ListCollapseHOC = <P extends object>(
  Component: React.ComponentType<P & ListCollapseParams>,
  stack?: ReactNode,
  stackPosition: "left" | "right" = "right",
  renderChildren: (
    visibleItems: ListCollapseItem[],
    items: ListCollapseItem[],
    filtered?: boolean
  ) => ReactElement = defaultRenderChildren,
  listClassName: string = "list-collapse-hoc"
) => {
  const WithListCollapse: React.ComponentType<
    P & Pick<ListCollapseParams, "items" | "filtered">
  > = ({ items, filtered, ...props }: ListCollapseParams) => {
    const listRef = useRef<HTMLDivElement>(null);
    const filtersCount = items.length;
    const [visibleItemsCount, setVisibleItemsCount] =
      useValueEffect<number>(filtersCount);
    const [listSize, setListSize] = useState<SetSizeObject>(defaultResizeValue);

    const updateOverflow = useCallback(() => {
      function computeItems(currentVisibleItemsCount: number) {
        const listItems = Array.from(
          listRef.current!.children
        ) as HTMLDivElement[];
        const containerWidth = listSize && listSize.width ? listSize?.width : 0;
        const isShowingStack =
          !!stack && filtersCount > currentVisibleItemsCount;
        return computeVisibleItems(
          listItems,
          containerWidth,
          isShowingStack,
          stackPosition
        );
      }

      setVisibleItemsCount(function* () {
        yield filtersCount;

        const newVisibleItems = computeItems(filtersCount);
        yield newVisibleItems;

        if (newVisibleItems < filtersCount) {
          yield computeItems(newVisibleItems);
        }
      });
    }, [setVisibleItemsCount, filtersCount, listSize]);

    useLayoutEffect(updateOverflow, [items, updateOverflow]);

    const shouldRenderStack = filtersCount > visibleItemsCount;
    const visibleItems = shouldRenderStack
      ? items.slice(0, visibleItemsCount)
      : items;
    const hideItems = shouldRenderStack
      ? items.slice(visibleItemsCount - filtersCount)
      : [];

    return (
      <Component {...(props as P)} hideItems={hideItems} items={visibleItems}>
        <OnResize onResize={setListSize}>
          <div ref={listRef} className={listClassName}>
            {shouldRenderStack && stack && stackPosition === "left" && (
              <div className={`list-collapse-hoc-children`}>{stack}</div>
            )}
            {renderChildren(visibleItems, items, filtered)}
            {shouldRenderStack && stack && stackPosition === "right" && (
              <div className={`list-collapse-hoc-children`}>{stack}</div>
            )}
          </div>
        </OnResize>
      </Component>
    );
  };
  return WithListCollapse;
};
