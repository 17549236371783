import moment, { Moment } from "moment";
import { useUserIsAdmin } from "../../../shared/state/user";
import React, { useState } from "react";
import { useMutation } from "react-query";
import {
  appQueryClient,
  appQueryClient as queryClient,
} from "../../../shared/state/appQueryClient";
import { message } from "antd";
import "../../marketPrices/marketPricesComponent/LiveMarketScreen.less";
import { CORETextEditor } from "../../../COREDesignSystem/Form/CORETextEditor";
import { ProductCurveCommentaries } from "../CarbonDailyCurvePageWrapper";
import { product_types as productTypesAdmin } from "../../../openapi-typescript/admin";
import { product_types as productTypesApproved } from "../../../openapi-typescript/approved";
import { TestID } from "../../../shared/testids/testids";

export type ProductTypes = productTypesAdmin | productTypesApproved;

type ProductCode = Exclude<Required<ProductTypes["code"]>, undefined>;

type commentary = ProductCode | "general";

const dateDBFormat = "YYYY-MM-DD";

const saveEodData: ({
  html,
  certificate,
  date,
}: {
  html: string;
  certificate: commentary;
  date: moment.Moment;
}) => Promise<string> = ({
  html,
  certificate,
  date,
}: {
  html: string;
  certificate: commentary;
  date: Moment;
}): Promise<string> => {
  const c: string = certificate.toString().toLowerCase();
  return queryClient
    .fetchQuery({
      queryKey: [
        "setProductCurveCommentaries",
        {
          action: "setProductCurveCommentaries",
          body: JSON.stringify({
            date: moment(date).format(dateDBFormat),
            commentary: html,
            // eslint-disable-next-line camelcase
            product_class: "carbon",
            // eslint-disable-next-line camelcase
            product_type:
              certificate === "general"
                ? null
                : certificate === "VCS"
                ? "vcu"
                : c,
          }),
          enabled: true,
        },
      ],
    })
    .then(() => {
      return "Updated success";
    });
};

export const EodEditor: ({
  certificate,
  commentaryData,
  date,
  isPublished,
  testId,
}: {
  certificate: commentary;
  commentaryData: {
    certificate: commentary;
    commentary?: ProductCurveCommentaries["commentary"];
  };
  date: moment.Moment;
  isPublished: boolean;
  testId?: TestID;
}) => JSX.Element | null = ({
  certificate,
  commentaryData,
  date,
  isPublished,
  testId,
}: {
  certificate: commentary;
  commentaryData: {
    certificate: commentary;
    commentary?: ProductCurveCommentaries["commentary"];
  };
  date: Moment;
  isPublished: boolean;
  testId?: TestID;
}) => {
  const isAdmin: boolean = useUserIsAdmin();
  const commentary = commentaryData?.commentary;

  const [curVal, setCurVal] = useState(commentary);
  const comment = curVal ?? commentary ?? "";

  const saveMutation = useMutation(saveEodData, {
    onSuccess: (successMessage: string) => {
      message.success(successMessage);
      appQueryClient.invalidateQueries("productCurveCommentaries");
    },
    onError: ({ response: { data: err } }) => {
      message.error(`Error: ${err.message}`);
      console.error(err.message);
    },
  });

  const handleEditorChange = (html: string, certificate: commentary) => {
    if (html === comment) return null;

    saveMutation.mutate({ html, certificate, date });
    setCurVal(html);
  };

  if ((!isAdmin && comment) || isPublished)
    return (
      <div
        className={"eod-editor"}
        dangerouslySetInnerHTML={{ __html: comment }}
        data-testid={testId}
      />
    );

  if (isAdmin) {
    return (
      <div className={"eod-editor"} data-testid={testId}>
        <CORETextEditor
          value={comment}
          onBlur={(newValue) => {
            handleEditorChange(newValue, certificate);
          }}
        />
      </div>
    );
  }

  return null;
};
