import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { useUserLoggedInStatus } from "../../../shared/state/user";

type LoginLayoutRouteProps = Pick<RouteProps, "path" | "exact" | "component">;
export const LoginLayoutRoute: React.FC<LoginLayoutRouteProps> = ({
  component,
  path,
  exact,
}) => {
  const isLoggedIn = useUserLoggedInStatus();
  const Component = component as React.ComponentType<RouteComponentProps>;
  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) =>
        !isLoggedIn ? <Component {...matchProps} /> : <Redirect to="/" />
      }
    />
  );
};
