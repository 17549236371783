import React, {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { COREContainer, COREContainerProps } from "../Layout/COREContainer";
import {
  InteractionMenuItem,
  InteractionProps,
} from "../Layout/COREAccordionItem";
import { COREProgress, COREProgressProps } from "../Feedback/COREProgress";
import { Layout, PlotlyHTMLElement } from "plotly.js/dist/plotly-myPlotly.js";
import { COREMenuItem } from "../Navigation/COREMenuItem";
import { TestID } from "../../shared/testids/testids";
import { AggregatesChartV2 } from "../../modules/tools/chartBuilder/AggregatesChartV2";
import sizeMe from "react-sizeme";
import { COREHeading } from "../Typography/COREHeading";
import { AggregatesWaterfallChart } from "../../modules/tools/chartBuilder/AggregatesWaterfallChart";
import { CORERadarChartAPI } from "./CORERadarChartAPI";
import { COREButton } from "../Action/COREButton";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CORETooltip } from "../Overlay/CORETooltip";
import { Plot } from "./COREChart";
import { CORESunburstChart } from "./CORESunburstChart";
import { CORERadarChart } from "./CORERadarChart";
import { COREDotPlotChart } from "./COREDotPlotChart";
import { COREBasicChart } from "./COREBasicChart";

export type PlotRefType =
  | {
      el: PlotlyHTMLElement;
      props: ComponentProps<typeof Plot> & {
        layout: Partial<Layout> & { filename?: string };
      };
      resizeHandler: () => void | undefined;
    }
  | undefined;

export type InjectedChartProps = {
  showlegend?: boolean;
  chartTitle?: string;
  chartHoverMode?: Layout["hovermode"];
};

export type WithContainerChartProps = Pick<
  COREContainerProps,
  "interactionSpace" | "className" | "fullHeight" | "roundCorner" | "bordered"
> & {
  testID: TestID;
  downloadCsv?: boolean;
  downloadPng?: boolean;
  percent?: COREProgressProps["percent"];
};

export type ChartProps = {
  setOnDownloadCSV?: Dispatch<SetStateAction<Function>>;
  setOnDownloadPNG?: Dispatch<SetStateAction<Function>>;
  setOnResetChart?: Dispatch<SetStateAction<Function>>;
  displayTitle?: boolean;
};

export type ContainerStyle = "panel" | "container";

const buildInteractionsRight = (
  testID: TestID,
  handleDownloadCSV: () => void,
  handleDownloadPng: () => void,
  handleResetChart: () => void,
  downloadCsv?: boolean,
  onDownloadCSV?: Function,
  downloadPng?: boolean,
  onDownloadPNG?: Function
) => {
  const interactionsRight: InteractionProps[] = [];
  if (downloadCsv && onDownloadCSV) {
    interactionsRight.push(
      <CORETooltip
        position={"top"}
        width={"auto"}
        title={""}
        message={"Download CSV"}
        testID={`${testID}-csv-tooltip`}
      >
        <COREButton
          type={"default"}
          size={"md"}
          icon={
            <COREIcon icon={icon({ name: "file-csv", style: "regular" })} />
          }
          onClick={handleDownloadCSV}
          testID={`${testID}-csv`}
        ></COREButton>
      </CORETooltip>
    );
  }
  if (downloadPng && onDownloadPNG) {
    interactionsRight.push(
      <CORETooltip
        position={"top"}
        width={"auto"}
        title={""}
        message={"Download PNG"}
        testID={`${testID}-png-tooltip`}
      >
        <COREButton
          type={"default"}
          size={"md"}
          icon={<COREIcon icon={icon({ name: "image", style: "regular" })} />}
          onClick={handleDownloadPng}
          testID={`${testID}-png`}
        ></COREButton>
      </CORETooltip>
    );
  }
  interactionsRight.push(
    <CORETooltip
      position={"top"}
      width={"auto"}
      title={""}
      message={"Reset chart"}
      testID={`${testID}-reset-tooltip`}
    >
      <COREButton
        type={"default"}
        size={"md"}
        icon={<COREIcon icon={icon({ name: "undo", style: "regular" })} />}
        onClick={handleResetChart}
        testID={`${testID}-relayout`}
      ></COREButton>
    </CORETooltip>
  );
  return interactionsRight;
};

const buildInteractionsMenu = (
  testID: TestID,
  handleDownloadCSV: () => void,
  handleDownloadPng: () => void,
  handleResetChart: () => void,
  downloadCsv?: boolean,
  onDownloadCSV?: Function,
  downloadPng?: boolean,
  onDownloadPNG?: Function
) => {
  const interactionsMenu: InteractionMenuItem[] = [];
  if (downloadCsv && onDownloadCSV) {
    interactionsMenu.push({
      component: (
        <COREMenuItem
          testID={`${testID}-download-as-csv-menu`}
          icon={
            <COREIcon
              icon={icon({ name: "file-csv", style: "regular" })}
              onClick={handleDownloadCSV}
            />
          }
        >
          <div onClick={handleDownloadCSV}>Download as CSV</div>
        </COREMenuItem>
      ),
      closeWhenClick: true,
    });
  }
  if (downloadPng && onDownloadPNG) {
    interactionsMenu.push({
      component: (
        <COREMenuItem
          testID={`${testID}-download-as-png-menu`}
          icon={
            <COREIcon
              icon={icon({ name: "image", style: "regular" })}
              onClick={handleDownloadPng}
            />
          }
        >
          <div onClick={handleDownloadPng}>Download as PNG</div>
        </COREMenuItem>
      ),
      closeWhenClick: true,
    });
  }
  interactionsMenu.push({
    component: (
      <COREMenuItem
        testID={`${testID}-reset-chart-menu`}
        icon={
          <COREIcon
            icon={icon({ name: "undo", style: "regular" })}
            onClick={handleResetChart}
          />
        }
      >
        <div onClick={handleResetChart}>Reset Chart</div>
      </COREMenuItem>
    ),
    closeWhenClick: true,
  });
  return interactionsMenu;
};

export const ChartHOC = <P extends object>(
  containerStyle: ContainerStyle,
  Chart: React.ComponentType<P & ChartProps>
) => {
  const WithContainer: React.ComponentType<
    P & WithContainerChartProps & InjectedChartProps
  > = ({
    testID,
    chartTitle,
    interactionSpace = 8,
    chartHoverMode = "x",
    showlegend = true,
    downloadCsv,
    downloadPng,
    className,
    percent = 0,
    fullHeight = true,
    roundCorner,
    bordered,
    ...props
  }: WithContainerChartProps & InjectedChartProps) => {
    const [progressPercent, setProgressPercent] =
      useState<COREProgressProps["percent"]>(percent);
    const plotRef = useRef<PlotRefType>();
    const chartLegendRef = useRef();
    const titleRef = useRef<HTMLDivElement>(null);

    const chartRef = useRef<typeof Chart>(null);

    const [onDownloadCSV, setOnDownloadCSV] = useState<Function>();
    const [onDownloadPNG, setOnDownloadPNG] = useState<Function>();
    const [onResetChart, setOnResetChart] = useState<Function>();

    const handleResetChart = () => {
      if (onResetChart) onResetChart();
    };

    const handleDownloadCSV = () => {
      if (onDownloadCSV) onDownloadCSV();
    };

    const handleDownloadPng = () => {
      if (onDownloadPNG) onDownloadPNG();
    };

    const interactionsRight: InteractionProps[] = buildInteractionsRight(
      testID,
      handleDownloadCSV,
      handleDownloadPng,
      handleResetChart,
      downloadCsv,
      onDownloadCSV,
      downloadPng,
      onDownloadPNG
    );

    const interactionsMenu: InteractionMenuItem[] = buildInteractionsMenu(
      testID,
      handleDownloadCSV,
      handleDownloadPng,
      handleResetChart,
      downloadCsv,
      onDownloadCSV,
      downloadPng,
      onDownloadPNG
    );

    let containerHeader = <div ref={titleRef}>{chartTitle}</div>;
    if (containerStyle === "panel") {
      containerHeader = (
        <COREHeading
          level={3}
          testID={(testID + "-container-header") as TestID}
        >
          <div ref={titleRef}>{chartTitle}</div>
        </COREHeading>
      );
    }

    return (
      <COREContainer
        progress={
          <COREProgress
            type={"line"}
            percent={progressPercent}
            testID={testID}
            hideOnEmpty={false}
            hideOnComplete={true}
          />
        }
        header={containerHeader}
        interactionSpace={interactionSpace}
        interactionsRight={interactionsRight}
        interactionsMenu={interactionsMenu}
        menuType={"differentMenu"}
        noScroll={true}
        testID={testID}
        fullHeight={fullHeight}
        type={containerStyle}
        roundCorner={roundCorner}
        bordered={bordered}
      >
        <Chart
          {...(props as P)}
          ref={chartRef}
          chartTitle={chartTitle}
          downloadCsv={false}
          downloadPng={false}
          chartHoverMode={chartHoverMode}
          showlegend={showlegend}
          setProgressPercent={setProgressPercent}
          chartPanel={true}
          plotRef={plotRef}
          chartIsIn={"containerChart"}
          chartLegendRef={chartLegendRef}
          testID={testID}
          titleRef={titleRef}
          setOnDownloadCSV={setOnDownloadCSV}
          setOnDownloadPNG={setOnDownloadPNG}
          setOnResetChart={setOnResetChart}
          displayTitle={false}
        />
      </COREContainer>
    );
  };
  return sizeMe({ noPlaceholder: true })(WithContainer);
};

export const COREAggregatesContainerChart = ChartHOC(
  "container",
  AggregatesChartV2
);
export const COREAggregatesPanelChart = ChartHOC("panel", AggregatesChartV2);
export const CORESunburstContainerChart = ChartHOC(
  "container",
  CORESunburstChart
);
export const CORESunburstPanelChart = ChartHOC("panel", CORESunburstChart);
export const COREWaterfallContainerChart = ChartHOC(
  "container",
  AggregatesWaterfallChart
);
export const COREWaterfallPanelChart = ChartHOC(
  "panel",
  AggregatesWaterfallChart
);
export const CORERadarContainerChart = ChartHOC("container", CORERadarChart);
export const CORERadarPanelChart = ChartHOC("panel", CORERadarChart);

export const CORERadarAPIContainerChart = ChartHOC(
  "container",
  CORERadarChartAPI
);

export const CORERadarAPIPanelChart = ChartHOC("panel", CORERadarChartAPI);

export const COREDotPlotContainerChart = ChartHOC(
  "container",
  COREDotPlotChart
);
export const COREDotPlotPanelChart = ChartHOC("panel", COREDotPlotChart);

export const COREBasicPanelChart = ChartHOC("panel", COREBasicChart);
export const COREBasicContainerChart = ChartHOC("container", COREBasicChart);
