import React, { useEffect, useState } from "react";
import { TradeTicker } from "./TradeTicker";
import { generateTestId } from "../../../shared/testids/testids";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { TradeTickerFilterType } from "./TradeTickerFilter";
import "./TradeTicker.less";

type TradeTickerWidgetItems = {
  props: {
    props?: TradeTickerFilterType | string;
    name: string;
    setProps: (value: unknown) => void;
  };
};

export const TradeTickerWidget: React.FC<TradeTickerWidgetItems> = ({
  props: widgetProps,
}) => {
  const [tradeTickerFilter, setTradeTickerFilter] =
    useState<TradeTickerFilterType>(
      widgetProps?.props && typeof widgetProps.props !== "string"
        ? widgetProps.props
        : {}
    );

  const onChangeFilter = (widget: TradeTickerFilterType) => {
    setTradeTickerFilter(widget);
    widgetProps.setProps({ widget, i: widgetProps.name });
  };

  useEffect(() => {
    setTradeTickerFilter(
      widgetProps?.props && typeof widgetProps.props !== "string"
        ? widgetProps.props
        : {}
    );
  }, [widgetProps?.props]);

  return (
    <div className={"trade-ticker-widgets"}>
      <TradeTicker
        heading={
          <>
            <COREHeading
              testID={generateTestId("tradeticker", "latest-trade-title")}
              level={3}
            >
              Latest trades
            </COREHeading>
            <COREDivider space={"md"} />
          </>
        }
        testID={generateTestId("tradeticker", "widget")}
        tradeTickerFilter={tradeTickerFilter}
        onChangeFilter={onChangeFilter}
      />
    </div>
  );
};
