import {
  APIResponse,
  FieldsOrders,
  TableOptions,
  useUpdatables,
} from "./useUpdatable";
import { keysToCamelCase, keysToSnake } from "../global";
import { FilterCompanyManagement } from "../../modules/admin/companyManagement/CompanyManagementTableWithFilters";
import { companies } from "../../openapi-typescript/common/companies";
import {
  dateFormatWithTimeZone,
  formatRelativeRangeFilterForServer,
} from "../date/DateTime";

export type Company = companies & {
  update: (data: Company) => Promise<APIResponse<Company>>;
};
type CompanyManagementOrder = FieldsOrders<keyof FilterCompanyManagement>;
type CompanyManagementParams = Partial<
  FilterCompanyManagement & {
    order: CompanyManagementOrder;
    id: Company["id"];
  }
>;
type UpdatePrams = Partial<
  Pick<
    Company,
    "persistWhenCopied" | "name" | "mfaIsRequired" | "businessNumber"
  >
>;
const filterBy = ({
  order,
  name,
  expiryTime,
  persistWhenCopy,
  subscriptionPackages,
  features,
  scopes,
  id,
}: CompanyManagementParams) => ({
  ...(id && { id: `eq.${id}` }),
  ...(order && { order: order }),
  ...(name && { name: `ilike.*${name}*` }),
  ...(features &&
    features.length !== 0 &&
    keysToSnake({
      "filteredCompanyAllowedFeature.feature": `in.(${features.join(",")})`,
    })),
  ...(scopes &&
    scopes.length !== 0 &&
    keysToSnake({
      "filteredCompanyAllowedScopes.scope": `in.(${scopes.join(",")})`,
    })),
  ...(expiryTime && {
    and: formatRelativeRangeFilterForServer(
      expiryTime,
      "subscription_expiry",
      dateFormatWithTimeZone
    ),
  }),
  ...(persistWhenCopy &&
    keysToSnake({
      persistWhenCopied: `eq.${persistWhenCopy}`,
    })),
  ...(subscriptionPackages &&
    subscriptionPackages.length !== 0 && {
      subscriptionPackages: "not.is.null",
    }),
  ...(subscriptionPackages &&
    subscriptionPackages.length !== 0 &&
    keysToSnake({
      "filtered_company_subscription_package.subscriptionPackage": `in.(${subscriptionPackages.join(
        ","
      )})`,
    })),
});

export const useUpdatableCompanies = (
  args?: CompanyManagementParams,
  tableOption?: TableOptions
) => {
  return useUpdatables<
    Company,
    Company,
    Company,
    Company[],
    Company,
    UpdatePrams
  >(
    {
      get: [
        "companyData",
        {
          select: `*,numberOfSeats:number_of_seats,seatsInUse:seats_in_use,autoRenewSubscription:auto_renew_subscription,subscriptionPackages:company_subscription_packages(id,company,subscriptionPackageId:subscription_package, subscriptionName:subscription_packages(name)),features:company_allowed_features(feature),scopes:company_scopes(*)${
            args && args.features && args.features.length !== 0
              ? ",filtered_company_allowed_feature:company_allowed_features!inner(feature)"
              : ""
          }${
            args && args.scopes && args.scopes.length !== 0
              ? ",filtered_company_allowed_scopes:company_scopes!inner(*)"
              : ""
          }${
            args &&
            args.subscriptionPackages &&
            args.subscriptionPackages.length !== 0
              ? ",filtered_company_subscription_package:company_subscription_packages!inner(subscription_package)"
              : ""
          }`,
          order: "name.asc",
          ...(args && filterBy(args)),
        },
      ],
      add: (newItem) => ({
        queryKey: [
          "addCompanyData",
          {
            action: "addCompanyData",
            body: JSON.stringify(newItem),
            enabled: true,
          },
        ],
      }),
      update: (newItem, oldItem) => ({
        queryKey: [
          "updateCompanyData",
          {
            action: "updateCompanyData",
            body: JSON.stringify(newItem),
            params: { id: oldItem.id },
            enabled: true,
          },
        ],
      }),
      transformToRichTypes: (record) => keysToCamelCase(record),
      invalidateQueries: ["companyData"],
    },
    tableOption
  );
};

export const useUpdatableCompany = (id: Company["id"]) =>
  useUpdatableCompanies({ id });
