import { UserScopes } from "../../openapi-typescript/common/user_scopes";
import { FeatureFlag, useFeatureFlags } from "./useFeatureFlags";

export type ProductClassType = "carbon" | "environmental";
export type DailyCurveProductType =
  | "accu"
  | "nzu"
  | "vcu"
  | "gs"
  | "cer"
  | "esc"
  | "lgc"
  | "prc"
  | "stc"
  | "veec";
export const carbonProductTypes = ["accu", "nzu", "vcu", "gs", "cer"];
export const environmentalProductTypes = ["esc", "lgc", "prc", "stc", "veec"];
const dailyCurveCarbon = "daily-curves-carbon";
const dailyCurveEnviron = "daily-curves-environmental";

export const getProductTypePermission = (
  features: FeatureFlag[],
  selectedProductType?: string
): boolean => {
  return selectedProductType
    ? features.includes(
        `core.product.${selectedProductType}.read` as UserScopes
      )
    : true;
};

export const useDailyCurveFeaturePermissions = (
  selectedProductType?: string
): boolean => {
  const { features } = useFeatureFlags();
  if (!features?.includes("core.daily-curves.read")) return false;

  if (selectedProductType) {
    const isCarbon = carbonProductTypes.includes(selectedProductType);
    const isEnviron = environmentalProductTypes.includes(selectedProductType);

    return isCarbon
      ? features?.includes(dailyCurveCarbon)
      : isEnviron && features?.includes(dailyCurveEnviron);
  }

  return (
    features?.includes(dailyCurveCarbon) ||
    features?.includes(dailyCurveEnviron)
  );
};

export const useProductTypes = () => {
  const { features } = useFeatureFlags();
  const regExScope = new RegExp(/core.product./);

  return features?.filter((f) => f.match(regExScope)) ?? [];
};

export const useCarbonProductTypes = () => {
  const availableScopes = useProductTypes();
  return availableScopes.flatMap((s) => {
    const parts = s.split(".");
    const product = parts[2];
    return carbonProductTypes.includes(product) ? product : [];
  });
};

export const useEnvironmentProductTypes = () => {
  const availableScopes = useProductTypes();
  return availableScopes.flatMap((s) => {
    const parts = s.split(".");
    const product = parts[2];
    return environmentalProductTypes.includes(product) ? product : [];
  });
};

export const useAllProductTypes = (): {
  productClass: ProductClassType;
  productTypes: string[];
}[] => {
  const { features } = useFeatureFlags();
  const dailyCurvePermission = useDailyCurveFeaturePermissions();
  const carbons = useCarbonProductTypes();
  const environs = useEnvironmentProductTypes();

  if (!dailyCurvePermission) return [];

  return [
    ...(features?.includes(dailyCurveCarbon)
      ? [
          {
            productClass: "carbon" as ProductClassType,
            productTypes: carbons,
          },
        ]
      : []),
    ...(features?.includes(dailyCurveEnviron)
      ? [
          {
            productClass: "environmental" as ProductClassType,
            productTypes: environs,
          },
        ]
      : []),
  ];
};
