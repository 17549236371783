import React, { forwardRef } from "react";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import {
  generateTree,
  CORETree,
  useCORETree,
} from "../../../COREDesignSystem/Form/CORETree";
import { Alert, Empty } from "antd";
import { generateTestId } from "../../../shared/testids/testids";

const staticVar = ["Flow", "Import limit", "Export limit"];
const getJoinedData = (rawData) => {
  const joinedData = [];
  rawData.forEach((item) => {
    staticVar.forEach((variable) => {
      joinedData.push({
        interconnectorName: `${item.name} (${item.aemo_id})`,
        variable: variable,
        value: {
          id: item.aemo_id,
          variable: variable,
          traceName: `${item.name} ${variable}`,
        },
      });
    });
  });
  return joinedData;
};

const InterconnectorsTreeWithData = ({
  data,
  multiple,
  onChange: parentOnChange,
  initialValue,
  ref,
  checkedKeys,
  includeAgg = false,
  ...otherProps
}) => {
  const joinedDataList = getJoinedData(data);
  const treeData = generateTree(
    joinedDataList,
    ["interconnectorName", "variable"],
    "value",
    includeAgg
  );

  const tree = useCORETree(treeData, {
    onChange: parentOnChange,
    initialRootValues: initialValue,
  });

  return (
    <CORETree
      ref={ref}
      {...tree}
      multiple={multiple}
      checkedKeys={checkedKeys}
      {...otherProps}
      testID={generateTestId("chartbuilder", `interconnectors-tree`)}
    />
  );
};

export const InterconnectorsTree = React.memo(
  forwardRef(
    (
      {
        multiple = false,
        activeProductOnly = false,
        includeAgg = false,
        ...otherProps
      },
      ref
    ) => {
      const data = useAPIQuery("getAllInterconnectors");

      const isLoading = data.loading && !data.sync;
      const hasError = data.error;
      const hasNoData = data.data.data === undefined;
      if (isLoading) return <CORELoading size={"lg"} />;
      if (hasError) {
        console.error(data);
        return (
          <Alert type={"error"} message={"Error loading interconnectors"} />
        );
      }
      if (hasNoData) return <Empty />;
      return (
        <InterconnectorsTreeWithData
          data={data.data.data}
          multiple={multiple}
          includeAgg={includeAgg}
          activeProductOnly={activeProductOnly}
          {...otherProps}
          ref={ref}
        />
      );
    }
  )
);
