import { useState } from "react";
import { Col, Collapse, Row } from "antd";
import { WeatherObservationTypeTree } from "./WeatherObservationTypeTree";
import { InterconnectorsTree } from "./InterconnectorsTree";
import { ElectricityTimeSeriesTree } from "./ElectricityTimeSeriesTree";
import { FCASTimeSeriesTree } from "./FCASTimeSeriesTree";
import { TimeseriesEnvironmentalProductTree } from "./TimeseriesEnvironmentalProductTree";
import { DUIDTree } from "./DUIDTree";
import "./ChartBuilderSelector.less";
import { TimeseriesElectricityProductTree } from "./ElectricityProductTree";
import { ElectricityProductVWAPsTree } from "./ElectricityProductVWAPsTree";
import { generateTestId, TestIDWrapper } from "../../../shared/testids/testids";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { CORETypographyInput } from "../../../COREDesignSystem/Typography/CORETypographyInput";

const initChecked = [
  "electricityTree",
  "FCASTree",
  "interconnectorsTree",
  "SCADATree",
  "productTree",
  "environmentalProductTree",
  "weatherObservationTree",
  "productVWAPsTree",
].reduce(
  (acc, tree) => ({
    ...acc,
    [tree]: { checkedKeys: [], change: [], value: [] },
  }),
  {}
);

let onChangeParent = () => {};
let checkedParent = initChecked;

const onChangeElectricityTree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    electricityTree: { change, value, checkedKeys },
  });
};

const onChangeFCASTree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    FCASTree: { change, value, checkedKeys },
  });
};

const onChangeInterConnectorsTree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    interconnectorsTree: { change, value, checkedKeys },
  });
};

const onChangeSCADATree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    SCADATree: { change, value, checkedKeys },
  });
};

const onChangeProductTree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    productTree: { change, value, checkedKeys },
  });
};

const onChangeProductVWAPsTree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    productVWAPsTree: { change, value, checkedKeys },
  });
};

const onChangeEnvironmentalProductTree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    environmentalProductTree: { change, value, checkedKeys },
  });
};

const onChangeWeatherObservationTree = (change, value, checkedKeys) => {
  return onChangeParent({
    ...checkedParent,
    weatherObservationTree: { change, value, checkedKeys },
  });
};

export const ChartBuilderSelector = ({
  onChange,
  checked = initChecked,
  checkTreeExpend,
  undoResetTree,
}) => {
  const [activeKey, setActiveKey] = useState(["NEM Electricity"]);
  onChangeParent = onChange;
  checkedParent = checked;

  const { sync, features: featureList } = useFeatureFlags();

  if (!sync) return null;

  const resetTree = { checkedExpandedKeys: checkTreeExpend };

  const panels = [
    {
      name: "NEM Electricity",
      tree: (
        <ElectricityTimeSeriesTree
          multiple={true}
          includeAgg={true}
          onChange={onChangeElectricityTree}
          checkedKeys={checked.electricityTree?.checkedKeys}
          testID={generateTestId(
            "chartbuilder",
            `electricity-time-series-tree`
          )}
          {...resetTree}
        />
      ),
      treeName: "electricityTree",
    },
    {
      name: "NEM FCAS",
      tree: (
        <FCASTimeSeriesTree
          multiple={true}
          includeAgg={true}
          onChange={onChangeFCASTree}
          checkedKeys={checked.FCASTree?.checkedKeys}
          testID={generateTestId("chartbuilder", `fcas-time-series-tree`)}
          {...resetTree}
        />
      ),
      treeName: "FCASTree",
    },
    {
      name: "NEM Interconnectors",
      tree: (
        <InterconnectorsTree
          multiple={true}
          includeAgg={true}
          onChange={onChangeInterConnectorsTree}
          checkedKeys={checked.interconnectorsTree?.checkedKeys}
          {...resetTree}
        />
      ),
      treeName: "interconnectorsTree",
    },
    {
      name: "SCADA",
      tree: (
        <DUIDTree
          search
          multiple={true}
          includeAgg={true}
          onChange={onChangeSCADATree}
          checkedKeys={checked.SCADATree?.checkedKeys}
          {...resetTree}
        />
      ),
      treeName: "SCADATree",
    },
    {
      name: "Electricity Products Market Data",
      tree: (
        <TimeseriesElectricityProductTree
          search
          activeProductOnly={false}
          multiple={true}
          includeAgg={true}
          onChange={onChangeProductTree}
          checkedKeys={checked.productTree?.checkedKeys}
          {...resetTree}
        />
      ),
      treeName: "productTree",
    },
    {
      name: "Electricity Products Settlement",
      tree: (
        <ElectricityProductVWAPsTree
          search
          activeProductOnly={false}
          multiple={true}
          includeAgg={true}
          onChange={onChangeProductVWAPsTree}
          checkedKeys={checked.productVWAPsTree?.checkedKeys}
          {...resetTree}
        />
      ),
      treeName: "productVWAPsTree",
    },
    {
      name: "Environmental Products",
      tree: (
        <TimeseriesEnvironmentalProductTree
          onlyCurrent={true}
          multiple={true}
          includeAgg={true}
          onChange={onChangeEnvironmentalProductTree}
          checkedKeys={checked.environmentalProductTree?.checkedKeys}
          testID={generateTestId(
            "chartbuilder",
            `weather-observation-type-tree`
          )}
          certificateProject={false}
          {...resetTree}
        />
      ),
      treeName: "environmentalProductTree",
    },
    {
      name: "Weather Observations",
      tree: (
        <WeatherObservationTypeTree
          multiple={true}
          includeAgg={true}
          primaryStationOnly={false}
          onChange={onChangeWeatherObservationTree}
          checkedKeys={checked.weatherObservationTree?.checkedKeys}
          {...resetTree}
        />
      ),
      treeName: "weatherObservationTree",
    },
  ];

  const hasChartBuilderFlag =
    featureList.includes("chart-builder") &&
    !featureList.includes("chart-builder-");

  return (
    <>
      <Row>
        <Col xs={24} md={24}>
          <CORECard
            hoverable={false}
            elevation={"none"}
            testID={generateTestId("chartbuilder", "select-chart-data-card")}
          >
            <COREBody type="p2" strong>
              <COREIcon
                className={"icon-colour"}
                icon={icon({ name: "list-check", style: "solid" })}
                size={"xs"}
              />{" "}
              Select chart data
            </COREBody>
            <COREDivider space={"md"}></COREDivider>
            <TestIDWrapper
              testID={generateTestId(
                "chartbuilder",
                "select-chart-data-collapse"
              )}
            >
              <Collapse
                bordered={true}
                activeKey={checkTreeExpend ? [] : activeKey}
                onChange={(value) => {
                  if (checkTreeExpend) undoResetTree();
                  setActiveKey([...value]);
                }}
              >
                {panels.map(({ name, tree, treeName }) => {
                  if (
                    !hasChartBuilderFlag &&
                    !featureList.includes(`chart-builder-${treeName}`)
                  )
                    return false;
                  return (
                    <Collapse.Panel
                      header={
                        <CORETypographyInput
                          type="label"
                          testID={generateTestId(
                            "chartbuilder",
                            `select-chart-data-collapse-${name}-panel`
                          )}
                        >
                          {name}
                        </CORETypographyInput>
                      }
                      key={name}
                    >
                      <TestIDWrapper
                        className={"tree-padding"}
                        testID={generateTestId("chartbuilder", `${name}-panel`)}
                      >
                        <span>{tree}</span>
                      </TestIDWrapper>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            </TestIDWrapper>
          </CORECard>
        </Col>
      </Row>
    </>
  );
};
