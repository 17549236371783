import React from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import "./COREInputNumber.less";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import classNames from "classnames";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const stepToDecimalPlaces = (step: string | number | undefined) =>
  step?.toString().split(".")[1]?.length;

type WidthSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "full";
type Size = "sm" | "md" | "lg";

export type COREInputNumberProps = Omit<InputNumberProps, "size"> & {
  testID: TestID;
  button?: boolean;
  widthSize?: WidthSize;
  size?: Size;
};

const CORESizeToAntSize: Record<Size, InputNumberProps["size"]> = {
  sm: "small",
  md: "middle",
  lg: "large",
};

export const COREInputNumber: React.FC<COREInputNumberProps> = ({
  testID,
  button,
  widthSize = "sm",
  size = "md",
  ...props
}) => {
  const hasValue: boolean =
    props.value !== undefined && props.value !== null && props.value !== "";

  const hasAddonBefore = !!props.addonBefore;
  const hasAddonAfter = !!props.addonAfter;
  const hasDisabled = !!props.disabled;

  const wrapperClass: string | undefined = classNames({
    "core-input-number-button": button,
    "core-input-number": !button,
  });

  const inputClass: string | undefined = classNames(
    `input-width-${widthSize}`,
    {
      "core-input-number-filled": hasValue,
      "addon-before": hasAddonBefore,
      "addon-after": hasAddonAfter,
      "core-input-number-disabled": hasDisabled,
    },
    props.className
  );

  const numberDecimalPlace = stepToDecimalPlaces(props.step);
  return (
    <TestIDWrapper testID={testID} className={wrapperClass}>
      <InputNumber
        {...props}
        precision={numberDecimalPlace}
        size={CORESizeToAntSize[size]}
        upHandler={
          <COREIcon icon={icon({ name: "caret-up", style: "solid" })} />
        }
        downHandler={
          <COREIcon icon={icon({ name: "caret-down", style: "solid" })} />
        }
        className={inputClass}
      />
    </TestIDWrapper>
  );
};
