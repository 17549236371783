import React from "react";
import { Card, Slider } from "antd";
import "./TimelineSlider.less";

const marks = {
  10: "1 Jan",
  20: "2 Jan",
  30: "3 Jan",
  40: "4 Jan",
  50: "5 Jan",
  60: "6 Jan",
  70: "7 Jan",
  100: "8 Jan",
};

export const TimelineSlider = (props) => {
  return (
    <div className={"wrapTimelineSlider"}>
      <Card className={"paddingLeft0"}>
        <Slider marks={marks} defaultValue={2} />
      </Card>
    </div>
  );
};
