import React from "react";
import { Badge } from "antd";
import { NumberFormat } from "../../../../shared/globals";
import { BadgeProps } from "antd/lib/badge";

type regionDetailsProps = {
  price: number;
  demand: number;
  generation: number;
  semi_generation: number; // eslint-disable-line camelcase
  availability: number;
  price_status: string; // eslint-disable-line camelcase
  intervention: number;
  weather: number;
};

type regionsType = {
  NSW1: regionDetailsProps;
  QLD1: regionDetailsProps;
  SA1: regionDetailsProps;
  TAS1: regionDetailsProps;
  VIC1: regionDetailsProps;
};

type regionValueType = {
  regions: regionsType;
  name: keyof regionsType;
  type: string;
  suffix?: string;
  prefix?: string;
};

export const RegionValue: React.FC<regionValueType> = ({
  regions,
  name,
  type,
  suffix = "",
  prefix = "",
}) => {
  if (
    regions === undefined ||
    regions[name] === undefined ||
    regions[name][type] === undefined
  ) {
    return <>-</>;
  }

  let number: number = regions[name][type];
  let priceStatus: BadgeProps["status"] = undefined;
  let decPlaces: number = 0;
  const hint: BadgeProps["title"][] = [];
  // priceStatus : "" is no badge, "error" is a red badge
  if (type === "price") {
    decPlaces = 2;
    if (regions[name].intervention === 1) {
      priceStatus = "error";
      hint.push("Intervention pricing");
    }
    if (regions[name].price_status !== "FIRM") {
      priceStatus = "error";
      hint.push("Not firm");
    }
  } else {
    number = Math.floor(number);
  }

  return (
    <Badge status={priceStatus} title={hint.join("; ")} offset={[5, 0]}>
      {prefix}
      {NumberFormat(number, decPlaces)} {suffix}
    </Badge>
  );
};
