/* eslint-disable react/prop-types */
import React, { ForwardedRef, forwardRef } from "react";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import {
  generateTree,
  joinedDataType,
  Key,
  CORETree,
  useCORETree,
} from "../../../COREDesignSystem/Form/CORETree";
import { TestID } from "../../../shared/testids/testids";

interface FCASTimeSeriesTreeType {
  multiple: boolean;
  activeProductOnly?: boolean;
  includeAgg?: boolean;
  testID: TestID;
}

/* eslint-disable camelcase */
export interface rowDataType {
  aemo_id: string;
  id: number;
  in_nem: boolean;
  name: string;
  short_name: string;
}
/* eslint-disable camelcase */
type Ref = HTMLElement;

const getJoinedData = (rawData: rowDataType[]): joinedDataType[] => {
  const joinedData: joinedDataType[] = [];
  const staticVar: string[] = [
    "Raise 6 sec RRP",
    "Raise 60 sec RRP",
    "Raise 5 min RRP",
    "Raise Reg RRP",
    "Lower 6 sec RRP",
    "Lower 60 sec RRP",
    "Lower 5 min RRP",
    "Lower Reg RRP",
  ];
  rawData.forEach((item) => {
    staticVar.forEach((variable) => {
      joinedData.push({
        id: item.id,
        name: item.name,
        shortName: item.short_name,
        aemoId: item.aemo_id,
        variable: variable,
        value: {
          id: item.id,
          aemoId: item.aemo_id,
          variable: variable,
          traceName: `${item.short_name} ${variable}`,
        },
      });
    });
  });
  return joinedData;
};

const FCASTimeSeriesTreeWithData: React.FC<{
  data: rowDataType[];
  multiple?: boolean;
  onChange?: (
    value: Key[],
    labelList: React.ReactNode[],
    extra: unknown
  ) => void;
  initialValue?: Key[];
  ref: ForwardedRef<Ref>;
  checkedKeys?:
    | Key[]
    | {
        checked: Key[];
        halfChecked: Key[];
      };
  testID: TestID;
}> = ({
  data: d,
  multiple,
  onChange: parentOnChange,
  initialValue,
  ref,
  checkedKeys,
  testID,
  ...otherProps
}) => {
  const data = getJoinedData(d);
  const treeData = generateTree(data, ["shortName", "variable"], "value", true);

  const tree = useCORETree(treeData, {
    onChange: parentOnChange,
    initialRootValues: initialValue,
  });
  return (
    <CORETree
      ref={ref}
      {...tree}
      multiple={multiple}
      checkedKeys={checkedKeys}
      {...otherProps}
      testID={testID}
    />
  );
};

export const FCASTimeSeriesTree = React.memo(
  forwardRef<Ref, FCASTimeSeriesTreeType>(
    (
      { multiple = false, activeProductOnly = false, testID, ...otherProps },
      ref
    ): JSX.Element => {
      const { data, sync } = useAPIQuery("regions_data", { in_nem: `eq.true` }); // eslint-disable-line camelcase
      if (!sync) return <CORELoading size={"lg"} />;

      return (
        <FCASTimeSeriesTreeWithData
          multiple={multiple}
          {...otherProps}
          ref={ref}
          data={data.data}
          testID={testID}
        />
      );
    }
  )
);
