import moment from "moment";
import {
  environmentalProductSortOrder,
  MonthsOrder,
  regionSortOrder,
} from "../globals";
import { momentFormat } from "../date/DateFormatContext";
import { AEMOTimezone } from "../date/DateTime";
import { keysToCamelCase } from "../global";
import { strToRange } from "../date/ranges";

const CFDSortOrder = {
  solar: 0,
  "inverse solar": 1,
  "super peak": 2,
  flat: 3,
  peak: 4,
  "off peak": 5,
  "flat cap": 6,
};
const tenorDatesCompare = (aTenorDates, bTenorDates) => {
  if (aTenorDates === null && bTenorDates === null) return 0;
  if (aTenorDates === null && bTenorDates !== null) return -1;
  if (aTenorDates !== null && bTenorDates === null) return 1;

  return aTenorDates.localeCompare(bTenorDates);
};

function tpCompare(a, b) {
  const aTenorDates = a.tradableProduct.tenor_dates;
  const bTenorDates = b.tradableProduct.tenor_dates;
  return tenorDatesCompare(aTenorDates, bTenorDates);
}

export const domain = () =>
  localStorage.getItem("domain") || process.env.REACT_APP_DOMAIN;
export const isDevEnv = () => domain() !== "https://api.coremarkets.co";
const restBaseUrl = "/rest";
export const fileBaseUrl = () => domain() + "/file";

const headers = {
  Prefer: "count=estimated",
};

const userConfigUpdates = [
  ({ refresh }) => refresh("user_configs"),
  ({ refresh }) => refresh("get_user_chart"),
];

const PostgrestAcceptType = "application/vnd.pgrst.object+json";
const returnRepresentation = "return=representation";
const returnMinimal = "return=minimal";
const returnMergeDuplicates = "resolution=merge-duplicates";
const returnCountExact = "count=exact";

const compareEnvironmentalProduct = (a, b) => {
  const aPosition = environmentalProductSortOrder.indexOf(
    a.environmental_product.name
  );
  const bPosition = environmentalProductSortOrder.indexOf(
    b.environmental_product.name
  );
  if (aPosition < bPosition) return -1;
  if (aPosition > bPosition) return 1;
  if (aPosition === bPosition) return 0;

  return a.vintage - b.vintage;
};

const compareCFDProduct = (a, b) => {
  const aPosition =
    CFDSortOrder[a.cfd_product.shape.type + (a.isCap ? " cap" : "")];
  const bPosition =
    CFDSortOrder[b.cfd_product.shape.type + (b.isCap ? " cap" : "")];
  if (aPosition > bPosition) return 1;
  if (aPosition < bPosition) return -1;
  const aRegionPos = regionSortOrder.indexOf(
    a.cfd_product.shape.region.short_name
  );
  const bRegionPos = regionSortOrder.indexOf(
    b.cfd_product.shape.region.short_name
  );
  if (aRegionPos > bRegionPos) return 1;
  if (aRegionPos < bRegionPos) return -1;
  const aMonth = MonthsOrder.indexOf(a.cfd_product.tenor.name);
  const bMonth = MonthsOrder.indexOf(b.cfd_product.tenor.name);
  if (aMonth !== bMonth) return aMonth - bMonth;

  return a.vintage - b.vintage;
};

export const DEFAULT_REQUEST_PRIORITY = 100; // lowest number runs first

/* eslint-disable camelcase */
export const ENDPOINTS = {
  certificateMethodTypeProducts: {
    url: restBaseUrl + `/certificate_method_type_products`,
  },
  activity: { url: restBaseUrl + `/activity` },
  user: {
    url: restBaseUrl + `/rpc/me`,
  },
  login: {
    url: restBaseUrl + `/rpc/login`,
    options: {
      method: "POST",
    },
    neverRefresh: true,
  },
  logout: {
    url: restBaseUrl + `/rpc/logout`,
    options: {
      method: "GET",
    },
  },
  changePasswordEmailRequest: {
    url: restBaseUrl + `/rpc/change_password_email_request`,
    options: {
      method: "POST",
    },
  },
  changePasswordEmailResponse: {
    url: `/change_password_email_response`,
    options: {
      method: "POST",
    },
  },
  changePassword: {
    url: `/change_password`,
    options: {
      method: "POST",
    },
  },
  changePasswordEmailForAdminRequest: {
    url: restBaseUrl + `/rpc/change_password_admin`,
    options: {
      method: "POST",
    },
  },
  verifyTwoFactor: {
    url: restBaseUrl + `/rpc/verify`,
    options: {
      method: "POST",
      token: false,
    },
  },
  enableTotp: {
    url: restBaseUrl + `/rpc/enable_totp`,
    options: {
      method: "POST",
    },
  },
  livePrices: {
    url: restBaseUrl + `/live_prices`,
    options: {
      headers: { ...headers, Accept: PostgrestAcceptType },
    },
  },
  liveInterconnectors: {
    url: restBaseUrl + `/live_interconnectors`,
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  interconnectorFlows: {
    url:
      restBaseUrl +
      `/interconnector_flows?select=interconnectorid,mwflow,importlimit,exportlimit,settlementdate&order=settlementdate&settlementdate=gte.:gte&settlementdate=lt.:lt`,
    transformer: (data) => {
      if (data === undefined) return;
      return {
        "N-Q-MNSP1": data.filter(
          (interconnectorFlows) =>
            interconnectorFlows.interconnectorid === "N-Q-MNSP1"
        ),
        "NSW1-QLD1": data.filter(
          (interconnectorFlows) =>
            interconnectorFlows.interconnectorid === "NSW1-QLD1"
        ),
        "T-V-MNSP1": data.filter(
          (interconnectorFlows) =>
            interconnectorFlows.interconnectorid === "T-V-MNSP1"
        ),
        "VIC1-NSW1": data.filter(
          (interconnectorFlows) =>
            interconnectorFlows.interconnectorid === "VIC1-NSW1"
        ),
        "V-SA": data.filter(
          (interconnectorFlows) =>
            interconnectorFlows.interconnectorid === "V-SA"
        ),
        "V-S-MNSP1": data.filter(
          (interconnectorFlows) =>
            interconnectorFlows.interconnectorid === "V-S-MNSP1"
        ),
      };
    },
  },
  duids: {
    url:
      restBaseUrl +
      `/duids?select=duid,stationnameDuid%3Astationname_duid,aemoRegion%3Aaemo_region,co2eEnergySource%3Aco2e_energy_source&order=aemo_region.asc.nullslast,co2e_energy_source.asc.nullslast,duid.asc.nullslast`,
  },
  eod: {
    url: restBaseUrl + `/eod?select=cfd_product,date,close`,
  },
  snapshots: {
    url: restBaseUrl + `/rpc/snapshots`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
    transformer: (data) => data.sort(tpCompare),
    headers: {
      ...headers,
      Prefer: returnRepresentation,
    },
  },
  snapshots_detail: {
    url: restBaseUrl + `/rpc/snapshot_details`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
    transformer: (data) => data.sort(tpCompare),
  },
  environmental_snapshots: {
    url:
      restBaseUrl + `/environmental_snapshots?order=tradable_product_name.desc`,
    options: {
      headers: {
        ...headers,
      },
    },
    transformer: (r) => {
      return r.sort((a, b) => {
        const field = a.hasOwnProperty("tradableProduct")
          ? "tradableProduct"
          : "tradable_product";
        if (a[field].tenor_dates === null || b[field].tenor_dates === null) {
          return -1;
        }

        return a[field].tenor_dates.localeCompare(b[field].tenor_dates);
      });
    },
  },
  rehub_bid_offers: {
    url: restBaseUrl + `/rehub_bid_offers`,
  },
  tenor_shape_performance_rollups: {
    url: restBaseUrl + `/tenor_shape_performance_rollups?shape_type=:shapeType`,
  },
  tradable_products: {
    options: {
      headers: { Prefer: returnCountExact },
    },
    url:
      restBaseUrl +
      "/tradable_products?select=id,shortName%3Ashort_name,name_parts,vintage,isStrip%3Ais_strip,isCap%3Ais_cap,environmentalProduct%3Aenvironmental_products!environmental_product(id,name,tenor%3Atenors(id,name)),project_method_types%3Aproject_method_types!project_method_type(*),cfdProduct%3Acfd_products!cfd_product(id,tenor%3Atenors(id,name),shape%3Ashapes(id,type,region%3Aregions(short_name))),tenor&order=vintage.nullsfirst,tenor_type,tenor_dates",
    transformer: (data) =>
      data.sort((a, b) => {
        if (a.environmental_product && b.environmental_product) {
          return compareEnvironmentalProduct(a, b);
        }
        if (a.cfd_product && b.cfd_product) {
          return compareCFDProduct(a, b);
        }

        return 0;
      }),
  },
  tradableProduct: {
    url:
      restBaseUrl +
      `/tradable_products?select=*,productTypes%3Aproduct_types(currency)`,
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  rehub_trade_logs: {
    url:
      restBaseUrl +
      `/rehub_trade_logs?select=id,seqNumber%3Aseq_number,expiryDate%3Aexpiry_date,time,volume,price,buyer,seller,created_user:users!created_user(name),created_time:created_at,modified_user:users!modified_user(name),modified_time:created_at,broker,notes,tp_id,settlementDate%3Asettlement_date,reported,name,code&order=time.desc,seq_number.desc`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  addRehubTradeLogs: {
    url: restBaseUrl + `/rpc/insert_rehub_trade_log`,
    options: {
      method: "POST",
      headers,
    },
    neverRefresh: true,
  },
  deleteRehubTradeLogs: {
    url: restBaseUrl + `/rehub_trade_logs?id=eq.:id`,
    neverRefresh: true,
    options: {
      method: "DELETE",
      headers: {
        ...headers,
        Prefer: returnMinimal,
      },
    },
  },
  updateRehubTradeLogs: {
    url: restBaseUrl + `/rehub_trade_logs?id=eq.:id`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  addUsersManagement: {
    url: restBaseUrl + `/users?select=id`,
    neverRefresh: true,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  deleteUsersManagement: {
    neverRefresh: true,
    url: restBaseUrl + `/users?id=eq.:id`,
    options: {
      method: "DELETE",
      headers: {
        ...headers,
        Prefer: returnMinimal,
      },
    },
    transformer: (data) => {
      if (data === undefined) {
        return undefined;
      }
      return data;
    },
  },
  editUsersManagement: {
    neverRefresh: true,
    url: restBaseUrl + `/users?select=id&id=eq.:id`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  listUsersManagement: {
    url:
      restBaseUrl +
      `/users?role=neq.api_account&select=id,name,email,phone,role,totp_status,most_recent_change_password_requested_at,most_recent_change_password_requested_by,company%3Acompanies!user_company_fkey(id, name, business_number),latestLogged%3Alast_login`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  listUserAnalytics: {
    url:
      restBaseUrl +
      `/user_page_analytics?select=page,version,time,user%3Ausers!inner(id,name,company%3Acompanies!user_company_fkey(id, name))&order=time.desc`,
    options: {
      headers,
    },
  },
  getUserName: {
    url:
      restBaseUrl +
      `/users?select=id,name,company%3Acompanies!user_company_fkey(id, name)`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  myDetails: {
    url: restBaseUrl + `/users`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  recordUserVisitPage: {
    url: restBaseUrl + `/user_page_analytics`,
    options: {
      method: "POST",
      headers,
    },
  },
  user_configs: {
    url: restBaseUrl + `/user_configs`,
  },
  user_configs_post: {
    neverRefresh: true,
    url: restBaseUrl + `/user_configs`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
      method: "POST",
    },
    postfetch: [...userConfigUpdates],
  },
  user_configs_patch: {
    neverRefresh: true,
    url: restBaseUrl + `/user_configs?id=eq.:id`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
    postfetch: [...userConfigUpdates],
  },
  user_configs_delete: {
    neverRefresh: true,
    url: restBaseUrl + `/user_configs?id=eq.:id`,
    options: {
      method: "DELETE",
      headers: {
        ...headers,
      },
    },
    postfetch: [...userConfigUpdates],
  },
  get_user_chart: {
    url: restBaseUrl + `/users?select=user_configs(charts)&me=eq.true`,
  },
  distinct_shape_types: {
    url: restBaseUrl + `/distinct_shape_types`,
  },
  userProfile: {
    url:
      restBaseUrl +
      `/users?select=id,name,email,phone,role,me,company%3Acompanies!user_company_fkey(id, name, business_number)&me=eq.true`,
  },
  userFeatures: {
    url: restBaseUrl + `/user_features?select=features`,
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  regions_data: {
    url: restBaseUrl + `/regions`,
  },
  regionsDataFilter: {
    url: restBaseUrl + `/regions?select=*,shortName%3Ashort_name&:propsFilter`,
  },

  //company management
  companyData: {
    url: restBaseUrl + `/companies`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  addCompanyData: {
    url: restBaseUrl + `/companies`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  deleteCompanyData: {
    url: restBaseUrl + `/companies?id=eq.:id`,
    options: {
      method: "DELETE",
      headers: {
        ...headers,
        Prefer: returnMinimal,
      },
    },
  },
  updateCompanyData: {
    url: restBaseUrl + `/companies?id=eq.:id`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  featureDataAll: {
    url: restBaseUrl + `/distinct_company_allowed_features?order=feature.asc`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  featureCompany: {
    url: restBaseUrl + `/company_allowed_features`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  addFeatureCompany: {
    url: restBaseUrl + `/company_allowed_features`,
    options: {
      method: "POST",
    },
  },
  deleteFeatureCompany: {
    url: restBaseUrl + `/company_allowed_features`,
    options: {
      method: "DELETE",
    },
  },
  scopesDataAll: {
    url: restBaseUrl + `/scopes`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  scopesCompany: {
    url: restBaseUrl + `/company_scopes`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  addScopesCompany: {
    url: restBaseUrl + `/company_scopes`,
    options: {
      method: "POST",
    },
  },
  deleteScopesCompany: {
    url: restBaseUrl + `/company_scopes`,
    options: {
      method: "DELETE",
    },
  },

  price_scenario_filter_rollups: {
    url: restBaseUrl + `/rpc/price_scenario_filter_rollups?scenario=:scenario`,
  },
  manage_load_profile: {
    url: restBaseUrl + `/load_profiles?id=eq.:id`,
    crud: true,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
    neverRefresh: true,
  },

  getForwardPriceCurveShapeProfileById: {
    url:
      restBaseUrl +
      `/generation_profiles?select=id,name,region,start,end,region(id, short_name)&id=eq.:id`,
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  manage_forward_price_curve_profile: {
    url: restBaseUrl + `/forward_price_curves?id=eq.:id`,
    crud: true,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
    neverRefresh: true,
  },
  manage_forward_price_curve_profile_shape: {
    url:
      restBaseUrl +
      `/forward_price_curve_shapes?forward_price_curve=eq.:load_profile`,
    crud: true,
    neverRefresh: true,
  },
  refreshToken: {
    url: restBaseUrl + `/rpc/refresh_token`,
    options: {
      method: "POST",
    },
    neverRefresh: true,
  },
  cfdProducts: {
    url:
      restBaseUrl +
      `/cfd_products?select=id,name,shape,shape_type,region_short_name,tenor,tenor_name,cap_price`,
  },
  cfdProduct: {
    url:
      restBaseUrl +
      `/cfd_products?select=id,name,shape,shape_type,region_short_name,tenor,tenor_name,cap_price&id=eq.:id`,
    cache: {
      expire: 10 * 60,
      id: ({ id }) => id,
    },
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  stations: {
    url: restBaseUrl + `/weather_stations`,
    cache: { expire: 3 * 60 },
  },
  distinctScenarioStatuses: {
    url:
      restBaseUrl +
      `/distinct_scenario_statuses?select=scenarioStatus%3Ascenario_status`,
  },
  scenarios: {
    url: restBaseUrl + `/scenarios`,
    options: {
      headers,
    },
  },
  updateScenarios: {
    url: restBaseUrl + `/scenarios`,
    options: {
      method: "PATCH",
      headers,
    },
  },
  deleteScenarios: {
    url: restBaseUrl + `/scenarios`,
    options: {
      method: "DELETE",
      headers,
    },
  },
  copyScenarios: {
    url: restBaseUrl + `/rpc/copy_scenarios`,
    options: {
      method: "POST",
      headers,
    },
  },
  resetScenarios: {
    url: restBaseUrl + `/rpc/reset_scenarios`,
    options: {
      method: "POST",
      headers,
    },
  },
  updateScenariosGenerate: {
    url: restBaseUrl + `/rpc/update_scenarios`,
    options: {
      method: "POST",
      headers,
    },
  },
  updateScenariosPrepare: {
    url: restBaseUrl + `/rpc/prepare_scenarios`,
    options: {
      method: "POST",
      headers,
    },
  },
  getScenarioTypes: {
    url:
      restBaseUrl +
      `/distinct_scenario_types?select=scenarioType%3Ascenario_type`,
  },
  getScenarioOffsetGoals: {
    url: restBaseUrl + `/distinct_offset_goals?select=offsetGoal%3Aoffset_goal`,
  },
  jobDetails: {
    url: restBaseUrl + `/job_details`,
    options: {
      method: "GET",
      headers,
    },
  },
  market_notices: {
    url: restBaseUrl + `/market_notices?order=notice_id.desc`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  get_product_data: {
    url:
      restBaseUrl +
      `/trading?select=:col&order=settlementdate&regionid=in.((:regionid)):filter`,
  },
  latest_station_observations: {
    url: restBaseUrl + `/latest_station_observations`,
  },
  get_observations: {
    url:
      restBaseUrl + `/observations?select=:col&station=in.((:station))&:filter`,
  },
  get_snapshots_chart: {
    url: restBaseUrl + `/cfd_snapshots?select=:col&:filter`,
  },
  get_environmental_snapshots: {
    url: restBaseUrl + `/environmental_snapshots?select=:col&:filter`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  tradableProductCurves: {
    url:
      restBaseUrl +
      `/tradable_product_curves?tradable_product=in.((:tradableProduct))&order=curve_date`,
  },
  current_rehub_futures_tradable_ids: {
    url:
      restBaseUrl +
      `/shapes?select=cfd_products(tradable_products(id))&type=eq.:name&cfd_products.tradable_products.vintage=gte.${moment().year()}&cfd_products.tradable_products.vintage=lte.${moment()
        .add(4, "year")
        .year()}`,
  },
  current_rehub_futures_300_cap_tradable_ids: {
    url:
      restBaseUrl +
      `/shapes?select=cfd_products(tradable_products(id))&type=eq.flat&cfd_products.tradable_products.name=like.*flat*300%20cap*&cfd_products.tradable_products.vintage=gte.${moment().year()}&cfd_products.tradable_products.vintage=lte.${moment()
        .add(4, "year")
        .year()}`,
  },
  environmental_tradable_ids: {
    url:
      restBaseUrl +
      `/environmental_products?select=tradable_products(id)&name=eq.:name`,
  },
  get_curve_chart: {
    url: restBaseUrl + `/tradable_product_curves?select=:col&:filter`,
  },
  getProductCurvePivots: {
    url:
      restBaseUrl +
      `/rpc/daily_curves?date=:date&type=:type&only_show_published_prices=false&select=price,change,tradable_product_tenor_type,modifiedAt%3Amodified_at,tradableProductId%3Atradable_product_id,tradableProductCode%3Atradable_product_code,nameParts%3Atradable_product_name_parts,tenorDates%3Atradable_product_tenor_dates,vintage%3Atradable_product_vintage,certificateProductPremiumCurves%3Acertificate_product_premium_curves,tradableProductType%3Atradable_product_type`,
    transformer: (data) =>
      data
        .sort((a, b) => tenorDatesCompare(a.tenorDates, b.tenorDates))
        .map(
          ({
            price,
            change,
            modifiedAt,
            tradableProductCode,
            nameParts: nameP,
            vintage,
            tradableProductId,
            certificateProductPremiumCurves,
          }) => {
            const isEnvironmentalProduct = !nameP[3]; // environmental products have no name part 4
            if (!isEnvironmentalProduct) {
              const [region, shape, cap, tenor] = nameP;
              const name = `${region} ${shape}`;

              return {
                price,
                change,
                modifiedAt,
                tradableProductCode,
                region,
                shape,
                term: `${tenor} ${vintage}`,
                tenor,
                name,
                tradableProductId,
                cap,
                certificateProductPremiumCurves,
              };
            }
            const [shape, tenor] = nameP;
            const name = `${shape} ${tenor}`;
            return {
              price,
              change,
              modifiedAt,
              tradableProductCode,
              shape,
              tenor,
              vintage,
              name,
              term: `${tenor} ${vintage}`,
              tradableProductId,
              certificateProductPremiumCurves,
            };
          }
        ),
  },

  getProductDailyCurve: {
    url:
      restBaseUrl +
      `/rpc/daily_curves?date=:date&type=:type&only_show_published_prices=false&select=price,change,tradable_product_tenor_type,modifiedAt%3Amodified_at,tradableProductId%3Atradable_product_id,tradableProductCode%3Atradable_product_code,nameParts%3Atradable_product_name_parts,tenorDates%3Atradable_product_tenor_dates,vintage%3Atradable_product_vintage,certificateProductPremiumCurves%3Acertificate_product_premium_curves,currency%3Atradable_product_currency,shortName%3Atradable_product_short_name,hasBaseCurve%3Ahas_nonmethodspecific_curve,productType%3Atradable_product_type,productTypeName%3Atradable_product_type_name&or=(tradable_product_tenor_type.is.null, tradable_product_tenor_type.neq.monthly)`,
  },

  post_cfd_product_curve_pivots: {
    url: restBaseUrl + `/tradable_product_curves`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  post_certificate_product_premium_curves: {
    url: restBaseUrl + `/certificate_product_premium_curves`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  getEnvironmentalProductTree: {
    url:
      restBaseUrl + `/environmental_products?select=id, name,tenor(id, name)`,
  },
  getEnvironmentalProductById: {
    url:
      restBaseUrl +
      `/environmental_products?select=id, name,tenor(id, name)&id=eq.:id`,
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  getEnvironmentalTradableProductIds: {
    url:
      restBaseUrl +
      `/environmental_products?select=tradable_products(id)&id=eq.:id`,
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  getEnvironmentalPriceTable: {
    url:
      restBaseUrl +
      `/environmental_snapshots?tradable_product=in.((:tradableProduct))`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  getGenerationProfilesTree: {
    url:
      restBaseUrl +
      `/generation_profiles?select=id,name,region,start,end,region(id, short_name)`,
  },
  getGenerationProfileById: {
    url:
      restBaseUrl +
      `/generation_profiles?select=id,name,region,start,end,region(id, short_name)&id=eq.:id`,
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  stationsWithRegions: {
    url:
      restBaseUrl +
      `/weather_stations?select=id,name,is_primary_station,regions(id,name,short_name,aemo_id)`,
    cache: { expire: 3 * 60 },
  },
  primaryStations: {
    url:
      restBaseUrl +
      `/weather_stations?select=id,name,is_primary_station,regions(id,name,short_name,aemo_id)&is_primary_station=eq.true`,
    cache: { expire: 3 * 60 },
  },
  getStationById: {
    url: restBaseUrl + `/weather_stations?id=eq.:id`,
    cache: {
      expire: 10 * 60,
      id: ({ id }) => id,
    },
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  getLoadProfilesTree: {
    url:
      restBaseUrl +
      `/load_profiles?select=id,name,region,start,end,region(id, short_name)`,
  },
  getLoadProfileById: {
    url:
      restBaseUrl +
      `/load_profiles?select=id,name,region,start,end,region(id, short_name)&id=eq.:id`,
    cache: {
      expire: 10 * 60,
      id: ({ id }) => id,
    },
    options: {
      headers: {
        ...headers,
        Accept: PostgrestAcceptType,
      },
    },
  },
  getAllInterconnectors: {
    url: restBaseUrl + `/interconnectors`,
  },
  aggregates: {
    url: restBaseUrl + `/rpc/aggregates`,
    options: {
      method: "POST",
    },
  },
  traderViewAggregates: {
    url:
      restBaseUrl +
      `/rpc/aggregates?table_name=tradable_cfd_product_schedule_generator&column_name=actual_volume&selected_item=:tradableProductId&agg_period=all&agg_function=sum&date_range=[,]`,
  },
  traderViewFilterRollups: {
    url: restBaseUrl + `/rpc/trader_view_filter_rollups`,
    neverRefresh: true,
    options: {
      method: "POST",
    },
  },
  addCompany: {
    url: restBaseUrl + `/companies`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnMinimal,
      },
    },
  },

  // Broker
  brokersList: {
    url: restBaseUrl + `/brokers?limit=500&order=broker`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  tradePartiesList: {
    url: restBaseUrl + `/trade_parties?order=party`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  feedbacks: {
    url: restBaseUrl + `/feedbacks`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnMinimal,
      },
    },
  },
  rehubTradeTimelines: {
    url: restBaseUrl + `/rehub_trade_timelines`,
    options: {
      headers: {
        Prefer: returnCountExact,
      },
    },
  },

  ///AssetPlus
  generationProfileAsset: {
    url: restBaseUrl + `/assets_portfolios`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  ApAssetProfile: {
    url: restBaseUrl + `/ap_asset_profiles`,
    options: { headers },
  },

  priceCurvesAsset: {
    url: restBaseUrl + `/ap_price_curves`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  calculationsPriceCurves: {
    url:
      restBaseUrl +
      `/ap_price_curve_calculations?order=created_at.desc&limit=1`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  recalculationsPriceCurves: {
    url: restBaseUrl + `/rpc/create_ap_price_curve_calculation`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  apPriceCurveResults: {
    url: restBaseUrl + `/ap_price_curve_results`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  apPriceCurveResultDetails: {
    url: restBaseUrl + `/ap_price_curve_result_details`,
    options: { headers },
    transformer: (data) =>
      data.map((d) => {
        const AEMOTime = moment(d.start_time)
          .tz(AEMOTimezone)
          .format(momentFormat.csvDateTime.format);

        return {
          ...d,
          start_time: AEMOTime,
        };
      }),
  },

  profileScenariosAsset: {
    url: restBaseUrl + `/ap_scenarios`,
    options: {
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  profileScenariosOfftakesAsset: {
    url: restBaseUrl + `/asset_portfolio_scenario_offtakes`,
    options: {
      headers,
    },
  },

  profileScenariosContractsAsset: {
    url: restBaseUrl + `/ap_scenario_contracts`,
    options: {
      headers,
    },
  },

  shapes: {
    url: restBaseUrl + `/shapes`,
    options: {
      headers,
    },
  },

  profileScenariosLivemarketAsset: {
    url: restBaseUrl + `/asset_portfolio_scenario_live_contracts`,
    options: {
      headers,
    },
  },
  apScenarioOfftakes: {
    url: restBaseUrl + `/ap_scenario_offtakes?order=name.asc,period.asc`,
    options: {
      headers,
    },
  },
  apScenarioLoad: {
    url: restBaseUrl + `/ap_scenario_loads`,
    options: {
      headers,
    },
  },
  createApPriceCurveResultCalculation: {
    url: restBaseUrl + `/rpc/create_ap_price_curve_result_calculation`,
    options: {
      headers,
    },
  },
  postCommentariesCurve: {
    url:
      restBaseUrl +
      `/environmental_product_curve_commentaries?on_conflict=date,certificate`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnMergeDuplicates,
      },
    },
  },
  getCurvePublish: {
    url: restBaseUrl + `/curve_publication_statuses`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  createApProfileFromAemoScada: {
    url: restBaseUrl + `/rpc/create_ap_profile_from_aemo_scada`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnMergeDuplicates,
      },
    },
  },
  inFocusTradableProducts: {
    url: restBaseUrl + `/in_focus_tradable_products`,
    postfetch: [({ refresh }) => refresh("snapshots_detail")],
  },
  getGreenMarketProductTypes: {
    url:
      restBaseUrl +
      `/product_types?green_marketplace_sort_order=not.is.null&order=green_marketplace_sort_order.asc`,
    options: {
      headers: {
        ...headers,
      },
    },
  },

  certificateProjects: {
    url: restBaseUrl + `/certificate_projects?product_type=eq.:productType`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  certificateProjectTypesGreenMarket: {
    url: restBaseUrl + `/certificate_project_types`,
  },
  certificateProjectDetails: {
    url: restBaseUrl + `/certificate_projects?id=eq.:id`,
  },
  certificateProjectSdGoals: {
    url:
      restBaseUrl + `/certificate_project_sd_goals?certificate_project=eq.:id`,
  },
  certificateProjectGreenProjects: {
    url: restBaseUrl + `/certificate_projects?order=name.asc`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  addCertificateProject: {
    url: restBaseUrl + `/certificate_projects`,
    neverRefresh: true,
    options: {
      method: "POST",
      headers: {
        ...headers,
      },
    },
  },
  editCertificateProject: {
    url: restBaseUrl + `/certificate_projects?id=eq.:id`,
    neverRefresh: true,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
      },
    },
  },
  certificateProjectTypesDropdown: {
    url:
      restBaseUrl + `/certificate_project_types?product_type=eq.:productType`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  unitedNationsSDGoals: {
    url: restBaseUrl + `/united_nations_sd_goals`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  greenProjectProductTypes: {
    url:
      restBaseUrl +
      `/product_types?green_marketplace_sort_order=not.is.null&order=green_marketplace_sort_order.asc&select=code%2CproductClass%3Aproduct_class%2Cname%2ClongName%3Along_name`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  allGreenProjectProductTypes: {
    url:
      restBaseUrl +
      `/product_types?order=green_marketplace_sort_order.asc&select=code%2CproductClass%3Aproduct_class%2Cname%2ClongName%3Along_name`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  tenors: {
    url:
      restBaseUrl +
      `/tenors?or=(and(type.eq.yearly,start_month.eq.1),type.is.null)`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  certificateProjectType: {
    url: restBaseUrl + `/certificate_project_types`,
  },
  certificateProductPeriods: {
    url:
      restBaseUrl +
      `/certificate_product_periods?order=tenor_type.nullsfirst,vintage.nullsfirst,delivery_year.nullsfirst,start_month.nullsfirst`,
  },
  greenOfferDetails: {
    url: restBaseUrl + `/green_offer_details`,
    options: {
      headers: {
        ...headers,
      },
    },
  },

  productCurveCommentaries: {
    url: restBaseUrl + `/product_curve_commentaries`,
    options: {
      headers: {
        ...headers,
      },
    },
  },

  earliestVintage: {
    url:
      restBaseUrl +
      `/product_types?green_marketplace_sort_order=not.is.null&code=eq.:code&select=earliestVintage%3Aearliest_vintage`,
  },

  setProductCurveCommentaries: {
    url: restBaseUrl + `/rpc/set_commentaries`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnMergeDuplicates,
      },
    },
  },

  postCurvePublicationStatuses: {
    url: restBaseUrl + `/curve_publication_statuses`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnMergeDuplicates,
      },
    },
  },

  getCurvePublicationStatuses: {
    url: restBaseUrl + `/curve_publication_statuses`,
    options: {
      headers: {
        ...headers,
        Prefer: returnMergeDuplicates,
      },
    },
  },

  patchCurvePublicationStatuses: {
    url: restBaseUrl + `/curve_publication_statuses?id=eq.:id`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
      },
    },
  },

  getProductForElectricityProductTree: {
    url: restBaseUrl + `/product_types?product_class=in.(rehub,electricity)`,
  },

  getAllProductTypes: {
    url: restBaseUrl + `/product_types`,
  },

  getProductTypesByProductClass: {
    url: restBaseUrl + `/product_types?product_class=eq.:productClass`,
  },

  uploadFile: {
    url: `/file/upload`,
    options: {
      method: "POST",
      headers,
    },
  },

  processEmissionCsv: {
    url: restBaseUrl + `/rpc/process_emission_csv`,
    options: {
      method: "POST",
      headers,
    },
  },

  fileJobStatus: {
    url: restBaseUrl + `/files`,
    options: {
      method: "GET",
      headers,
      refetchInterval: 5e3,
      staleTime: 5e3,
    },
  },

  avaliableTradableProducts: {
    url:
      restBaseUrl +
      `/tradable_products?certificate_project=eq.:id&select=id&is_active=eq.true`,
  },

  tradableProductsGreenProject: {
    url:
      restBaseUrl + `/tradable_products?certificate_project=eq.:id&select=id`,
  },

  environmentalProducts: {
    url: restBaseUrl + `/environmental_products`,
  },

  certificateProjectProductBidsOffers: {
    url: restBaseUrl + `/rpc/certificate_project_product_bids_offers`,
    options: {
      method: "POST",
      headers: headers,
    },
  },

  productTypeRegions: {
    url: restBaseUrl + `/product_type_regions`,
  },

  projectMethodTypes: {
    url: restBaseUrl + `/project_method_types`,
  },

  addTradableProductCurves: {
    url: restBaseUrl + `/tradable_product_curves`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },

  portfolios: {
    url: restBaseUrl + `/portfolios`,
    options: {
      headers,
    },
  },
  editPortfolios: {
    url: restBaseUrl + `/portfolios`,
    options: {
      method: "PATCH",
      headers,
    },
  },
  portfolioCategoryHeadings: {
    url: restBaseUrl + `/portfolio_category_headings`,
  },
  editPortfolioCategoryHeadings: {
    url: restBaseUrl + `/portfolio_category_headings`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  setCategoryHeadings: {
    url: restBaseUrl + `/rpc/set_category_headings`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  categoryDefaults: {
    url: restBaseUrl + `/category_defaults`,
  },
  userAccessibleSections: {
    url:
      restBaseUrl +
      `/user_accessible_sections?sort_order=not.is.null&order=sort_order.asc&select=id,section,title,subtitle,description,callToActionText%3Acall_to_action_text,callToActionUrl%3Acall_to_action_url,sortOrder%3Asort_order,featureFlag%3Afeature_flag,isShownInMenu%3Ais_shown_in_menu&:filter`,
  },
  createOrUpdatePortfolios: {
    url: restBaseUrl + `/rpc/create_or_update_portfolios`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  initiativeCategoryBreakdownsCombined: {
    url:
      restBaseUrl +
      `/rpc/initiative_category_breakdowns?select=labels,assigned,gross,grossRatio%3Agross_ratio`,
  },
  initiativeCategoryBreakdowns: {
    url:
      restBaseUrl +
      `/rpc/initiative_category_breakdowns?select=labels,assigned,gross,grossRatio%3Agross_ratio&portfolio=:id`,
  },
  portfolioCategoryEmissions: {
    url:
      restBaseUrl +
      `/rpc/portfolio_category_emissions_breakdowns?select=labels,assigned,gross,grossRatio%3Agross_ratio&portfolio_period_scope=:id`,
  },
  initiativeSummaries: {
    url: restBaseUrl + `/initiative_summaries?:where`,
    transformer: (data) => {
      return keysToCamelCase(data);
    },
  },
  distinctScope3Categories: {
    url: restBaseUrl + `/distinct_scope_3_categories`,
  },
  initiativeCategories: {
    url: restBaseUrl + `/initiative_categories`,
  },
  initiativeTypes: {
    url: restBaseUrl + `/initiative_types`,
  },
  initiatives: {
    url: restBaseUrl + `/initiatives`,
    options: {
      headers: headers,
    },
  },
  createInitiative: {
    url: restBaseUrl + `/initiatives`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  updateInitiative: {
    url: restBaseUrl + `/initiatives`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  deleteInitiative: {
    url: restBaseUrl + `/initiatives`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  initiativeFiles: {
    url: restBaseUrl + `/initiative_files`,
  },
  deleteInitiativeFiles: {
    url: restBaseUrl + `/initiative_files`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  initiativeStatuses: {
    url: restBaseUrl + `/initiative_statuses`,
  },
  initiativeMonthlyImpacts: {
    url: restBaseUrl + `/initiative_monthly_impacts`,
  },
  upsertInitiativeMonthlyImpacts: {
    url:
      restBaseUrl + `/initiative_monthly_impacts?on_conflict=initiative,month`,
    options: {
      method: "POST",
      headers: {
        ...headers,
      },
    },
  },
  initiativeComments: {
    url: restBaseUrl + `/initiative_comments?initiative=eq.:initiative`,
    options: {
      headers: headers,
    },
  },
  createCommentToInitiative: {
    url: restBaseUrl + `/initiative_comments`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  editInitiativeComments: {
    url: restBaseUrl + `/initiative_comments`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  deleteInitiativeComments: {
    url: restBaseUrl + `/initiative_comments`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  initiativeTimelines: {
    url: restBaseUrl + "/rpc/initiative_timelines",
  },
  distinctEmissionScopes: {
    url: restBaseUrl + `/distinct_emission_scopes`,
  },
  distinctCreditTypes: {
    url: restBaseUrl + `/distinct_credit_types`,
  },
  getEmissions: {
    transformer: (data) =>
      data.map(({ activeRange, ...emission }) => ({
        activeRange: strToRange(activeRange),
        activeRangeText: activeRange,
        ...emission,
      })),
    url: restBaseUrl + `/emissions`,
    options: {
      headers: headers,
    },
  },
  emissionTypes: {
    url: restBaseUrl + `/distinct_emission_types`,
  },
  createEmissions: {
    url: restBaseUrl + `/emissions`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  deleteEmissions: {
    url: restBaseUrl + `/emissions`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  emissionsCSVTemplate: {
    url: restBaseUrl + `/rpc/emissions_csv_template`,
    options: {
      method: "GET",
      headers: { ...headers },
    },
  },

  offsets: {
    url: restBaseUrl + `/offsets`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  createOffset: {
    url: restBaseUrl + `/offsets`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  updateOffset: {
    url: restBaseUrl + `/offsets`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  deleteOffset: {
    url: restBaseUrl + `/offsets`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  createCommentToOffset: {
    url: restBaseUrl + `/offset_comments`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  createComments: {
    url: restBaseUrl + `/comments`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  comments: {
    url: restBaseUrl + `/comments`,
    options: {
      headers: headers,
    },
  },
  offsetComments: {
    url: restBaseUrl + `/offset_comments?emission_offset=eq.:emissionOffset`,
    options: {
      headers: headers,
    },
  },
  editComments: {
    url: restBaseUrl + `/comments?id=eq.:id`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  editOffsetComments: {
    url: restBaseUrl + `/offset_comments`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  deleteOffsetComments: {
    url: restBaseUrl + `/offset_comments`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  offsetAssignments: {
    url: restBaseUrl + `/offset_assignments`,
    options: {
      headers: headers,
    },
  },
  createOffsetAssignments: {
    url: restBaseUrl + `/offset_assignments`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  editOffsetAssignments: {
    url: restBaseUrl + `/offset_assignments?id=eq.:id`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  deleteOffsetAssignments: {
    url: restBaseUrl + `/offset_assignments?id=eq.:id`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  tradableProductsTradeTacker: {
    url: restBaseUrl + `/tradable_products`,
  },
  emissionTargetTypes: {
    url: restBaseUrl + `/distinct_emission_target_types`,
  },
  editPortfolioPeriodScopes: {
    url: restBaseUrl + `/portfolio_period_scopes`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  addPortfolioPeriodScopes: {
    url: restBaseUrl + `/portfolio_period_scopes`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  portfolioPeriodScopeWithCalculation: {
    url: restBaseUrl + `/portfolio_period_scopes_with_calculations`,
    options: {
      headers: headers,
    },
  },
  offsetOverviews: {
    url: restBaseUrl + `/rpc/offset_overviews?portfolio_period_scope_id=:id`,
  },
  offsetBreakdowns: {
    url: restBaseUrl + `/rpc/offset_breakdowns`,
  },
  offsetAssignmentSummaries: {
    url: restBaseUrl + `/rpc/offset_assignment_summaries`,
  },
  unassignedOffsetQuantities: {
    url: restBaseUrl + `/rpc/unassigned_offset_quantities`,
  },
  getPortfolioCategories: {
    url: restBaseUrl + `/portfolio_categories`,
  },
  portfolioCategories: {
    url: restBaseUrl + `/portfolio_categories`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  getContractTypes: {
    url: restBaseUrl + `/rpc/distinct_offset_contract_types`,
  },
  getOffsetGenerators: {
    url: restBaseUrl + `/offset_generators`,
  },
  createOffsetGenerator: {
    url: restBaseUrl + `/offset_generators`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  createFullyAssignedOffsets: {
    url: restBaseUrl + `/rpc/create_fully_assigned_offsets`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  getAvailableOffsetGenerators: {
    url: restBaseUrl + `/available_offset_generators`,
  },
  getOfftakeQuantityTypes: {
    url: restBaseUrl + `/rpc/distinct_offset_quantity_types`,
  },
  getMWhTotCO2eRatio: {
    url: restBaseUrl + `/rpc/get_mwh_to_tco2e_ratio`,
  },
  getOfftakeEquivalent: {
    url: restBaseUrl + `/rpc/normalise_offset_quantities`,
  },
  createOffsetWithNewGenerator: {
    url: restBaseUrl + `/rpc/create_offset_with_generators`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  getCurrencies: {
    url: restBaseUrl + `/distinct_currencies`,
  },
  offsetPreferences: {
    url: restBaseUrl + `/offset_preferences`,
  },
  editOffsetPreferences: {
    url: restBaseUrl + `/offset_preferences`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  offsetPreferenceProductTypes: {
    url: restBaseUrl + `/offset_preference_product_types`,
  },
  addOffsetPreferenceProductTypes: {
    url: restBaseUrl + `/offset_preference_product_types`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  deleteOffsetPreferenceProductTypes: {
    url: restBaseUrl + `/offset_preference_product_types`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  getOffsetFilters: {
    url: restBaseUrl + `/offset_filters`,
    options: {
      headers: headers,
    },
  },
  addOffsetFilters: {
    url: restBaseUrl + `/offset_filters`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  editOffsetFilters: {
    url: restBaseUrl + `/offset_filters`,
    options: {
      method: "PATCH",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  deleteOffsetFilters: {
    url: restBaseUrl + `/offset_filters`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  addOffsetFilterProjectMethodTypes: {
    url: restBaseUrl + `/offset_filter_project_method_types`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  deleteOffsetFilterProjectMethodTypes: {
    url: restBaseUrl + `/offset_filter_project_method_types`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  addOffsetFilterProductTypeRegions: {
    url: restBaseUrl + `/offset_filter_product_type_regions`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  deleteOffsetFilterProductTypeRegions: {
    url: restBaseUrl + `/offset_filter_product_type_regions`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  addOffsetFilterCobenefits: {
    url: restBaseUrl + `/offset_filter_cobenefits`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  deleteOffsetFilterCobenefits: {
    url: restBaseUrl + `/offset_filter_cobenefits`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  getDataRegions: {
    url:
      restBaseUrl +
      `/data_regions?select=id,code,label,name,shortName%3Ashort_name,isCountry%3Ais_country,productTypeRegions%3Aproduct_type_regions(id,productType%3Aproduct_type)`,
  },
  getCounterParties: {
    url:
      restBaseUrl +
      `/counterparties?select=id,name,registrationNumber%3Aregistration_number,country%3Adata_regions(id,name),company%3Acompanies(id,name),createdUser%3Acreated_user,createdAt%3Acreated_at,modifiedUser%3Amodified_user,modifiedAt%3Amodified_at&order=name.asc`,
    options: {
      headers: headers,
    },
  },
  mergeDuplicateCounterParties: {
    url: restBaseUrl + `/rpc/merge_counterparties`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  addCounterParties: {
    url: restBaseUrl + `/counterparties`,
    options: {
      method: "POST",
      headers: {
        ...headers,
        Prefer: returnRepresentation,
      },
    },
  },
  editCounterParties: {
    url: restBaseUrl + `/counterparties`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  deleteCounterParties: {
    url: restBaseUrl + `/counterparties`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  getAwayBrokers: {
    url:
      restBaseUrl +
      `/away_brokers?select=id,name,createdAt%3Acreated_at,modifiedAt%3Amodified_at&order=name.asc`,
    options: {
      headers: headers,
    },
  },
  addAwayBrokers: {
    url: restBaseUrl + `/away_brokers`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  updateAwayBrokers: {
    url: restBaseUrl + `/away_brokers`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  portfolioBudgets: {
    url: restBaseUrl + `/portfolio_budgets`,
  },
  addPortfolioBudgets: {
    url: restBaseUrl + `/portfolio_budgets`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  editPortfolioBudgets: {
    url: restBaseUrl + `/portfolio_budgets`,
    options: {
      method: "PATCH",
      headers: headers,
    },
  },
  getPortfolioPeriodStats: {
    url: restBaseUrl + `/rpc/portfolio_period_stats`,
    options: {
      headers: headers,
    },
  },
  getScenarioOffsetSinglePeriods: {
    url: restBaseUrl + `/rpc/product_type_offset_assignment_breakdowns`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  getScenarioOffsetMultiplePeriods: {
    url: restBaseUrl + `/rpc/scenario_offset_multiple_periods`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  getAPITokens: {
    url:
      restBaseUrl +
      `/api_tokens?select=id,scopes,createdAt%3Acreated_at,company%3Acompanies(id,name),expiry,user%3Ausers!api_token_user_fkey(name),createdUser%3Ausers!api_token_user_fkey(name)&order=created_at.desc`,
    options: {
      headers,
    },
  },
  updateAPITokens: {
    url: restBaseUrl + `/api_tokens`,
    options: {
      method: "PATCH",
      headers,
    },
  },
  addAPITokens: {
    url: restBaseUrl + `/rpc/generate_api_token`,
    options: {
      method: "POST",
      headers,
    },
  },
  userScopes: {
    url: restBaseUrl + `/distinct_roles`,
  },
  getSubscriptionPackages: {
    url: restBaseUrl + `/subscription_packages`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  addSubscriptionPackages: {
    url: restBaseUrl + `/subscription_packages`,
    options: {
      method: "POST",
      headers,
    },
  },
  updateSubscriptionPackages: {
    url: restBaseUrl + `/subscription_packages`,
    options: {
      method: "PATCH",
      headers,
    },
  },
  distinctFeatureSubscriptionPackage: {
    url: restBaseUrl + `/distinct_subscription_features?order=feature.asc`,
    options: {
      headers: {
        ...headers,
      },
    },
  },
  getRECRegistries: {
    url: restBaseUrl + `/rec_registry_holdings`,
  },
  getRegistryOwnrAccounts: {
    url: restBaseUrl + `/rec_registry_owner_accounts`,
  },
  aggInitiativeImpactsOverTime: {
    url: restBaseUrl + `/rpc/agg_initiative_impacts_over_time`,
    options: {
      method: "POST",
      headers,
    },
  },
  addCompanySubscriptionPackages: {
    url: restBaseUrl + `/company_subscription_packages`,
    options: {
      method: "POST",
      headers: headers,
    },
  },
  deleteCompanySubscription: {
    url: restBaseUrl + `/company_subscription_packages`,
    options: {
      method: "DELETE",
      headers: headers,
    },
  },
  insights: {
    url: `/webflow/collections/64f51b1170fc318bbf2051e4/items`,
  },
};

/* eslint-enable camelcase */
