import React, { useState } from "react";
import { InsightsFilters, InsightsFilterValue } from "./InsightsFilters";
import { Space } from "antd";
import { COREBody } from "../../../../COREDesignSystem/Typography/COREBody";
import { COREIcon } from "../../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useInsights } from "../../../../shared/customHoooks/useInsights";
import { convertRelativeRangeToNonEmptyRange } from "../../../../COREDesignSystem/Form/CORERangePicker";
import { generateTestId } from "../../../../shared/testids/testids";
import { WidgetPanel } from "../../WidgetPanel";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import { COREInfiniteScroll } from "../../../../COREDesignSystem/Content/COREInfiniteScroll";
import { COREDivider } from "../../../../COREDesignSystem/Layout/COREDivider";
import "./InsightsWidget.less";
import {
  grey120,
  grey140,
} from "../../../../COREDesignSystem/Content/COREColour";
import { InsightsList } from "./InsightsList";

type InsightsWidgetProps = {
  props: {
    name: string;
    props: { filters: InsightsFilterValue };
    editMode: boolean;
    setProps: (value: {
      widget: { filters: InsightsFilterValue };
      i: string;
    }) => void;
  };
};

const SortButton: React.FC<{
  type: InsightsFilterValue["sortOrder"];
  onClick: (value: InsightsFilterValue["sortOrder"]) => void;
}> = ({ type, onClick }) => {
  if (type === "asc")
    return (
      <Space align={"center"} onClick={() => onClick("desc")}>
        <COREBody
          marginBottom={false}
          color={grey140}
          onClick={() => onClick("asc")}
        >
          <COREIcon
            icon={icon({ name: "arrow-up-wide-short" })}
            color={grey120}
          />{" "}
          Oldest first
        </COREBody>
      </Space>
    );
  return (
    <Space align={"center"}>
      <COREBody
        marginBottom={false}
        color={grey140}
        onClick={() => onClick("asc")}
      >
        <COREIcon
          onClick={() => onClick("asc")}
          icon={icon({ name: "arrow-down-wide-short" })}
          color={grey120}
        />{" "}
        Newest first
      </COREBody>
    </Space>
  );
};

export const ArticlesAndUpdatesWidget: React.FC<InsightsWidgetProps> = ({
  props: {
    name,
    props: { filters },
    setProps,
  },
}) => {
  const defaultFilters = filters ?? { sortOrder: "desc" };
  const defaultLimit: number = 100;
  const [paginations, setPaginations] = useState([
    { limit: defaultLimit, offset: 0 },
  ]);
  const [widgetFilter, setWidgetFilter] =
    useState<InsightsFilterValue>(defaultFilters);
  const publishedDate =
    widgetFilter.publishedDate &&
    convertRelativeRangeToNonEmptyRange(widgetFilter.publishedDate);
  const onFilters = (values: InsightsFilterValue) => {
    setProps({
      widget: {
        filters: values,
      },
      i: name,
    });
  };

  const { loading, sync, hasMore, data } = useInsights(paginations, {
    ...(publishedDate && {
      "lastPublished[gte]": publishedDate?.from.toISOString(),
      "lastPublished[lte]": publishedDate?.to.toISOString(),
    }),
    sortOrder: widgetFilter.sortOrder,
  });

  let insights = data;
  if (widgetFilter.type?.length) {
    insights = insights.filter(
      (item) =>
        item?.fieldData?.type &&
        widgetFilter.type?.includes(item.fieldData.type)
    );
  }

  if (widgetFilter.categories?.length) {
    insights = insights.filter((item) =>
      item?.fieldData?.categories?.some((category) =>
        widgetFilter.categories?.includes(category)
      )
    );
  }

  return (
    <WidgetPanel
      testID={generateTestId("dashboard", "articles-and-updates-widget")}
      panelTitle="Articles and updates"
    >
      <div className={"articles-and-updates-container"}>
        <InsightsFilters
          onChange={(values) => {
            if (values && Object.keys(values).length === 0)
              return setWidgetFilter((prev) => ({
                sortOrder: prev.sortOrder,
              }));
            setWidgetFilter((prev) => {
              const filters = { ...prev, ...values };
              onFilters(filters);
              return filters;
            });
          }}
          value={widgetFilter}
        />
        <SortButton
          type={widgetFilter.sortOrder}
          onClick={(value) => {
            setWidgetFilter((prev) => {
              const filters = { ...prev, sortOrder: value };
              onFilters(filters);
              return filters;
            });
          }}
        />
        <COREDivider space="sm" />
        <div className={"articles-and-updates-content"}>
          <COREInfiniteScroll
            loadMore={() => {
              setPaginations((prev) => [
                ...prev,
                {
                  limit: defaultLimit,
                  offset: prev[prev.length - 1].offset + defaultLimit,
                },
              ]);
            }}
            loading={loading}
            sync={sync}
            hasMore={hasMore}
            contentRange={insights.length}
            emptyRender={
              <COREEmpty
                testID={generateTestId(
                  "dashboard",
                  "no-articles-and-updates-found"
                )}
                description="No content found"
                hint="there are no matches based on your filter selections."
              />
            }
            useWindow={false}
            loader={<CORELoading delay={0} />}
          >
            <InsightsList insights={insights} />
          </COREInfiniteScroll>
        </div>
      </div>
    </WidgetPanel>
  );
};
