import React from "react";
import { Form } from "antd";
import { generateTestId } from "../../../../shared/testids/testids";
import { COREModal } from "../../../../COREDesignSystem/Overlay/COREModal";
import { COREButton } from "../../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../../COREDesignSystem/Content/COREIcon";
import { grey100 } from "../../../../COREDesignSystem/Content/COREColour";
import { COREForm, Store } from "../../../../COREDesignSystem/Form/COREForm";
import {
  DashboardNameFormItem,
  DashboardTemplateFormItem,
  MarkAsFavouriteFormItem,
} from "./AddEditDashboardFormItems";
import { COREDivider } from "../../../../COREDesignSystem/Layout/COREDivider";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Dashboard,
  DashboardOutput,
  UseUserDashboardOutput,
} from "../../../../shared/customHoooks/useUserDashboard";
import { separator } from "../widgets/reactGridLayout/ReactGridLayoutFunction";
import { useHistory } from "react-router-dom";
import { appQueryClient } from "../../../../shared/state/appQueryClient";
import { uniqueName } from "../../dashboardHelper";

type DashboardTabModalParams = {
  modalVisible?: boolean;
  closeModal: () => void;
  userDashboards?: Dashboard[] | DashboardOutput[];
} & Pick<UseUserDashboardOutput, "addDashboard">;

export const DashboardConfigurationModal: React.FC<DashboardTabModalParams> = ({
  modalVisible,
  addDashboard,
  closeModal,
  userDashboards,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const onCancel = () => {
    form.resetFields();
    closeModal();
  };
  const onFinish = async (values: Store) => {
    const breakpoints = ["lg", "md", "sm", "xs", "xxs"];
    const layouts =
      values.templates &&
      breakpoints.reduce((acc, breakpoint) => {
        acc[breakpoint] = values.templates.map(
          (template: string, index: number) => ({
            i: `${index + 1}${separator}${template}`,
            w: 6,
            h: 15,
            static: false,
            y: Infinity,
            x: (index * 6) % 12,
            // props: menu,
          })
        );
        return acc;
      }, {} as ReactGridLayout.Layouts);
    const res = await addDashboard(
      values.name,
      layouts,
      values.markAsFavourite
    );
    if (res) {
      await appQueryClient.invalidateQueries("user_configs");
      await appQueryClient.invalidateQueries("userAccessibleSections");
      history.push(`/dashboard/${values.name}`);
    }
    onCancel();
  };

  return (
    <COREModal
      width="576px"
      type="basic"
      testID={generateTestId("dashboard", "configuration-modal")}
      visible={modalVisible}
      title="Dashboard configuration"
      onCancel={onCancel}
      icon={<COREIcon icon={icon({ name: "gauge-high" })} color={grey100} />}
      footer={[
        <COREButton
          type="primary"
          size="lg"
          onClick={form.submit}
          icon={
            <COREIcon icon={icon({ name: "check-circle", style: "regular" })} />
          }
          testID={generateTestId("dashboard", "confirm-delete-button")}
          key="confirm-delete-button"
        >
          Save
        </COREButton>,
        <COREButton
          onClick={onCancel}
          type="default"
          size="lg"
          testID={generateTestId("dashboard", "cancel-delete-button")}
          key="cancel-delete-button"
        >
          Cancel
        </COREButton>,
      ]}
    >
      <COREForm
        onFinish={onFinish}
        form={form}
        testID={generateTestId("dashboard", "edit-dashboard-form")}
        layout="none"
      >
        <DashboardTemplateFormItem />
        <DashboardNameFormItem
          rules={[
            {
              required: true,
              validator: (_, value) => uniqueName(value, userDashboards),
            },
          ]}
        />
        <COREDivider dashed />
        <MarkAsFavouriteFormItem />
      </COREForm>
    </COREModal>
  );
};
