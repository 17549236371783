import { DurationInputArg2 } from "moment";
import { ProductCurveCommentaries } from "../../modules/dailyCurves/CarbonDailyCurvePageWrapper";
import { keysToSnake } from "../global";
import { useUpdatablesWithDatePaginate } from "./useUpdatable";
import {
  NonEmptyRange,
  RangeString,
  convertRangeToNonEmptyRange,
  strToRange,
} from "../date/ranges";
import {
  dateFormatWithTimeZone,
  formatNonEmptyRangeToRangeFilter,
} from "../date/DateTime";
import { ProductClassType } from "./useProductTypes";

export type MarketCommentaryFilters = {
  productClass?: ProductClassType | null;
  productType?: string[] | null;
  dateRange?: RangeString | null;
};

const buildParams = ({
  productClass,
  productType,
  dateRange,
}: MarketCommentaryFilters) => {
  const getNullProductType = productType?.includes("general");
  const getByProductTypes = productType?.filter((p) => p !== "general") ?? [];
  const queryByProductType =
    getNullProductType && getByProductTypes?.length !== 0
      ? {
          or: `(product_type.is.null,product_type.in.(${getByProductTypes.join(
            ","
          )}))`,
        }
      : {
          ...(getNullProductType && { productType: `is.null` }),
          ...(getByProductTypes?.length !== 0 && {
            productType: `in.(${getByProductTypes.join(",")})`,
          }),
        };
  const range = dateRange && strToRange(dateRange);

  return {
    ...(productClass && { productClass: `eq.${productClass}` }),
    ...queryByProductType,
    ...(range && {
      and: formatNonEmptyRangeToRangeFilter(
        convertRangeToNonEmptyRange(range),
        "date",
        dateFormatWithTimeZone
      ),
    }),
  };
};

export const useProductCurveCommentariesByDatePaginate = (
  args: MarketCommentaryFilters,
  loadedRange?: NonEmptyRange,
  unit?: DurationInputArg2
) => {
  return useUpdatablesWithDatePaginate<ProductCurveCommentaries>(
    {
      get: [
        "productCurveCommentaries",
        keysToSnake({
          select:
            "id,date,commentary,productClass:product_class,productType:product_type",
          order: "date.desc",
          ...buildParams(args),
        }),
        !args.productClass,
      ],
      transformToRichTypes: (record) => record,
    },
    args.dateRange ? null : "date",
    undefined,
    loadedRange,
    unit
  );
};
