import { priceFormatter, volumeFormatter } from "../../../shared/globals";
import {
  CertificateCurveData,
  DailyCurvesWithSnapshot,
  ProductCurveCommentaries,
} from "../CarbonDailyCurvePageWrapper";
import moment from "moment";

export const dateFormat = "DD/MM/YY";

const header: string = `<tr>
    <th style="color: darkblue;font-weight: 400; text-align: left;">Tenor</th>
    <th style="color: red">Curve</th>
    <th>Change</th>
    <th>Last</th>
    <th>Trade Date</th>
</tr>`;

const rowHTML: (
  shortName: string,
  price: number | undefined,
  change?: number | undefined,
  lastPrice?: number | undefined,
  lastDate?: string | undefined
) => string = (shortName, price, change, lastPrice, lastDate) => {
  return `<tr>
    <td style="color: darkblue;font-weight: bold; text-align: left;">${shortName}</td>
    <td style="color: red;">${priceFormatter(price, false) ?? ""}</td>
    <td style="font-weight: bold;">${priceFormatter(change, false) ?? ""}</td>
    <td>${volumeFormatter(lastPrice)}</td>
    <td>${lastDate ? moment(lastDate)?.format(dateFormat) : ""}</td>
</tr>`;
};

const renderRow: ({
  shortName,
  price,
  change,
  lastPrice,
  lastDate,
}: DailyCurvesWithSnapshot) => string = ({
  shortName,
  price,
  change,
  lastPrice,
  lastDate,
}) => {
  return rowHTML(shortName, price, change, lastPrice, lastDate);
};

const renderSpotHIR = (ds: DailyCurvesWithSnapshot[]) => {
  return rowHTML("Spot (HIR)", getSpotHIRPrice(ds));
};

export const getSpotHIRPrice = (ds: DailyCurvesWithSnapshot[]) => {
  const spotData = ds.filter((d) => d.shortName === "Spot");
  if (spotData.length === 0 || !spotData[0].certificateProductPremiumCurves)
    return undefined;
  const HIRData = spotData[0].certificateProductPremiumCurves.filter(
    (d) => d.certificate_project_type_name === "HIR"
  );

  return HIRData[0].price as number;
};

const renderTable = (
  productType: DailyCurvesWithSnapshot["productType"],
  productTypeName: DailyCurvesWithSnapshot["productTypeName"],
  ds: DailyCurvesWithSnapshot[],
  commentary?: ProductCurveCommentaries
): string => {
  const HIRSpotData = productType === "accu" ? renderSpotHIR(ds) : "";
  const renderHeader = ds.length > 0 ? header : "";
  const renderTableHTML =
    renderHeader + HIRSpotData + ds.map(renderRow).join("");

  return `<h1>INDICATIVE FORWARD ${productTypeName.toUpperCase()} CURVE</h1>${
    commentary?.commentary ?? ""
  }<table>${renderTableHTML}</table>`;
};

export const getCopyTables: (
  general: ProductCurveCommentaries | undefined,
  tables: CertificateCurveData[]
) => string = (
  general: ProductCurveCommentaries | undefined,
  tables: CertificateCurveData[]
): string => {
  return `<style>
    h1 {
        font-size: 15pt;
        color: #33335b;
    }

    table {
        font-family: Calibri, serif;
        font-size: 11pt;
        text-align: center;
        border-collapse: collapse
    }

    table th {
        font-weight: bold;
    }

    table td, table th {
        border: black 1px solid;
        font-family: Calibri, serif;
        padding:0 25px;
    }

    table tr:nth-child(odd) {
        background: #a4b4b4;
    }
</style>${general?.commentary ?? ""} ${tables
    .map(({ productTypeName, productType, dailyCurves, productCommentary }) =>
      renderTable(productType, productTypeName, dailyCurves, productCommentary)
    )
    .join("")}`;
};
