import { TradeLogFilterItems } from "./TradeLogTableFilter";
import { buildQuerySelect, keysToSnake } from "../../../shared/global";
import {
  TableOptions,
  Updatables,
  useUpdatablesWithDatePaginate,
  useUpdatablesWithPaginate,
} from "../../../shared/customHoooks/useUpdatable";
import {
  dateFormatWithTimeZone,
  formatRelativeRangeFilterForServer,
  RangeFilterFormat,
} from "../../../shared/date/DateTime";
import { NonEmptyRange } from "../../../shared/date/ranges";
import { DurationInputArg2 } from "moment";

export type TradeLogItem = {
  name: string;
  code: string;
  productTypeName: string;
  period: string;
  projectMethodTypeName: string;
  projectIdentifier: string;
  tradeTime: string;
  deliveryTime: string;
  strikePrice: number;
  volatility: number;
  volume: number;
  price: number;
  certificateProject?: number;
};

export const buildFilterTradeLogs = (
  {
    search,
    accreditation,
    term,
    method,
    deliveryTime,
    tradeTime,
    ...other
  }: TradeLogFilterItems,
  dateFormat: RangeFilterFormat
) => {
  return keysToSnake({
    ...(search && {
      name: `ilike.*${search}*`,
    }),
    ...(accreditation &&
      accreditation.length > 0 && {
        productTypeName: `in.(${accreditation.join()})`,
      }),
    ...(term && {
      period: `ilike.*${term}*`,
    }),
    ...(method &&
      method.length > 0 && {
        projectMethodTypeName: `in.(${method.join()})`,
      }),
    ...(tradeTime && {
      and: formatRelativeRangeFilterForServer(
        tradeTime,
        "trade_time",
        dateFormat
      ),
    }),
    ...(deliveryTime && {
      and: formatRelativeRangeFilterForServer(
        deliveryTime,
        "delivery_time",
        dateFormat
      ),
    }),
    ...(deliveryTime &&
      tradeTime && {
        and: `(and${formatRelativeRangeFilterForServer(
          tradeTime,
          "trade_time",
          dateFormat
        )},and${formatRelativeRangeFilterForServer(
          deliveryTime,
          "delivery_time",
          dateFormat
        )})`,
      }),
    ...other,
  });
};

const tradeTimelinesArgs = (
  filters: TradeLogFilterItems
): Updatables<TradeLogItem> => {
  const selectQuery =
    "*," +
    buildQuerySelect({
      name: "name",
      code: "code",
      productTypeName: "product_type_name",
      period: "period",
      projectMethodTypeName: "project_method_type_name",
      projectIdentifier: "project_identifier",
      certificateProject: "certificate_project",
      tradeTime: "trade_time",
      deliveryTime: "delivery_time",
      strikePrice: "option_strike_price",
      tradeType: "trade_type",
      optionType: "option_type",
      exerciseExpiry: "exercise_expiry",
      volatility: "volatility",
      volume: "volume",
      price: "price",
    });
  return {
    get: [
      "rehubTradeTimelines",
      {
        select: selectQuery,
        order: "trade_time.desc",
        ...buildFilterTradeLogs(filters, dateFormatWithTimeZone),
      },
    ],
    transformToRichTypes: (record) => record,
  };
};

export const useTradeLogsWithPaginate = (
  args: TradeLogFilterItems,
  tableOption?: TableOptions,
  limitRecordsPerPage?: number,
  pagesToLoad?: number
) => {
  return useUpdatablesWithPaginate<TradeLogItem>(
    tradeTimelinesArgs(args),
    tableOption,
    limitRecordsPerPage,
    pagesToLoad
  );
};

export const useTradeLogsWithDatePaginate = (
  args: TradeLogFilterItems,
  tableOption?: TableOptions,
  loadedRange?: NonEmptyRange,
  unit?: DurationInputArg2
) => {
  return useUpdatablesWithDatePaginate<TradeLogItem>(
    tradeTimelinesArgs(args),
    args.tradeTime ? null : "tradeTime",
    tableOption,
    loadedRange,
    unit
  );
};
