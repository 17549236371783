import React, { useContext } from "react";
import { Alert, Empty, Popover } from "antd";
import { useAPIQueryRequest } from "../customHoooks/useAPI";
import { CORELoading } from "../../COREDesignSystem/Feedback/CORELoading";
import moment, { Moment } from "moment";
import { COREChart } from "../../COREDesignSystem/Chart/COREChart";
import { DEPRECATEDDateFormatContext } from "../date/DateFormatContext";
import { generateTestId } from "../testids/testids";

const timePeriod = "year";

const getDefaultDates = (todayDate: Moment): [Moment, Moment] => [
  moment(todayDate).startOf("day").subtract(1, timePeriod), // Start
  moment(todayDate).add(1, "day"), // End
];

const CFDChart: React.FC<{ id: number }> = ({ id }) => {
  const { format } = useContext(DEPRECATEDDateFormatContext);

  const [start, end] = getDefaultDates(moment());
  const tsRange = `[${start.format(format)},${end.format(format)})`;
  const {
    data: { data: chartData },
    loading,
    sync,
    error,
  } = useAPIQueryRequest("aggregates", {
    tableName: "trading",
    columnName: "trade_price",
    selectedItem: id,
    aggPeriod: "none",
    aggFunc: "none",
    dateRange: tsRange,
  });
  if (loading && !sync) return <CORELoading size={"lg"} />;
  if (!sync) return null;
  if (error) {
    console.error(error);
    return <Alert type={"error"} message={"Error: failed to load chart"} />;
  }
  if (chartData.length === 0) {
    return <Empty description={`No trades in the last ${timePeriod}.`} />;
  }

  return (
    <COREChart
      chartId={`tradable product - ${id}`}
      testID={generateTestId("marketprices", "tradable-product-popover-chart")}
      data={[
        {
          name: "Trades",
          x: chartData.map(({ date }) => date),
          y: chartData.map(({ value }) => value),
          type: "scatter",
          line: { width: 2 },
          hoverlabel: { namelength: -1 },
        },
      ]}
      layout={{
        xaxis: {
          title: "Date",
          showgrid: true,
          showline: true,
          showticklabels: true,
        },
        yaxis: {
          title: "$",
          showgrid: true,
          zeroline: true,
          showline: true,
          showticklabels: true,
        },
        margin: {
          l: 50,
          r: 50,
          t: 50,
          b: 50,
        },
      }}
      showTimeButtons={false}
    />
  );
};

export const TradableProductPopover: React.FC<{ id: number }> = ({
  id,
  children,
}) => {
  return (
    <Popover content={<CFDChart id={id} />}>
      <>{children}</>
    </Popover>
  );
};
