/* eslint-disable */
import "./COREInput.less";
import React from "react";
import { Col, Input, InputProps, Row } from "antd";
import { PasswordProps, TextAreaProps } from "antd/es/input";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import classNames from "classnames";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREBody } from "../Typography/COREBody";

const { TextArea, Password } = Input;

type StatusType = "default" | "warning" | "error" | "success";
export type WidthSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "full";
export type Size = "sm" | "md" | "lg";
interface CommonProps {
  testID: TestID;
  status?: StatusType;
  widthSize?: WidthSize;
  size?: Size;
}

export type BasicInputProps = CommonProps & Omit<InputProps, "size">;
const ErrorIcon: React.FC = () => (
  <span
    role="img"
    aria-label="info-circle"
    className="anticon anticon-info-circle"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.125C3.65117 0.125 0.125 3.65117 0.125 8C0.125 12.3488 3.65117 15.875 8 15.875C12.3488 15.875 15.875 12.3488 15.875 8C15.875 3.65117 12.3488 0.125 8 0.125ZM10.9074 10.9918L9.74727 10.9865L8 8.90352L6.25449 10.9848L5.09258 10.99C5.01523 10.99 4.95195 10.9285 4.95195 10.8494C4.95195 10.816 4.96426 10.7844 4.98535 10.758L7.27227 8.0334L4.98535 5.31055C4.96411 5.28478 4.95233 5.25253 4.95195 5.21914C4.95195 5.1418 5.01523 5.07852 5.09258 5.07852L6.25449 5.08379L8 7.1668L9.74551 5.08555L10.9057 5.08027C10.983 5.08027 11.0463 5.1418 11.0463 5.2209C11.0463 5.2543 11.034 5.28594 11.0129 5.3123L8.72949 8.03516L11.0146 10.7598C11.0357 10.7861 11.048 10.8178 11.048 10.8512C11.048 10.9285 10.9848 10.9918 10.9074 10.9918Z"
        fill="#D32C2C"
      />
    </svg>
  </span>
);

const WarningIcon: React.FC = () => (
  <span
    role="img"
    aria-label="info-circle"
    className="anticon anticon-info-circle"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.125C3.65117 0.125 0.125 3.65117 0.125 8C0.125 12.3488 3.65117 15.875 8 15.875C12.3488 15.875 15.875 12.3488 15.875 8C15.875 3.65117 12.3488 0.125 8 0.125ZM7.4375 4.20312C7.4375 4.12578 7.50078 4.0625 7.57812 4.0625H8.42188C8.49922 4.0625 8.5625 4.12578 8.5625 4.20312V8.98438C8.5625 9.06172 8.49922 9.125 8.42188 9.125H7.57812C7.50078 9.125 7.4375 9.06172 7.4375 8.98438V4.20312ZM8 11.9375C7.77921 11.933 7.56898 11.8421 7.41442 11.6844C7.25986 11.5266 7.1733 11.3146 7.1733 11.0938C7.1733 10.8729 7.25986 10.6609 7.41442 10.5031C7.56898 10.3454 7.77921 10.2545 8 10.25C8.22079 10.2545 8.43103 10.3454 8.58558 10.5031C8.74014 10.6609 8.8267 10.8729 8.8267 11.0938C8.8267 11.3146 8.74014 11.5266 8.58558 11.6844C8.43103 11.8421 8.22079 11.933 8 11.9375Z"
        fill="#FF9900"
      />
    </svg>
  </span>
);

const SuccessIcon: React.FC = () => (
  <span
    role="img"
    aria-label="info-circle"
    className="anticon anticon-info-circle"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.125C3.65117 0.125 0.125 3.65117 0.125 8C0.125 12.3488 3.65117 15.875 8 15.875C12.3488 15.875 15.875 12.3488 15.875 8C15.875 3.65117 12.3488 0.125 8 0.125ZM11.4014 5.42832L7.69941 10.5611C7.64767 10.6334 7.57946 10.6922 7.50044 10.7328C7.42142 10.7734 7.33386 10.7946 7.24502 10.7946C7.15618 10.7946 7.06862 10.7734 6.9896 10.7328C6.91057 10.6922 6.84237 10.6334 6.79063 10.5611L4.59863 7.52363C4.53184 7.43047 4.59863 7.30039 4.71289 7.30039H5.5373C5.7166 7.30039 5.88711 7.38652 5.99258 7.53418L7.24414 9.2709L10.0074 5.43887C10.1129 5.29297 10.2816 5.20508 10.4627 5.20508H11.2871C11.4014 5.20508 11.4682 5.33516 11.4014 5.42832Z"
        fill="#73D13D"
      />
    </svg>
  </span>
);

const statusGlobalClass = (status?: StatusType) => {
  return status ? (status !== "default" ? `core-input-${status}` : "") : "";
};

const statusStyleClass = (status?: StatusType): string =>
  classNames(
    { ["core-input"]: status === "default" },
    { ["core-input-error"]: status === "error" },
    { ["core-input-warning"]: status === "warning" },
    { ["core-input-success"]: status === "success" }
  );

const inputSuffix = (
  inputStatus: StatusType | undefined,
  suffix: InputProps["suffix"] = <span />
): InputProps["suffix"] => {
  let paramSuffix: InputProps["suffix"] = suffix;
  if (inputStatus === "error") {
    if (!suffix) {
      paramSuffix = <ErrorIcon />;
    } else if (suffix && typeof suffix !== "string") {
      paramSuffix = (
        <>
          {suffix}
          <ErrorIcon />
        </>
      );
    }
  }
  if (inputStatus === "warning") {
    if (!suffix) {
      paramSuffix = <WarningIcon />;
    } else if (suffix && typeof suffix !== "string") {
      paramSuffix = (
        <>
          {suffix}
          <WarningIcon />
        </>
      );
    }
  }
  if (inputStatus === "success") {
    if (!suffix) {
      paramSuffix = <SuccessIcon />;
    } else if (suffix && typeof suffix !== "string") {
      paramSuffix = (
        <>
          {suffix}
          <SuccessIcon />
        </>
      );
    }
  }

  return paramSuffix;
};

export const CORESizeToAntSize: Record<Size, SizeType> = {
  sm: "small",
  md: "middle",
  lg: "large",
};

const BasicInput: React.FC<BasicInputProps> = ({
  testID,
  status = "default",
  suffix,
  widthSize = "md",
  size = "md",
  disabled,
  ...props
}) => {
  const className = statusStyleClass(status);

  return (
    <TestIDWrapper testID={testID} className={className}>
      <Input
        className={classNames([
          statusGlobalClass(status),
          `input-width-${widthSize}`,
          { [`input-disabled-addon`]: disabled },
        ])}
        suffix={inputSuffix(status, suffix)}
        size={CORESizeToAntSize[size]}
        disabled={disabled}
        {...props}
      />
    </TestIDWrapper>
  );
};

type BasicTextAreaProps = Omit<TextAreaProps, "size"> & {
  testID: TestID;
  allowResize: boolean;
  widthSize?: WidthSize;
  showCount?: TextAreaProps["showCount"];
  value?: string;
  size?: Size;
};

const BasicTextArea: React.FC<BasicTextAreaProps> = ({
  showCount,
  testID,
  allowResize = false,
  widthSize = "xxl",
  size = "md",
  ...props
}) => {
  const inputClass: string | undefined = classNames({
    ["core-textarea-no-resize"]: !allowResize,
  });

  return (
    <TestIDWrapper testID={testID} className={"core-textarea"}>
      <TextArea
        size={CORESizeToAntSize[size]}
        className={classNames(inputClass, `input-width-${widthSize}`)}
        {...props}
      />
      {showCount && (
        <Row className={"character-limit"}>
          <Col span={24}>
            <COREBody type={"p5"} italic>
              Character limit {props.value?.length ?? 0}/{props.maxLength ?? 0}
            </COREBody>
          </Col>
        </Row>
      )}
    </TestIDWrapper>
  );
};

export const passwordIconRender = (
  visible: boolean,
  inputStatus: StatusType | undefined
) => {
  const defIcon = visible ? (
    <COREIcon icon={icon({ name: "eye", style: "regular" })} />
  ) : (
    <COREIcon icon={icon({ name: "eye-slash", style: "regular" })} />
  );
  return inputSuffix(inputStatus, defIcon);
};

export type InputPasswordProps = CommonProps & Omit<PasswordProps, "size">;

const InputPassword: React.FC<InputPasswordProps> = ({
  testID,
  status = "default",
  suffix,
  widthSize = "md",
  size = "md",
  disabled,
  ...props
}) => {
  const className = statusStyleClass(status);

  return (
    <TestIDWrapper testID={testID} className={className}>
      <Password
        className={classNames([
          `input-width-${widthSize}`,
          { [`input-disabled-addon`]: disabled },
        ])}
        prefix={<COREIcon icon={icon({ name: "lock", style: "regular" })} />}
        suffix={inputSuffix(status, suffix)}
        iconRender={(visible) => passwordIconRender(visible, status)}
        size={CORESizeToAntSize[size]}
        disabled={disabled}
        {...props}
      />
    </TestIDWrapper>
  );
};

const InputSearch: React.FC<CommonProps & Omit<InputProps, "size">> = ({
  testID,
  status = "default",
  className,
  widthSize = "md",
  size = "md",
  ...props
}) => (
  <TestIDWrapper testID={testID} className={statusStyleClass(status)}>
    <Input
      className={classNames(className, `input-width-${widthSize}`)}
      prefix={<COREIcon icon={icon({ name: "search", style: "regular" })} />}
      size={CORESizeToAntSize[size]}
      {...props}
    />
  </TestIDWrapper>
);

export const COREInput = {
  Input: BasicInput,
  TextArea: BasicTextArea,
  Password: InputPassword,
  Search: InputSearch,
};
