import { Col, ColProps, Row, RowProps } from "antd";
import React, { Children, ReactNode } from "react";
import { CORETag } from "./CORETag";
import { TestID } from "../../shared/testids/testids";
import { CORETooltip } from "../Overlay/CORETooltip";
import "./CORETagList.less";
import classNames from "classnames";

export type CORETagListProps = {
  children: ReactNode;
  truncateAfter?: number;
  showTooltip?: boolean;
  testID?: TestID;
  vertical?: boolean;
} & Pick<RowProps, "wrap" | "justify"> &
  Pick<ColProps, "span">;

const TooltipContents: React.FC<{ hiddenChildren: ReactNode[] }> = ({
  hiddenChildren,
}) => {
  return (
    <Row gutter={[0, 4]} justify={"center"} className={"tag-list-vertical"}>
      {hiddenChildren.map((child, index) => (
        <Col key={index}>{child}</Col>
      ))}
    </Row>
  );
};

const TotalTags: React.FC<
  Pick<CORETagListProps, "showTooltip" | "testID"> & {
    hiddenChildren: ReactNode[];
  }
> = ({ showTooltip, hiddenChildren, testID }) => {
  return (
    <CORETooltip
      overlay
      hidden={!showTooltip}
      message={<TooltipContents hiddenChildren={hiddenChildren} />}
      testID={`${testID}-tags-tooltip` as TestID}
    >
      <CORETag label={`+${hiddenChildren.length}`} type={"basic"} />
    </CORETooltip>
  );
};

export const CORETagList: React.FC<CORETagListProps> = ({
  children,
  truncateAfter,
  wrap = false,
  justify = "start",
  vertical = false,
  showTooltip = false,
  span,
  testID,
}) => {
  const arrayChildren = Children.toArray(children);
  const visibleChildren =
    truncateAfter !== 0 ? arrayChildren.slice(0, truncateAfter) : arrayChildren;
  const hiddenChildren = truncateAfter
    ? arrayChildren.slice(truncateAfter - 1)
    : [];
  if (truncateAfter === undefined) {
    return (
      <Row gutter={[4, 4]}>
        {Children.map(arrayChildren, (child) => (
          <Col>{child}</Col>
        ))}
      </Row>
    );
  }
  return (
    <Row
      gutter={[4, 4]}
      wrap={wrap}
      justify={justify}
      className={classNames({ "tag-list-vertical": vertical })}
    >
      {visibleChildren.map((child, index) => (
        <Col key={index} span={span}>
          {index === truncateAfter - 1 && hiddenChildren.length > 0 ? (
            <TotalTags
              testID={testID}
              hiddenChildren={hiddenChildren}
              showTooltip={showTooltip}
            />
          ) : (
            child
          )}
        </Col>
      ))}
    </Row>
  );
};
