import React, { useState } from "react";
import { useAPIQueryRequest } from "../customHoooks/useAPI";
import { Result, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { DEPRECATEDCORETable } from "./DEPRECATEDCORETable";
import { useLocalStorage } from "react-use";
import { FilterInput } from "../dataSheet/COREDataSheet";
import { momentFormat } from "../date/DateFormatContext";
import { appQueryClient as queryClient } from "../state/appQueryClient";
import { CORECSVButton } from "../../COREDesignSystem/Action/CORECSVButton";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";
import { generateTestId, TestIDWrapper } from "../testids/testids";
import { financeMarketTimeZone } from "../date/DateTime";

const urlParams = (url, params) => {
  if (!url) {
    return "";
  }
  return url.replace(":value", params[0]);
};

export const getFilters = (searchedColumn) => {
  let filter = [];
  Object.entries(searchedColumn).forEach(
    ([key, { type, column, op, value }]) => {
      if (type === "date_range" || type === "datetime") {
        const formatTime = (time) => time.format(momentFormat.server.format);

        filter[column] = op
          .replace(
            ":from",
            `${formatTime(moment(value[0]))} 00:00:00 ${financeMarketTimeZone}`
          )
          .replace(
            ":to",
            `${formatTime(moment(value[1]))} 23:59:59 ${financeMarketTimeZone}`
          );
      } else {
        filter[column] = urlParams(op, value);
      }
    }
  );

  return filter;
};

const getOrderStr = (columnProps, columnKey, order) => {
  return columnProps && columnProps.hasOwnProperty("sortNullLast")
    ? !columnProps.sortNullLast
      ? `${columnKey}${order === "descend" ? ".desc" : ""}`
      : `${columnKey}${order === "descend" ? ".desc.nullslast" : ".nullslast"}`
    : `${columnKey}${order === "descend" ? ".desc.nullslast" : ".nullslast"}`;
};

export const getOrderColumn = (sortedColumn, columns) => {
  let orderColumn = {};
  if (sortedColumn) {
    let orderBy = [];
    Object.entries(sortedColumn).forEach(([key, { columnKey, order }]) => {
      const columnProps = columns.find((c) => c.key === columnKey);
      const orderStr = getOrderStr(columnProps, columnKey, order);
      orderBy.push(orderStr);
    });

    if (orderBy.length > 0) {
      orderColumn = {
        order: orderBy.join(","),
      };
    }
  }
  return orderColumn;
};

export const onTableChange = (
  newPagination,
  newFilters,
  newSorter,
  parentOnChange,
  setTableOptions,
  setSortColumn
) => {
  if (parentOnChange) {
    parentOnChange(newPagination, newFilters, newSorter);
  }
  setTableOptions(({ pagination, filters, sorter }) => {
    return {
      pagination: { ...pagination, ...newPagination },
      filters: { ...filters, ...newFilters },
      sorter: { ...sorter, ...newSorter },
    };
  });
  if (newSorter) {
    setSortColumn((current) => {
      current = {};
      if (newSorter.column !== undefined) {
        current[newSorter.columnKey] = newSorter;
      }
      return { ...current };
    });
  }
};

export const onTableSearch = (
  selectedKeys,
  dataIndex,
  { type, column, op },
  clearFilters,
  handleReset,
  setSearchColumn,
  setTableOptions
) => {
  if (selectedKeys && selectedKeys.length > 0) {
    if (
      type !== "date_range" &&
      type !== "datetime" &&
      selectedKeys[0] === ""
    ) {
      handleReset(clearFilters, dataIndex);
      return false;
    }

    setSearchColumn((current) => {
      current[dataIndex] = {
        type,
        column,
        op,
        value: selectedKeys,
      };
      return { ...current };
    });
  } else {
    handleReset(clearFilters, dataIndex);
  }

  setTableOptions(({ pagination, filters, sorter }) => {
    return {
      pagination: { ...pagination, current: 1 },
      filters: { ...filters },
      sorter: { ...sorter },
    };
  });
};

const getColumnSearchProps = (
  dataIndex,
  title,
  filter,
  handleSearch,
  handleReset,
  searchedColumn,
  testID
) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <FilterInput
          testID={generateTestId(testID, "input-" + title)}
          type={filter.type}
          title={title}
          dataIndex={dataIndex}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          handleSearch={handleSearch}
          filter={filter}
        />
        <Space>
          <TestIDWrapper
            testID={generateTestId(testID, "search-" + title)}
            inline={true}
          >
            <COREButton
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys, dataIndex, filter, clearFilters)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </COREButton>
          </TestIDWrapper>
          <TestIDWrapper
            testID={generateTestId(testID, "reset-" + title)}
            inline={true}
          >
            <COREButton
              onClick={() => handleReset(clearFilters, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </COREButton>
          </TestIDWrapper>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <TestIDWrapper
        testID={generateTestId(testID, "filter-icon-" + title)}
        inline={true}
      >
        <SearchOutlined
          style={{
            color:
              filtered || searchedColumn.hasOwnProperty(dataIndex)
                ? "#1890ff"
                : undefined,
          }}
        />
      </TestIDWrapper>
    ),
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function DEPRECATEDCORETableAPI({
  api,
  apiParams = {},
  transform,
  group = null,
  tablePageSize = null,
  onChange: parentOnChange,
  columns,
  initSortedColumns = {},
  localStorageName = "pagination",
  downloadable = false,
  downloadFileName = "Table Data",
  testID = "",
  ...otherProps
}) {
  let initPageSize;
  const [userPagination] = useLocalStorage(localStorageName, 10);

  if (tablePageSize) {
    initPageSize = tablePageSize;
  } else {
    initPageSize = group !== null ? 100 : userPagination;
  }

  const initPagination = {
    total: Number.MAX_SAFE_INTEGER,
    hideOnSinglePage: false,
    current: 1,
    pageSize: initPageSize,
  };

  const [tableOptions, setTableOptions] = useState({
    pagination: initPagination,
  });
  const [searchedColumn, setSearchColumn] = useState([]);
  const [sortedColumn, setSortColumn] = useState(initSortedColumns);
  const { pagination } = tableOptions;
  const { current, pageSize, total } = pagination;
  const filter = getFilters(searchedColumn);
  const orderColumn = getOrderColumn(sortedColumn, columns);

  const {
    loading,
    sync,
    data: { data },
    error,
    headers,
    lastUpdate,
  } = useAPIQueryRequest(api, {
    ...apiParams,
    ...filter,
    ...orderColumn,
    limit: pageSize,
    offset: (current - 1) * pageSize,
  });
  if (error) {
    if (error.response.status === 416) {
      setTableOptions((curTableOption) => {
        const {
          pagination: { current, ...others },
        } = curTableOption;
        return {
          pagination: {
            ...others,
            current: 1,
          },
        };
      });
    }
    console.error(error);
    return <Result status="error" title="Error loading table data" />;
  }
  let dataSource = data;
  if (sync && transform) {
    dataSource = dataSource.map(transform);
  }
  if (headers) {
    const totalRecords = headers
      .find(([h]) => h === "content-range")[1]
      .split("/")[1];
    const newTotal = totalRecords === "*" ? total : totalRecords;
    if (newTotal !== total) {
      setTableOptions(({ pagination: { total, ...others } }) => ({
        pagination: {
          ...others,
          total: newTotal,
        },
      }));
    }
  }

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    onTableChange(
      newPagination,
      newFilters,
      newSorter,
      parentOnChange,
      setTableOptions,
      setSortColumn
    );
    localStorage.setItem(
      localStorageName,
      JSON.stringify(newPagination.pageSize)
    );
  };

  const handleSearch = (
    selectedKeys,
    dataIndex,
    { type, column, op },
    clearFilters
  ) => {
    onTableSearch(
      selectedKeys,
      dataIndex,
      { type, column, op },
      clearFilters,
      handleReset,
      setSearchColumn,
      setTableOptions
    );
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchColumn((current) => {
      delete current[dataIndex];
      return { ...current };
    });
  };

  const handleSort = (sorter) => {
    const { pagination, filters } = tableOptions;
    handleTableChange(pagination, filters, sorter);
  };

  const columnTransform = (columns, testID) => {
    return columns.map((c) => {
      return c.filter
        ? {
            ...c,
            ...getColumnSearchProps(
              c.dataIndex,
              c.title,
              c.filter,
              handleSearch,
              handleReset,
              searchedColumn,
              testID
            ),
          }
        : {
            ...c,
          };
    });
  };

  const onClickDownloadCSV = async () => {
    const response = await queryClient
      .fetchQuery({
        queryKey: [
          api,
          {
            action: api,
            fetchOptions: { method: "GET" },
            params: {
              ...apiParams,
              ...filter,
              ...orderColumn,
            },
            enabled: true,
          },
        ],
      })
      .then((res) => {
        return res.data;
      });
    if (response && response.length > 0) {
      const transformedData = transform ? response.map(transform) : response;
      const data = transformedData.map((t) => {
        columns.forEach((column) => {
          if (column.renderCSV) {
            t[column.key] = column.renderCSV(t[column.key]);
          }
        });
        return t;
      });
      const headers = columns.map((c) => {
        return { label: c.title, key: c.dataIndex };
      });
      return { data, headers };
    } else {
      return { data: [], headers: [] };
    }
  };

  return (
    <>
      <DEPRECATEDCORETable
        dataSource={dataSource}
        columns={columnTransform(columns, testID)}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onPaginationChange={(current, size) => {
          handleTableChange({
            ...pagination,
            current: current,
            pageSize: size,
          });
        }}
        searchedColumn={searchedColumn}
        handleSearch={handleSearch}
        handleReset={handleReset}
        sortedColumn={sortedColumn}
        handleSort={handleSort}
        group={group}
        lastUpdate={lastUpdate}
        {...(downloadable && dataSource && dataSource.length > 0
          ? {
              footer: () => (
                <CORECSVButton
                  testID={generateTestId("table", "download-csv")}
                  filename={downloadFileName}
                  getData={onClickDownloadCSV}
                >
                  Download to CSV
                </CORECSVButton>
              ),
            }
          : {})}
        {...otherProps}
      />
    </>
  );
}
