import React, { Dispatch, SetStateAction, useState } from "react";
import { Affix, Card, Col, Empty, Layout, Radio, Row } from "antd";
import { PriceTable } from "../../../shared/tables/PriceTable";
import { LiveMarketScreenGrouping } from "./LiveMarketScreenGrouping";
import {
  capitaliseEachAllWord,
  capitaliseEachWord,
} from "../../../shared/stringUtil";
import "./LiveMarketScreen.less";
import { useUserIsAdmin } from "../../../shared/state/user";
import { contentHeaderForPriceTable } from "../../../shared/global";
import { InFocusTable } from "./InFocusLiveMarketScreen";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../../shared/testids/testids";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import classNames from "classnames";

type OptionType = "electricity" | "rehub";
const { Content } = Layout;

const initDropdown = {
  electricity: "Flat",
  rehub: "Solar",
};

const ShapeSelector = ({
  onChange,
  shapes,
  shape,
  type,
}: {
  onChange: Dispatch<SetStateAction<string>>;
  shapes: string[];
  shape: string;
  type: OptionType;
}) => {
  return (
    <Affix offsetTop={0} className={"live-market-screen-page-affix-position"}>
      <div className={"live-market-screen-dropdown"}>
        <Radio.Group
          value={shape}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          data-testid={generateTestId(
            "marketprices",
            "live-market-screen-tab-group"
          )}
        >
          {shapes.map((shape) => (
            <Radio.Button
              value={capitaliseEachWord(shape)}
              key={`radio-button-${shape}`}
            >
              <span
                data-testid={generateTestId(
                  "marketprices",
                  `live-market-screen-tab-${shape.toLowerCase()}`
                )}
              >
                {capitaliseEachWord(shape)}
              </span>
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </Affix>
  );
};

const CFDTableGroup = ({
  tableType,
  propsPriceTable,
  isAdmin,
}: {
  tableType?: string;
  propsPriceTable: { cap: boolean; name: string; type: string };
  isAdmin: boolean;
}) => {
  return (
    <>
      {contentHeaderForPriceTable.map((region) => {
        const testID: TestID = generateTestId(
          "marketprices",
          `${propsPriceTable.name}-${region}-${tableType}-${
            propsPriceTable.type
          }${propsPriceTable.cap ? "-cap" : ""}`
        );

        const priceTableProps = {
          ...propsPriceTable,
          rowKey: "name",
          testID,
          region,
          group: LiveMarketScreenGrouping,
        };
        let cardProps = {
          title: `${capitaliseEachAllWord(region)}`,
          className: "live-market-screen-firm",
        };

        if (tableType) {
          Object.assign(priceTableProps, { tableType: tableType });
          cardProps = {
            title: `${capitaliseEachAllWord(region)} - ${capitaliseEachWord(
              tableType
            )}`,
            className: `live-market-screen-${tableType}`,
          };
        }

        return (
          <Col
            span={12}
            xs={24}
            xxl={12}
            key={`live-market-price-table-${region}-block`}
          >
            <TestIDWrapper
              testID={generateTestId(
                "marketprices",
                `LiveMarketScreen-PriceTable-${region}${
                  tableType ? "-" + tableType : ""
                }`
              )}
            >
              <Card {...cardProps}>
                <PriceTable {...priceTableProps} />
              </Card>
            </TestIDWrapper>
          </Col>
        );
      })}
    </>
  );
};

const CFDLiveMarketScreenElement = ({
  type,
  dropdown,
  setDropdown,
  children,
}: {
  type: OptionType;
  dropdown: string;
  setDropdown: Dispatch<SetStateAction<string>>;
  children: JSX.Element;
}) => {
  const {
    loading,
    sync,
    data: { data },
    error: productTypesDataError,
  } = useAPIQuery("getProductTypesByProductClass", { productClass: type });
  if (productTypesDataError) return <COREError />;
  if (loading && !sync) return <CORELoading size={"lg"} />;

  if (data.length === 0) {
    return (
      <Row className={"live-market-screen-page-children-menu"}>
        <Col span={24}>
          <Empty description={"No product types found."}></Empty>
        </Col>
      </Row>
    );
  }

  const propsShapeSelector = {
    shapes: data.map((p) => p.name),
    shape: dropdown,
    onChange: setDropdown,
    type: type,
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <ShapeSelector {...propsShapeSelector} />
        </Col>
      </Row>
      <Row className={"live-market-screen-page-children-menu"}>{children}</Row>
    </>
  );
};

export const CFDLiveMarketScreen = ({
  type,
  disableInFocus = false,
  hasAdmin = false,
}: {
  type: OptionType;
  disableInFocus?: boolean;
  hasAdmin?: boolean;
}) => {
  const [dropdown, setDropdown] = useState<string>(initDropdown[type]);
  const isAdmin = useUserIsAdmin();
  const cap = dropdown === "$300 Cap";
  const shape = cap ? "flat" : dropdown.toLowerCase();
  const propsPriceTable = {
    cap: cap,
    name: shape,
    type: "cfd",
    option: false,
    disableInFocus,
  };
  const propsCFDLiveMarket = {
    type: type,
    dropdown: dropdown,
    setDropdown: setDropdown,
  };

  const containerClassNames = classNames(
    "live-market-screen-page",
    "content-page"
  );

  if (hasAdmin && isAdmin) {
    const rehubPropsPriceTable = {
      ...propsPriceTable,
      type: "rehub",
    };

    return (
      <div className={containerClassNames}>
        {!disableInFocus && (
          <InFocusTable propsPriceTable={rehubPropsPriceTable} />
        )}
        <CFDLiveMarketScreenElement {...propsCFDLiveMarket}>
          <>
            <CFDTableGroup
              propsPriceTable={rehubPropsPriceTable}
              tableType={"firm"}
              isAdmin={isAdmin}
            />
            <CFDTableGroup
              propsPriceTable={rehubPropsPriceTable}
              tableType={"indicative"}
              isAdmin={isAdmin}
            />
          </>
        </CFDLiveMarketScreenElement>
      </div>
    );
  }

  return (
    <div className={containerClassNames}>
      <Content>
        {!disableInFocus && (
          <InFocusTable
            propsPriceTable={{
              ...propsPriceTable,
              type,
            }}
          />
        )}
        <CFDLiveMarketScreenElement {...propsCFDLiveMarket}>
          <CFDTableGroup propsPriceTable={propsPriceTable} isAdmin={isAdmin} />
        </CFDLiveMarketScreenElement>
      </Content>
    </div>
  );
};
