import React, { Children } from "react";
import { COREDescriptionSectionProps } from "./COREDescriptionSection";
import { COREDivider } from "../Layout/COREDivider";
import { Col, Row } from "antd";

type COREDescriptionListProps = {
  children:
    | React.ReactElement<COREDescriptionSectionProps>
    | React.ReactElement<COREDescriptionSectionProps>[];
  direction?: "vertical" | "horizontal";
} & Pick<COREDescriptionSectionProps, "itemSpace">;

export const COREDescriptionList: React.FC<COREDescriptionListProps> = ({
  children,
  direction = "vertical",
  itemSpace = "regular",
}) => {
  const arrayChildren = Children.toArray(children);
  return (
    <Row gutter={[64, 0]}>
      {Children.map(arrayChildren, (child, index) => (
        <Col {...(direction === "vertical" && { span: 24 })}>
          {React.isValidElement(child)
            ? React.cloneElement(child, { ...{ itemSpace: itemSpace } })
            : child}
          {arrayChildren.length !== index + 1 && (
            <COREDivider space={"md"} dashed={true} />
          )}
        </Col>
      ))}
    </Row>
  );
};
