import React, { useState } from "react";
import { Col, Row, Tree } from "antd";
import "./CORETree.less";
import "./CORETreeV2.less";
import "./CORETreeSelectV2.less";
import { useDeepCompareEffect } from "react-use";
import { FilterOutlined } from "@ant-design/icons";
import { Merge } from "../../shared/TypeScriptHelpers";
import { TreeProps } from "antd/es/tree";
import { TreeNodeNormal } from "antd/es/tree/Tree";
import {
  aggFuncOptions,
  aggOptions as aggPeriodOptions,
  RpcAggFuncType,
  RpcAggPeriodType,
} from "../../modules/tools/chartBuilder/useChartBuilderApiV2";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import classNames from "classnames";
import { COREButton } from "../Action/COREButton";
import { COREInput } from "./COREInput";
import { COREDivider } from "../Layout/COREDivider";

const { Search } = COREInput;

type CORETreeNode<V> = Merge<
  TreeNodeNormal,
  {
    children?: CORETreeData<V>;
    value: V;
    realValue: V;
    path: V[];
    isLeaf?: boolean;
    render?: (props: {
      beforeStr?: string;
      afterStr?: string;
      title: string;
      str?: string;
    }) => React.FC;
  }
>;
export type CORETreeData<V> = CORETreeNode<V>[];
export type CORETreeProps<V> = Merge<
  TreeProps,
  {
    ref: React.ForwardedRef<any>;
    search?: boolean;
    limit?: number;
    filterMode?: boolean;
    treeData?: CORETreeData<V>;
    onCheck?: (
      checked: Parameters<Exclude<TreeProps["onCheck"], undefined>>[0]
    ) => ReturnType<Exclude<TreeProps["onCheck"], undefined>>;
    testID: TestID;
    searchPlaceholder?: string;
  }
>;

export type Key = Exclude<
  CORETreeProps<any>["selectedKeys"],
  undefined
>[number];

export type UseCORETreeProps<V> = {
  initialRootValues?: V[];
  value?: V[];
  onChange?: (rootValues: V[], values, checkedKeys) => void;
};

export interface joinedDataType {
  id: number;
  name: string;
  shortName: string;
  aemoId: string;
  variable: string;
  aggPeriod?: RpcAggPeriodType;
  aggFunc?: RpcAggFuncType | null;
  value: {
    id: number;
    aemoId: string;
    variable: string;
    traceName: string;
    aggPeriod?: RpcAggPeriodType;
    aggFunc?: RpcAggFuncType | null;
  };
}

export const generateTraceName = (traceName, period, aggFunc) =>
  period
    ? `${traceName} - ${period.label}${
        period.value !== "none" ? " - " + aggFunc.label : ""
      }`
    : traceName;

export const joinAggOptions = (
  treeData: joinedDataType[]
): joinedDataType[] => {
  let joinData: joinedDataType[] = [];
  treeData.forEach((item) => {
    aggPeriodOptions.forEach((period) => {
      aggFuncOptions.forEach((aggFunc) => {
        joinData.push({
          ...item,
          aggPeriod: period.label,
          aggFunc: period.value !== "none" ? aggFunc.label : null,
          value: {
            ...item.value,
            aggPeriod: period.value,
            aggFunc: period.value !== "none" ? aggFunc.value : null,
            traceName: generateTraceName(
              item.value?.traceName || item.value,
              period,
              aggFunc
            ),
          },
        });
      });
    });
  });

  return joinData;
};

export const useCORETree = <V extends any>(
  treeData: CORETreeData<V>,
  {
    initialRootValues,
    value,
    onChange: parentOnChange,
  }: UseCORETreeProps<V> = {
    initialRootValues: undefined,
    value: undefined,
    onChange: undefined,
  }
) => {
  const [checkedKeys, setCheckedKeys] = useState(
    rootValuesToKeys(treeData, initialRootValues)
  );

  useDeepCompareEffect(() => {
    setCheckedKeys(rootValuesToKeys(treeData, value ?? initialRootValues));
  }, [value, setCheckedKeys]);

  return {
    onCheck: (currentKeyChecked) => {
      setCheckedKeys(currentKeyChecked);
      if (parentOnChange instanceof Function) {
        parentOnChange(
          keysToRootValues(treeData, currentKeyChecked),
          keysToValues(treeData, currentKeyChecked),
          currentKeyChecked
        );
      }
    },
    treeData: treeData,
    checkedKeys: checkedKeys,
  };
};

export const nodeNotEmpty = (node, newPath, value, field, d, isLastField) => {
  let index: number = node.findIndex((child) => child.realValue === field);

  if (index === -1) {
    let indexNode: {
      path: string;
      realValue: string;
      children: [];
      title: string;
      value: string;
      key: string;
      render?;
    } = {
      key: `${newPath.join("-")}`,
      value: value,
      realValue: field,
      path: newPath,
      title: field.toString(),
      children: [],
    };

    if (d.render && isLastField) {
      indexNode.render = d.render;
    }

    index = node.push(indexNode) - 1;
  }
  return [node[index].children, newPath];
};

export const generateTree = (
  data,
  columnOrder,
  valueFieldName,
  includeAgg = false
) => {
  let tree = [];
  const joinAggOptionData = includeAgg ? joinAggOptions(data) : data;
  const joinAggColumnOrder = includeAgg
    ? [...columnOrder, "aggPeriod", "aggFunc"]
    : columnOrder;
  for (let i = 0; i < joinAggOptionData.length; i++) {
    const d = joinAggOptionData[i];
    joinAggColumnOrder.reduce(
      ([node, path], column, colIndex) => {
        const remainingColumns = joinAggColumnOrder.slice(colIndex + 1);
        const remainingFieldsEmpty = remainingColumns.every(
          (col) => d[col] === null || !d[col]
        );
        const isLastField =
          colIndex === joinAggColumnOrder.length - 1 || remainingFieldsEmpty;
        const field = d[column];
        const value = isLastField ? d[valueFieldName] : field;
        const isEmpty: boolean = field === null || !field;
        const orderPath = path.filter((p) => p !== null);
        const newPath = isLastField
          ? [...orderPath, field, value]
          : [...orderPath, field];

        return isEmpty
          ? [node, newPath]
          : nodeNotEmpty(node, newPath, value, field, d, isLastField);
      },
      [tree, []]
    );
  }
  return tree;
};

export const keysToValues = (treeData, keys) => {
  return treeData.flatMap((node) => {
    const hasChildren: boolean =
      node.hasOwnProperty("children") && node.children.length !== 0;
    const matches = keys.includes(node.key);

    if (matches && hasChildren)
      return [node.value, keysToValues(node.children, keys)];
    if (matches && !hasChildren) return [node.value];

    if (hasChildren) {
      return keysToValues(node.children, keys);
    }
    return [];
  });
};

export const keysToRootValues = (treeData, keys) => {
  return treeData.flatMap((node) => {
    const hasChildren: boolean =
      node.hasOwnProperty("children") && node.children.length !== 0;

    if (hasChildren) return keysToRootValues(node.children, keys);
    const matches = keys.includes(node.key);

    if (matches && !hasChildren) {
      return [node.value];
    }

    return [];
  });
};

export const rootValuesToKeys = (treeData, values) => {
  if (values === undefined) return undefined;
  return treeData.flatMap((node) => {
    const hasChildren: boolean =
      node.hasOwnProperty("children") && node.children.length !== 0;

    if (hasChildren) return rootValuesToKeys(node.children, values);

    const matches = values.includes(node.value);

    if (matches && !hasChildren) {
      return [node.key];
    }

    return [];
  });
};

const treeNodeMatches = ({ title, value, key }, searchValue) => {
  if (!searchValue) return false;
  const searchValueLowerCase: string = searchValue.toString().toLowerCase();
  const titleLowerCase: string = title ? title.toString().toLowerCase() : "";
  const keyLowerCase: string = key ? key.toString().toLowerCase() : "";

  const valueLowerCase: string = value ? value.toString().toLowerCase() : "";
  return (
    valueLowerCase.includes(searchValueLowerCase) ||
    titleLowerCase.includes(searchValueLowerCase) ||
    keyLowerCase.includes(searchValueLowerCase)
  );
};

export const treeNodeRecursiveMatches = (node, searchValue) => {
  if (!searchValue) return true;

  const thisMatches = treeNodeMatches(node, searchValue);
  if (thisMatches) return true;

  const hasChildren: boolean = node.children && node.children.length !== 0;
  if (!hasChildren) {
    return false;
  }

  return node.children.some((child) =>
    treeNodeRecursiveMatches(child, searchValue)
  );
};

export const renderTreeNodes = (
  tree,
  multiple,
  checkAbleNodePosition,
  treeDepth,
  searchValue,
  selectable
) => {
  return tree
    .map((item) => {
      const {
        title,
        render = ({ beforeStr, afterStr, title: titleStr, str }) =>
          str ? (
            <span title={titleStr}>
              {beforeStr}
              <span className={"search-value"}>{str}</span>
              {afterStr}
            </span>
          ) : (
            <span title={titleStr}>{titleStr}</span>
          ),
      } = item;

      const thisNodeMatches: boolean = treeNodeMatches(item, searchValue);
      const thisNodeRecursiveMatches = treeNodeRecursiveMatches(
        item,
        searchValue
      );

      let titleRender: JSX.Element = render({ title });

      const nodeClassName =
        searchValue &&
        searchValue !== "" &&
        !thisNodeRecursiveMatches &&
        "tree-filter-node-hide";

      const index =
        thisNodeMatches &&
        title.toLowerCase().indexOf(searchValue.toLowerCase());
      if (thisNodeMatches && index > -1) {
        const beforeStr = title.substr(0, index);
        const str = title.substr(index, searchValue.length);
        const afterStr = title.substr(index + searchValue.length);

        titleRender = render({ beforeStr, afterStr, title, str });
      }

      const nodeLevel =
        item.children?.length !== 0 ? item.path.length : treeDepth;
      const nodeLevelCondition = nodeLevel >= checkAbleNodePosition;
      const checkable =
        item.checkable !== undefined ? item.checkable : nodeLevelCondition;
      const isLeaf =
        item.isLeaf !== undefined ? item.isLeaf : nodeLevelCondition;

      const nodeClassNames = nodeClassName ? [nodeClassName] : [];
      if (nodeLevel === 1) {
        nodeClassNames.push("tree-treenode-top-level");
      }

      return item.children?.length !== 0
        ? {
            className: classNames(nodeClassNames),
            title: titleRender,
            key: item.key,
            value: item.value,
            checkable: checkable,
            isLeaf: isLeaf,
            selectable: selectable,
            children: renderTreeNodes(
              item.children,
              multiple,
              checkAbleNodePosition,
              treeDepth,
              searchValue,
              selectable
            ),
          }
        : {
            className: nodeClassName ?? "",
            title: titleRender,
            checkable: checkable,
            isLeaf: isLeaf,
            selectable: selectable,
            key: item.key,
            value: item.value,
          };
    })
    .filter((node) => node !== null);
};
const getDepth = <Value extends any>(
  obj:
    | {
        children: CORETreeData<Value> | undefined;
      }
    | CORETreeNode<Value>
) => {
  let depth = 0;
  if (obj.children) {
    obj.children.forEach(function (d) {
      var tmpDepth = getDepth(d);
      if (tmpDepth > depth) {
        depth = tmpDepth;
      }
    });
  }
  return 1 + depth;
};

export const getTreeDepth = <Value extends any>(
  treeData: CORETreeData<Value> | undefined
): number => {
  const treeObj: {
    children: CORETreeData<Value> | undefined;
  } = {
    children: treeData,
  };
  return getDepth(treeObj) - 1;
};

export const CORETree = <Value extends any>({
  treeData,
  onCheck,
  selectable = true,
  checkedKeys,
  multiple = false,
  limit = -1,
  search = false,
  filterMode = false,
  searchPlaceholder = "Search",
  testID,
}: CORETreeProps<Value>) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const onCheckFunc = (checkedKeysValue, checked, node) => {
    if (!onCheck) return;
    if (multiple) return onCheck(checkedKeysValue);
    if (checked) return onCheck([node.key]);
    return onCheck([]);
  };

  const onSelectFunc = (selectKeys, node) => {
    if (!selectable || !onCheck) {
      return false;
    }
    if (!node.children) {
      setSelectedKeys(selectKeys);
      return onCheck(selectKeys);
    }
    const k: Key[] = node.expanded
      ? expandedKeys.filter((kFilter) => kFilter !== node.key)
      : expandedKeys.concat(node.key);
    setExpandedKeys(k);
  };

  function* flatten(array) {
    for (const el of array) {
      yield el;
      yield* flatten(el.children);
    }
  }

  const searchExpandedTree = (tree, searchValue): string[] => {
    let pathKey: string[] = [];
    for (const el of flatten(tree)) {
      const matchTreeNodes = treeNodeRecursiveMatches(el, searchValue);
      if (matchTreeNodes) {
        pathKey = [...pathKey, el.key];
      }
    }
    return [...new Set(pathKey)];
  };

  const onSearchChange = ({ target: { value: searchValue } }) => {
    setSearchValue(searchValue);
    const matchKey: string[] = searchExpandedTree(treeData, searchValue);
    setExpandedKeys(matchKey);
  };

  if (Array.isArray(checkedKeys) && checkedKeys !== selectedKeys) {
    setSelectedKeys(checkedKeys);
  }

  const treeDepth: number = getTreeDepth(treeData);
  const checkAbleNodePosition: number =
    limit < 0 ? treeDepth + limit + 1 : limit > treeDepth ? treeDepth : limit;
  return (
    <>
      <TestIDWrapper testID={testID} className={"core-tree"}>
        {search || filterMode ? (
          <>
            <Row gutter={8} align={"middle"}>
              {search && (
                <Col flex={"auto"}>
                  <Search
                    testID={`${testID}-search-box`}
                    placeholder={searchPlaceholder}
                    onChange={onSearchChange}
                    allowClear={true}
                    size={"lg"}
                    widthSize={"full"}
                  />
                </Col>
              )}
              {filterMode && (
                <Col flex={"none"}>
                  <COREButton
                    type={"primary"}
                    danger
                    onClick={() => {
                      if (!onCheck) return;
                      onCheck([]);
                    }}
                  >
                    <FilterOutlined />
                  </COREButton>
                </Col>
              )}
            </Row>
            <COREDivider space={"sm"} />
          </>
        ) : null}

        {treeData && (
          <div className={"selector"}>
            <Tree
              multiple={multiple}
              checkable
              selectable={selectable}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={(expandedKeysValue) => {
                // if not set autoExpandParent to false, if children expanded, parent can not collapse.
                // or, you can remove all expanded children keys.
                setExpandedKeys(expandedKeysValue);
              }}
              onSelect={(selectKeys, { node }) => {
                onSelectFunc(selectKeys, node);
              }}
              onCheck={(checkedKeysValue, { checked, node }) => {
                onCheckFunc(checkedKeysValue, checked, node);
              }}
              checkedKeys={checkedKeys}
              treeData={renderTreeNodes(
                treeData,
                multiple,
                checkAbleNodePosition,
                treeDepth,
                searchValue,
                selectable
              )}
            />
          </div>
        )}
      </TestIDWrapper>
    </>
  );
};
