import "./COREGridItem.less";
import React from "react";
import { Col, Row } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";

export interface GridItemProps<T> {
  render: (item: T) => React.ReactNode;
  keyExtractor: (item: T) => string;
  horizontalMargin?: number;
  verticalMargin?: number;
  data: T[];
  testID: TestID;
}

export const COREGridItem = <T extends unknown>({
  render,
  keyExtractor,
  horizontalMargin = 32,
  verticalMargin = 32,
  data,
  testID,
}: GridItemProps<T>) => (
  <TestIDWrapper testID={testID}>
    <Row gutter={[horizontalMargin, verticalMargin]}>
      {data &&
        data.map((col: T) => (
          <Col
            key={keyExtractor(col)}
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={8}
            xxl={6}
            className={`grid-item-container`}
          >
            {render(col)}
          </Col>
        ))}
    </Row>
  </TestIDWrapper>
);
