import React from "react";
import { COREBody, COREBodyProps } from "../Typography/COREBody";
import {
  CORETypographyInput,
  CORETypographyInputProps,
} from "../Typography/CORETypographyInput";
import "./COREDescriptionSection.less";
import { TestID } from "../../shared/testids/testids";
import classNames from "classnames";

export type COREDescriptionSectionProps = {
  items: {
    label: CORETypographyInputProps["children"];
    value: COREBodyProps["children"];
    testID?: TestID;
  }[];
  itemSpace?: "tight" | "regular" | "relaxed";
};

export const COREDescriptionSection: React.FC<COREDescriptionSectionProps> = ({
  items,
  itemSpace = "regular",
}) => (
  <div className={classNames("grid-container", itemSpace)}>
    {items.map((item, index) => (
      <React.Fragment key={`${item.label}-${item.value}-${index}`}>
        <div className={"label"}>
          <CORETypographyInput
            type={"label"}
            {...(item.testID && { testID: `${item.testID}-label` })}
          >
            {item.label}
          </CORETypographyInput>
        </div>
        <div className={"content"}>
          <COREBody
            marginBottom={false}
            {...(item.testID && { testID: `${item.testID}-value` })}
          >
            {item.value}
          </COREBody>
        </div>
      </React.Fragment>
    ))}
  </div>
);
