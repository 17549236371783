import Plotly, { Layout } from "plotly.js/dist/plotly-myPlotly.js";
import React, { useEffect } from "react";
import { bodyFontFamily, chartFont } from "../../shared/global";
import { Merge } from "../../shared/TypeScriptHelpers";
import { ChartProps } from "./ChartHOC";
import { COREChartLegend, Legend } from "./COREChartLegend";
import { grey100, prepareChartColor } from "../Content/COREColour";
import {
  AggregatesChartProps,
  Trace,
} from "../../modules/tools/chartBuilder/AggregatesChartV2";
import { ChartDataType, downloadCSVFile } from "./COREChart";
import equal from "fast-deep-equal";

export type BasicTrace = Pick<
  Trace,
  "name" | "line.color" | "type" | "hovertemplate"
> & {
  x: string[];
  y: number[];
};

export type COREBasicChartProps = Merge<
  Pick<
    AggregatesChartProps,
    | "downloadPng"
    | "downloadCsv"
    | "testID"
    | "plotRef"
    | "chartPanel"
    | "chartTitle"
    | "chartLegendRef"
    | "empty"
    | "setProgressPercent"
  >,
  {
    layout?: Partial<Layout>;
    traces: BasicTrace[];
    xAxisLabel?: string;
    yAxisLabel?: string;
  }
>;

const generateChartLayout = (
  layout: Partial<Plotly.Layout> | undefined,
  chartTitle: COREBasicChartProps["chartTitle"],
  xAxisLabel: COREBasicChartProps["xAxisLabel"],
  yAxisLabel: COREBasicChartProps["yAxisLabel"]
): Partial<Layout> & {
  filename?: string;
} => {
  return {
    font: {
      family: chartFont,
    },
    filename: chartTitle,
    titlefont: {
      family: bodyFontFamily,
    },
    margin: {
      t: 30,
      r: 20,
    },
    barmode: "stack",
    hovermode: "closest",
    showlegend: false,
    xaxis: {
      showline: false,
      automargin: true,
      title: {
        text: xAxisLabel,
        standoff: 15,
        font: {
          family: chartFont,
        },
      },
      linecolor: grey100,
    },
    yaxis: {
      linecolor: "#F2F2F2",
      showline: false,
      showgrid: true,
      tickformat: "~s",
      title: {
        text: yAxisLabel,
        standoff: 15,
        font: {
          family: chartFont,
        },
      },
    },
    ...layout,
  };
};

const generateLegends = (traces: BasicTrace[]): Legend[] => {
  return traces.map((item) => {
    let tagStyle = {};

    if (
      item["line.dash"] &&
      ["dash", "longdash", "dashdot", "longdashdot"].includes(item["line.dash"])
    ) {
      tagStyle = { tagStyle: `dashed` };
    } else if (item["line.dash"] === `solid`) {
      tagStyle = { tagStyle: `outline` };
    }

    return {
      label: item.name,
      color: item["line.color"],
      checked: true,
      key: item.name.replaceAll(" ", "-"),
      ...tagStyle,
    };
  });
};

export const BasicChart: React.FC<COREBasicChartProps & ChartProps> = ({
  traces,
  testID,
  plotRef,
  chartTitle,
  xAxisLabel,
  yAxisLabel,
  chartPanel,
  downloadCsv,
  downloadPng,
  setOnDownloadCSV,
  setOnDownloadPNG,
  setOnResetChart,
  layout,
  chartLegendRef,
  empty,
  setProgressPercent,
}) => {
  const tracesWithColor: BasicTrace[] = prepareChartColor(traces);
  const legends: Legend[] = generateLegends(tracesWithColor);
  const chartLayout = generateChartLayout(
    layout,
    chartTitle,
    xAxisLabel,
    yAxisLabel
  );
  const newTraces: Partial<ChartDataType>[] = tracesWithColor.map((item) => {
    return {
      ...item,
      marker: {
        color: item["line.color"],
      },
    };
  });

  setProgressPercent && setProgressPercent(1);
  useEffect(() => {
    const handleResetChart = () => {
      if (!plotRef?.current) {
        return;
      }
      Plotly.relayout(plotRef.current.el, {
        xaxis: {
          ...plotRef.current.props.layout.xaxis,
          autorange: true,
        },
        yaxis: {
          ...plotRef.current.props.layout.yaxis,
          autorange: true,
        },
      });
    };

    const handleDownloadPNG = () => {
      chartLegendRef?.current?.callDrawImage();
    };

    const handleDownloadCSV = () => {
      if (!plotRef?.current) {
        return;
      }
      plotRef.current.props.layout.filename = chartTitle;
      downloadCSVFile(plotRef.current.el);
    };

    if (setOnResetChart) setOnResetChart(() => handleResetChart);
    if (setOnDownloadPNG) setOnDownloadPNG(() => handleDownloadPNG);
    if (setOnDownloadCSV) setOnDownloadCSV(() => handleDownloadCSV);
  }, [
    chartLegendRef,
    chartTitle,
    plotRef,
    setOnDownloadCSV,
    setOnDownloadPNG,
    setOnResetChart,
  ]);

  return (
    <COREChartLegend
      chartId={testID}
      chartTitle={chartTitle}
      chartPanel={chartPanel}
      downloadCsv={downloadCsv ?? false}
      downloadPng={downloadPng}
      data={newTraces}
      layout={chartLayout}
      testID={testID}
      legends={legends}
      showlegend={true}
      legendFilter={true}
      plotRef={plotRef}
      ref={chartLegendRef}
      empty={empty}
    />
  );
};

export const COREBasicChart = React.memo<
  React.ComponentType<
    COREBasicChartProps & ChartProps & { ref?: React.ForwardedRef<any> }
  >
>(
  BasicChart,
  (prevProps, nextProps) =>
    prevProps &&
    equal(prevProps.layout, nextProps.layout) &&
    equal(prevProps.traces, nextProps.traces)
);
