import React, { useMemo, useRef } from "react";
import JoditEditor, { IJoditEditorProps } from "jodit-react";

export const CORETextEditor: React.FC<IJoditEditorProps> = (props) => {
  const editorRef = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
      height: "350px",
      uploader: {
        insertImageAsBase64URI: true,
      },
      disablePlugins: ["image-properties"],
      removeButtons: [
        "font",
        "print",
        "about",
        "speechRecognize",
        "classSpan",
        "ai-commands",
        "ai-assistant",
      ],
    }),
    []
  );

  return <JoditEditor ref={editorRef} config={config} {...props} />;
};
