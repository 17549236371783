import { Tabs, TabsProps } from "antd";
import classnames from "classnames";
import React, { FC } from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import "./CORETabs.less";

export type CORETabsType = CommonProps & TabsProps;

interface CommonProps {
  testID: TestID;
  marginBottom?: boolean;
  bodered?: boolean;
}

export const CORETabs: FC<CORETabsType> = ({
  testID,
  marginBottom = true,
  bodered = false,
  ...props
}) => {
  const classNames = classnames([
    "core-tabs",
    { "no-margin-bottom": !marginBottom },
    { bodered: bodered },
  ]);
  return (
    <TestIDWrapper testID={testID}>
      <div className={classNames}>
        <Tabs {...props} />
      </div>
    </TestIDWrapper>
  );
};
