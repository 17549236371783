import React, { useEffect, useState } from "react";
import { ProjectMarketplaceFilters } from "../ProjectFiltersHOC";
import { generateTestId } from "../../../shared/testids/testids";
import {
  GreenOfferDetailsType,
  useGreenOfferDetail,
} from "../projectMarketplace/ProjectMarketplaceContainer";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { uniqueByKey } from "../../../shared/global";
import "./ProjectFinder.less";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { ViewProjectsAsMap } from "../projectMarketplace/ViewProjectsAsMap";
import { CertificateProjectsFilterValues } from "../../../shared/customHoooks/useGreenProject";

type ProjectFinderWidgetItems = {
  props: {
    editMode?: boolean;
    name?: string;
    props?: CertificateProjectsFilterValues | string;
    setProps: (value: unknown) => void;
  };
};

export const ProjectFinderWidget: React.FC<ProjectFinderWidgetItems> = ({
  props: projectFinderProps,
}) => {
  const textName = "Project finder";
  const [finderFilter, setFinderFilter] =
    useState<CertificateProjectsFilterValues>(
      projectFinderProps.props && typeof projectFinderProps.props !== "string"
        ? projectFinderProps.props
        : {}
    );
  const onChangeFilter = (widget: CertificateProjectsFilterValues) => {
    setFinderFilter(widget);
    projectFinderProps.setProps({ widget, i: projectFinderProps.name });
  };

  useEffect(() => {
    setFinderFilter(
      projectFinderProps?.props && typeof projectFinderProps.props !== "string"
        ? projectFinderProps.props
        : {}
    );
  }, [projectFinderProps?.props]);

  const { data, error } = useGreenOfferDetail(finderFilter, "list");
  if (error) return <COREError />;
  const allProjects = data?.length ? data : [];
  const carbonProjects: GreenOfferDetailsType[] = uniqueByKey(
    allProjects.filter(
      (p: GreenOfferDetailsType) => p.certificate_project !== null
    ),
    "certificate_project"
  );
  return (
    <div className={"project-finder-widget"}>
      <COREHeading
        testID={generateTestId("dashboard", "project-finder-header")}
        level={3}
        marginBottom={false}
      >
        Project finder
      </COREHeading>
      <COREDivider space={"md"} />
      <ProjectMarketplaceFilters
        testID={generateTestId("dashboard", "project-finder-filter")}
        modalTitle={textName}
        filterHeaderText={textName}
        filterValues={finderFilter}
        onChange={onChangeFilter}
        liteMode
      />
      <div className={"project-finder-layout"}>
        <ViewProjectsAsMap
          carbonProjects={carbonProjects}
          allProjects={allProjects}
          mapHeight={"100%"}
          mapWidth={"100%"}
        />
      </div>
    </div>
  );
};
