/* eslint-disable react/prop-types */
import React, { forwardRef, ReactElement } from "react";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import {
  generateTree,
  Key,
  CORETree,
  useCORETree,
} from "../../../COREDesignSystem/Form/CORETree";
import { Empty } from "antd";
import { generateTestId, Module } from "../../../shared/testids/testids";
import { useTestModule } from "../../../shared/testids/useTestModule";

interface joinedDataType {
  id: number;
  name: string;
  region: string;
  variable: string;
  value: {
    id: number;
    name: string;
    variable: string;
    traceName: string;
  };
}

/* eslint-disable camelcase */
export interface rawDataType {
  aemo_id: string;
  id: number;
  in_nem: boolean;
  name: string;
  short_name: string;
  aemo_region_id: string;
  regions: {
    short_name: string;
  };
}
/* eslint-disable camelcase */

interface WeatherObservationTypeTreeType {
  multiple?: boolean;
  primaryStationOnly?: boolean;
  includeAgg?: boolean;
}

const getJoinedData = (rawData: rawDataType[]): joinedDataType[] => {
  const joinedData: joinedDataType[] = [];
  const staticVar: string[] = ["Max Temp", "Min Temp", "Wind", "Solar"];
  rawData.forEach((item) => {
    staticVar.forEach((variable) => {
      joinedData.push({
        id: item.id,
        name: item.name,
        region: item.regions ? item.regions.short_name : item.aemo_region_id,
        variable: variable,
        value: {
          id: item.id,
          name: item.name,
          variable: variable,
          traceName: `${
            item.regions ? item.regions.short_name : item.aemo_region_id
          } ${item.name} ${variable}`,
        },
      });
    });
  });

  return joinedData;
};

const WeatherObservationTypeTreeWithData = ({
  testModule,
  multiple,
  primaryStationOnly,
  onChange: parentOnChange,
  initialValue,
  ref,
  checkedKeys,
  includeAgg = false,
  ...otherProps
}: {
  testModule: Module;
  multiple: boolean;
  primaryStationOnly: boolean;
  onChange?: (
    value: Key[],
    labelList: React.ReactNode[],
    extra: unknown
  ) => void;
  initialValue?: Key[];
  ref: React.ForwardedRef<ReactElement>;
  checkedKeys?:
    | Key[]
    | {
        checked: Key[];
        halfChecked: Key[];
      };
  includeAgg: boolean;
}): JSX.Element => {
  const actionName = primaryStationOnly
    ? "primaryStations"
    : "stationsWithRegions";
  const data = useAPIQuery(actionName, {});
  let joinedDataList: joinedDataType[] = [];
  let treeData = [];

  if (data.data.data && data.data.data.length > 0) {
    joinedDataList = getJoinedData(data.data.data);
    treeData = generateTree(
      joinedDataList,
      ["region", "name", "variable"],
      "value",
      includeAgg
    );
  }

  const tree = useCORETree(treeData, {
    onChange: parentOnChange,
    initialRootValues: initialValue,
  });

  if (!data.sync && !data.data.data) return <CORELoading size={"lg"} />;
  if (tree.treeData.length === 0)
    return <Empty description={"No data for selection(s)."} />;
  return (
    <>
      <CORETree
        ref={ref}
        {...tree}
        multiple={multiple}
        checkedKeys={checkedKeys}
        {...otherProps}
        testID={generateTestId(testModule, `weather-observation-type-tree`)}
      />
    </>
  );
};

export const WeatherObservationTypeTree = React.memo(
  forwardRef<ReactElement, WeatherObservationTypeTreeType>(
    (
      {
        multiple = false,
        includeAgg = false,
        primaryStationOnly = false,
        ...otherProps
      },
      ref
    ): JSX.Element => {
      return (
        <>
          <WeatherObservationTypeTreeWithData
            testModule={useTestModule()}
            multiple={multiple}
            includeAgg={includeAgg}
            primaryStationOnly={primaryStationOnly}
            {...otherProps}
            ref={ref}
          />
        </>
      );
    }
  )
);
