import React from "react";
import { Col, Card } from "antd";
import { PriceTable, PriceTableProps } from "../../../shared/tables/PriceTable";
import "./LiveMarketScreen.less";
import { generateTestId, TestID } from "../../../shared/testids/testids";
import classNames from "classnames";

export const InFocusTable = ({
  propsPriceTable,
}: {
  propsPriceTable: Omit<
    PriceTableProps,
    "testID" | "name" | "showLastUpdate" | "inFocus"
  >;
}) => {
  const testID: TestID = generateTestId(
    "marketprices",
    `infocus-${propsPriceTable.type}`
  );

  const priceTableProps: PriceTableProps = {
    ...propsPriceTable,
    testID,
    name: "",
    rowKey: "name",
    showLastUpdate: false,
    inFocus: true,
  };

  let cardProps = {
    title: "In Focus",
    className: "live-market-screen-firm",
  };

  return (
    <Col
      span={24}
      className={classNames("in-focus-table-block", propsPriceTable.className)}
    >
      <Card {...cardProps}>
        <PriceTable {...priceTableProps} />
      </Card>
    </Col>
  );
};
