import "./COREModal.less";
import React from "react";
import { Col, Modal, ModalProps, Row, Space } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { COREHeading } from "../Typography/COREHeading";
import classnames from "classnames";
import { Merge, RequireAtLeastOne } from "../../shared/TypeScriptHelpers";
import { COREIcon } from "../Content/COREIcon";
import { icon as generateIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { grey100, lime100, orange100, red100 } from "../Content/COREColour";
import { COREButton } from "../Action/COREButton";

type StatusType = "info" | "primary" | "error" | "success" | "warning";

type IconModalType = RequireAtLeastOne<
  {
    icon: Required<React.ReactNode>;
    status: StatusType;
  },
  "icon" | "status"
>;

type BaseCOREModalProps = Merge<
  Pick<
    ModalProps,
    | "visible"
    | "footer"
    | "onCancel"
    | "title"
    | "className"
    | "afterClose"
    | "width"
    | "maskClosable"
    | "zIndex"
    | "destroyOnClose"
    | "forceRender"
  >,
  {
    testID: TestID;
    closeIcon?: React.ReactNode;
  }
>;

export type COREModalProps = Merge<
  Merge<
    | {
        type: "feature";
        closable?: boolean;
      }
    | {
        type: "basic";
        closable?: never;
      },
    BaseCOREModalProps
  >,
  IconModalType
>;

const CircleXMarkSolid: React.FC<{
  className?: string;
}> = ({ className }) => (
  <COREIcon
    icon={generateIcon({ name: "circle-xmark", style: "solid" })}
    className={className}
    color={red100}
  />
);

const CircleXMarkRegular: React.FC<{}> = () => (
  <COREIcon
    icon={generateIcon({ name: "circle-xmark", style: "regular" })}
    size={"xs"}
  />
);

const IconModal: React.FC<IconModalType> = ({ icon: customIcon, status }) => {
  if (status === undefined) {
    return <span className={"icon"}>{customIcon}</span>;
  }
  const iconClassName = "status-icon";
  switch (status) {
    case "info":
    case "primary":
      return (
        <COREIcon
          icon={generateIcon({ name: "circle-info", style: "solid" })}
          className={iconClassName}
          color={grey100}
        />
      );
    case "error":
      return <CircleXMarkSolid className={iconClassName} />;
    case "success":
      return (
        <COREIcon
          icon={generateIcon({ name: "circle-check", style: "solid" })}
          className={iconClassName}
          color={lime100}
        />
      );
    case "warning":
      return (
        <COREIcon
          icon={generateIcon({
            name: "triangle-exclamation",
            style: "solid",
          })}
          className={iconClassName}
          color={orange100}
        />
      );
  }
};

const FeatureModal: React.FC<Omit<COREModalProps, "type">> = ({
  visible,
  testID,
  icon,
  status,
  onCancel,
  closable,
  className,
  title,
  footer,
  afterClose,
  width,
  children,
  maskClosable,
  zIndex,
  destroyOnClose,
  forceRender,
}) => (
  <Modal
    footer={footer}
    visible={visible}
    width={width}
    maskClosable={maskClosable}
    forceRender={forceRender}
    title={
      <COREHeading level={3} testID={(testID + "-header") as TestID}>
        <Space align={"center"} direction={"vertical"} size={20}>
          <IconModal icon={icon} status={status} />
          {title}
        </Space>
      </COREHeading>
    }
    className={classnames(["core-modal", "feature-type", className])}
    closeIcon={
      <COREButton
        onClick={onCancel}
        type={"action"}
        icon={<CircleXMarkRegular />}
        testID={(testID + "-header-close-btn") as TestID}
        size={"sm"}
      />
    }
    closable={closable}
    onCancel={onCancel}
    afterClose={afterClose}
    zIndex={zIndex}
    destroyOnClose={destroyOnClose}
  >
    <TestIDWrapper testID={(testID + "-body") as TestID}>
      {children}
    </TestIDWrapper>
  </Modal>
);

const BasicModal: React.FC<Omit<COREModalProps, "type">> = ({
  visible,
  footer,
  testID,
  icon,
  status,
  onCancel,
  className,
  title,
  afterClose,
  width,
  children,
  maskClosable,
  zIndex,
  destroyOnClose,
  forceRender,
}) => (
  <Modal
    width={width}
    visible={visible}
    footer={footer ? <Space wrap={true}>{footer}</Space> : null}
    maskClosable={maskClosable}
    forceRender={forceRender}
    title={
      <COREHeading level={3} testID={(testID + "-header") as TestID}>
        <Row wrap={false}>
          {(status || icon) && (
            <Col flex="none">
              <IconModal icon={icon} status={status} />
            </Col>
          )}
          <Col flex="auto">{title}</Col>
          <Col flex="22px">
            <COREButton
              onClick={onCancel}
              type={"action"}
              icon={<CircleXMarkRegular />}
              testID={(testID + "-header-close-btn") as TestID}
              size={"sm"}
            />
          </Col>
        </Row>
      </COREHeading>
    }
    className={classnames("core-modal", className)}
    closable={false}
    onCancel={onCancel}
    afterClose={afterClose}
    zIndex={zIndex}
    destroyOnClose={destroyOnClose}
  >
    <TestIDWrapper testID={(testID + "-body") as TestID}>
      {children}
    </TestIDWrapper>
  </Modal>
);

export const COREModal: React.FC<COREModalProps> = ({
  visible,
  footer,
  closable = false,
  type,
  testID,
  icon,
  status,
  onCancel,
  className,
  title,
  afterClose,
  width,
  children,
  maskClosable,
  zIndex,
  destroyOnClose,
  forceRender = false,
}) => {
  if (type === "feature") {
    return (
      <TestIDWrapper testID={testID}>
        <FeatureModal
          forceRender={forceRender}
          width={width}
          footer={footer}
          visible={visible}
          testID={testID}
          icon={icon}
          status={status}
          title={title}
          className={className}
          closable={closable}
          onCancel={onCancel}
          afterClose={afterClose}
          maskClosable={maskClosable}
          zIndex={zIndex}
          destroyOnClose={destroyOnClose}
        >
          {children}
        </FeatureModal>
      </TestIDWrapper>
    );
  }
  return (
    <TestIDWrapper testID={testID}>
      <BasicModal
        forceRender={forceRender}
        width={width}
        footer={footer}
        visible={visible}
        testID={testID}
        icon={icon}
        status={status}
        title={title}
        className={className}
        onCancel={onCancel}
        afterClose={afterClose}
        maskClosable={maskClosable}
        zIndex={zIndex}
        destroyOnClose={destroyOnClose}
      >
        {children}
      </BasicModal>
    </TestIDWrapper>
  );
};
