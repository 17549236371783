import React from "react";
import { Col, Layout, Row } from "antd";
import "./COREPageFooter.less";
import { COREBody } from "../Typography/COREBody";
import moment from "moment";
import { useUserLoggedInStatus } from "../../shared/state/user";
import { CORELink } from "../Action/CORELink";
import {
  COREMARKETS_PRIVACY_POLICY_URL,
  COREMARKETS_TERMS_OF_USE_URL,
} from "../../shared/global";
import { COREDivider } from "./COREDivider";
import classNames from "classnames";

const { Footer } = Layout;

type COREPageFooterProps = {
  showDetails: boolean;
};

const footerTextColour = "footer-text-colour";

export const COREPageFooter: React.FC<COREPageFooterProps> = ({
  showDetails,
}) => {
  const isLoggedIn = useUserLoggedInStatus();
  return (
    <Row
      align={"middle"}
      className={classNames(
        "core-footer",
        { "core-page-footer-logged-in-pages": isLoggedIn },
        { "core-page-footer-login": !isLoggedIn }
      )}
    >
      <Col span={24}>
        <Footer>
          <Row gutter={[24, 12]} align={"middle"} className={"footer-content"}>
            <Col span={24}>
              <COREDivider
                space={"none"}
                className={"footer-divider"}
              ></COREDivider>
            </Col>
            <Col flex={"auto"}>
              <COREBody
                type={"p4"}
                testID={"page-footer-copyright"}
                marginBottom={false}
                className={footerTextColour}
              >
                {`©${moment().year()} CORE Markets. All Rights Reserved.`}
              </COREBody>
            </Col>
            <Col>
              <Row gutter={[24, 2]} align="middle">
                {showDetails && (
                  <Col>
                    <CORELink
                      type={"p4"}
                      href={COREMARKETS_TERMS_OF_USE_URL}
                      target={"_blank"}
                      testID={"page-footer-terms-of-use"}
                      className={footerTextColour}
                    >
                      Terms of Use
                    </CORELink>
                  </Col>
                )}
                {showDetails && (
                  <Col>
                    <CORELink
                      type={"p4"}
                      href={COREMARKETS_PRIVACY_POLICY_URL}
                      target={"_blank"}
                      testID={"page-footer-privacy-policy"}
                      className={footerTextColour}
                    >
                      Privacy Policy
                    </CORELink>
                  </Col>
                )}
                <Col>
                  <COREBody
                    strong
                    type={"p4"}
                    className={"version"}
                    testID={"page-footer-version"}
                    marginBottom={false}
                  >
                    v. {process.env.REACT_APP_TAG ?? " Unknown"}
                  </COREBody>
                </Col>
              </Row>
            </Col>
          </Row>
        </Footer>
      </Col>
    </Row>
  );
};
