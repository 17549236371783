import {
  CertificateCurveData,
  DailyCurvesWithSnapshot,
  ProductCurveCommentaries,
} from "../CarbonDailyCurvePageWrapper";
import { priceFormatter } from "../../../shared/globals";
import moment from "moment";
import { dateFormat, getSpotHIRPrice } from "./copyTables";

const csvFormat = (
  productTypeName: DailyCurvesWithSnapshot["productTypeName"],
  shortName: DailyCurvesWithSnapshot["shortName"],
  vintage: string,
  price: DailyCurvesWithSnapshot["price"],
  change: DailyCurvesWithSnapshot["change"],
  lastPrice: DailyCurvesWithSnapshot["lastPrice"],
  lastDate: DailyCurvesWithSnapshot["lastDate"]
) => [
  productTypeName,
  shortName,
  vintage,
  priceFormatter(price, false) || "0.00",
  priceFormatter(change, false) || "0.00",
  priceFormatter(lastPrice, false) || "0.00",
  lastDate ? moment(lastDate)?.format(dateFormat) : "",
];

const renderTable = (
  productType: DailyCurvesWithSnapshot["productType"],
  productTypeName: DailyCurvesWithSnapshot["productTypeName"],
  ds: DailyCurvesWithSnapshot[]
): string[][] => {
  const productData = ds
    .map((d) => {
      if (
        ["vcu", "gs", "cer"].includes(productType) &&
        d.certificateProductPremiumCurves
      ) {
        return d.certificateProductPremiumCurves.map((c) =>
          csvFormat(
            productTypeName,
            d.shortName,
            c.certificate_project_type_name,
            c.price,
            c.change,
            d.lastPrice,
            d.lastDate
          )
        );
      }
      return [
        csvFormat(
          productTypeName,
          d.shortName,
          "",
          d.price,
          d.change,
          d.lastPrice,
          d.lastDate
        ),
      ];
    })
    .flat();

  if (productTypeName === "ACCU") {
    const spotHIRArr = [
      [
        "ACCU",
        "Spot (HIR)",
        "",
        priceFormatter(getSpotHIRPrice(ds), false) ?? "0.00",
        "0.00",
      ],
    ];

    productData.splice(0, 0, ...spotHIRArr);
  }

  return [...productData];
};

export const getCSVData = (
  general: ProductCurveCommentaries | null | undefined,
  tables: CertificateCurveData[]
): string[][] =>
  tables
    .map(({ productType, productTypeName, dailyCurves }) =>
      renderTable(productType, productTypeName, dailyCurves)
    )
    .flat();
