import React from "react";
import { COREMenuItem } from "../../../../COREDesignSystem/Navigation/COREMenuItem";
import { CORESubMenu } from "../../../../COREDesignSystem/Navigation/CORESubMenu";
import { UserScopes } from "../../../../openapi-typescript/common/user_scopes";
import { OHLCPriceHistoryByProductType } from "../../../tools/projectMarketplace/widgets/OHLCPriceHistoryByProductType";
import { SetupOHLCPriceHistoryWidget } from "../../../tools/projectMarketplace/widgets/SetupOHLCPriceHistoryWidget";
import {
  currentWidgetFilters,
  onSelectSetupMenu,
  WidgetPanel,
  WidgetProps,
} from "../../WidgetPanel";
import { useScopes } from "../../../../shared/state/user";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import { PriceHistoryChartFilterValues } from "../../../tools/projectMarketplace/PriceHistoryChart";
import {
  FeatureFlag,
  useFeatureFlags,
} from "../../../../shared/customHoooks/useFeatureFlags";

type OHLCPriceHistoryWidgetType<T, WidgetFilterType extends object> = {
  props: WidgetProps<T, WidgetFilterType>;
};

export type OHLCProductType = "accu" | "lgc";
export type OHLCPriceHistoryWidgetFilters = {};

export const isEnableOHLCPriceHistoryWidget = (
  features?: FeatureFlag[],
  scopes?: UserScopes[]
) => {
  if (!features || !scopes) return false;

  return (
    features.includes("market-prices-carbon") &&
    (scopes.includes("core.product.accu.read") ||
      scopes.includes("core.product.lgc.read"))
  );
};

const getMenuPermission = (
  selectedType: OHLCProductType | undefined,
  features: FeatureFlag[],
  scopes: UserScopes[]
): OHLCProductType | "all" | "noPermission" => {
  if (
    !isEnableOHLCPriceHistoryWidget(features, scopes) ||
    (selectedType && !scopes.includes(`core.product.${selectedType}.read`))
  )
    return "noPermission";

  const enableACCU = scopes.includes("core.product.accu.read");
  const enableLGC = scopes.includes("core.product.lgc.read");

  if (enableACCU && enableLGC) return "all";
  if (enableACCU) return "accu";
  if (enableLGC) return "lgc";
  return "noPermission";
};

export const OHLCPriceHistoryWidget = ({
  props: widgetProps,
}: OHLCPriceHistoryWidgetType<
  OHLCProductType,
  PriceHistoryChartFilterValues
>) => {
  const { sync, loading, features } = useFeatureFlags();
  const scopes = useScopes();
  if (loading && !sync) {
    return <CORELoading />;
  }

  const currentProps = currentWidgetFilters(widgetProps);
  const permissionType = getMenuPermission(
    currentProps?.widgetType,
    features,
    scopes
  );

  if (permissionType === "noPermission") {
    return (
      <COREEmpty
        testID={"dashboard-ohlc-price-history-widgets-empty"}
        description={"No content available."}
      />
    );
  }

  const currentWidgetType =
    permissionType === "all"
      ? currentProps?.widgetType
      : permissionType ?? currentProps?.widgetType;

  const interactionsMenu = [
    {
      component: (
        <CORESubMenu testID="storybook-CORESubMenu" title={"Widget setup"}>
          <COREMenuItem
            testID={"dashboard-ohlc-price-history-widgets-1"}
            onClick={() => {
              onSelectSetupMenu(widgetProps, "accu");
            }}
          >
            ACCU
          </COREMenuItem>
          <COREMenuItem
            testID={"dashboard-ohlc-price-history-widgets-2"}
            onClick={() => {
              onSelectSetupMenu(widgetProps, "lgc");
            }}
          >
            LGC
          </COREMenuItem>
        </CORESubMenu>
      ),
      closeWhenClick: true,
    },
  ];

  return (
    <WidgetPanel
      interactionsMenu={[...(permissionType === "all" ? interactionsMenu : [])]}
      testID={"dashboard-ohlc-price-history-widgets"}
      panelTitle={
        currentWidgetType
          ? `${currentWidgetType.toUpperCase()} - Price history`
          : "Price history - widget setup"
      }
    >
      {currentWidgetType ? (
        <OHLCPriceHistoryByProductType
          testID={"dashboard-ohlc-price-history-widgets-content"}
          widgetProps={widgetProps}
          widgetType={currentWidgetType}
        />
      ) : (
        <SetupOHLCPriceHistoryWidget
          testID={"dashboard-ohlc-price-history-widgets-setup"}
          widgetProps={widgetProps}
        />
      )}
    </WidgetPanel>
  );
};
