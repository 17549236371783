import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { PostHogProvider } from "posthog-js/react";
import { keysToSnake } from "./shared/global";

const { REACT_APP_ENV, REACT_APP_FORCE_SEND_POSTHOG } = process.env;
const SHOULD_SEND_POSTHOG =
  REACT_APP_ENV === "LIVE" || REACT_APP_FORCE_SEND_POSTHOG === "true";
const options = SHOULD_SEND_POSTHOG
  ? keysToSnake({ apiHost: process.env.REACT_APP_PUBLIC_POSTHOG_HOST })
  : undefined;

async function unregister() {
  if ("serviceWorker" in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let registration of registrations) {
      await registration.unregister();
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={
        SHOULD_SEND_POSTHOG
          ? process.env.REACT_APP_PUBLIC_POSTHOG_KEY
          : undefined
      }
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

unregister().then();

reportWebVitals();
