// eslint-disable-file

export const TAB_KEY = 9;
export const ENTER_KEY = 13;
export const ESCAPE_KEY = 27;
export const LEFT_KEY = 37;
export const UP_KEY = 38;
export const RIGHT_KEY = 39;
export const DOWN_KEY = 40;
export const DELETE_KEY = 46;
export const BACKSPACE_KEY = 8;
