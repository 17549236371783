import copy from "copy-to-clipboard";
import { useCallback } from "react";
import { useMountedState, useSetState } from "react-use";

export const useCopyToClipboard = (): [
  { value?: string; error?: Error; noUserInteraction: boolean },
  (value, options) => void
] => {
  const isMounted = useMountedState();
  const [state, setState] = useSetState<{
    value?: string;
    error?: Error;
    noUserInteraction: boolean;
  }>({
    value: undefined,
    error: undefined,
    noUserInteraction: true,
  });

  const copyToClipboard = useCallback(
    (value, options) => {
      if (!isMounted()) {
        return;
      }
      let noUserInteraction;
      let normalizedValue;
      try {
        // only strings and numbers casted to strings can be copied to clipboard
        if (typeof value !== "string" && typeof value !== "number") {
          const error = new Error(
            `Cannot copy typeof ${typeof value} to clipboard, must be a string`
          );
          console.error(error);
          setState({
            value,
            error,
            noUserInteraction: true,
          });
          return;
        }
        // empty strings are also considered invalid
        else if (value === "") {
          const error = new Error(`Cannot copy empty string to clipboard.`);
          console.error(error);
          setState({
            value,
            error,
            noUserInteraction: true,
          });
          return;
        }
        normalizedValue = value.toString();
        noUserInteraction = copy(normalizedValue, options);
        setState({
          value: normalizedValue,
          error: undefined,
          noUserInteraction,
        });
      } catch (error) {
        setState({
          value: normalizedValue,
          // @ts-ignore
          error,
          noUserInteraction,
        });
      }
    },
    [isMounted, setState]
  );

  return [state, copyToClipboard];
};
