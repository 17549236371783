import React from "react";
import { generateTestId } from "../../../shared/testids/testids";
import { Trace } from "../../tools/chartBuilder/AggregatesChartV2";
import { TabData } from "./CertificateCurve";
import { CertificateCurveData } from "../CarbonDailyCurvePageWrapper";
import {
  COREAggregatesContainerChart,
  COREAggregatesPanelChart,
} from "../../../COREDesignSystem/Chart/ChartHOC";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";

type BrokerChartProps = {
  data: TabData;
  productType: CertificateCurveData["productType"];
  productTypeName: CertificateCurveData["productTypeName"];
  date: moment.Moment;
  displayByPanel?: boolean;
  empty: React.ReactElement;
};

export const BrokerChart: React.FC<BrokerChartProps> = ({
  data,
  productType,
  productTypeName,
  date,
  displayByPanel = true,
  empty,
}) => {
  const chartTraces: Trace[] = data.tradableProduct.map((t) => ({
    aggFunction: "avg",
    aggPeriod: "none",
    dateRange: {
      isRelative: false,
      from: date.clone().subtract(6, "month"),
      to: date,
      bounds: "[)",
    },
    type: "scatter",
    axis: {
      side: "left",
      title: "$ / Certificate",
    },
    columnName: "curve",
    tableName: "trading",
    title: {
      text: t.shortName,
    },
    name: t.shortName,
    selectedValue: t.id ? t.id.toString() : "",
  }));

  const chartRef = `${productTypeName} - ${data.name}`;
  const { features: featureList } = useFeatureFlags();
  const displayDownloadCsvButton =
    featureList &&
    featureList.includes(`chart-builder-csv-environmentalProductTree`);

  return (
    <>
      {displayByPanel ? (
        <COREAggregatesPanelChart
          chartId={chartRef}
          chartTitle={chartRef}
          traces={chartTraces}
          chartFilter={{
            hasTodayBreak: false,
            todayDate: date.format(),
          }}
          downloadPng={true}
          downloadCsv={displayDownloadCsvButton}
          adjustHeightOfChart={true}
          enableEmptyIcon={true}
          legendFilter={true}
          roundCorner={false}
          testID={generateTestId(
            "carbondailycurve",
            `broker-chart-${productType}`
          )}
          empty={empty}
        />
      ) : (
        <COREAggregatesContainerChart
          chartId={chartRef}
          chartTitle={chartRef}
          traces={chartTraces}
          chartFilter={{
            hasTodayBreak: false,
            todayDate: date.format(),
          }}
          downloadPng={true}
          downloadCsv={true}
          adjustHeightOfChart={true}
          enableEmptyIcon={true}
          legendFilter={true}
          roundCorner={false}
          testID={generateTestId(
            "carbondailycurve",
            `broker-chart-${productType}`
          )}
          empty={empty}
        />
      )}
    </>
  );
};
