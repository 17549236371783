import React from "react";
import { Result } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ResultStatusType } from "antd/lib/result";
import { COREButton } from "../Action/COREButton";
import { generateTestId } from "../../shared/testids/testids";

export const COREError: React.FC<{
  title?: string;
  extra?: string;
  status?: ResultStatusType;
}> = ({
  title = "An unknown error occurred. Refreshing the page might help.",
  extra = "The most likely cause is a required site update. Refreshing the page will resolve this.",
  status = "warning",
  ...otherProps
}) => (
  <Result
    status={status}
    title={title}
    extra={[
      <div key={"bnt-home-block"} className="bnt-home-block">
        <Link to="/">
          <COREButton
            testID={generateTestId("page", "core-error-back-to-home-btn")}
            danger
            icon={<ArrowLeftOutlined />}
            type="primary"
          >
            BACK TO HOME
          </COREButton>
        </Link>
      </div>,
    ]}
    className="core-result-error"
    {...otherProps}
  />
);
