import React from "react";
import { Pagination, PaginationProps } from "antd";
import { TestID } from "../../shared/testids/testids";
import "./COREPagination.less";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import classNames from "classnames";

interface CommonProps {
  testID: TestID;
}

export type COREPaginationProps = PaginationProps & CommonProps;

export const COREPagination: React.FC<COREPaginationProps> = ({
  size = "default",
  disabled = false,
  testID,
  defaultCurrent = 1,
  onChange,
  ...props
}) => {
  const arrowRender = (_current, type, originalElement) => {
    const classArr = classNames(size, "arrow-button", { disabled: disabled });

    if (type === "prev") {
      return (
        <button
          type="button"
          className={classNames(classArr, "arrow-button-prev")}
        >
          <CaretLeftOutlined />
        </button>
      );
    }

    if (type === "next") {
      return (
        <button
          type="button"
          className={classNames(classArr, "arrow-button-next")}
        >
          <CaretRightOutlined />
        </button>
      );
    }

    return originalElement;
  };

  return (
    <div data-testid={testID} className={"pagination"}>
      <Pagination
        size={size}
        disabled={disabled}
        defaultPageSize={20}
        itemRender={arrowRender}
        current={defaultCurrent}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};
