import { useEffect, useState } from "react";
import { appQueryClient } from "./appQueryClient";
import { keysToCamelCase } from "../global";
import { users as adminUsers } from "../../openapi-typescript/admin";
import { users as approvedUsers } from "../../openapi-typescript/approved";
import type { usePostHog } from "posthog-js/react";
import { SuccessUserLogin } from "../../openapi-typescript/common/sucess_user_login";

export type Users = adminUsers | approvedUsers;

type MFASetupTOTPRequiredUser = {
  userToken: string;
  otpSecret: string;
  status: "setup totp";
};
type VerifyTOTPRequiredUser = {
  userToken: string;
  otpSecret: string;
  status: "verify totp";
};
export type LoggedInUser =
  | SuccessUserLogin
  | MFASetupTOTPRequiredUser
  | VerifyTOTPRequiredUser;

const storageKey = "user";
const storeUser = (user) =>
  localStorage.setItem(storageKey, JSON.stringify(user));
const initUser = {};

const isEmptyObject = (object) =>
  !!object && Object.entries(object).length === 0;
const isInSetup2MFProcess = (object) =>
  !!object && object.me?.status === "setup totp";
const retrieveUser = () => {
  // @ts-ignore
  const user = JSON.parse(localStorage.getItem(storageKey));
  if (user === undefined) return initUser;
  if (isEmptyObject(user)) return initUser;

  return user;
};
let user = retrieveUser() || initUser;
let userSubscribers = { total: 0, subscribers: {} };
export const getUser = () => ({ ...user });
const setUser = (newUser: LoggedInUser | {}) => {
  user = newUser;
  alertAll();
};
export const alertAuthError = () => alertAll();
export const logout = (posthog?: ReturnType<typeof usePostHog>) => {
  if (posthog) posthog.reset();
  appQueryClient.clear();
  setUser(initUser);
};
const alertAll = () => {
  Object.entries(userSubscribers.subscribers).forEach(([i, sub]) => {
    if (!sub || typeof sub !== "function") {
      return;
    }
    sub(getUser());
  });
};
const unsubscriber = (index) => {
  return () => {
    if (!userSubscribers.subscribers.hasOwnProperty(index)) {
      throw Error(
        `Can't remove user subscription: ${index} ${JSON.stringify(
          userSubscribers
        )}`
      );
    }
    delete userSubscribers.subscribers[index];
  };
};
const subscribe = (func) => {
  if (!func || typeof func !== "function") {
    throw new Error("A subscription needs a function");
  }
  //add subscriber
  const nextSubscriber = userSubscribers.total + 1;
  userSubscribers.subscribers[nextSubscriber] = func;
  userSubscribers.total = nextSubscriber;
  return unsubscriber(nextSubscriber);
};

export const login = (
  posthog: ReturnType<typeof usePostHog>,
  user: LoggedInUser
) => {
  setUser(user);
  appQueryClient.clear();

  if (user.status !== "success") {
    return;
  }
  if (!user.me.id) {
    return;
  }
  posthog.reset();

  posthog?.identify(user.me.id.toString(), {
    email: user.me.email,
    companyID: user.me.companyId,
    name: user.me.name,
    role: user.me.role,
    frontendVersion: process.env.REACT_APP_TAG || "Unknown",
  });
  if (!user.me.companyId) {
    return;
  }
  posthog?.group("company_id", user.me.companyId.toString());
};

//helper functions

// react hooks
const useUserAll = () => {
  const [user, updateUser] = useState<LoggedInUser[]>(getUser());
  useEffect(() => subscribe(updateUser), []);
  return keysToCamelCase(user);
};
export const useUser = (): SuccessUserLogin["me"] | undefined =>
  useUserAll()?.me;

export const useScopes = () => {
  return useUser()?.scopes ?? [];
};

export const useUserID = () => useUser()?.id;
export const useUserRole = () => useUser()?.role;
export const useUserIsAdmin = () => useUserRole() === "admin";
export const useUserCompanyId = () => useUser()?.companyId;
export const useUserLoggedInStatus = () => {
  const object = useUserAll();
  return !isInSetup2MFProcess(object) && !isEmptyObject(object);
};
// localstorage Updater
subscribe((newUser) => {
  storeUser(newUser);
});
