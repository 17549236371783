import React from "react";
import { Tooltip } from "antd";
import { useAPIQueryRequest } from "../customHoooks/useAPI";

export const TradableProductTooltip: React.FC<{
  id: number;
}> = ({ id }) => {
  const {
    data: { data: tradableProduct },
    sync,
  } = useAPIQueryRequest("tradableProduct", { id: `eq.${id}` });

  if (id === null) {
    throw new Error("A tradable product tooltip, must have an ID");
  }

  if (!sync) return null;

  const { name, code: actualCode } = tradableProduct;
  const code = actualCode || name;

  return <Tooltip title={name}>{code}</Tooltip>;
};
