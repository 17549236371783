import React, { useState } from "react";
import { Col, Row } from "antd";
import moment, { Moment } from "moment";
import { COREBody } from "../Typography/COREBody";
import "./COREDisplayComment.less";
import { COREButton } from "../Action/COREButton";
import { COREDropdownMenu } from "../Form/COREDropdownMenu";
import { COREIcon } from "./COREIcon";
import { TestID } from "../../shared/testids/testids";
import { COREMenuItem } from "../Navigation/COREMenuItem";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { grey140, grey180 } from "./COREColour";
import {
  OnResize,
  SetSizeObject,
  defaultResizeValue,
} from "../../shared/OnResize";
import { COREInput } from "../Form/COREInput";
import classNames from "classnames";
import { coreMessage } from "../Feedback/COREMessage";

const { TextArea } = COREInput;

export type COREDisplayCommentProps<T> = {
  key: string | number;
  title: string;
  comment: string;
  createdAt?: Moment;
  modifiedAt?: Moment;
  testID: TestID;
  showButton?: React.ReactNode;
} & (
  | {
      isAuthor: true;
      onSubmitEdit: (
        comment: COREDisplayCommentProps<T>["comment"]
      ) => Promise<T>;
      onDelete: () => void;
    }
  | {
      isAuthor: false;
      onSubmitEdit?: null;
      onDelete?: null;
    }
);

const OwnerCommentMenu = <T,>({
  testID,
  setOnEditingComment,
  onDelete,
}: {
  testID: TestID;
  setOnEditingComment: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: COREDisplayCommentProps<T>["onDelete"];
}) => {
  return (
    <COREDropdownMenu
      placement="bottomRight"
      testID={`${testID}-dropdown-menu`}
      isDisabled={false}
      overlayStyle={{ minWidth: "192px" }}
      interactionsMenu={[
        {
          component: (
            <COREMenuItem
              testID={`${testID}-edit-comment-button`}
              icon={
                <COREIcon
                  icon={icon({
                    name: "pen-to-square",
                    style: "regular",
                  })}
                />
              }
              onClick={() => setOnEditingComment(true)}
            >
              Edit comment
            </COREMenuItem>
          ),
          closeWhenClick: true,
        },
        {
          component: (
            <COREMenuItem
              testID={`${testID}-delete-comment-button`}
              icon={
                <COREIcon
                  icon={icon({
                    name: "trash",
                    style: "regular",
                  })}
                />
              }
              onClick={() => onDelete && onDelete()}
            >
              Delete comment
            </COREMenuItem>
          ),
          closeWhenClick: true,
        },
      ]}
    >
      <COREButton
        type="action"
        size="sm"
        icon={<COREIcon icon={icon({ name: "ellipsis", style: "solid" })} />}
        testID={`${testID}-ellipsis`}
      />
    </COREDropdownMenu>
  );
};

const EditingComment = <T,>({
  testID,
  comment,
  onSubmitEdit,
  setOnEditingComment,
}: Pick<COREDisplayCommentProps<T>, "comment" | "onSubmitEdit"> & {
  testID: TestID;
  setOnEditingComment: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [commentValue, setCommentValue] = useState<string>(comment);

  return (
    <>
      <Row>
        <Col span={24}>
          <TextArea
            widthSize="full"
            allowResize={true}
            placeholder="Add a comment"
            testID={`${testID}-editing-comment`}
            value={commentValue}
            onChange={({ target: { value } }) => {
              setCommentValue(value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <COREButton
            type="primary"
            size="sm"
            icon={
              <COREIcon
                icon={icon({ name: "paper-plane-top", style: "solid" })}
              />
            }
            onAsyncClick={async () => {
              return onSubmitEdit && (await onSubmitEdit(commentValue));
            }}
            onSuccess={(res) => {
              setOnEditingComment(false);
              return coreMessage({
                type: "success",
                content: `Edit comment successfully.`,
              });
            }}
            onError={(error) => {
              setOnEditingComment(false);
              return coreMessage({
                type: "error",
                content: `Failed: ${error}`,
              });
            }}
            testID={`${testID}-update-comment-button`}
          >
            Comment
          </COREButton>
        </Col>
      </Row>
    </>
  );
};

const DisplayComment = <T,>({
  comment,
  showButton,
  setSize,
  setContainerSize,
  displayShowMoreButton,
  testID,
}: Pick<COREDisplayCommentProps<T>, "comment" | "showButton" | "testID"> & {
  setSize: React.Dispatch<React.SetStateAction<SetSizeObject>>;
  setContainerSize: React.Dispatch<React.SetStateAction<SetSizeObject>>;
  displayShowMoreButton: boolean;
}) => {
  const [onShowingAll, setOnShowingAll] = useState<boolean>(false);

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            className={classNames("display-html-break-line", {
              hidden: !onShowingAll,
            })}
          >
            <OnResize onResize={setSize}>
              <COREBody
                type="p2"
                marginBottom={false}
                testID={`${testID}-full-comment`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: comment,
                  }}
                />
              </COREBody>
            </OnResize>
          </div>
          <OnResize onResize={setContainerSize}>
            <COREBody
              type="p2"
              testID={`${testID}-comment`}
              marginBottom={false}
              className={classNames(
                "truncate-three-lines",
                "display-html-break-line",
                {
                  hidden: onShowingAll,
                }
              )}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: comment,
                }}
              />
            </COREBody>
          </OnResize>
        </Col>
      </Row>
      {showButton
        ? showButton
        : displayShowMoreButton && (
            <Row>
              <Col span={24} className={"custom-button-style"}>
                <COREButton
                  type="link"
                  size="sm"
                  onClick={() => setOnShowingAll((prev) => !prev)}
                  testID={`${testID}-showing-comment`}
                >
                  {onShowingAll ? "Show less" : "Show more"}
                </COREButton>
              </Col>
            </Row>
          )}
    </>
  );
};

export const COREDisplayComment = <T,>({
  title,
  comment,
  createdAt,
  modifiedAt,
  isAuthor,
  testID,
  showButton,
  onSubmitEdit,
  onDelete,
  key,
}: COREDisplayCommentProps<T>) => {
  const [size, setSize] = useState<SetSizeObject>(defaultResizeValue);
  const [containerSize, setContainerSize] =
    useState<SetSizeObject>(defaultResizeValue);
  const [onEditingComment, setOnEditingComment] = useState<boolean>(false);
  const isEdit = !moment(createdAt).isSame(modifiedAt);

  const childrenHeight = size && size.height !== null ? size.height : 0;
  const containerHeight =
    containerSize && containerSize.height !== null ? containerSize.height : 0;
  const displayShowMoreButton = childrenHeight > containerHeight;

  return (
    <div
      className={classNames(
        "core-display-comment",
        { "own-bg-color": isAuthor },
        { "other-bg-color": !isAuthor }
      )}
      key={`${key}-item`}
    >
      <Row align="middle" gutter={[4, 4]} wrap={false}>
        <Col flex="auto">
          <COREBody
            type="p4"
            strong
            marginBottom={false}
            color={grey180}
            ellipsis={true}
            testID={`${testID}-user-name`}
          >
            {isAuthor ? "You wrote" : title}
          </COREBody>
        </Col>
        {isEdit && (
          <Col flex="none">
            <COREBody
              type="p5"
              marginBottom={false}
              color={grey140}
              italic={true}
              testID={`${testID}-modified-status`}
            >
              Edited
            </COREBody>
          </Col>
        )}
        {createdAt && (
          <Col flex="none">
            <COREBody
              type="p4"
              marginBottom={false}
              color={grey140}
              testID={`${testID}-comment-date`}
            >
              {createdAt.format("HH:mm, DD-MMM-YYYY")}
            </COREBody>
          </Col>
        )}
        {isAuthor && (
          <Col flex={"none"}>
            <OwnerCommentMenu
              testID={testID}
              setOnEditingComment={setOnEditingComment}
              onDelete={onDelete}
            />
          </Col>
        )}
      </Row>
      {onEditingComment ? (
        <EditingComment
          testID={testID}
          comment={comment}
          onSubmitEdit={onSubmitEdit}
          setOnEditingComment={setOnEditingComment}
        />
      ) : (
        <DisplayComment
          testID={testID}
          comment={comment}
          showButton={showButton}
          setSize={setSize}
          setContainerSize={setContainerSize}
          displayShowMoreButton={displayShowMoreButton}
        />
      )}
    </div>
  );
};
