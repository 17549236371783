// eslint-disable-file
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import CellShape from "./CellShape";
import classnames from "classnames";

class Row extends PureComponent {
  render() {
    const { onSelectRow, children, cells, selected, row, hoverable } =
      this.props;

    return (
      <tr
        className={classnames({
          ["data-sheet-selected-row"]: selected,
          ["data-sheet-selectable-row"]: !!onSelectRow,
          ["data-sheet-hoverable"]: hoverable,
        })}
        onClick={() => onSelectRow && onSelectRow(cells, row)}
      >
        {children}
      </tr>
    );
  }
}

Row.propTypes = {
  row: PropTypes.number.isRequired,
  cells: PropTypes.arrayOf(PropTypes.shape(CellShape)).isRequired,
  onSelectRow: PropTypes.func,
  selecetd: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default Row;
