/* eslint-disable react/prop-types */
import React, { forwardRef, ReactElement, useEffect, useState } from "react";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import {
  useAPIQuery,
  useAPIQueryPagination,
} from "../../../shared/customHoooks/useAPI";
import {
  CORETree,
  generateTree,
  Key,
  useCORETree,
} from "../../../COREDesignSystem/Form/CORETree";
import {
  sortByEnvironmentalProducts,
  sortByShortName,
} from "../../../shared/globals";
import { Alert, Checkbox, Empty } from "antd";
import "../../../COREDesignSystem/Form/CORETree.less";
import { TestID } from "../../../shared/testids/testids";
import { Merge } from "../../../shared/TypeScriptHelpers";
import { TradableProductType } from "../../../shared/select/TradableProductTree";

interface enviroTreeType {
  key: string;
  value: string;
  realValue: string;
  path: string[];
  title: string;
}
type dataTreeType = Merge<
  enviroTreeType,
  {
    children: enviroTreeType[];
  }
>;

const buildTraceName = (enviroProduct: TradableProductType, name: string) =>
  [
    enviroProduct.environmentalProduct.name,
    enviroProduct.shortName,
    enviroProduct.environmentalProduct.tenor.name,
    name,
  ]
    .filter((a) => a !== null)
    .join(" ");

const buildNodeData = (
  enviroProduct: TradableProductType,
  isPremiumCurves: boolean,
  name: string,
  tooltip?: string
) => {
  return {
    name: enviroProduct.environmentalProduct.name,
    shortName: enviroProduct.shortName,
    tenor: enviroProduct.environmentalProduct.tenor.name,
    premiumCurves: isPremiumCurves ? "Premium Curves" : undefined,
    isStrip: enviroProduct.isStrip,
    id: enviroProduct.id,
    field: name,
    value: {
      id: enviroProduct.id,
      variable: name,
      traceName: buildTraceName(enviroProduct, name),
    },
    render: ({ beforeStr, afterStr, title, str }) => {
      if (str === undefined) {
        return <span title={tooltip}>{title}</span>;
      }
      return (
        <span title={tooltip}>
          {beforeStr}
          <span className={"search-value"}>{str}</span>
          {afterStr}
        </span>
      );
    },
  };
};

const EnvironmentalProductTree = ({
  data,
  onChange: parentOnChange,
  initialValue,
  includeAgg = false,
  testID,
  ...otherProps
}: {
  search?: boolean;
  multiple: boolean;
  includeAgg: boolean;
  onlyCurrent?: boolean;
  showOnlyCurrentToggle?: boolean;
  optionType?: "firm" | "option";
  onChange?: (
    value: Key[],
    labelList: React.ReactNode[],
    extra: unknown
  ) => void;
  checkedKeys?:
    | Key[]
    | {
        checked: Key[];
        halfChecked: Key[];
      };
  testID: TestID;
} & {
  data: TradableProductType[];
  initialValue?: Key[];
  ref: React.ForwardedRef<ReactElement>;
}): JSX.Element => {
  const {
    data: { data: productPremiumCurves },
  } = useAPIQuery("certificateProjectType");

  const enviroProducts = data
    .filter(
      (d) => d.hasOwnProperty("environmentalProduct") && d.environmentalProduct
    )
    .flatMap((enviro) => {
      if (enviro.isStrip) {
        return [
          buildNodeData(
            enviro,
            false,
            "Strip Trades (Price)",
            "Only include trades that are part of a strip"
          ),
          buildNodeData(enviro, false, "Strip Trades (Volume)"),
        ];
      } else {
        const result = [buildNodeData(enviro, false, "Broker Curve")];
        if (productPremiumCurves) {
          productPremiumCurves
            .filter(
              (productPremiumCurve) =>
                enviro.environmentalProduct.name.toLowerCase() ===
                productPremiumCurve.product_type?.toLowerCase()
            )
            .forEach((productPremiumCurve) => {
              // // TODO: remove Premium Curve until for now until we find the way to NOT hard code them
              // result.push(
              //   buildNodeData(enviro, true, productPremiumCurve.name)
              // );
            });
        }
        result.push(
          buildNodeData(
            enviro,
            false,
            "Trades (Price)",
            "Exclude any trades that are part of a strip"
          )
        );
        result.push(buildNodeData(enviro, false, "Trades (Volume)"));
        return result;
      }
    });

  const enviroProductsReorder = enviroProducts.sort((a, b) => {
    const aField = a.premiumCurves ? `Premium Curves ${a.field}` : a.field;
    const bField = b.premiumCurves ? `Premium Curves ${b.field}` : b.field;
    if (aField > bField) {
      return 1;
    } else if (aField < bField) {
      return -1;
    } else {
      return 0;
    }
  });

  let enviroTree = generateTree(
    enviroProductsReorder,
    // // TODO: remove Premium Curve until for now until we find the way to NOT hard code them
    // ["name", "shortName", "tenor", "premiumCurves", "field"],
    ["name", "shortName", "tenor", "field"],
    "value",
    includeAgg
  );
  enviroTree = sortByEnvironmentalProducts(enviroTree);
  enviroTree.map((item: dataTreeType) => sortByShortName(item.children));

  const enviroTreeState = useCORETree(enviroTree, {
    onChange: parentOnChange,
    initialRootValues: initialValue,
  });
  return <CORETree testID={testID} {...enviroTreeState} {...otherProps} />;
};

const mapSpecificHIRAndSavannaToACCU = (data) => {
  const genericSpotProduct = ["VCS", "CER", "GS"];
  const specificMethodTypesByProduct = {
    accu: [
      "HIR",
      "Savanna burning",
      "Savanna burning+",
      "Agriculture",
      "Avoided deforestation",
      "Industrial",
      "Landfill gas capture",
      "Plantings",
      "Soil Carbon",
    ],
    vcu: ["Community-based", "REDD+", "Renewables", "Re/afforestation"],
    cer: ["Renewables"],
    gs: ["Community-based", "Forestry", "Renewables"],
  };
  const filterData = data.filter((item) => {
    return (
      (!item.project_method_types &&
        !genericSpotProduct.includes(item.environmentalProduct?.name)) ||
      (item.project_method_types &&
        // specificProduct.includes(item.project_method_types.product_type) &&
        specificMethodTypesByProduct[item.project_method_types.product_type] &&
        specificMethodTypesByProduct[
          item.project_method_types.product_type
        ].includes(item.project_method_types.name))
    );
  });

  return filterData.map((item) => {
    if (item.project_method_types) {
      return {
        ...item,
        // eslint-disable-next-line camelcase
        environmentalProduct: {
          ...item.environmentalProduct,
          name: `${item.environmentalProduct.name} ${item.project_method_types.name}`,
        },
      };
    }

    return item;
  });
};

export const TimeseriesEnvironmentalProductTree = React.memo(
  forwardRef<
    ReactElement,
    {
      search?: boolean;
      multiple: boolean;
      includeAgg: boolean;
      onlyCurrent?: boolean;
      showOnlyCurrentToggle?: boolean;
      certificateProject?: boolean;
      tradeType?: "firm" | "option";
      onChange?: (
        value: Key[],
        labelList: React.ReactNode[],
        extra: unknown
      ) => void;
      checkedKeys?:
        | Key[]
        | {
            checked: Key[];
            halfChecked: Key[];
          };
      testID: TestID;
    }
  >(
    (
      {
        multiple = false,
        includeAgg = false,
        onlyCurrent: oc = true,
        showOnlyCurrentToggle = true,
        certificateProject = null,
        onChange,
        checkedKeys,
        tradeType = "firm",
        testID,
        ...otherProps
      },
      ref
    ): JSX.Element => {
      const [onlyCurrent, setOnlyCurrent] = useState(oc);
      useEffect(() => setOnlyCurrent(oc), [oc]);
      let params = {
        environmental_product: `not.is.null`, // eslint-disable-line camelcase,
        // project_method_type: `is.null`, // eslint-disable-line camelcase,
        // or: `(project_method_type.is.null, project_method_type.eq.hir)`, // eslint-disable-line camelcase,
      };
      if (onlyCurrent) {
        params["is_active"] = `eq.true`; // eslint-disable-line camelcase
      }
      if (tradeType === "option") {
        params["trade_type"] = "eq.option";
      } else if (tradeType === "firm") {
        params["trade_type"] = "eq.firm";
      }
      if (certificateProject !== null) {
        params["certificate_project"] = certificateProject
          ? `not.is.null`
          : `is.null`; // eslint-disable-line camelcase
      }
      const data = useAPIQueryPagination(
        "tradable_products",
        { params },
        {
          perPage: 10000,
          shouldLoadAll: true,
        }
      );
      if (data.loading || !data.sync) return <CORELoading size={"lg"} />;
      if (data.error) {
        console.error(data);
        return (
          <Alert type={"error"} message={"Error loading tradable products"} />
        );
      }
      const hasNoData = data.data === undefined || data.data.length === 0;
      if (hasNoData) return <Empty />;

      const chartData = mapSpecificHIRAndSavannaToACCU(data.data);

      return (
        <>
          {showOnlyCurrentToggle && (
            <>
              <Checkbox
                checked={!onlyCurrent}
                onChange={(e) => setOnlyCurrent(!e.target.checked)}
                className={"show-all-btn"}
              >
                Show all
              </Checkbox>
              <br />
            </>
          )}
          <EnvironmentalProductTree
            data={chartData}
            multiple={multiple}
            includeAgg={includeAgg}
            checkedKeys={checkedKeys}
            onChange={onChange}
            testID={testID}
            {...otherProps}
            ref={ref}
          />
        </>
      );
    }
  )
);
