import React from "react";
import { ProductType } from "../ProjectMarketplacePageWrapper";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import {
  CORESelect,
  CORESelectProps,
} from "../../../COREDesignSystem/Form/CORESelect";
import {
  getLabelFromProduct,
  isCandleStickChart,
  useTradableProductsForType,
} from "./PriceHistoryChart";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { ProductSelectedType } from "./MarketSnapshot";

export const PriceHistoryProductSelect: React.FC<
  {
    productType: ProductType;
    projectMethodType?: ProductSelectedType["projectMethodType"];
  } & Omit<CORESelectProps, "mode">
> = ({ productType, projectMethodType, ...props }) => {
  const allowMultiple = !isCandleStickChart(productType);

  const { sync, loading, error, tradableProducts } = useTradableProductsForType(
    productType,
    projectMethodType
  );

  if (error) {
    return <COREError />;
  }

  const options = tradableProducts?.map((tp) => {
    const label = getLabelFromProduct(tp);
    return { value: tp.id, label };
  });

  return (
    <CORELoading loading={loading && !sync} layout={"horizontal"}>
      <CORESelect
        {...props}
        options={options}
        mode={allowMultiple ? "multiple" : undefined}
      />
    </CORELoading>
  );
};
