import React from "react";
import {
  COREDataSheetProps,
  COREDataTable,
} from "../../../COREDesignSystem/Content/COREDataTable";
import { TestID } from "../../../shared/testids/testids";
import "./CarbonTable.less";
import { NumberFormat, priceFormatter } from "../../../shared/globals";
import { useUserIsAdmin } from "../../../shared/state/user";
import {
  appQueryClient as queryClient,
  mutation,
} from "../../../shared/state/appQueryClient";
import { useMutation } from "react-query";
import { Store } from "antd/lib/form/interface";
import { keysToSnake } from "../../../shared/global";
import { COREInputNumber } from "../../../COREDesignSystem/Form/COREInputNumber";
import { momentFormat } from "../../../shared/date/DateFormatContext";
import { coreMessage } from "../../../COREDesignSystem/Feedback/COREMessage";
import moment from "moment";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { DailyCurvesWithSnapshot } from "../CarbonDailyCurvePageWrapper";
import { Merge } from "../../../shared/TypeScriptHelpers";
import { MethodType } from "./CertificateCurve";
import classNames from "classnames";

export type CarbonTableProps = Merge<
  {
    dailyCurves: DailyCurvesWithSnapshot[];
    currency: DailyCurvesWithSnapshot["currency"];
    hasBaseCurve: boolean;
    isPublished: boolean;
    date: moment.Moment;
    testId: TestID;
    productType?: DailyCurvesWithSnapshot["productType"];
  },
  | {
      methodTypes: Required<
        DailyCurvesWithSnapshot["certificateProductPremiumCurves"]
      >;
      hasMethods: true;
    }
  | {
      methodTypes: undefined;
      hasMethods: false;
    }
>;

type NumericValues =
  | DailyCurvesWithSnapshot["price"]
  | DailyCurvesWithSnapshot["change"]
  | DailyCurvesWithSnapshot["lastPrice"]
  | DailyCurvesWithSnapshot["lastSize"]
  | MethodType;

type NumericValuesDisplayedProps = {
  value: NumericValues;
  digit?: number;
};

type ChangeColumnProps = {
  value: NumericValues;
};

interface DataSource extends Record<string, any> {
  curvePriceTradableProductID: DailyCurvesWithSnapshot["tradableProductId"];
  product: DailyCurvesWithSnapshot["shortName"];
  curvePrice: DailyCurvesWithSnapshot["price"];
  change: DailyCurvesWithSnapshot["change"];
  lastPrice: DailyCurvesWithSnapshot["lastPrice"];
  lastDate: DailyCurvesWithSnapshot["lastDate"];
  lastSize: DailyCurvesWithSnapshot["lastSize"];
}

const NumericValuesDisplayed: React.FC<NumericValuesDisplayedProps> = ({
  value,
  digit = 2,
}) => <div>{value !== null ? NumberFormat(value, digit) : "-"}</div>;

const ChangeColumn: React.FC<ChangeColumnProps> = ({ value }) =>
  value && typeof value === "number" && value > 0 ? (
    <div className={"number-is-positive"}>
      <CaretUpOutlined className={"caret-down-up-outlined-size"} />{" "}
      {NumberFormat(value, 2)}
    </div>
  ) : (
    <div className={"number-is-negative"}>
      <CaretDownOutlined className={"caret-down-up-outlined-size"} />{" "}
      {NumberFormat(Math.abs(value as number), 2)}
    </div>
  );

const onEdit: COREDataSheetProps<DataSource>["columns"][0]["editRender"] = (
  value,
  _cell,
  _index,
  onChange,
  onCommit
) => (
  <COREInputNumber
    autoFocus
    size={"sm"}
    onChange={onChange}
    value={priceFormatter(value, false)}
    testID={"carbondailycurve-curve-input"}
    onPressEnter={() => onCommit(value)}
  />
);

const createGroupColumn = (p: MethodType) => ({
  title: p.certificate_project_type_name,
  dataIndex: `${p.certificate_project_type_name}Price`,
  render: (value: NumericValues) => <NumericValuesDisplayed value={value} />,
  editRender: onEdit,
});

const createMethodSpecificCurve = (
  currency: string,
  methodTypes?: MethodType[]
) => {
  return methodTypes && methodTypes.length !== 0
    ? methodTypes.length === 1
      ? methodTypes?.map(createGroupColumn)
      : [
          {
            title: `Method-specific Curve (${currency})`,
            className: textCenter,
            children: methodTypes?.map(createGroupColumn),
          },
        ]
    : [];
};

const textCenter = "text-center";
export const CarbonTable: React.FC<CarbonTableProps> = ({
  dailyCurves,
  currency,
  methodTypes,
  hasMethods,
  hasBaseCurve,
  isPublished,
  date,
  testId,
  productType,
}) => {
  const isAdmin: boolean = useUserIsAdmin();
  const editable: boolean = !isPublished && isAdmin;

  const { mutate } = useMutation(
    (values: Store) => {
      return mutation({
        queryKey: [
          "addTradableProductCurves",
          {
            action: "addTradableProductCurves",
            body: JSON.stringify(
              keysToSnake({
                tradableProduct: values.tradableProductId,
                price: values.price,
                curveDate: date.format(momentFormat.server.format),
              })
            ),
            enabled: true,
          },
        ],
      });
    },
    {
      onMutate: async (newCurve: Store) => {
        const queryKey = [
          "getProductDailyCurve",
          {
            action: "getProductDailyCurve",
            enabled: true,
            params: keysToSnake({
              date: date.format(momentFormat.server.format),
              type: "carbon",
              ...(productType && { tradableProductType: `eq.${productType}` }),
            }),
            retry: true,
          },
        ];
        const previousTodos = queryClient.getQueryData(queryKey);

        queryClient.setQueryData<{ data?: DailyCurvesWithSnapshot[] }>(
          queryKey,
          (old) => {
            const { ...other } = old;
            const newData = old?.data?.map((d: DailyCurvesWithSnapshot) => {
              if (d.tradableProductId === newCurve.tradableProductId) {
                return { ...d, price: newCurve.price };
              }
              const newMethodsValue = d.certificateProductPremiumCurves?.map(
                (cppc) => {
                  if (cppc.tradable_product.id === newCurve.tradableProductId) {
                    return { ...cppc, price: newCurve.price };
                  }
                  return { ...cppc };
                }
              );

              return { ...d, certificateProductPremiumCurves: newMethodsValue };
            });

            return { ...other, data: newData };
          }
        );
        return { previousTodos };
      },
      onSuccess: (): void => {
        coreMessage({ type: "success", content: "Updated successfully" });
      },
      onError: ({ response: { data: err } }): void => {
        coreMessage({ type: "error", content: err });
      },
    }
  );

  let differentColumns: COREDataSheetProps<DataSource>["columns"] = [];
  if (hasMethods && hasBaseCurve) {
    differentColumns = [
      {
        title: `Curve (${currency})`,
        key: "price",
        dataIndex: "curvePrice",
        className: "curve-col",
        editable,
        render: (value: NumericValues) => (
          <NumericValuesDisplayed value={value} />
        ),
        editRender: onEdit,
      },
      {
        title: "Change",
        key: "change",
        dataIndex: "change",
        render: (value: NumericValues) =>
          value ? <ChangeColumn value={value} /> : "-",
        editable: false,
      },
      {
        title: "Last",
        key: "lastPrice",
        dataIndex: "lastPrice",
        render: (value: NumericValues) => (
          <NumericValuesDisplayed value={value} />
        ),
        editable: false,
      },
      ...createMethodSpecificCurve(currency, methodTypes),
    ];
  } else if (!hasMethods && hasBaseCurve) {
    differentColumns = [
      {
        title: `Curve (${currency})`,
        key: "price",
        dataIndex: "curvePrice",
        className: "curve-col",
        render: (value: NumericValues) => (
          <NumericValuesDisplayed value={value} />
        ),
        editRender: onEdit,
      },
      {
        title: "Change",
        key: "change",
        dataIndex: "change",
        render: (value: NumericValues) =>
          value ? <ChangeColumn value={value} /> : "-",
        editable: false,
      },
      {
        title: "Last",
        className: textCenter,
        children: [
          {
            title: "Price",
            key: "lastPrice",
            dataIndex: "lastPrice",
            className: "price-col",
            render: (value: NumericValues) => (
              <NumericValuesDisplayed value={value} />
            ),
            editable: false,
          },
          {
            title: "Volume",
            key: "lastSize",
            dataIndex: "lastSize",
            render: (value: NumericValues) => (
              <NumericValuesDisplayed value={value} digit={0} />
            ),
            editable: false,
          },
          {
            title: "Date",
            key: "lastDate",
            dataIndex: "lastDate",
            className: "last-date-col",
            render: (value: DailyCurvesWithSnapshot["lastDate"]) =>
              value ? moment(value).format(momentFormat.server.format) : "-",
            editable: false,
          },
        ],
      },
    ];
  } else if (hasMethods && methodTypes && !hasBaseCurve) {
    differentColumns = methodTypes.map((p: MethodType) => {
      return {
        title: p.certificate_project_type_name,
        className: `method-typs-col ${textCenter}`,
        children: [
          {
            title: "Curve",
            key: "price",
            dataIndex: `${p.certificate_project_type_name}Price`,
            render: (value: NumericValues) => (
              <NumericValuesDisplayed value={value} />
            ),
            editRender: onEdit,
          },
          {
            title: "Change",
            key: "change",
            dataIndex: `${p.certificate_project_type_name}Change`,
            render: (value: NumericValues) =>
              value ? <ChangeColumn value={value} /> : "-",
            editable: false,
          },
        ],
      };
    });
  }

  const columns: COREDataSheetProps<DataSource>["columns"] = [
    {
      title: "Product",
      key: "product",
      dataIndex: "product",
      className: "product-col",
      editable: false,
    },
    ...differentColumns,
  ];

  const dataSource: DataSource[] = dailyCurves.map((d) => ({
    curvePriceTradableProductID: d.tradableProductId,
    product: d.shortName,
    curvePrice: d.price,
    change: d.change,
    lastPrice: d.lastPrice,
    lastDate: d.lastDate,
    lastSize: d.lastSize,
    ...d.certificateProductPremiumCurves?.reduce(
      (p, c) => ({
        ...p,
        [`${c.certificate_project_type_name}Price`]: c.price,
        [`${c.certificate_project_type_name}Change`]: c.change,
        [`${c.certificate_project_type_name}PriceTradableProductID`]:
          c.tradable_product.id,
      }),
      {}
    ),
  }));

  return (
    <div className={"carbon-table"}>
      <div
        className={classNames({
          "has-method-types":
            hasMethods && methodTypes && methodTypes.length <= 4,
          "has-method-types-less-than-equal-2":
            hasMethods && methodTypes && methodTypes.length <= 2,
          "has-method-types-greater-than-equal-5":
            hasMethods && methodTypes && methodTypes.length >= 5,
        })}
      >
        <COREDataTable
          selectCellMode={true}
          onSave={(rowData: DataSource, cellIndex) => {
            const price =
              rowData[cellIndex as number] !== ""
                ? rowData[cellIndex as number]
                : null;
            const tradableProductId = rowData[`${cellIndex}TradableProductID`];
            return mutate({
              tradableProductId,
              price,
            });
          }}
          editMode={editable}
          testID={testId}
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};
