import React from "react";
import { Typography } from "antd";
import { ParagraphProps } from "antd/lib/typography/Paragraph";
import classnames from "classnames";
import { TestID } from "../../shared/testids/testids";
import { Merge } from "../../shared/TypeScriptHelpers";
import "./COREBody.less";
import { AllColorHEX } from "../Content/COREColour";

const { Paragraph } = Typography;

export type COREBodyProps = Merge<
  Pick<
    ParagraphProps,
    "underline" | "italic" | "strong" | "children" | "onClick" | "ellipsis"
  >,
  {
    type?: "p1" | "p2" | "p3" | "p4" | "p5";
    testID?: TestID;
    marginBottom?: boolean;
    className?: string;
    inline?: boolean;
    color?: AllColorHEX;
    textWrap?: boolean;
  }
>;

export const COREBody: React.FC<COREBodyProps> = ({
  underline,
  italic,
  strong,
  children,
  type = "p2",
  testID,
  marginBottom = true,
  textWrap = true,
  className,
  inline = false,
  color,
  onClick,
  ellipsis,
}) => {
  const classes = classnames([
    "core-body",
    type,
    className,
    { "no-margin-bottom": !marginBottom },
    { inline: inline },
    { onClick: onClick },
    { "no-text-wrap": !textWrap },
  ]);
  return (
    <Paragraph
      onClick={onClick}
      data-testid={testID}
      underline={underline}
      italic={italic}
      strong={strong}
      className={classes}
      ellipsis={ellipsis}
      {...(color && { style: { color: color } })}
    >
      {children}
    </Paragraph>
  );
};
