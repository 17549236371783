import React, { useContext } from "react";
import "./AemoPriceMapOverview.less";
import moment from "moment";
import {
  useAPIQueryRequest,
  useAPIQueryRequests,
} from "../../../../shared/customHoooks/useAPI";
import { DEPRECATEDDateFormatContextStandard } from "../../../../shared/date/DateFormatContext";
import {
  AggregatesChartProps,
  AggregatesChartV2,
  ChartFilterOptions,
  getTodayDate,
  Trace,
} from "../../../tools/chartBuilder/AggregatesChartV2";
import { generateTestId } from "../../../../shared/testids/testids";
import { RelativeRangeValue } from "../../../../COREDesignSystem/Form/CORERangePicker";
import { Alert } from "antd";
import { ColorHex } from "../../../../COREDesignSystem/Content/COREColour";
import { keysToSnake } from "../../../../shared/global";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import classNames from "classnames";

type InterconnectPopupType = { interconnector: string };

export const InterconnectPopup: React.FC<InterconnectPopupType> = ({
  interconnector,
}) => {
  // variable
  const momentStandFormatStandard = useContext(
    DEPRECATEDDateFormatContextStandard
  );
  const hrFormat = "HH:mm";
  const unitOfTimeDay = "day";

  // NEM Time
  const {
    sync: syncAPI,
    data: {
      data: { settlementdate: settlementdateAPI } = { settlementdate: null },
    },
  } = useAPIQueryRequest("livePrices");

  const nemTime = syncAPI ? settlementdateAPI : null;

  // get start, end date
  const getDefaultDates = (todayDate) => [
    moment(todayDate)
      .startOf(unitOfTimeDay)
      .format(momentStandFormatStandard.format), // Start
    moment(todayDate)
      .add(1, unitOfTimeDay)
      .format(momentStandFormatStandard.format), // End
  ];

  // map time range
  const todayDate = moment(nemTime);
  const hovertemplate = "%{x}, %{y:,.0f}";
  const todayDateFormat = moment(nemTime).format();
  const lastNemTime = todayDate.format(hrFormat);
  const [start, end] = getDefaultDates(todayDate);
  const tsRange = `[${start},${end})`;
  const chartFlowColor: ColorHex = "#35A53A";
  const chartExportColor: ColorHex = "#2177B3";
  const chartImportColor: ColorHex = "#FF8E2E";
  const {
    results: [
      { sync: syncMwflow, data: dataMwflow, error: errorMwflow },
      { sync: syncExportlimit, data: dataExportlimit, error: errorExport },
      { sync: syncImportlimit, data: dataImportlimit, error: errorImport },
    ],
  } = useAPIQueryRequests("aggregates", [
    {
      body: keysToSnake({
        tableName: "interconnector",
        columnName: "mwflow",
        selectedItem: interconnector,
        aggPeriod: "none",
        aggFunction: "none",
        dateRange: tsRange,
      }),
    },
    {
      body: keysToSnake({
        tableName: "interconnector",
        columnName: "exportlimit",
        selectedItem: interconnector,
        aggPeriod: "none",
        aggFunction: "none",
        dateRange: tsRange,
      }),
    },
    {
      body: keysToSnake({
        tableName: "interconnector",
        columnName: "importlimit",
        selectedItem: interconnector,
        aggPeriod: "none",
        aggFunction: "none",
        dateRange: tsRange,
      }),
    },
  ]);

  // Popup
  if (errorMwflow)
    return (
      <Alert
        type={"error"}
        message={`Unable to load ${interconnector} data for the specified time period`}
      />
    );
  if (errorExport)
    return (
      <Alert
        type={"error"}
        message={`Unable to load ${interconnector} data for the specified time period`}
      />
    );
  if (errorImport)
    return (
      <Alert
        type={"error"}
        message={`Unable to load ${interconnector} data for the specified time period`}
      />
    );

  const mwflow = syncMwflow ? dataMwflow : [];
  const exportlimit = syncExportlimit ? dataExportlimit : [];
  const importlimit = syncImportlimit ? dataImportlimit : [];
  const actualMwflow = mwflow.filter(({ date }) => date <= todayDateFormat);
  const actualExport = exportlimit.filter(
    ({ date }) => date <= todayDateFormat
  );
  const actualImport = importlimit.filter(
    ({ date }) => date <= todayDateFormat
  );

  const getChartTraces = (interconnector: string): Trace[] => {
    const mwflowTitle: Trace["title"] = {
      text: "MW Flow",
      font: {},
      standoff: 0,
      position: "top left",
    };
    const exportlimitTitle: Trace["title"] = {
      text: "Export limit",
      font: {},
      standoff: 0,
      position: "top left",
    };
    const importlimitTitle: Trace["title"] = {
      text: "Import limit",
      font: {},
      standoff: 0,
      position: "top left",
    };
    const dateRange: RelativeRangeValue = {
      isRelative: false,
      from: moment(),
      to: moment(),
      bounds: "[)",
    };

    const mwflowTrace: Trace = {
      tableName: "interconnector",
      columnName: "mwflow",
      title: mwflowTitle,
      name: "MW Flow",
      selectedValue: interconnector,
      aggFunction: "none",
      aggPeriod: "none",
      dateRange: dateRange,
      type: "scatter",
      "line.color": chartFlowColor,
      axis: {
        side: "left",
        title: "",
      },
      hovertemplate: hovertemplate,
    };

    const exportlimitTrace: Trace = {
      tableName: "interconnector",
      columnName: "exportlimit",
      title: exportlimitTitle,
      name: "Export limit",
      selectedValue: interconnector,
      aggFunction: "none",
      aggPeriod: "none",
      dateRange: dateRange,
      type: "scatter",
      "line.color": chartExportColor,
      axis: {
        side: "left",
        title: "",
      },
      hovertemplate: hovertemplate,
    };

    const importlimitTrace: Trace = {
      tableName: "interconnector",
      columnName: "importlimit",
      title: importlimitTitle,
      name: "Import limit",
      selectedValue: interconnector,
      aggFunction: "none",
      aggPeriod: "none",
      dateRange: dateRange,
      type: "scatter",
      "line.color": chartImportColor,
      axis: {
        side: "left",
        title: "",
      },
      hovertemplate: hovertemplate,
    };

    return [mwflowTrace, exportlimitTrace, importlimitTrace];
  };

  const layoutChart: AggregatesChartProps["layout"] = {
    autosize: true,
    showlegend: false,
    margin: { l: 0, r: 0, t: 0, b: 25 },
    shapes: [
      {
        type: "rect",
        xref: "x",
        yref: "paper",
        x0: todayDateFormat,
        y0: 0,
        x1: todayDateFormat,
        y1: 1,
        opacity: 1,
        line: { dash: "dot", width: 1, color: "#FF0000" },
      },
    ],
    xaxis: {
      automargin: true,
      tickformat: "%H:%M",
    },
    yaxis: {
      automargin: true,
      tickformat: ",.0f",
      showline: true,
      showgrid: false,
      autorange: true,
    },
    height: 210,
  };

  const todayDateChart: string = getTodayDate("none").format();
  const chartFilter: ChartFilterOptions = {
    hasTodayBreak: true,
    todayDate: todayDateChart,
  };

  const getLastValue = (arr): number =>
    arr.slice(-1)[0] && arr.slice(-1)[0].value;
  const mostRecentMWFlow: number = getLastValue(actualMwflow);
  const mostRecentExport: number = getLastValue(actualExport);
  const mostRecentImport: number = getLastValue(actualImport);

  return (
    <div className={"live-price-popover"}>
      <div className={"content-wrapper-block"}>
        {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
        <div className={"content-item-block"}>
          {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
          <div className={"label-block"}>
            <span className={"icon"} style={{ background: chartFlowColor }} />
            Flow
          </div>
          {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
          <div className={"value-block"}>{Math.round(mostRecentMWFlow)}</div>
          MW
        </div>
        <div className={"content-item-block"}>
          <div className={"label-block"}>
            <span className={"icon"} style={{ background: chartExportColor }} />
            Export
          </div>
          <div className={"value-block"}>{Math.round(mostRecentExport)}</div>
          MW
        </div>
        <div className={"content-item-block"}>
          <div className={"label-block"}>
            <span className={"icon"} style={{ background: chartImportColor }} />
            Import
          </div>
          <div className={"value-block"}>{Math.round(mostRecentImport)}</div>
          MW
        </div>
      </div>

      <div className={"graph-date"}>{lastNemTime}</div>

      <div className={classNames("graph-block", "with-axis-label")}>
        <div className={"axis-y-left-label"}>Flow (MW)</div>
        <AggregatesChartV2
          chartId={`interconnector - ${interconnector}`}
          traces={getChartTraces(interconnector)}
          chartFilter={chartFilter}
          downloadCsv={true}
          downloadPng={true}
          layout={layoutChart}
          testID={generateTestId("dashboard", "interconnect-popup-chart")}
          showlegend={false}
          chartPanel={true}
          empty={
            <COREEmpty
              description={"No chart data available"}
              hint={
                "Try reloading the page or update your chart's configuration in Chart Builder."
              }
              testID={generateTestId(
                "dashboard",
                "interconnect-popup-chart-no-data"
              )}
            />
          }
        />
      </div>
    </div>
  );
};
