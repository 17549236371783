import React from "react";
import "./LivePriceOverview.less";
import { PriceTable } from "../../../../shared/tables/PriceTable";

export const LivePriceTable = ({ tradableProducts, codes }) => {
  return (
    <div className={"livePriceOverview"}>
      <PriceTable
        theme="w"
        tradableProducts={tradableProducts}
        tablePageSize={null}
        widget={true}
        codes={codes}
      />
    </div>
  );
};
