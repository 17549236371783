import "./COREMainMenuBlock.less";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../shared/testids/testids";
import Menu, { Item, SubMenu } from "rc-menu";
import { CORETypographyInput } from "../Typography/CORETypographyInput";
import { COREIcon } from "../Content/COREIcon";
import { icon as getIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Space } from "antd";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useIsMobileScreen } from "../../shared/customHoooks/useBreakpoint";
import classNames from "classnames";
import { CORELogo } from "../Content/CORELogo";
import { useAPIQuery } from "../../shared/customHoooks/useAPITypes";
import { CORELoading } from "../Feedback/CORELoading";
import { GroupByNative } from "../../shared/globals";
import { useHistory, useLocation } from "react-router-dom";
import {
  useLocalStorageUntilLogout,
  UseLocalStorageUntilLogoutProps,
} from "../../shared/customHoooks/useLocalStorageUntilLogout";
import { useUserDashboard } from "../../shared/customHoooks/useUserDashboard";
import { DashboardConfigurationModal } from "../../modules/dashboards/dashboardsComponent/addEditDeleteDasboard/DashboardConfigurationModal";
import { MenuInfo } from "rc-menu/lib/interface";
import { user_accessible_sections as UserAccessibleSectionsAdmin } from "../../openapi-typescript/admin";
import { user_accessible_sections as UserAccessibleSectionsApproved } from "../../openapi-typescript/approved";

type IsPanel = boolean;
type IsHidden = boolean;
export type COREMainMenuProps = {
  testID: TestID;
  isHidden: IsHidden;
  setIsHidden: ReturnType<UseLocalStorageUntilLogoutProps<IsHidden>>[1];
  isPanel: IsPanel;
  setIsPanel: ReturnType<UseLocalStorageUntilLogoutProps<IsPanel>>[1];
};

type MenuItemData = {
  key: string;
  title: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  url?: string;
  onClick?: () => void;
};

type MenuData = MenuItemData & {
  children?: MenuItemData[];
};

type MenuIconProps = {
  regularIcon: IconDefinition;
  solidIcon: IconDefinition;
};

const MenuIcon: React.FC<MenuIconProps> = ({ regularIcon, solidIcon }) => (
  <>
    <div className={"regular-icon"}>
      <COREIcon icon={regularIcon} />
    </div>
    <div className={"solid-icon"}>
      <COREIcon icon={solidIcon} />
    </div>
  </>
);

const menuIcon: Record<string, React.ReactElement> = {
  Dashboards: (
    <MenuIcon
      regularIcon={getIcon({ name: "gauge-high", style: "regular" })}
      solidIcon={getIcon({ name: "gauge-high", style: "solid" })}
    />
  ),
  "Emissions Manager": (
    <MenuIcon
      regularIcon={getIcon({ name: "square-poll-vertical", style: "regular" })}
      solidIcon={getIcon({ name: "square-poll-vertical", style: "solid" })}
    />
  ),
  Tools: (
    <MenuIcon
      regularIcon={getIcon({ name: "screwdriver-wrench", style: "regular" })}
      solidIcon={getIcon({ name: "screwdriver-wrench", style: "solid" })}
    />
  ),
  "Market Prices": (
    <MenuIcon
      regularIcon={getIcon({ name: "money-check-dollar", style: "regular" })}
      solidIcon={getIcon({ name: "money-check-dollar", style: "solid" })}
    />
  ),
  "Daily Curves": (
    <MenuIcon
      regularIcon={getIcon({ name: "chart-line", style: "regular" })}
      solidIcon={getIcon({ name: "chart-area", style: "solid" })}
    />
  ),
  Admin: (
    <MenuIcon
      regularIcon={getIcon({ name: "cog", style: "regular" })}
      solidIcon={getIcon({ name: "cog", style: "solid" })}
    />
  ),
  "Broker Admin": (
    <MenuIcon
      regularIcon={getIcon({
        name: "money-check-dollar-pen",
        style: "regular",
      })}
      solidIcon={getIcon({ name: "money-check-dollar-pen", style: "solid" })}
    />
  ),
  "Wholesale Inventory": (
    <MenuIcon
      regularIcon={getIcon({
        name: "cart-flatbed",
        style: "regular",
      })}
      solidIcon={getIcon({ name: "cart-flatbed", style: "solid" })}
    />
  ),
};

const mainMenuUrl: Record<string, string> = {
  Dashboards: "/dashboard",
  "Emissions Manager": "/emissions-manager",
  Tools: "/tools",
  "Market Prices": "/market-prices",
  "Daily Curves": "/daily-curves",
  Admin: "/admin",
  "Broker Admin": "/broker-admin",
  "Wholesale Inventory": "/wholesale-inventory",
};

const getInitOpenKeys = (currentPath?: string, menuData?: MenuData[]) => {
  if (menuData === undefined) {
    return [];
  }
  const selectedMenu: string[] = [];
  menuData.forEach((m) => {
    if (m.url === currentPath) {
      selectedMenu.push(m.key);
    }
    m.children?.forEach((c) => {
      if (c.url && currentPath?.includes(c.url)) {
        selectedMenu.push(m.key);
      }
    });
  });
  return selectedMenu;
};

export type UserAccessibleSections =
  | UserAccessibleSectionsAdmin
  | UserAccessibleSectionsApproved;
const getInitMenuData = (
  setDashboardModelVisible: Dispatch<SetStateAction<boolean>>,
  data?: UserAccessibleSections[]
): MenuData[] => {
  if (!data || data.length === 0) return [];
  const sections = GroupByNative(data, "section");
  const sectionNames = Object.keys(sections);
  return sectionNames.map((sectionName) => {
    const section: MenuData = {
      key: `menu-${sectionName}`,
      title: sectionName,
      icon: menuIcon[sectionName],
      url: mainMenuUrl[sectionName],
      children: data
        .filter((d) => d.isShownInMenu && d.section === sectionName)
        .map((d) => ({
          key: `menu-${sectionName}-nav-bar-menu-item-${
            sectionName === "Dashboards" ? d.title : d.id
          }`,
          title: d.title,
          url: d.callToActionUrl,
        })),
    };
    if (section.children && sectionName === "Dashboards") {
      section.children.push({
        key: "create-new",
        title: "Create New",
        icon: (
          <COREIcon icon={getIcon({ name: "circle-plus", style: "regular" })} />
        ),
        onClick: () => {
          setDashboardModelVisible(true);
        },
      });
    }
    return section;
  });
};

const getInitSelectedMenu = (currentPath: string, menuData?: MenuData[]) => {
  if (!currentPath || !menuData) {
    return [];
  }
  const selectedMenu: string[] = [];
  menuData.forEach((m) => {
    if (m.url === currentPath) {
      selectedMenu.push(m.key);
    }
    m.children?.forEach((c) => {
      if (c.url && currentPath.includes(c.url)) {
        selectedMenu.push(c.key);
      }
    });
  });
  return selectedMenu;
};

type IconAndTitleProps = {
  title: MenuItemData["title"];
  type: "sub" | "item";
  icon?: React.ReactElement;
  hideText?: boolean;
  url?: string;
};

const IconAndTitle: React.FC<IconAndTitleProps> = ({
  title,
  icon,
  type,
  hideText,
  url,
}) => {
  const history = useHistory();
  const onClickIcon = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!url) return;
    const isControl = e.ctrlKey;
    if (isControl) {
      // we want browser to handle (and no change page client side)
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    history.push(url);
  };
  const textType = type === "sub" ? "label" : "default";
  return icon ? (
    <Space size={8}>
      <div
        onClick={hideText ? undefined : onClickIcon}
        className={classNames("icon", `link-container`)}
      >
        {url && (
          <LinkArea
            testID={generateTestId("page", `menu-link-${title}`)}
            url={url}
          />
        )}
        {icon}
      </div>
      {!hideText && (
        <CORETypographyInput type={textType}>{title}</CORETypographyInput>
      )}
    </Space>
  ) : !hideText ? (
    <CORETypographyInput type={textType}>{title}</CORETypographyInput>
  ) : (
    <></>
  );
};

type MainMenuBlockWithDataProps = {
  data: MenuData[];
  isHidden: boolean;
  setIsHidden: ReturnType<UseLocalStorageUntilLogoutProps<IsHidden>>[1];
  selectedKeys: string[];
  initOpenKeys: string[];
  isPanel: boolean;
  setIsPanel: ReturnType<UseLocalStorageUntilLogoutProps<IsPanel>>[1];
  isMobileScreen: boolean;
};

const renderExpandIcon = ({ isOpen }: { isOpen: boolean }) =>
  isOpen ? (
    <div className={"expand-icon"}>
      <COREIcon icon={getIcon({ name: "caret-down", style: "solid" })} />
    </div>
  ) : (
    <div className={"expand-icon"}>
      <COREIcon icon={getIcon({ name: "caret-up", style: "solid" })} />
    </div>
  );

type LinkAreaProps = {
  url: string;
  testID: TestID;
};

const LinkArea: React.FC<LinkAreaProps> = ({ url, testID }) => {
  const handleLeftClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const isControl = e.ctrlKey;
    const isCommand = e.metaKey;
    const isLeftClick = e.button === 0;
    if (isLeftClick && !(isControl || isCommand)) {
      // we want browser to handle (and no change page client side)
      e.preventDefault();
      return;
    }
    // we handle client side not browser
    e.preventDefault();
  };

  return (
    <a href={url} onClick={handleLeftClick}>
      <TestIDWrapper className={"link-area"} testID={testID} />
    </a>
  );
};

const MenuItemWithData: React.FC<MainMenuBlockWithDataProps> = ({
  data,
  isHidden,
  setIsHidden,
  selectedKeys,
  initOpenKeys,
  isPanel,
  setIsPanel,
  isMobileScreen,
}) => {
  const history = useHistory();
  const [openKeys, setOpenKeys] = useLocalStorageUntilLogout<string[]>({
    key: "mainMenuOpenKeys",
    initialValue: initOpenKeys,
  });

  const menuMode = isMobileScreen ? "inline" : isPanel ? "inline" : "vertical";
  const isBarMode = !isPanel && !isMobileScreen;
  const classWrapper = classNames(
    "core-main-menu",
    { hidden: isMobileScreen && isHidden },
    { mobile: isMobileScreen },
    { bar: !isMobileScreen && !isPanel }
  );

  const handleOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys);
  };

  const handleTogglePanel = () => {
    setIsPanel(!isPanel);
  };

  const onClickMenuItem = (
    menuInfo: MenuInfo,
    data: MenuData | MenuItemData
  ) => {
    if (data.onClick) {
      data.onClick();
      return;
    }
    if (!data.url) return;
    const e = menuInfo.domEvent;
    const isControl = e.ctrlKey;
    const isCommand = e.metaKey;
    if (isControl || isCommand) {
      window.open(data.url, "_blank");
      return;
    }

    history.push(data.url);
  };

  return (
    <div
      className={classNames(
        "side-nav-block",
        { bar: !isMobileScreen && !isPanel },
        { mobile: isMobileScreen }
      )}
    >
      <div className={classWrapper}>
        <div className={"mobile-header"}>
          <div className={"menu-header"}>
            <div className={"logo"}>
              <CORELogo />
            </div>
            <div
              className={"close-button"}
              onClick={() => {
                setIsHidden(true);
              }}
            >
              <COREIcon
                icon={getIcon({ name: "circle-xmark", style: "regular" })}
              />
            </div>
          </div>
          <div className={"progress-gradient"} />
        </div>
        <div className={"menu-content"}>
          <div className={"hidden-scrollable"}>
            <Menu
              mode={menuMode}
              onOpenChange={handleOpenChange}
              openKeys={openKeys}
              inlineIndent={0}
              triggerSubMenuAction={"click"}
              expandIcon={renderExpandIcon}
              selectedKeys={selectedKeys}
            >
              {data.map((d) => {
                if (d.children && d.children.length > 0) {
                  return (
                    <SubMenu
                      key={d.key}
                      title={
                        <IconAndTitle
                          hideText={isBarMode}
                          title={d.title}
                          icon={d.icon}
                          type={"sub"}
                          url={d.url}
                        />
                      }
                      disabled={d.disabled}
                      popupClassName={"core-main-menu-popup"}
                      popupOffset={[0, 0]}
                    >
                      {isBarMode && (
                        <Item
                          key={`${d.key}-vertical-submenu`}
                          onClick={(e) => {
                            onClickMenuItem(e, d);
                          }}
                          className={classNames(
                            "text-uppercase",
                            `link-container`
                          )}
                        >
                          {d.url && (
                            <LinkArea
                              testID={generateTestId(
                                "page",
                                `menu-link-${d.title}`
                              )}
                              url={d.url}
                            />
                          )}
                          <IconAndTitle title={d.title} type={"sub"} />
                        </Item>
                      )}
                      {d.children.map((c) => (
                        <Item
                          key={c.key}
                          disabled={c.disabled}
                          onClick={(e) => {
                            onClickMenuItem(e, c);
                          }}
                          className={`link-container`}
                        >
                          {c.url && (
                            <LinkArea
                              testID={generateTestId(
                                "page",
                                `menu-link-${c.title}`
                              )}
                              url={c.url}
                            />
                          )}
                          <IconAndTitle
                            title={c.title}
                            icon={c.icon}
                            type={"item"}
                          />
                        </Item>
                      ))}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Item
                      key={d.key}
                      disabled={d.disabled}
                      className={`link-container`}
                    >
                      {d.url && (
                        <LinkArea
                          testID={generateTestId(
                            "page",
                            `menu-link-${d.title}`
                          )}
                          url={d.url}
                        />
                      )}
                      <IconAndTitle
                        hideText={isBarMode}
                        title={d.title}
                        icon={d.icon}
                        type={"sub"}
                      />
                    </Item>
                  );
                }
              })}
            </Menu>
          </div>
          <div className={"toggle-button-row"}>
            {isMobileScreen ? (
              <></>
            ) : (
              <div onClick={handleTogglePanel} className={"toggle-button"}>
                {isPanel ? (
                  <COREIcon
                    icon={getIcon({ name: "chevrons-left", style: "regular" })}
                  />
                ) : (
                  <COREIcon
                    icon={getIcon({ name: "chevrons-right", style: "regular" })}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobileScreen && !isHidden && <div className={"mobile-overlay"} />}
    </div>
  );
};

export const COREMainMenuBlock: React.FC<COREMainMenuProps> = ({
  testID,
  isHidden,
  setIsHidden,
  isPanel = true,
  setIsPanel,
}) => {
  const isMobileScreen = useIsMobileScreen();
  const { sync, loading, data } = useAPIQuery<UserAccessibleSections[]>(
    "userAccessibleSections",
    {}
  );

  const {
    sync: syncDashboard,
    loading: loadingDashboard,
    configs: { dashboards },
    addDashboard,
  } = useUserDashboard();

  const currentPath = useLocation().pathname;
  const [dashboardModelVisible, setDashboardModelVisible] = useState(false);

  if ((loading && !sync) || (loadingDashboard && !syncDashboard))
    return <CORELoading size={"lg"} />;
  if (!sync || !syncDashboard) return null;

  const menuData = getInitMenuData(setDashboardModelVisible, data);
  const selectedMenu = getInitSelectedMenu(currentPath, menuData);
  const openKeys = getInitOpenKeys(currentPath, menuData);

  return (
    <TestIDWrapper
      className={classNames("main-menu-container", {
        mobile: isMobileScreen,
      })}
      testID={testID}
    >
      <MenuItemWithData
        isMobileScreen={isMobileScreen}
        selectedKeys={selectedMenu}
        initOpenKeys={openKeys}
        isHidden={isHidden}
        setIsHidden={setIsHidden}
        data={menuData}
        isPanel={isPanel}
        setIsPanel={setIsPanel}
      />
      <DashboardConfigurationModal
        addDashboard={addDashboard}
        modalVisible={dashboardModelVisible}
        closeModal={() => {
          setDashboardModelVisible(false);
        }}
        userDashboards={dashboards}
      />
    </TestIDWrapper>
  );
};
