import { Moment } from "moment";
import { useSnapshots } from "../../../shared/customHoooks/useSnapshots";
import { keysToSnake } from "../../../shared/global";
import { DailyCurveProductType } from "../../../shared/customHoooks/useProductTypes";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";

export const useEnvironSnapshot = ({
  futureDate,
  productType,
}: {
  futureDate: Moment;
  productType?: DailyCurveProductType;
}) => {
  return useSnapshots({
    asOf: futureDate.format(),
    type: "environmental",
    strip: false,
    genericProductsOnly: true,
    filter: keysToSnake({
      methodType: `is.null`,
    }),
    ...(productType && { productType: `eq.${productType}` }),
  });
};

export const useEnvironProductCurvePivots = ({
  date,
  productType,
}: {
  date: Moment;
  productType?: DailyCurveProductType;
}): {
  loading?: boolean;
  sync?: boolean;
  error: string | null;
  data: {
    data: {
      id: number;
      price: number;
      // eslint-disable-next-line camelcase
      environmental_product: null | { id: number };
    }[];
  };
} => {
  return useAPIQuery(
    "getProductCurvePivots",
    keysToSnake({
      date: date.format("YYYY-MM-DD"),
      type: "environmental",
      ...(productType && { tradableProductType: `eq.${productType}` }),
    })
  );
};
