import React, { useState } from "react";
import "./MarketCommentaryWidget.less";
import {
  createRange,
  NonEmptyRange,
  rangeFormatToServer,
  strToRange,
} from "../../../shared/date/ranges";
import {
  MarketCommentaryFilters,
  useProductCurveCommentariesByDatePaginate,
} from "../../../shared/customHoooks/useProductCurveCommentaries";
import { ProductClassType } from "../../../shared/customHoooks/useProductTypes";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREInfiniteScroll } from "../../../COREDesignSystem/Content/COREInfiniteScroll";
import {
  convertMomentToMarketTimezone,
  formatDate,
} from "../../../shared/date/DateTime";
import moment from "moment";
import { keysToSnake } from "../../../shared/global";
import { COREDisplayComment } from "../../../COREDesignSystem/Content/COREDisplayComment";
import { capitaliseEachAllWord } from "../../../shared/stringUtil";
import {
  CORESelectAPI,
  groupOptions,
} from "../../../COREDesignSystem/Form/CORESelectAPI";
import { CORERangePickerV2 } from "../../../COREDesignSystem/Form/CORERangePicker";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { Col, Row } from "antd";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { TestID, TestIDWrapper } from "../../../shared/testids/testids";
import {
  ContentWidgetProps,
  currentWidgetFilters,
  onSelectFilters,
} from "../../dashboards/WidgetPanel";
import { useDeepCompareEffect } from "react-use";

type MarketCommentaryByProductClassProps = ContentWidgetProps<
  ProductClassType,
  MarketCommentaryFilters
>;

const Filters: React.FC<
  Pick<
    MarketCommentaryByProductClassProps,
    "testID" | "widgetProps" | "widgetType"
  > & {
    widgetFilters: MarketCommentaryFilters | null;
    setWidgetFilters: React.Dispatch<
      React.SetStateAction<MarketCommentaryFilters | null>
    >;
  }
> = ({
  widgetProps,
  widgetType: productClass,
  testID,
  widgetFilters,
  setWidgetFilters,
}) => {
  return (
    <div className={"filter-block"}>
      <div className={"filter-items"}>
        <CORERangePickerV2
          className={"date-picker-width"}
          allowClear={true}
          size="lg"
          placeholder={["Select date from", "Select date to"]}
          testID={`${testID}-select-date-range` as TestID}
          onChange={(value) => {
            const rangeToServer = value ? rangeFormatToServer(value) : value;
            setWidgetFilters((prev) => {
              return {
                ...prev,
                dateRange: rangeToServer,
              };
            });
            onSelectFilters(widgetProps, {
              ...widgetFilters,
              dateRange: rangeToServer,
            });
          }}
          {...(widgetFilters &&
            !!widgetFilters.dateRange && {
              value: strToRange(widgetFilters.dateRange),
            })}
        />
      </div>
      <div className={"filter-items"}>
        <CORESelectAPI
          endpoint="getAllProductTypes"
          params={keysToSnake({
            productClass: `eq.${productClass}`,
            dailyCurvesSortOrder: "not.is.null",
            dailyCurvesCustomerVisible: "eq.true",
            order: "daily_curves_sort_order.asc",
          })}
          testID={`${testID}-select-product-type` as TestID}
          size="lg"
          transform={(data) => {
            const options = groupOptions(data, "name", "code", "code");
            return [
              {
                label: "General commentary",
                value: "general",
              },
              ...options,
            ];
          }}
          loadingLayout="horizontal"
          onChange={(value: string[] | null) => {
            setWidgetFilters((prev) => {
              return {
                ...prev,
                productType: value,
              };
            });
            onSelectFilters(widgetProps, {
              ...widgetFilters,
              productType: value,
            });
          }}
          mode="multiple"
          widthSize="full"
          value={widgetFilters?.productType}
          placeholder="Select product type"
        />
      </div>
    </div>
  );
};

export const MarketCommentaryByProductClass = ({
  widgetProps,
  widgetType: productClass,
  testID,
}: MarketCommentaryByProductClassProps) => {
  const defaultWidgetFilters = currentWidgetFilters(widgetProps)?.filters;
  const [widgetFilters, setWidgetFilters] =
    useState<MarketCommentaryFilters | null>(defaultWidgetFilters ?? null);
  const [loadedRange, setLoadedRange] = useState<NonEmptyRange>();

  const { loading, sync, updatable, hasMore } =
    useProductCurveCommentariesByDatePaginate(
      { productClass: productClass, ...widgetFilters },
      loadedRange,
      "week"
    );

  let currentDate: moment.Moment | null = null;

  useDeepCompareEffect(() => {
    setWidgetFilters(defaultWidgetFilters ?? null);
  }, [defaultWidgetFilters]);

  if (!productClass) return <COREError />;

  return (
    <>
      <div className={"market-commentary-widgets"}>
        <Filters
          widgetProps={widgetProps}
          widgetFilters={widgetFilters}
          widgetType={productClass}
          setWidgetFilters={setWidgetFilters}
          testID={testID}
        />
        <div className={"infinite-scroll-block"}>
          <COREInfiniteScroll
            loadMore={() => {
              if (!hasMore) return;
              if (!loadedRange) {
                const latestRecord = updatable[updatable.length - 1];
                const latestRecordDate = convertMomentToMarketTimezone(
                  moment(latestRecord.data?.date)
                );
                setLoadedRange(createRange(latestRecordDate, latestRecordDate));
              } else {
                setLoadedRange(
                  createRange(
                    loadedRange.from.subtract(1, "week"),
                    loadedRange.to,
                    loadedRange.bounds
                  )
                );
              }
            }}
            loading={loading}
            sync={sync}
            hasMore={hasMore}
            contentRange={updatable.length}
            emptyRender={
              <COREEmpty
                testID={`${testID}-widgets-empty` as TestID}
                description="No commentary information found"
                hint="There are no results for the applied filters. Try expanding your selections to see commentary results."
              />
            }
            useWindow={false}
            className={"infinite-scroll"}
            loader={<CORELoading delay={0} />}
          >
            <Row align="top" gutter={[0, 8]}>
              {updatable.map((item, index) => {
                if (!item.data) return null;

                const commentaryItem = item.data;
                const dateOfCurrentItem = moment(commentaryItem?.date);
                const isItFirstItemForEachDay =
                  currentDate === null ||
                  !currentDate.isSame(dateOfCurrentItem, "date");

                if (isItFirstItemForEachDay) {
                  currentDate = dateOfCurrentItem;
                }

                return (
                  <React.Fragment key={`item-${index}`}>
                    {isItFirstItemForEachDay && (
                      <Col span={24}>
                        <div className={"full-block"}>
                          <COREDivider
                            orientation="left"
                            testID={`${testID}-divider-date` as TestID}
                            space="sm"
                            noOrientationMargin={isItFirstItemForEachDay}
                          >
                            {formatDate(dateOfCurrentItem.clone().toDate(), {
                              dateStyle: "medium",
                            })}{" "}
                            End of day
                          </COREDivider>
                        </div>
                      </Col>
                    )}
                    <Col span={24}>
                      <TestIDWrapper
                        testID={`${testID}-item-${item.data?.id}` as TestID}
                      >
                        <COREDisplayComment
                          testID={`${testID}-comment-block` as TestID}
                          title={
                            commentaryItem.productType
                              ? `${capitaliseEachAllWord(
                                  commentaryItem.productType
                                )} - Daily update`
                              : "General commentary"
                          }
                          comment={commentaryItem.commentary}
                          isAuthor={false}
                          key={commentaryItem.id}
                        />
                      </TestIDWrapper>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </COREInfiniteScroll>
        </div>
      </div>
    </>
  );
};
