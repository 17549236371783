import { Insights } from "../../openapi-typescript/common/insights";
import { useAPIQueries, UseAPIQueriesRequest } from "./useAPITypes";

type Pagination = { limit: number; offset: number };

export const useInsights = (
  paginations: Pagination[],
  params: UseAPIQueriesRequest["apiOptions"][number]["params"]
) => {
  const response = useAPIQueries<{
    items: Insights;
    pagination: Pagination & { total: number };
  }>(
    "insights",
    paginations.map((pagination, index) => ({
      params: {
        cmsLocaleId: "64f51b1270fc318bbf205a3f",
        sortBy: "lastPublished",
        ...params,
        ...pagination,
      },
    }))
  );
  const total = response.results?.[0].data?.pagination?.total;
  const data = response.results
    .flatMap((r) => r.data?.items)
    .filter(Boolean) as Insights[];
  return {
    loading: response.loading,
    hasMore: total !== data.length,
    error: response.error,
    sync: response.sync,
    data: data,
  };
};
