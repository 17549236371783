const capitaliseEachWord = (text) =>
  text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

const capitaliseEachAllWord = (text) => text.toUpperCase();

export { capitaliseEachWord, capitaliseEachAllWord };
