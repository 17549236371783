import React from "react";
import {
  CORESelect,
  CORESelectProps,
} from "../../../../COREDesignSystem/Form/CORESelect";
import { BeZeroCarbonRatingType } from "./BeZeroCarbonRatingTag";

export const BeZeroRatingSelector = ({
  testID,
  size,
  widthSize,
  ...props
}: CORESelectProps) => {
  const bezeroLists: BeZeroCarbonRatingType[] = [
    "AAA",
    "AA",
    "A",
    "BBB",
    "BB",
    "B",
    "C",
    "D",
  ];

  return (
    <CORESelect
      {...props}
      options={bezeroLists.map((b) => {
        return {
          label: b,
          value: b,
        };
      })}
      size={size}
      widthSize={widthSize}
      testID={testID}
      placeholder={"Select rating"}
      mode={"multiple"}
    />
  );
};
