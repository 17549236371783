import { Col, Space } from "antd";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { NumberFormat } from "../../../shared/globals";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { ColorHex } from "../../../COREDesignSystem/Content/COREColour";
import { COREDataTable } from "../../../COREDesignSystem/Content/COREDataTable";
import { COREIndicative } from "../../../COREDesignSystem/Content/COREFlag";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { CORETag } from "../../../COREDesignSystem/Content/CORETag";
import {
  CORETagList,
  CORETagListProps,
} from "../../../COREDesignSystem/Content/CORETagList";
import {
  getOfferSummary,
  GreenOfferDetailsType,
  OfferSummaryType,
  ProjectViewPagination,
} from "./ProjectMarketplaceContainer";
import "./ViewProjectsAsCardsAndList.less";
import { EmptyProjectsRow } from "./ViewProjectsAsCards";
import classNames from "classnames";
import { generateTestId, TestIDWrapper } from "../../../shared/testids/testids";
import { projectMarketplaceDetailsLink } from "./projectMarketplaceHelper";
import { keysToCamelCase } from "../../../shared/global";
import {
  BeZeroCarbonRatingTag,
  BezeroRatingType,
} from "./BeZeroCarbonRating/BeZeroCarbonRatingTag";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

type ViewProjectsAsListProps = {
  allProjects: GreenOfferDetailsType[];
  carbonProjects: GreenOfferDetailsType[];
  wholesaleProducts: GreenOfferDetailsType[];
  onProjectPaginationChange: (val: number) => void;
  pagination: ProjectViewPagination;
};

interface GroupingTable<T> {
  key?: T;
  name?: ReactNode;
  extra?: ReactNode;
  id?: number;
  children?: GreenOfferDetailsType[];
}

type RowsHeaderProps = {
  name: GreenOfferDetailsType["name"];
  productType: GreenOfferDetailsType["product_type"];
  id: GreenOfferDetailsType["project_identifier"];
  totalOffer: number;
  minimumOffer: number;
  bezeroRatings: BezeroRatingType;
};

type BidAndOfferColProps = {
  price:
    | GreenOfferDetailsType["bid_price"]
    | GreenOfferDetailsType["offer_price"];
  size: GreenOfferDetailsType["bid_size"] | GreenOfferDetailsType["offer_size"];
  type: GreenOfferDetailsType["bid_type"] | GreenOfferDetailsType["offer_type"];
};

type CoBenefitsColProps = {
  unSDGoals: GreenOfferDetailsType["un_sd_goals"];
  unSDGoalColors: Exclude<
    GreenOfferDetailsType["un_sd_goal_colors"],
    undefined
  >;
  max?: CORETagListProps["truncateAfter"];
};

const RowsHeader: React.FC<RowsHeaderProps> = ({
  name,
  productType,
  id,
  totalOffer,
  minimumOffer,
  bezeroRatings,
}) => {
  const projectID = `ID: ${id}`;

  return (
    <div className={"col-flex"}>
      <Col sm={24} xl={2} xxl={2}>
        <CORETag type={"basic"} label={productType} />
      </Col>
      <Col sm={24} xl={10} xxl={14}>
        <TestIDWrapper
          testID={generateTestId("projectmarketplace", "project-name")}
        >
          <div className={"name-block"}>{name}</div>
          <div className={"project-id-block"}>
            {id && (
              <COREBody marginBottom={false} className={"font-color-grey-160"}>
                {projectID}
              </COREBody>
            )}
          </div>
        </TestIDWrapper>
      </Col>
      <Col sm={24} xl={6} xxl={4} className={"display-bezero-tag"}>
        {bezeroRatings ? (
          <BeZeroCarbonRatingTag
            rating={bezeroRatings.rating}
            isOnWatch={bezeroRatings.isOnWatch}
          />
        ) : (
          <></>
        )}
      </Col>
      <Col sm={24} xl={6} xxl={4}>
        {totalOffer === 0 ? (
          <COREBody marginBottom={false}>Call for details</COREBody>
        ) : (
          <Space>
            <COREBody marginBottom={false} className={"font-color-grey-160"}>
              {totalOffer > 1 ? `${totalOffer} offers` : `${totalOffer} offer`}{" "}
              from
            </COREBody>
            <COREBody marginBottom={false} strong>
              ${NumberFormat(minimumOffer, 2)}/unit
            </COREBody>
          </Space>
        )}
      </Col>
    </div>
  );
};

const BidAndOfferCol: React.FC<BidAndOfferColProps> = ({
  price,
  size,
  type,
}) => {
  const valueClass: string | undefined = classNames("vol-val", {
    "vol-val-padding": size,
  });

  const priceChild = NumberFormat(price, 2);

  return (
    <>
      {type === "indicative" ? (
        <COREIndicative>{priceChild}</COREIndicative>
      ) : (
        priceChild
      )}
      {size && <div className={valueClass}>({NumberFormat(size)})</div>}
    </>
  );
};

export const CoBenefitsCol: React.FC<CoBenefitsColProps> = ({
  unSDGoals,
  unSDGoalColors,
  max = 3,
}) => (
  <CORETagList truncateAfter={max}>
    {unSDGoals?.map((item, index) => (
      <CORETag
        colour={unSDGoalColors[index] as ColorHex}
        label={
          <COREBody type={"p4"} strong color={"#FFF"}>
            {item.toString().padStart(2, "0")}
          </COREBody>
        }
        type={"unSdGoal"}
        tagStyle={`outline`}
        key={`unsdGoal-tag-${index}`}
      />
    ))}
  </CORETagList>
);

const BidOfferHeader: React.FC<{ text: string }> = ({ text }) => (
  <>
    <div>{text}</div>
    <div>(Volume)</div>
  </>
);

export const ViewProjectsAsList: React.FC<ViewProjectsAsListProps> = ({
  allProjects,
  carbonProjects,
  wholesaleProducts,
  onProjectPaginationChange,
  pagination,
}) => {
  const history = useHistory();
  const { currentPage, defaultPageSize, setDefaultPageSize } = pagination;

  const columns = [
    {
      title: "Vintage",
      dataIndex: "short_name",
      key: "short_name",
      width: "100px",
      className: `vertical-align-middle`,
    },
    {
      title: <BidOfferHeader text={"Bid"} />,
      dataIndex: "bid_price",
      key: "bid",
      width: "120px",
      className: "text-align-right",
      render: (price, records) =>
        records.bid_size &&
        price && (
          <BidAndOfferCol
            price={price}
            size={records.bid_size}
            type={records.bid_type}
          />
        ),
    },
    {
      title: <BidOfferHeader text={"Offer"} />,
      dataIndex: "offer_price",
      key: "offer",
      width: "120px",
      className: "text-align-right",
      render: (price, records) =>
        records.offer_size &&
        price && (
          <BidAndOfferCol
            price={price}
            size={records.offer_size}
            type={records.offer_type}
          />
        ),
    },
    {
      title: "Location",
      dataIndex: "region_name",
      key: "location",
      width: "260px",
      className: `text-align-center`,
    },
    {
      title: "Method",
      dataIndex: "certificate_project_type_name",
      key: "certificate_project_type_name",
      width: "150px",
      className: `text-align-center`,
    },
    {
      title: "Co-benefits",
      dataIndex: "un_sd_goals",
      key: "un_sd_goals",
      width: "230px",
      className: classNames("text-align-center", "vertical-align-middle"),
      render: (_v, records) => (
        <CoBenefitsCol
          unSDGoals={records.un_sd_goals}
          unSDGoalColors={records.un_sd_goal_colors}
        />
      ),
    },
    {
      dataIndex: "certificate_project",
      key: "action",
      width: "20px",
      className: `text-align-center`,
      render: (id) =>
        id && (
          <COREButton
            type={"action"}
            size={"md"}
            icon={<COREIcon icon={icon({ name: "eye", style: "solid" })} />}
          />
        ),
    },
  ];

  const generateGroupData = () => {
    let groupData: GroupingTable<string>[] = [];

    const generateGroupChildrenData = (
      uniqueProjects,
      groupByKeys,
      isWholesale: boolean
    ) => {
      const childItems: GreenOfferDetailsType[] = allProjects.filter(
        (p: GreenOfferDetailsType) => {
          return groupByKeys.every((key) => {
            return p[key] === uniqueProjects[key];
          });
        }
      );
      const projectsWithOffer = isWholesale
        ? childItems.filter(
            (item) => item.certificate_project === null && item.offer_price
          )
        : childItems.filter((item) => item.offer_price);
      const summary: OfferSummaryType = getOfferSummary(projectsWithOffer);
      const project = keysToCamelCase(uniqueProjects);
      groupData.push({
        key: `${project.certificateProject} - ${project.name}`,
        id: project.certificateProject,
        name: (
          <RowsHeader
            name={
              isWholesale
                ? project.projectMethodTypeName
                  ? `Wholesale offers - ${project.projectMethodTypeName}`
                  : "Wholesale offers"
                : project.name
            }
            productType={project.productTypeName}
            id={project.projectIdentifier}
            totalOffer={summary.countOffers}
            minimumOffer={summary.minOffersPrice}
            bezeroRatings={project.bezeroRatings}
          />
        ),
        children: projectsWithOffer,
      });
    };

    carbonProjects?.forEach((cp) =>
      generateGroupChildrenData(cp, ["certificate_project"], false)
    );
    wholesaleProducts?.forEach((wp) =>
      generateGroupChildrenData(
        wp,
        ["product_type", "project_method_type"],
        true
      )
    );

    return groupData;
  };

  const generateGroupResult = generateGroupData();

  return (
    <div className={"view-as-list-block"}>
      <COREDataTable
        emptyGroupTable={(id) => (
          <COREEmpty
            image={false}
            description={"Currently no products"}
            hint={
              id
                ? "This project currently has no available products. Continue to the project details or call our Broker Hotline and speak to our experts to find out more"
                : "This project currently has no available products. Call our Broker Hotline and speak to our experts to find out more"
            }
            testID={"projectmarketplace-group-table-empty"}
          >
            {id && (
              <COREButton
                type={"primary"}
                icon={
                  <COREIcon
                    icon={icon({
                      name: "circle-arrow-right",
                      style: "regular",
                    })}
                  />
                }
                onClick={() =>
                  id && history.push(projectMarketplaceDetailsLink(id))
                }
              >
                View details
              </COREButton>
            )}
          </COREEmpty>
        )}
        empty={
          <EmptyProjectsRow
            testID={"projectmarketplace-viewas-list-empty-block"}
          />
        }
        testID={"projectmarketplace-project-marketplace-table"}
        onSelectRow={(row) =>
          row[6].value &&
          history.push(projectMarketplaceDetailsLink(row[6].value))
        }
        pagination={{
          hideOnSinglePage: false,
          total: generateGroupResult.length,
          current: currentPage,
          pageSize: defaultPageSize,
        }}
        columns={columns}
        dataSource={allProjects}
        group={() =>
          generateGroupResult.slice(
            (currentPage - 1) * defaultPageSize,
            currentPage * defaultPageSize
          )
        }
        onPaginationChange={(page, size) => {
          onProjectPaginationChange(page);
          setDefaultPageSize(size);
        }}
        showSizeChanger={true}
      />
    </div>
  );
};
