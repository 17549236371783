import React from "react";
import { Typography } from "antd";
import { TitleProps } from "antd/lib/typography/Title";
import classNames from "classnames";
import { TestID } from "../../shared/testids/testids";
import { Merge } from "../../shared/TypeScriptHelpers";
import "./COREHeading.less";

const { Title } = Typography;

export type COREHeadingProps = Merge<
  TitleProps,
  {
    testID: TestID;
    marginBottom?: boolean;
    className?: string;
  }
>;

export const COREHeading: React.FC<COREHeadingProps> = ({
  level,
  children,
  testID,
  marginBottom = true,
  className = "",
  ...props
}) => (
  <span className={"core-heading"}>
    <Title
      level={level}
      data-testid={testID}
      className={classNames(className, {
        "no-margin-bottom": !marginBottom,
      })}
      {...props}
    >
      {children}
    </Title>
  </span>
);
