import { useEffect, useState } from "react";
import { useAPIQueryRequest } from "../customHoooks/useAPI";
import { useDeepCompareEffect } from "react-use";
import { useUserIsAdmin, useUserLoggedInStatus } from "./user";
import { useQueryClient } from "react-query";
import ReconnectingWebSocket from "reconnecting-websocket";
import { domain as baseUrl } from "./rest";
import {
  commentEmissionHandler,
  commonEmissionHandler,
  emissionHandler,
} from "../../modules/emissions/useEmissions";
import { scenariosHandler } from "../../modules/emissions/scenarioComponents/useScenarios";
import { jobsHandler } from "../../modules/emissions/scenarioComponents/useJobs";
import {
  portfolioHandler,
  portfolioPeriodScopesHandler,
} from "../../modules/emissions/portfolio_helper";

export const getUseApiRefresh =
  (...apiOptions) =>
  () => {
    const {
      data: { data: apiData },
      forceRefresh,
      ...otherAPI
    } = useAPIQueryRequest(...apiOptions);

    const [data, setData] = useState(apiData);
    useDeepCompareEffect(() => setData(apiData), [apiData]);
    return { ...otherAPI, data: { data: data ?? apiData }, forceRefresh };
  };

export const useLiveInterconnectors = getUseApiRefresh("liveInterconnectors");

export const useLivePrices = getUseApiRefresh("livePrices");

const productTypeToSnapshotTypes = (type) => {
  switch (type) {
    case "accu":
    case "nzu":
    case "CER":
    case "GS":
    case "vcs":
      return ["carbon", "green_marketplace"];
    case "stc":
    case "esc":
    case "veec":
    case "lgc":
      return ["environmental", "green_marketplace"];
    case "solar":
    case "inverse solar":
    case "super peak":
      return ["cfd", "rehub"];
    case "cap300":
    case "flat":
    case "off peak":
    case "peak":
      return ["cfd", "electricity"];
    default:
      return [];
  }
};

const handleWebsocketMessage = (event, queryClient, isAdmin) => {
  const data = JSON.parse(event.data);

  const livePriceHandler = () => {
    if (!data.channel.startsWith("dispatch_price")) return;
    queryClient.invalidateQueries("livePrices");
  };

  const snapshotsHandler = () => {
    const {
      channel,
      payload: {
        row: { product_type: type, tradable_product: tradableProductId },
      },
    } = data;

    if (
      !channel.startsWith("snapshot_detail") ||
      !channel.startsWith("snapshot")
    ) {
      return;
    }

    const key = isAdmin ? "snapshots_detail" : "snapshots";

    queryClient.invalidateQueries({
      predicate: (query) => {
        if (
          query.queryKey[0] === key &&
          query.queryKey[1]?.params.hasOwnProperty("codes")
        ) {
          return true;
        }

        if (
          query.queryKey[0] === key &&
          query.queryKey[1]?.params.tradable_products?.includes(
            tradableProductId
          )
        ) {
          return true;
        }

        return (
          query.queryKey[0] === key &&
          productTypeToSnapshotTypes(type).includes(
            query.queryKey[1]?.params.type
          )
        );
      },
    });
  };

  const companyHandler = () => {
    if (!data.channel.startsWith("company")) return;
    queryClient.invalidateQueries("companyData");
  };

  const tradeTransactionHandler = () => {
    if (!data.channel.startsWith("rehub_trade_transaction")) return;
    queryClient.invalidateQueries("rehub_trade_logs");
    queryClient.invalidateQueries("rehubTradeTimelines");
  };

  const apPriceCurveResultsHandler = () => {
    if (!data.channel.startsWith("ap_price_curve_results")) return;
    queryClient.invalidateQueries("apPriceCurveResults");
  };

  const fileUploadHandler = () => {
    if (!data.channel.startsWith("file-user-")) return;
    const key = "fileJobStatus";
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === key,
    });
  };

  const certificateProjectAdminHandler = () => {
    if (!data.channel.startsWith("certificate_project-admin")) return;
    queryClient.invalidateQueries("certificateProjectGreenProjects");
  };

  const wsHandlers = [
    livePriceHandler,
    snapshotsHandler,
    companyHandler,
    tradeTransactionHandler,
    apPriceCurveResultsHandler,
    fileUploadHandler,
    certificateProjectAdminHandler,
    () => scenariosHandler(data, queryClient),
    () => portfolioHandler(data, queryClient),
    () => emissionHandler(data, queryClient),
    () => portfolioPeriodScopesHandler(data, queryClient),
    () => jobsHandler(data, queryClient),
    () => commonEmissionHandler(data, "offset-company-", "offsets"),
    () => commonEmissionHandler(data, "initiative-company-", "initiatives"),
    () =>
      commentEmissionHandler(
        data,
        "offset-comment-company-",
        "offsetComments",
        "emissionOffset"
      ),
    () =>
      commentEmissionHandler(
        data,
        "initiative-comment-company-",
        "initiativeComments",
        "initiative"
      ),
    () =>
      commonEmissionHandler(
        data,
        "initiative-file-company-",
        "initiativeFiles"
      ),
  ];

  wsHandlers.forEach((handler) => handler());
};

const wsURL = () => {
  const domainWithProtocol = baseUrl();
  const domainURL = new URL(domainWithProtocol);
  const domain = domainURL.host;
  const protocol = domainURL.protocol;
  return `${protocol === "https:" ? "wss://" : "ws://"}${domain}/ws/`;
};

export const useReactQuerySubscription = () => {
  const isAdmin = useUserIsAdmin();
  const isLoggedIn = useUserLoggedInStatus();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const websocket = new ReconnectingWebSocket(wsURL());
    websocket.onmessage = (event) => {
      handleWebsocketMessage(event, queryClient, isAdmin);
    };
    return () => {
      websocket.close();
    };
  }, [isLoggedIn, isAdmin, queryClient]);
};
