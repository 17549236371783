import React from "react";
import "./MarketCommentaryWidget.less";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MarketCommentaryFilters } from "../../../shared/customHoooks/useProductCurveCommentaries";
import { ProductClassType } from "../../../shared/customHoooks/useProductTypes";
import {
  onSelectSetupMenu,
  SetupWidgetProps,
} from "../../dashboards/WidgetPanel";

type SetupMarketCommentaryWidgetProps = SetupWidgetProps<
  ProductClassType,
  MarketCommentaryFilters
>;

export const SetupMarketCommentaryWidget = ({
  widgetProps,
  testID,
}: SetupMarketCommentaryWidgetProps) => {
  return (
    <div className={"setup-market-block-custom"}>
      <COREHeading
        level={3}
        testID={`${testID}-select-market`}
        marginBottom={false}
      >
        Select a market
      </COREHeading>
      <COREBody>
        Use the buttons below or the widget menu (
        {<COREIcon icon={icon({ name: "bars", style: "regular" })} size="xs" />}
        ) to configure your content preferences. You can have multiple versions
        of this widget each configured differently and your dashboard will
        remember your selections each time you log in.
      </COREBody>
      <div key={`setup-menu-carbon`}>
        <COREButton
          block={true}
          size="lg"
          onClick={() => {
            onSelectSetupMenu(widgetProps, "carbon");
          }}
          testID={`${testID}-setup-menu-carbon-button`}
        >
          Carbon market
        </COREButton>
      </div>
      <div key={`setup-menu-environmental`}>
        <COREButton
          block={true}
          size="lg"
          onClick={() => {
            onSelectSetupMenu(widgetProps, "environmental");
          }}
          testID={`${testID}-setup-menu-environment-button`}
        >
          Environmental market
        </COREButton>
      </div>
    </div>
  );
};
