/* eslint-disable */
import React, { useState } from "react";
import {
  useDeepCompareEffect,
  useLocalStorage,
  useStateWithHistory,
} from "react-use";
import { Collapse, Pagination, Popconfirm, Spin, Table } from "antd";
import { sortByNative, useEventListener } from "../globals";
import { CopyOutlined, DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import "./DEPRECATEDCORETable.less";
import { COREDataSheet } from "../dataSheet/COREDataSheet";

import ReactDataSheet from "../../dependencies/react-datasheet/DataSheet";
import "../../dependencies/react-datasheet/react-datasheet.less";
import { Merge } from "../TypeScriptHelpers";
import Cell from "../../dependencies/react-datasheet/Cell";
import { TableProps } from "antd/lib/table/Table";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { generateTestId, TestIDWrapper } from "../testids/testids";
import "../../modules/dailyCurves/environComponent/EnvironmentalDailyCurve.less";
import classNames from "classnames";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";

const { Panel }: any = Collapse;

export type ExpandedRowKeys = Exclude<
  DEPRECATEDCORETableProps<any>["expandedRowKeys"],
  undefined
>[number];

const DataSheetPagination = ({ group, pagination, onPageChange }) => {
  const [, setUserPagination]: [
    number | undefined,
    (
      value:
        | ((prevState: number | undefined) => number | undefined)
        | number
        | undefined
    ) => void,
    () => void
  ] = useLocalStorage("pagination", 10);

  if (group || !pagination) return false;

  const { current, total, pageSize, hideOnSinglePage }: any = pagination;

  return (
    <Pagination
      onChange={onPageChange}
      onShowSizeChange={(currPage, size) => {
        setUserPagination(size);
        return onPageChange;
      }}
      defaultCurrent={current}
      total={total}
      size="small"
      className={classNames(
        "ant-table-pagination-right",
        "pagination-overflow"
      )}
      hideOnSinglePage={hideOnSinglePage}
      pageSize={pageSize ? pageSize : 10}
    />
  );
};

type DEPRECATEDCORETableExternal<RecordType> = {
  isNewTableDesign?: boolean;
  showUndo?: boolean;
  ellipsis?: boolean;
  editMode?: boolean;
  deleteMode?: boolean;
  copyDuplicateMode?: boolean;
  deleteTitle?: string;
  deleteColumnClassName?: string;
  onDuplicate?: (arg: RecordType) => void;
  onDelete?: (arg: RecordType) => void;
  onSave?: (arg: RecordType, field: keyof RecordType) => void;
  onPaginationChange?: any;
  group?: true | (null | ((arg: RecordType) => void));
  searchedColumn?: null | ((arg: RecordType) => void);
  handleSearch?: null | ((arg: RecordType) => void);
  handleReset?: null | ((arg: RecordType) => void);
  handleSort?: null | ((arg: RecordType) => void);
  sortedColumn?: null | ((arg: RecordType) => void);
  loading?: boolean;
  tableType?: string;
  showLastUpdate?: boolean;
  theme?: "w" | "b";
  lastUpdate?: moment.Moment;
  sticky?: boolean;
  headerColSpan?: number;
  testId?: string;
};

export type DEPRECATEDCORETableProps<RecordType> = Merge<
  TableProps<RecordType>,
  DEPRECATEDCORETableExternal<RecordType>
>;

type RenderDataSheetProps<RecordType> = Merge<
  DEPRECATEDCORETableProps<RecordType>,
  {
    cellRenderer?: Cell;
    onCellChange?: any;
    data?: any;
    column?: any;
    gridData?: any;
    gridDataColumn?: any;
  }
>;

export type DEPRECATEDTableColumns<RecordType> =
  DEPRECATEDCORETableProps<RecordType>["columns"];

const ReactDataSheetBlock = <RecordType extends object>({
  onCellChange,
  cellRenderer,
  gridData,
  gridDataColumn,
  group,
  theme,
  tableType,
  defaultExpandedRowKeys = [],
  columns,
  handleSearch,
  handleReset,
  handleSort,
  searchedColumn,
  sortedColumn,
  sticky,
  headerColSpan,
  testId,
}: RenderDataSheetProps<RecordType>) => {
  const [curExpand, setCurExpand] = useState<
    ExpandedRowKeys[] | string | string[]
  >(defaultExpandedRowKeys);

  if (group) {
    return (
      <>
        {/*  For Column Headers*/}
        {/* @ts-ignore */}
        <RenderDataSheet
          data={[gridDataColumn]}
          column={true}
          theme={theme}
          cellRenderer={cellRenderer}
          onCellChange={onCellChange}
          tableType={tableType}
          headerColSpan={headerColSpan}
        />
        {/*Actual table*/}
        <Collapse
          bordered={false}
          defaultActiveKey={curExpand}
          expandIcon={({ isActive, header }) => (
            <TestIDWrapper
              testID={generateTestId(
                "table",
                `group-header-${header?.toString()}`
              )}
            >
              <button
                type="button"
                className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                  isActive ? "expanded" : "collapsed"
                }`}
              />
            </TestIDWrapper>
          )}
          className="datasheet-group-collapse"
          onChange={(e) => {
            // @ts-ignore
            setCurExpand(e);
          }}
        >
          {gridData.map(({ name, data }) => (
            <Panel header={name} key={name}>
              <TestIDWrapper
                testID={generateTestId("table", `group-body-${name}`)}
              >
                {/* @ts-ignore */}
                <RenderDataSheet
                  data={data}
                  group={true}
                  theme={theme}
                  cellRenderer={cellRenderer}
                  onCellChange={onCellChange}
                  tableType={tableType}
                  headerColSpan={headerColSpan}
                />
              </TestIDWrapper>
            </Panel>
          ))}
        </Collapse>
      </>
    );
  }

  return (
    // @ts-ignore
    <RenderDataSheet
      testId={testId}
      data={gridData}
      theme={theme}
      cellRenderer={cellRenderer}
      onCellChange={onCellChange}
      columns={columns}
      handleSearch={handleSearch}
      handleReset={handleReset}
      handleSort={handleSort}
      sortedColumn={sortedColumn}
      searchedColumn={searchedColumn}
      sticky={sticky}
      tableType={tableType}
      headerColSpan={headerColSpan}
    />
  );
};

const RenderDataSheet = ({
  data,
  group,
  column,
  columns,
  theme,
  cellRenderer,
  onCellChange,
  tableType,
  handleSearch,
  handleReset,
  handleSort,
  sortedColumn,
  searchedColumn,
  sticky,
  headerColSpan,
  testId,
}) => {
  let className = classNames(
    "table-overflow",
    `datasheet-${theme === "w" ? "light" : "dark"}-theme`,
    { ["datasheet-grouping"]: group },
    { [`${tableType}-table`]: tableType },
    { ["sticky"]: sticky }
  );
  if (columns) {
    return (
      <div className={className}>
        <COREDataSheet
          testID={testId}
          data={data}
          columns={columns}
          onCellsChanged={(changes) => {
            onCellChange(changes, column);
          }}
          valueRenderer={(cell) => cell.value}
          cellRenderer={cellRenderer}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleSort={handleSort}
          sortedColumn={sortedColumn}
          searchedColumn={searchedColumn}
          headerColSpan={headerColSpan}
          editMode={true}
          selectCellMode={true}
          hoverable={false}
        />
      </div>
    );
  }
  return (
    <div className={className}>
      <ReactDataSheet
        data={data}
        onCellsChanged={(changes) => {
          onCellChange(changes, column);
        }}
        valueRenderer={(cell) => cell.value}
        cellRenderer={cellRenderer}
        editMode={true}
        selectCellMode={true}
      />
    </div>
  );
};

export function getColumn(name, dataSource, filteredInfo) {
  let f: any = dataSource
    .map((data) => data[name])
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((field) => ({
      text: field,
      value: field,
    }));
  return {
    dataIndex: name,
    filters: f.sort(sortByNative("text")),
    filteredValue: filteredInfo[name] || null,
    onFilter: (value, record) => record[name] === value,
    sorter: (a, b) => a[name].toString().localeCompare(b[name].toString()),
  };
}

const generateGridRowData = (
  columns,
  cell,
  groupIndex = false,
  editMode = true
) =>
  columns.map(
    ({
      dataIndex,
      key,
      render,
      editRender,
      editable = editMode,
      className,
      width,
      keyRender,
      escKeyPress,
      setEscKeyPress,
    }) => {
      const dKey: string = cell.hasOwnProperty(key) ? key : null;
      const index: number = cell.hasOwnProperty(dataIndex) ? dataIndex : dKey;
      const rawValue: string = cell.hasOwnProperty(index) ? cell[index] : null;

      const cellProperties = {
        value: rawValue,
        index,
        readOnly: !editable,
        className,
        width,
        sourceIndex: cell.sourceIndex,
        groupIndex: groupIndex,
        keyRender: keyRender,
      };
      if (editRender) {
        // @ts-ignore
        cellProperties.dataEditor = ({
          value: rawCellValue,
          row,
          col,
          onChange,
          onCommit,
          onRevert,
          onKeyDown,
        }) => {
          const editResult =
            editRender(
              rawCellValue,
              cell,
              index,
              onChange,
              onCommit,
              onRevert,
              onKeyDown,
              row,
              col,
              escKeyPress
            ) ?? null;

          if (escKeyPress) {
            onRevert();
            setEscKeyPress(false);
          }
          return editResult;
        };
      }
      if (render) {
        // @ts-ignore
        cellProperties.valueViewer = ({
          value,
          onChange,
          onCommit,
          onRevert,
        }) =>
          render(rawValue, cell, index, onChange, onCommit, onRevert) ?? value;
      }

      return cellProperties;
    }
  );

const generateGridColumn = (columns: any) =>
  columns.map(({ title, width, className }) => ({
    value: title,
    readOnly: true,
    width,
    className,
  }));

const generateGridData = (columns, dataSource, group, editMode) => {
  if (dataSource === undefined) return [];
  let convertCol: ColumnsType = [];
  columns.forEach((c) => {
    if (c.hasOwnProperty("children")) {
      c.children.forEach((chi) => {
        convertCol.push(chi);
      });
    } else {
      convertCol.push(c);
    }
  });
  if (group) {
    // @ts-ignore
    const dataSourceGroup: any = group(dataSource);
    return dataSourceGroup.map(({ name, children }, i) => ({
      name,
      data: children.map((cell) =>
        generateGridRowData(convertCol, cell, i, editMode)
      ),
    }));
  }
  return dataSource.map((cell) => generateGridRowData(convertCol, cell));
};

const DeleteColumn = ({ copyDuplicateMode, action, record, deleteTitle }) => (
  <div style={{ display: "flex" }}>
    {copyDuplicateMode && (
      <COREButton
        icon={<CopyOutlined />}
        size={"sm"}
        onClick={() => action.onDuplicate(record)}
      />
    )}

    <Popconfirm
      placement="topRight"
      title={deleteTitle}
      onConfirm={() => {
        action.onDelete(record);
      }}
      okText="Yes"
      cancelText="No"
    >
      <COREButton danger icon={<DeleteOutlined />} size={"sm"} />
    </Popconfirm>
  </div>
);

const UndoComponent = ({ showUndo, grid }) => {
  const noHistory = grid.gridLogHistory.history === undefined;
  return showUndo ? (
    <div className={"datasheet-undo-block"}>
      <Popconfirm
        title="Are you sure you want to undo?"
        onConfirm={() => {
          const { position: curPos, history } = grid.gridLogHistory;
          const nextPos: number = curPos - 1;
          const nextHistory: any = history[nextPos];

          return (
            nextHistory &&
            nextHistory.forEach((newRow, i) => {
              const curRow: any = grid.gridLog[i];
              const isSame: boolean =
                JSON.stringify(newRow) === JSON.stringify(curRow);
              if (!isSame) {
                grid.onSave(newRow);
              }
            })
          );
        }}
        okText="Yes"
        cancelText="No"
        disabled={noHistory}
      >
        <COREButton danger disabled={noHistory} icon={<UndoOutlined />}>
          Undo
        </COREButton>
      </Popconfirm>
    </div>
  ) : null;
};

const LastUpdateTime = ({ showLastUpdate, children }) =>
  showLastUpdate ? <p className={"date-time"}>{children}</p> : null;

const saveGridData = (newData, grid) => {
  const updateData = [];
  const prepareSaveData = [];

  const tmpUpdate = grid.gridLog;

  newData.forEach(({ cell, row, value }) => {
    const updateRow = grid.group ? cell.sourceIndex : row;

    const { index } = cell;

    const masterData = grid.gridLog[updateRow];

    const updateIndex = updateData.findIndex(
      // @ts-ignore
      (i) => i.tradableProductId === masterData.tradableProductId
    );

    const rowData = updateIndex !== -1 ? updateData[updateIndex] : masterData;

    if (rowData[index] !== value) {
      rowData[index] = value;
    }

    tmpUpdate[updateRow] = rowData;

    if (updateIndex !== -1) {
      // @ts-ignore
      updateData[updateIndex] = rowData;
    } else {
      // @ts-ignore
      updateData.push(rowData);
    }

    prepareSaveData.push({
      // @ts-ignore
      index,
      // @ts-ignore
      data: rowData,
    });
  });

  grid.setGridLog(() => [...tmpUpdate]);
  prepareSaveData.forEach(({ index, data }) => {
    if (data) {
      grid.onSave(data, index);
    }
  });
};

export const groupBy = <A extends object>(
  xs: A[],
  getGroup: (x: A) => string
) => {
  return xs.reduce<Record<keyof A, A[]> | {}>(
    (rv: Record<keyof A, A[]> | {}, x: A) => {
      const xKey = getGroup(x);
      if (rv.hasOwnProperty(xKey)) {
        rv[xKey].push(x);
      } else {
        rv[xKey] = [x];
      }
      return rv;
    },
    {}
  );
};

const hasConfigScrollBar = (conf, scroll) => {
  return conf ? { x: 600 } : scroll;
};

export const DEPRECATEDCORETable = <RecordType extends object>({
  isNewTableDesign = false,
  columns: cs,
  editMode = false,
  deleteMode = false,
  deleteTitle = "Are you sure you want to delete?",
  copyDuplicateMode = false,
  onDuplicate,
  onSave,
  onDelete,
  dataSource,
  group = undefined,
  pagination = false,
  onPaginationChange,
  ellipsis = false,
  scroll,
  defaultExpandedRowKeys,
  showUndo = false,
  searchedColumn,
  handleSearch,
  handleReset,
  handleSort,
  sortedColumn,
  loading = false,
  tableType,
  showLastUpdate,
  lastUpdate,
  ...props
}: DEPRECATEDCORETableProps<RecordType>) => {
  // Add event listener using our hook
  const [escKeyPress, setEscKeyPress]: any = useState(false);
  useEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setEscKeyPress(true);
    }
  });

  // this is needed as we don't want to modify the original columns array, as it will cause delete mode to be added for each rerender
  const columns: ColumnsType<RecordType> | undefined = cs?.map((c) => ({
    escKeyPress: escKeyPress,
    setEscKeyPress: setEscKeyPress,
    ...c,
  }));

  const [gridLog, setGridLog, gridLogHistory] = useStateWithHistory(dataSource);
  const gridDataColumn = generateGridColumn(columns);

  const tableClasses = classNames(["core-table", "table-overflow"]);

  // update the grid if the datasource prop changes ( from the API)
  useDeepCompareEffect(() => {
    setGridLog(() => dataSource);
  }, [dataSource, lastUpdate]);

  // ====== Delete mode ====== //
  if (deleteMode && columns) {
    columns.push({
      key: "delete",
      className: props?.deleteColumnClassName,
      // @ts-ignore
      editable: false,
      render: (text, record) => {
        return (
          <DeleteColumn
            copyDuplicateMode={copyDuplicateMode}
            action={{
              onDelete,
              onDuplicate,
            }}
            record={record}
            deleteTitle={deleteTitle}
          />
        );
      },
    });
  }

  // merge
  if (!group && !editMode && columns !== undefined) {
    return (
      <div className={tableClasses}>
        <Table
          size={"small"}
          expandable={{ expandRowByClick: true }}
          columns={columns}
          scroll={hasConfigScrollBar(isNewTableDesign, scroll)}
          dataSource={dataSource}
          pagination={pagination}
          defaultExpandedRowKeys={defaultExpandedRowKeys}
          loading={loading}
          className={
            isNewTableDesign
              ? "daily-curve-table"
              : props.theme === "w"
              ? "table-light-theme"
              : "table-dark-theme"
          }
          {...props}
        />
      </div>
    );
  }

  const gridProps = {
    tableType,
    group,
    gridLog,
    setGridLog,
    onSave,
  };

  return (
    <div className={tableClasses}>
      <LastUpdateTime
        children={lastUpdate?.calendar()}
        showLastUpdate={showLastUpdate}
      />
      <UndoComponent
        showUndo={showUndo}
        grid={{
          gridLogHistory,
          gridLog,
        }}
      />

      <Spin spinning={loading} tip="Loading...">
        <ReactDataSheetBlock
          testId={props.testId}
          onCellChange={(changes) =>
            editMode && saveGridData(changes, gridProps)
          }
          gridDataColumn={gridDataColumn}
          theme={props.theme}
          // @ts-ignore
          gridData={generateGridData(columns, gridLog, group, editMode)}
          group={group}
          tableType={tableType}
          defaultExpandedRowKeys={defaultExpandedRowKeys}
          columns={columns}
          searchedColumn={searchedColumn}
          handleSearch={handleSearch}
          handleReset={handleReset}
          sortedColumn={sortedColumn}
          handleSort={handleSort}
          sticky={props.sticky}
          headerColSpan={props.headerColSpan}
        />
      </Spin>
      {/* @ts-ignore */}
      <DataSheetPagination
        group={group && !loading}
        pagination={pagination}
        onPageChange={onPaginationChange}
      />
    </div>
  );
};
