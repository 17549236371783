import L from "leaflet";
import imgWind from "./img/wind.svg";
import imgTemperature from "./img/temperature.svg";
import imgWeather from "./img/weather.svg";

const iconWind = new L.Icon({
  iconUrl: imgWind,
  iconRetinaUrl: imgWind,
  iconSize: new L.Point(40, 40),
});

const iconTemperature = new L.Icon({
  iconUrl: imgTemperature,
  iconRetinaUrl: imgTemperature,
  iconSize: new L.Point(40, 40),
});

const iconWeather = new L.Icon({
  iconUrl: imgWeather,
  iconRetinaUrl: imgWeather,
  iconSize: new L.Point(40, 40),
});

export { iconWind, iconTemperature, iconWeather };
