import React from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import "./COREPageHeader.less";
import { MenuItemProps, PageHeader, PageHeaderProps } from "antd";
import { Merge } from "../../shared/TypeScriptHelpers";
import { COREHeading } from "../Typography/COREHeading";
import { COREDivider } from "./COREDivider";
import { BreadcrumbItem, COREBreadcrumb } from "../Navigation/COREBreadcrumb";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useWindowSize } from "react-use";
import { SCREEN_SM_SIZE } from "../../shared/customHoooks/useBreakpoint";
import { COREButton } from "../Action/COREButton";
import { COREDropdownMenu } from "../Form/COREDropdownMenu";
import { COREMenuItem } from "../Navigation/COREMenuItem";

export type ExtraMenu =
  | {
      extra: PageHeaderProps["extra"];
      extraResponsive: {
        onClick?: () => void;
        icon?: MenuItemProps["icon"];
        children: PageHeaderProps["extra"];
        closeWhenClick?: boolean;
      }[];
    }
  | {
      extra?: never;
      extraResponsive?: never;
    };
export type COREPageHeaderProps = Merge<
  Omit<PageHeaderProps, "extra">,
  {
    testID: TestID;
    feature?: React.ReactNode;
  } & ExtraMenu & {
      breadcrumbs?: BreadcrumbItem[];
    }
>;

const mappingExtraMenuWithDropdownMenu = (
  menu: Exclude<ExtraMenu["extraResponsive"], undefined>,
  testID: TestID
) =>
  menu.map(({ onClick, icon, children, closeWhenClick = false }, index) => {
    return {
      component: (
        <COREMenuItem
          testID={`${testID}-menu-item`}
          key={`${index}-default-type`}
          icon={icon}
          onClick={onClick}
        >
          {children}
        </COREMenuItem>
      ),
      closeWhenClick: closeWhenClick,
    };
  });

export const COREPageHeader: React.FC<COREPageHeaderProps> = ({
  title,
  testID,
  extra = [],
  feature,
  extraResponsive,
  breadcrumbs,
  ...otherProps
}) => {
  const { width } = useWindowSize();
  const isSmScreen = width <= SCREEN_SM_SIZE;

  const titleContent = (
    <div className={"title"}>
      {feature}
      <COREHeading
        level={1}
        marginBottom={false}
        testID={(testID + "-title") as TestID}
      >
        {title}
      </COREHeading>
    </div>
  );

  return (
    <TestIDWrapper testID={testID}>
      <div className={"core-page-header"}>
        <PageHeader
          title={titleContent}
          {...(isSmScreen
            ? {
                breadcrumbRender: () => (
                  <COREBreadcrumb
                    testID={"page-header-title"}
                    breadcrumbs={breadcrumbs}
                  />
                ),
                ...(extraResponsive && {
                  extra: [
                    <COREDropdownMenu
                      interactionsMenu={mappingExtraMenuWithDropdownMenu(
                        extraResponsive,
                        testID
                      )}
                      isDisabled={false}
                      testID={(testID + "-dropdown-menu") as TestID}
                      key={"extra-menu"}
                      overlayStyle={{ minWidth: "192px" }}
                    >
                      <COREButton
                        icon={
                          <COREIcon
                            icon={icon({ name: "bars", style: "regular" })}
                            key="1"
                          />
                        }
                        key={"extra-menu-button"}
                      />
                    </COREDropdownMenu>,
                  ],
                }),
              }
            : { extra: extra })}
          {...otherProps}
        />
        <COREDivider space={"none"} />
      </div>
    </TestIDWrapper>
  );
};
