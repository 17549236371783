import React from "react";
import "./InsightsWidget.less";
import { InsightsCard } from "./InsightsCard";
import { generateTestId } from "../../../../shared/testids/testids";
import { Insights } from "../../../../openapi-typescript/common/insights";

export const InsightsList: React.FC<{ insights?: Insights[] }> = ({
  insights,
}) => (
  <div className={"insights-list-container"}>
    {insights?.map((insight) => (
      <div key={insight.id}>
        <InsightsCard
          insights={insight}
          testID={generateTestId("dashboard", "insights")}
        />
      </div>
    ))}
  </div>
);
