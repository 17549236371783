import React, { useContext, useEffect, useState } from "react";
import ReactDataSheet from "../../dependencies/react-datasheet/DataSheet";
import {
  Col,
  DatePicker,
  Dropdown,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./COREDataSheet.less";
import moment from "moment";
import { DEPRECATEDDateFormatContextStandard } from "../date/DateFormatContext";
import { useUserIsAdmin } from "../state/user";
import classnames from "classnames";
import { TestIDWrapper } from "../testids/testids";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { green100, grey100 } from "../../COREDesignSystem/Content/COREColour";
import classNames from "classnames";

const getNormalDefaultValue = (selectedKeys, filterValue) => {
  return selectedKeys.length > 0 || selectedKeys[0] !== undefined
    ? selectedKeys[0]
    : filterValue
    ? filterValue[0]
    : null;
};

const generateNormalInput = ({
  title,
  selectedKeys,
  filterValue,
  setSelectedKeys,
  handleSearch,
  dataIndex,
  filter,
  testID,
}) => {
  return (
    <TestIDWrapper testID={testID}>
      <Input
        placeholder={`Search ${title}`}
        value={getNormalDefaultValue(selectedKeys, filterValue)}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : [""]);
        }}
        onPressEnter={() => handleSearch(selectedKeys, dataIndex, filter)}
        className={"normal-filter"}
      />
    </TestIDWrapper>
  );
};

const generateInputNumber = ({
  title,
  selectedKeys,
  filterValue,
  setSelectedKeys,
  handleSearch,
  dataIndex,
  filter,
  testID,
}) => {
  return (
    <TestIDWrapper testID={testID}>
      <InputNumber
        placeholder={`Search ${title}`}
        value={getNormalDefaultValue(selectedKeys, filterValue)}
        onChange={(e) => {
          setSelectedKeys(e ? [e] : [""]);
        }}
        onPressEnter={() => handleSearch(selectedKeys, dataIndex, filter)}
        className={"normal-filter"}
      />
    </TestIDWrapper>
  );
};

const generateInputDate = ({
  momentFormatStandard,
  selectedKeys,
  filterValue,
  setSelectedKeys,
  testID,
}) => {
  const defValue =
    selectedKeys.length > 0 ? selectedKeys : filterValue ? filterValue : [];
  return (
    <div style={{ marginBottom: "10px" }}>
      <TestIDWrapper testID={testID}>
        <DatePicker.RangePicker
          onChange={(e) => {
            setSelectedKeys(e ? [...e.map((t) => t.format("YYYY-MM-DD"))] : []);
          }}
          value={defValue.map((t) => moment(t))}
          format={momentFormatStandard.format}
        />
      </TestIDWrapper>
    </div>
  );
};

const generateInputBoolean = ({
  setSelectedKeys,
  selectedKeys,
  filterValue,
  testID,
}) => {
  const defValue = getNormalDefaultValue(selectedKeys, filterValue);
  return (
    <TestIDWrapper testID={testID}>
      <Select
        value={defValue ? (defValue === "is.null" ? "true" : "false") : null}
        onChange={(value) => {
          setSelectedKeys(
            value === "true"
              ? ["is.null"]
              : value === "false"
              ? ["not.is.null"]
              : [""]
          );
        }}
        className={"filter-dropdown"}
        placeholder={"Please select"}
      >
        <Select.Option key={"optionTrue"} value={"true"}>
          TRUE
        </Select.Option>
        <Select.Option key={"optionFalse"} value={"false"}>
          FALSE
        </Select.Option>
      </Select>
    </TestIDWrapper>
  );
};

const generateInputSelections = ({
  setSelectedKeys,
  selectedKeys,
  filter,
  filterValue,
  testID,
}) => {
  const defValue = getNormalDefaultValue(selectedKeys, filterValue);
  const options = filter.selectionList ? filter.selectionList : [];
  return (
    <TestIDWrapper testID={testID}>
      <Select
        value={defValue ? defValue : null}
        onChange={(value) => {
          setSelectedKeys([value]);
        }}
        className={"filter-dropdown"}
        placeholder={"Please select"}
      >
        {options.map((r, index) => (
          <Select.Option key={index} value={r.id}>
            {r.name}
          </Select.Option>
        ))}
      </Select>
    </TestIDWrapper>
  );
};

export const FilterInput = ({
  testID = "",
  type,
  selectedKeys = [],
  setSelectedKeys,
  handleSearch,
  dataIndex,
  title,
  filter,
  filterValue,
}) => {
  const momentFormatStandard = useContext(DEPRECATEDDateFormatContextStandard);
  let input;

  if (type === "number") {
    input = generateInputNumber({
      title,
      selectedKeys,
      filterValue,
      setSelectedKeys,
      handleSearch,
      dataIndex,
      filter,
      testID,
    });
  } else if (type === "date_range" || type === "datetime") {
    input = generateInputDate({
      momentFormatStandard,
      selectedKeys,
      filterValue,
      setSelectedKeys,
      testID,
    });
  } else if (type === "boolean") {
    input = generateInputBoolean({
      setSelectedKeys,
      selectedKeys,
      filterValue,
      testID,
    });
  } else if (type === "dropdown") {
    input = generateInputSelections({
      setSelectedKeys,
      selectedKeys,
      filter,
      filterValue,
      testID,
    });
  } else {
    input = generateNormalInput({
      title,
      selectedKeys,
      filterValue,
      setSelectedKeys,
      handleSearch,
      dataIndex,
      filter,
      testID,
    });
  }

  return input;
};

const FilterDropdown = ({
  type = "string",
  title,
  dataIndex,
  filter,
  handleSearch = () => {},
  handleReset = () => {},
  filterIndex,
  updateFilterIndex,
  searchedColumn,
}) => {
  const [visible, setVisible] = useState(filterIndex === dataIndex);
  const [selectedKeys, setSelectedKeys] = useState(
    searchedColumn.hasOwnProperty(dataIndex)
      ? searchedColumn[dataIndex].value
      : undefined
  );
  const clearFilters = () => {
    setSelectedKeys(undefined);
    setVisible(false);
  };
  const filterValue = searchedColumn.hasOwnProperty(dataIndex)
    ? searchedColumn[dataIndex].value
    : null;
  useEffect(() => {
    if (filterIndex !== dataIndex) setVisible(false);
  }, [filterIndex, dataIndex, visible]);
  const filterPanel = (
    <div style={{ padding: 8, background: "#ffffff" }}>
      <FilterInput
        type={type}
        title={title}
        dataIndex={dataIndex}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        handleSearch={handleSearch}
        filter={filter}
        filterValue={filterValue}
      />
      <Space>
        <COREButton
          type="primary"
          onClick={() => {
            handleSearch(selectedKeys, dataIndex, filter, clearFilters);
            setVisible(false);
          }}
          icon={<SearchOutlined />}
          size={"sm"}
        >
          Search
        </COREButton>
        <COREButton
          onClick={() => {
            handleReset(clearFilters, dataIndex);
          }}
          size={"sm"}
        >
          Reset
        </COREButton>
      </Space>
    </div>
  );
  return (
    <Dropdown
      overlay={filterPanel}
      trigger={["click"]}
      placement="bottomRight"
      visible={visible}
      onVisibleChange={setVisible}
      className={"core-datasheet-filter"}
    >
      <COREIcon
        icon={icon({ name: "magnifying-glass", style: "solid" })}
        color={filterValue ? green100 : grey100}
        onClick={() => {
          setVisible(!visible);
          updateFilterIndex(dataIndex);
        }}
        size={"xxs"}
      />
    </Dropdown>
  );
};

const SorterIcons = ({ column, handleSort, sorterIndex }) => {
  let isActive;
  if (sorterIndex.hasOwnProperty(column.key) && sorterIndex[column.key].order) {
    isActive = sorterIndex[column.key].order;
  }
  return (
    <div className={"core-datasheet-sorter"} onClick={() => handleSort(column)}>
      <CaretUpOutlined
        className={classNames("table-column-sorter-up", {
          "sort-active": isActive === "ascend",
        })}
      />
      <CaretDownOutlined
        className={classNames("table-column-sorter-down", {
          "sort-active": isActive === "descend",
        })}
      />
    </div>
  );
};

const SheetHeaderColumn = ({
  col,
  index,
  className,
  updateFilterIndex,
  filterIndex,
  handleReset,
  handleSearch,
  sorterIndex,
  handleSort,
  searchedColumn,
}) => {
  let column;
  let filterCol = "";
  let sortCol = "";
  if (col.filter) {
    const { type } = col.filter;
    filterCol = (
      <div className={"filter-col"}>
        <FilterDropdown
          key={col.dataIndex}
          title={col.title}
          type={type}
          dataIndex={col.dataIndex}
          filter={col.filter}
          updateFilterIndex={updateFilterIndex}
          filterIndex={filterIndex}
          handleReset={handleReset}
          handleSearch={handleSearch}
          searchedColumn={searchedColumn}
        />
      </div>
    );
  }

  if (col.sorter) {
    sortCol = (
      <SorterIcons
        column={col}
        handleSort={handleSort}
        sorterIndex={sorterIndex}
      />
    );
  }

  column = (
    <div
      className={className}
      style={{ display: "flex", alignItems: "center" }}
    >
      <span className={"ant-table-filter-column-title"}>{col.title}</span>
      {sortCol}
      {filterCol}
    </div>
  );

  return (
    <td
      key={`header-${index}`}
      className={classNames(className, col.className, "core-sheet-header")}
      style={{ width: col.width }}
    >
      {column}
    </td>
  );
};

const SheetRenderer = ({
  isAdmin,
  className,
  columns,
  children,
  handleReset,
  handleSearch,
  sortedColumn = {},
  searchedColumn = {},
  handleSort: parentSort,
  headerColSpan,
  empty,
  testID,
}) => {
  const [filterIndex, setFilterIndex] = useState();

  const handleSort = (column) => {
    let order = "ascend";
    if (sortedColumn.hasOwnProperty(column.key)) {
      if (sortedColumn[column.key].order === "ascend") {
        order = "descend";
      } else if (sortedColumn[column.key].order === "descend") {
        order = undefined;
      } else {
        order = "ascend";
      }
    }

    const sortItem = {
      column: order ? column : undefined,
      columnKey: column.key,
      field: column.dataIndex,
      order: order,
    };

    parentSort(sortItem);
  };

  const renderChildren = (childrenData) => {
    if (childrenData.length > 0) {
      return childrenData;
    } else if (columns) {
      return (
        <tr className={"core-data-sheet-empty-block"}>
          <td colSpan={isAdmin ? columns.length + 2 : columns.length}>
            <Row justify="center" className={"empty-row"}>
              <Col span={24}>{empty}</Col>
            </Row>
          </td>
        </tr>
      );
    } else {
      return (
        <tr className={"core-data-sheet-empty-block"}>
          <td>
            <Row justify="center" className={"empty-row"}>
              <Col span={24}>{empty}</Col>
            </Row>
          </td>
        </tr>
      );
    }
  };

  const defaultDataSheetHeader = (cols) => {
    return (
      <>
        <tr>
          {cols.map((col, index) => {
            return (
              <SheetHeaderColumn
                key={`col-${index}`}
                className={className}
                col={col}
                index={index}
                filterIndex={filterIndex}
                updateFilterIndex={(i) => setFilterIndex(i)}
                handleReset={handleReset}
                handleSearch={handleSearch}
                sorterIndex={sortedColumn}
                handleSort={handleSort}
                searchedColumn={searchedColumn}
              />
            );
          })}
        </tr>
      </>
    );
  };

  const calculateColumnRowColSpan = (cols) => {
    // travel cols tree by using DFS to calculate rowSpan and colSpan
    const results = [];
    const dfsRecursive = (lv, node) => {
      let sumColSpan = 0;

      // check if node has children then continue
      if (node.children && node.children.length > 0) {
        node.children.forEach((nodeChildren) => {
          sumColSpan += dfsRecursive(lv + 1, nodeChildren);
        });
      }

      // check if node is leaf
      if (!node.children || node.children?.length === 0) {
        sumColSpan = 1;
      }

      // collect current lv and leaf count
      results.push({ node, rowSpan: 1, colSpan: sumColSpan, lv });

      return sumColSpan;
    };

    cols.forEach((col) => dfsRecursive(0, col));
    const maxLv = Math.max.apply(
      Math,
      results.map(function (c) {
        return c.rowSpan;
      })
    );
    results.forEach((result) => {
      if (result.colSpan === 1) {
        // is leaf
        result.rowSpan = maxLv - result.lv + 1;
      }
    });

    return results;
  };

  const RenderColumnWithHeader = ({ headers }) => {
    return (
      <>
        {headers.map((tr, i) => {
          const rowKey = tr.key ?? i;
          return (
            <tr key={rowKey}>
              {tr.map((th, i) => {
                const colKey = th.key ?? i;
                return (
                  <th
                    key={`${rowKey}-${colKey}`}
                    rowSpan={th.rowSpan}
                    colSpan={th.colSpan}
                    className={classnames(th.node?.className)}
                  >
                    {th.node.title}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  const columnWithHeader = (cols) => {
    const columnsInfo = calculateColumnRowColSpan(cols);

    // travel cols tree by using BFS to build table header structure
    const queue = cols.map((c) => c);
    let currentLv = 0;
    let headers = [[]];

    while (queue.length > 0) {
      const currentCol = queue.shift();
      const currentColInfo = columnsInfo.find(
        (info) => currentCol === info.node
      );
      if (currentColInfo.lv === currentLv) {
        headers[headers.length - 1].push(currentColInfo);
      } else {
        headers.push([currentColInfo]);
        currentLv += 1;
      }

      if (currentCol.children && currentCol.children.length > 0) {
        queue.push(...currentCol.children);
      }
    }

    return <RenderColumnWithHeader headers={headers} />;
  };

  const hasChildren = () => {
    return columns.some((c) => c.hasOwnProperty("children"));
  };
  const renderHeader = (cols) => {
    return hasChildren()
      ? columnWithHeader(cols)
      : defaultDataSheetHeader(cols);
  };

  return (
    <TestIDWrapper testID={testID}>
      <table className={classNames("core-data-sheet", className)}>
        <thead>{columns && renderHeader(columns)}</thead>
        <tbody>{renderChildren(children)}</tbody>
      </table>
    </TestIDWrapper>
  );
};

export const COREDataSheet = ({
  columns,
  data,
  onCellsChanged,
  handleReset,
  handleSearch,
  handleSort,
  sortedColumn,
  searchedColumn,
  headerColSpan,
  hoverable,
  ...props
}) => {
  const isAdmin = useUserIsAdmin();
  const sortMapColumn =
    sortedColumn && Array.isArray(sortedColumn)
      ? sortedColumn.reduce((a, v) => ({ ...a, [v.columnKey]: v }), {})
      : sortedColumn;
  const renderSheet = (propsSheet) => {
    return (
      <SheetRenderer
        isAdmin={isAdmin}
        columns={columns}
        {...propsSheet}
        handleReset={handleReset}
        handleSearch={handleSearch}
        handleSort={handleSort}
        sortedColumn={sortMapColumn}
        searchedColumn={searchedColumn}
        headerColSpan={headerColSpan}
        hoverable={hoverable}
        empty={props.empty}
        testID={props.testID}
      />
    );
  };

  // to solve issue extra cell on paste
  const parsePaste = (clipboardData) => {
    // the parser looks for "\n" for a new row and "\t" to assume a new column.
    return clipboardData
      .split(/\r\n|\n|\r/)
      .filter((row) => row.length > 0)
      .map((row) => row.split("\t"));
  };
  return (
    <ReactDataSheet
      data={data}
      onCellsChanged={onCellsChanged}
      valueRenderer={(cell) => cell.value}
      cellRenderer={props.cellRenderer}
      sheetRenderer={renderSheet}
      parsePaste={parsePaste}
      onSelectRow={props.onSelectRow}
      selectedRowKey={props.selectedRowKey}
      editMode={props.editMode}
      selectCellMode={props.selectCellMode}
      hoverable={hoverable}
    />
  );
};
