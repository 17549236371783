import React from "react";
import {
  CORESelectWithPlaceHolderAPI,
  CORESelectAPIProps,
} from "../../COREDesignSystem/Form/CORESelectAPI";
import { productTypeRegions as productTypeRegionsAdmin } from "../../openapi-typescript/admin";
import { productTypeRegions as productTypeRegionsApproved } from "../../openapi-typescript/approved";
import { keysToSnake } from "../global";
import { ProductType } from "../../modules/tools/ProjectMarketplacePageWrapper";

export type ProductTypeRegion =
  | productTypeRegionsAdmin
  | productTypeRegionsApproved;

type LocationSelectProps = { productType: ProductType[] } & Omit<
  CORESelectAPIProps<ProductTypeRegion>,
  "endpoint" | "props"
>;

export const LocationSelect: React.FC<LocationSelectProps> = ({
  productType,
  placeholder = "Select location",
  ...props
}) => {
  return (
    <CORESelectWithPlaceHolderAPI
      placeholder={placeholder}
      params={{
        order: "sort_order.asc,region_name.asc",
        select: "id,region_name,region_short_name,sort_order",
        ...(productType &&
          productType.length > 0 &&
          keysToSnake({
            productType: `in.(${productType.join(",")})`,
          })),
      }}
      endpoint={"productTypeRegions"}
      {...props}
    />
  );
};
