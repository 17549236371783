import React from "react";
import { Alert, Progress } from "antd";
import "./AemoPriceMapOverview.less";

function getArrowDirection(interconnector, isImporting) {
  if (interconnector === "V-S-MNSP1" || interconnector === "V-SA") {
    return isImporting ? "graph-arrow-left-block" : "graph-arrow-right-block";
  }
  return isImporting ? "graph-arrow-up-block" : "graph-arrow-down-block";
}

export const InterconnectorFlows = ({ data, name }) => {
  if (data === undefined) {
    return (
      <Alert
        message="Unable to load the Interconnector."
        type="error"
        showIcon
      />
    );
  }

  const dataName = data[name];

  if (!dataName) {
    return <></>;
  }

  const dataMwFlow = dataName["mwflow"];

  const mwFlow = dataMwFlow ? Math.round(dataMwFlow) : 0;
  const mwFlowNum = dataMwFlow !== undefined ? Math.abs(mwFlow) : "-";

  const importLimit = dataName["importlimit"] ? dataName["importlimit"] : 0;
  const exportLimit = dataName["exportlimit"] ? dataName["exportlimit"] : 0;

  const isImporting = mwFlow > 0;

  const percentCircleWidth =
    Math.abs(mwFlow) / Math.trunc(isImporting ? exportLimit : importLimit); // Reverse formula from arrow direction
  const circleWidth = Math.abs(percentCircleWidth) * 100;

  let arrowPos = getArrowDirection(name, isImporting);

  return (
    <div className={arrowPos}>
      <div className={"graph-text-block"}>
        <Progress
          type="circle"
          width={35}
          strokeWidth={8}
          percent={circleWidth}
          format={() => mwFlowNum}
        />
      </div>
    </div>
  );
};
