import { COREPageWrapper } from "../../COREDesignSystem/Layout/COREPageWrapper";
import { COREPageHeader } from "../../COREDesignSystem/Layout/COREPageHeader";
import { generateTestId, TestIDWrapper } from "../../shared/testids/testids";
import React, { useState } from "react";
import { CORECard } from "../../COREDesignSystem/Content/CORECard";
import { COREHeading } from "../../COREDesignSystem/Typography/COREHeading";
import { Col, message, Popconfirm, Row, Space } from "antd";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";
import { CORETag } from "../../COREDesignSystem/Content/CORETag";
import { CORESwitch } from "../../COREDesignSystem/Form/CORESwitch";
import { COREContainer } from "../../COREDesignSystem/Layout/COREContainer";
import moment, { Moment } from "moment";
import { COREDivider } from "../../COREDesignSystem/Layout/COREDivider";
import { EodEditor } from "./carbonComponent/EodEditor";
import { CertificateCurve } from "./carbonComponent/CertificateCurve";
import { COREDatePicker } from "../../COREDesignSystem/Form/COREDatePicker";
import { momentFormat } from "../../shared/date/DateFormatContext";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { CORELoading } from "../../COREDesignSystem/Feedback/CORELoading";
import { useMutation } from "react-query";
import {
  appQueryClient as queryClient,
  mutation,
} from "../../shared/state/appQueryClient";
import { SnapshotRecord } from "../../shared/customHoooks/useSnapshots";
import { CORECSVButton } from "../../COREDesignSystem/Action/CORECSVButton";
import { useUserIsAdmin } from "../../shared/state/user";
import { keysToSnake } from "../../shared/global";
import {
  curve_publication_statuses as CurvePublicationStatuseApproved,
  daily_curves as DailyCurveApproved,
} from "../../openapi-typescript/approved";
import {
  curve_publication_statuses as CurvePublicationStatuseAdmin,
  daily_curves as DailyCurveAdmin,
} from "../../openapi-typescript/admin";
import { product_curve_commentaries as productCurveCommentariesCommon } from "../../openapi-typescript/common/product_curve_commentaries";
import { Merge } from "../../shared/TypeScriptHelpers";
import { getCopyTables } from "./carbonComponent/copyTables";
import { useCopyToClipboard } from "../../shared/customHoooks/useCopyToClipboard";
import { getCSVData } from "./carbonComponent/getCSVData";
import { useFeatureFlags } from "../../shared/customHoooks/useFeatureFlags";
import { CORETypographyInput } from "../../COREDesignSystem/Typography/CORETypographyInput";
import { ListCollapseHOC, ListCollapseItem } from "./shared/ListCollapseHOC";
import "./carbonComponent/CarbonDailyCurvePageWrapper.less";
import { COREIcon } from "../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Status, useCarbonCurve } from "./carbonComponent/useCarbonCurve";

export type DailyCurve = DailyCurveAdmin | DailyCurveApproved;
export type ProductCurveCommentaries = productCurveCommentariesCommon;

export type CurvePublicationStatus =
  | CurvePublicationStatuseAdmin
  | CurvePublicationStatuseApproved;

export type DailyCurvesWithSnapshot = Merge<
  DailyCurve,
  Partial<Pick<SnapshotRecord, "lastPrice" | "lastSize" | "lastDate">>
>;

export type CertificateCurveData = {
  productCommentary?: ProductCurveCommentaries;
  dailyCurves: DailyCurvesWithSnapshot[];
  currency: DailyCurvesWithSnapshot["currency"];
  productTypeName: DailyCurvesWithSnapshot["productTypeName"];
  productType: DailyCurvesWithSnapshot["productType"];
};

const onPublish = async ({
  currentlyPublished,
  curveDate,
}: {
  currentlyPublished: boolean;
  curveDate: moment.Moment;
}) => {
  const newStatus: Status = currentlyPublished ? "draft" : "published";

  await mutation({
    queryKey: [
      "postCurvePublicationStatuses",
      {
        action: "postCurvePublicationStatuses",
        params: keysToSnake({ onConflict: "date,product_class" }),
        body: JSON.stringify(
          keysToSnake({
            productClass: "carbon",
            date: curveDate.format(serverDateFormat),
            status: newStatus,
          })
        ),
        enabled: true,
      },
    ],
  });
  return "Updated success";
};

export const disabledDate = (
  current: moment.Moment,
  publishedDateArray: CurvePublicationStatus["date"][]
) => !publishedDateArray.includes(moment(current).format("YYYY-MM-DD"));

const serverDateFormat = momentFormat.server.format;
const CSVDownload: React.FC<{
  curveDate: moment.Moment;
  certificateCurves: CertificateCurveData[];
  generalCommentary?: ProductCurveCommentaries;
}> = ({ curveDate, certificateCurves, generalCommentary }) => {
  const { features: featureList } = useFeatureFlags();

  if (!(featureList && featureList.includes("daily-curves-csv"))) {
    return null;
  }
  return (
    <CORECSVButton
      testID={generateTestId("carbondailycurve", "downloadcsv")}
      buttonSize={"lg"}
      icon={<COREIcon icon={icon({ name: "file-csv", style: "regular" })} />}
      filename={`Carbon curves - ${curveDate.format(serverDateFormat)}`}
      getData={() => ({
        headers: [
          "Accreditation",
          "Product",
          "Method",
          "Curve",
          "Change",
          "Last",
          "Last Trade Date",
        ],
        data: getCSVData(generalCommentary, certificateCurves),
      })}
    >
      Download
    </CORECSVButton>
  );
};

export const useEnsureOnDateUserCanView = (
  isPublished: boolean,
  sync: boolean,
  publishedDateArray: CurvePublicationStatus["date"][]
): undefined | string => {
  const isAdmin = useUserIsAdmin();
  if (isAdmin) return;
  if (!sync) return;
  if (isPublished) return;
  return publishedDateArray[publishedDateArray.length - 1];
};

type EndOfDayItem = {
  dividerBefore?: boolean;
} & ListCollapseItem;

type EndOfDayParams = {
  items: EndOfDayItem[];
  hideItems?: EndOfDayItem[];
};

const EndOfDayContent: React.FC<EndOfDayParams> = ListCollapseHOC(
  ({ hideItems, children }) => {
    return (
      <>
        <div>{children}</div>
        {hideItems && (
          <Space className={"row-warp-items"} direction={"vertical"}>
            {hideItems.map((item) => (
              <div key={item.key}>
                {item.dividerBefore ? (
                  <>
                    {React.cloneElement(item.input.props.children[0], {
                      space: "md",
                      type: "horizontal",
                    })}
                    <Space wrap={true}>
                      {item.input.props.children[1]}
                      {item.input.props.children[3]}
                    </Space>
                  </>
                ) : (
                  item.input
                )}
              </div>
            ))}
          </Space>
        )}
      </>
    );
  },
  undefined,
  undefined,
  undefined,
  "end-of-day-content-list"
);

const CarbonDailyCurvePublicationSwitch: React.FC<{
  isPublished: boolean;
  curveDate: moment.Moment;
  curvePublishLoading: boolean;
}> = ({ isPublished, curveDate, curvePublishLoading }) => {
  const publishMutation = useMutation(onPublish, {
    onSuccess: (successMessage: string) => {
      queryClient
        .invalidateQueries("getCurvePublicationStatuses")
        .then(() => message.success(successMessage));
    },
    onError: ({ response: { data: err } }: { response: { data: Error } }) => {
      message.error(`Error: ${err.message}`);
      console.error(err.message);
    },
  });

  const nextStatus = isPublished ? "Draft" : "Publish";
  return (
    <Popconfirm
      placement="topLeft"
      title={
        <TestIDWrapper
          testID={generateTestId("carbondailycurve", "popover-message")}
        >
          {`Are you sure you want to ${nextStatus}?`}
        </TestIDWrapper>
      }
      onConfirm={() =>
        publishMutation.mutate({
          currentlyPublished: isPublished,
          curveDate,
        })
      }
      okText={
        <TestIDWrapper
          testID={generateTestId("carbondailycurve", "popover-confirm")}
        >
          Yes
        </TestIDWrapper>
      }
      cancelText={
        <TestIDWrapper
          testID={generateTestId("carbondailycurve", "popover-cancel")}
        >
          No
        </TestIDWrapper>
      }
    >
      <Space wrap>
        <CORETypographyInput type={"label"}>Publish</CORETypographyInput>
        <CORESwitch
          loading={curvePublishLoading}
          disabled={curvePublishLoading}
          size="lg"
          checked={isPublished}
          testID={generateTestId("carbondailycurve", "publish")}
        />
      </Space>
    </Popconfirm>
  );
};

const CarbonDailyCurvePageWrapper: React.FC = () => {
  const history = useHistory();
  const isAdmin = useUserIsAdmin();
  const [, copyToClipboard] = useCopyToClipboard();

  const { date } = useParams<{ date: string }>();
  const curveDate: Moment = moment(date);
  const {
    loading,
    sync,
    generalCommentary,
    publishedDateArray,
    certificateCurves,
    isPublished,
    curvePublishLoading,
  } = useCarbonCurve({ curveDate });

  const [isUserView, setIsUserView] = useState<boolean>(false);

  const redirectToDate = useEnsureOnDateUserCanView(
    isPublished,
    sync,
    publishedDateArray
  );
  if (redirectToDate !== undefined) {
    return (
      <Redirect
        to={`/daily-curves/carbon/${
          publishedDateArray[publishedDateArray.length - 1]
        }`}
      />
    );
  }
  const generateNewLink = (dateSelected: string) => {
    const newLink = `/daily-curves/carbon/${dateSelected}`;
    history.push(newLink);

    return newLink;
  };

  const matchUrl = (dateString: string | moment.Moment) => {
    const dateFormat = moment(dateString).format(serverDateFormat);
    generateNewLink(dateFormat);
  };

  const copyTables = () => {
    const tablesHTML = getCopyTables(generalCommentary, certificateCurves);

    copyToClipboard(tablesHTML, {
      format: "text/html",
    });
    message.success(`Copied to clipboard`);
  };

  const copyForEmailButton =
    isAdmin && !isUserView
      ? [
          {
            key: "eod-copy-for-email",
            input: (
              <div>
                <COREDivider
                  className={"divider"}
                  space={"xs"}
                  type={"vertical"}
                  height={"30px"}
                />
                <COREButton
                  testID={generateTestId("carbondailycurve", "copyforemail")}
                  onClick={copyTables}
                  size={"lg"}
                  icon={
                    <COREIcon icon={icon({ name: "copy", style: "regular" })} />
                  }
                >
                  Copy
                </COREButton>
                <COREDivider space={"md"} type={"vertical"} height={"30px"} />
                <div className={"download-btn-inline"}>
                  <CSVDownload
                    curveDate={curveDate}
                    certificateCurves={certificateCurves}
                    generalCommentary={generalCommentary}
                  />
                </div>
              </div>
            ),
            dividerBefore: true,
          },
        ]
      : [
          {
            key: "eod-copy-for-email",
            input: (
              <div>
                <COREDivider
                  className={"divider"}
                  space={"xs"}
                  type={"vertical"}
                  height={"30px"}
                />
                <div className={"download-btn-inline"}>
                  <CSVDownload
                    curveDate={curveDate}
                    certificateCurves={certificateCurves}
                    generalCommentary={generalCommentary}
                  />
                </div>
              </div>
            ),
            dividerBefore: true,
          },
        ];

  const items: EndOfDayItem[] = [
    {
      key: "eod-label",
      input: (
        <COREHeading
          level={3}
          testID={generateTestId("carbondailycurve", "endofdaytitle")}
          marginBottom={false}
        >
          End of day
        </COREHeading>
      ),
    },
    {
      key: "eod-date-picker",
      input: (
        <COREDatePicker
          {...(!isAdmin && {
            disabledDate: (current: moment.Moment) =>
              disabledDate(current, publishedDateArray),
          })}
          value={curveDate}
          onChange={(_, dateString) => {
            matchUrl(dateString ? dateString : moment());
          }}
          testID={generateTestId("carbondailycurve", "endofdayselect")}
          size={"lg"}
        />
      ),
    },
    ...copyForEmailButton,
  ];

  const breadcrumbs = [
    {
      title: "Daily Curves",
      href: "/daily-curves",
    },
    {
      title: "Carbon",
    },
  ];
  return (
    <COREPageWrapper
      breadcrumbs={breadcrumbs}
      header={
        <COREPageHeader
          breadcrumbs={breadcrumbs}
          testID={generateTestId("carbondailycurve", "header")}
          extra={[
            isAdmin && (
              <COREButton
                testID={generateTestId("carbondailycurve", "view-button")}
                size={"lg"}
                type={"primary"}
                onClick={() => setIsUserView(!isUserView)}
              >
                {isUserView ? "EDIT VIEW" : "USER VIEW"}
              </COREButton>
            ),
          ]}
          extraResponsive={[
            {
              onClick: () => setIsUserView(!isUserView),
              children: isUserView ? "EDIT VIEW" : "USER VIEW",
              closeWhenClick: true,
            },
          ]}
          title="Carbon curves"
        />
      }
      testID={generateTestId("carbondailycurve", "pagewrapper")}
    >
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <CORECard
            testID={generateTestId("carbondailycurve", "endofday")}
            hoverable={false}
          >
            <Row align={"middle"} gutter={[8, 8]}>
              <Col flex={"auto"}>
                <EndOfDayContent items={items} />
              </Col>
              {isAdmin && !isUserView && (
                <Col>
                  <Space>
                    <CORETag
                      label={isPublished ? "Published" : "DRAFT"}
                      type="status"
                      status={isPublished ? "success" : "warning"}
                      testID={generateTestId("carbondailycurve", "status-tag")}
                    />
                    <COREDivider
                      space={"md"}
                      type={"vertical"}
                      height={"30px"}
                    />
                    <CarbonDailyCurvePublicationSwitch
                      isPublished={isPublished}
                      curveDate={curveDate}
                      curvePublishLoading={curvePublishLoading}
                    />
                  </Space>
                </Col>
              )}
            </Row>
          </CORECard>
        </Col>
        <Col span={24}>
          <CORELoading loading={loading && !sync} size={"lg"}>
            <COREContainer
              noScroll
              testID={generateTestId("carbondailycurve", "general-commentary")}
              header={"General commentary"}
            >
              <EodEditor
                key={`carbonDailyCurve${date}`}
                testId={generateTestId(
                  "carbondailycurve",
                  "general-commentary-text-content"
                )}
                certificate={"general"}
                date={curveDate}
                commentaryData={{
                  certificate: "general",
                  commentary: generalCommentary?.commentary,
                }}
                isPublished={isPublished || isUserView}
              />
            </COREContainer>
          </CORELoading>
        </Col>
        <Col span={24}>
          <COREDivider space="none" />
        </Col>
        {certificateCurves.map((c, i) => (
          <React.Fragment key={c.productType}>
            <Col span={24}>
              <CertificateCurve
                certificateCurve={c}
                curveDate={curveDate}
                isPublished={isPublished || isUserView}
                isLoading={loading && !sync}
              />
            </Col>
            {i !== certificateCurves.length - 1 && (
              <Col span={24}>
                <COREDivider space="none" />
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>
    </COREPageWrapper>
  );
};

export default CarbonDailyCurvePageWrapper; // eslint-disable-line import/no-default-export
