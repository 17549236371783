import React, { useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { TestID } from "../../../shared/testids/testids";
import {
  capitalizeFirstLetter,
  keysToSnake,
  volumeRender,
} from "../../../shared/global";
import { TradeTickerFilter, TradeTickerFilterType } from "./TradeTickerFilter";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { Space } from "antd";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import "./TradeTicker.less";
import { projectMarketplaceDetailsLink } from "../projectMarketplace/projectMarketplaceHelper";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { useTradeLogsWithDatePaginate } from "../tradeLog/useTradeLog";
import { priceFormatter } from "../../../shared/globals";
import { displayRelativeDate, formatDate } from "../../../shared/date/DateTime";
import moment from "moment";
import classNames from "classnames";
import { COREInfiniteScroll } from "../../../COREDesignSystem/Content/COREInfiniteScroll";
import { convertMomentToMarketTimezone } from "../../../shared/date/DateTime";
import { NonEmptyRange, createRange } from "../../../shared/date/ranges";

const NoMoreTrade = ({ testID }: { testID: TestID }) => (
  <COREBody
    type={"p2"}
    italic={true}
    className={"no-more-trade-text"}
    testID={`${testID}-no-more-trade`}
  >
    No more trade records
  </COREBody>
);

const TradeTickerContent: React.FC<{
  testID: TestID;
  tradeTickerFilter: TradeTickerFilterType;
}> = ({ testID, tradeTickerFilter }) => {
  const [loadedRange, setLoadedRange] = useState<NonEmptyRange>();

  useDeepCompareEffect(() => {
    setLoadedRange(undefined);
  }, [tradeTickerFilter]);

  const params = {
    ...(tradeTickerFilter.search && {
      code: `ilike.*${tradeTickerFilter.search}*`,
    }),
    ...(tradeTickerFilter.productType &&
    tradeTickerFilter.productType.length > 0
      ? keysToSnake({
          tradableProduct: `in.(${tradeTickerFilter.productType.join(",")})`,
        })
      : {}),
  };
  const { updatable, loading, sync, hasMore } = useTradeLogsWithDatePaginate(
    params,
    undefined,
    loadedRange,
    "week"
  );
  let currentDisplayDate: string | null = null;

  return (
    <COREInfiniteScroll
      loadMore={() => {
        if (!hasMore) return;
        if (!loadedRange) {
          const latestRecord = updatable[updatable.length - 1];
          const latestRecordDate = convertMomentToMarketTimezone(
            moment(latestRecord.data?.tradeTime)
          );
          setLoadedRange(createRange(latestRecordDate, latestRecordDate));
        } else {
          setLoadedRange(
            createRange(
              loadedRange.from.subtract(1, "week"),
              loadedRange.to,
              loadedRange.bounds
            )
          );
        }
      }}
      loading={loading}
      sync={sync}
      hasMore={hasMore}
      contentRange={updatable.length}
      noMoreRender={<NoMoreTrade testID={testID} />}
      emptyRender={<NoMoreTrade testID={testID} />}
      useWindow={false}
      className={"infinite-scroll"}
      loader={<CORELoading testID={`${testID}-loading`} delay={0} />}
    >
      <div className={classNames("pr-12", "trade-ticker-block")}>
        {updatable.map((item, index) => {
          if (!item.data) return null;

          const tradeLogItem = item.data;
          const isYesterdayOrToday = !moment(
            tradeLogItem?.tradeTime
          ).isSameOrBefore(moment().subtract(2, "day"), "day");
          const currentRelativeDate = isYesterdayOrToday
            ? capitalizeFirstLetter(
                displayRelativeDate(moment(tradeLogItem?.tradeTime), ["day"], {
                  numeric: "auto",
                })
              )
            : formatDate(moment(tradeLogItem?.tradeTime).toDate(), {
                dateStyle: "medium",
              });
          const isFirstTradeForEachDate =
            currentDisplayDate === null ||
            currentDisplayDate !== currentRelativeDate;

          if (isFirstTradeForEachDate) {
            currentDisplayDate = currentRelativeDate;
          }

          return (
            <React.Fragment key={`${item.data?.code}-${index}`}>
              <div className={"full-block"}>
                <COREDivider
                  dashed={!isFirstTradeForEachDate}
                  orientation={"left"}
                  testID={`${testID}-divider-date`}
                  space={isFirstTradeForEachDate ? "lg" : "sm"}
                  noOrientationMargin={isFirstTradeForEachDate}
                >
                  {isFirstTradeForEachDate && currentDisplayDate}
                </COREDivider>
              </div>
              <COREBody
                type={"p3"}
                strong={true}
                marginBottom={false}
                testID={`${testID}-${tradeLogItem?.code}`}
                className={"minimum-label-size"}
              >
                {tradeLogItem?.code}
              </COREBody>
              <Space className={"price-block"}>
                <COREBody
                  type={"p2"}
                  marginBottom={false}
                  testID={`${testID}-${tradeLogItem?.code}-volume`}
                >
                  {tradeLogItem?.volume && volumeRender(tradeLogItem.volume)}
                </COREBody>
                {tradeLogItem?.price && (
                  <COREBody
                    type={"p2"}
                    marginBottom={false}
                    testID={`${testID}-${tradeLogItem?.code}-price`}
                  >{`@ $${priceFormatter(
                    tradeLogItem.price,
                    false
                  )}`}</COREBody>
                )}
              </Space>
              <div className={"full-block"}>
                {tradeLogItem?.certificateProject && (
                  <COREButton
                    type={"link"}
                    target={"_blank"}
                    size={"sm"}
                    href={projectMarketplaceDetailsLink(
                      tradeLogItem.certificateProject
                    )}
                  >
                    View project
                  </COREButton>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </COREInfiniteScroll>
  );
};

export const TradeTicker: React.FC<{
  heading?: React.ReactNode;
  testID: TestID;
  tradeTickerFilter: TradeTickerFilterType;
  onChangeFilter: (selectedFilter: TradeTickerFilterType) => void;
}> = ({ tradeTickerFilter, heading = null, testID, onChangeFilter }) => {
  return (
    <div className={"trade-ticker-layout"}>
      <div>
        {heading}

        <TradeTickerFilter
          tradeTickerFilter={tradeTickerFilter}
          testID={`${testID}-filter`}
          onChangeFilter={(selectedFilter: TradeTickerFilterType) => {
            onChangeFilter(selectedFilter);
          }}
        />
      </div>
      <div className={"trade-ticker-component"}>
        <TradeTickerContent
          tradeTickerFilter={tradeTickerFilter}
          testID={`${testID}-content`}
        />
      </div>
    </div>
  );
};
