import React from "react";
import { DEPRECATEDCORETable } from "../../../shared/deprecatedTables/DEPRECATEDCORETable";
import { Badge, message, Tooltip } from "antd";
import { useUserIsAdmin } from "../../../shared/state/user";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { priceFormatter, priceParser } from "../../../shared/globals";
import { capitaliseEachAllWord } from "../../../shared/stringUtil";
import { Moment } from "moment";
import { Certificate, dateFormat, tableRow } from "./EnvironmentalDailyCurve";
import {
  appQueryClient as queryClient,
  mutation,
} from "../../../shared/state/appQueryClient";
import { useMutation } from "react-query";
import { generateTestId } from "../../../shared/testids/testids";

interface TableRow {
  bidPrice: number | null | "";
  offerPrice: number | null | "";
  tradableProductId: number | null;
  curve: number | null;
}

interface EditColumnData {
  tableRow: TableRow;
  field: string;
  curveDate: Moment;
  bidOfferDate: Moment;
}

export type EnviroTableProps = {
  date: Moment;
  futureDate: Moment;
  dataSource: tableRow[];
  loading: boolean;
  isPublished: boolean;
  certificate: Certificate;
};

const firmness = "indicative";
const defaultBidOfferVol = 5000;
const editColumn = (param: EditColumnData) => {
  const {
    tableRow: { bidPrice, offerPrice, tradableProductId, curve },
    field,
    curveDate,
    bidOfferDate,
  } = param;

  const isBid = field === "bidPrice";

  if (field === "offerPrice" || field === "bidPrice") {
    const price = isBid ? bidPrice : offerPrice;
    return queryClient
      .fetchQuery({
        queryKey: [
          "rehub_bid_offers",
          {
            action: "rehub_bid_offers",
            fetchOptions: { method: "POST" },
            body: JSON.stringify({
              type: isBid ? "bid" : "offer",
              price: price === "" ? null : price,
              volume: defaultBidOfferVol,
              firmness: firmness,
              time: bidOfferDate.format(),
              // eslint-disable-next-line camelcase
              tradable_product: tradableProductId,
            }),
            enabled: true,
          },
        ],
      })
      .then(() => {
        return `Updating ${isBid ? "bid" : "offer"}`;
      });
  } else {
    const price = parseFloat(priceParser(curve));
    return mutation({
      queryKey: [
        "post_cfd_product_curve_pivots",
        {
          action: "post_cfd_product_curve_pivots",
          fetchOptions: { method: "POST" },
          body: JSON.stringify({
            tradable_product: tradableProductId, // eslint-disable-line camelcase
            price,
            curve_date: curveDate.format("YYYY-MM-DD"), // eslint-disable-line camelcase
          }),
          enabled: true,
        },
      ],
    }).then(() => {
      return "Updating curve";
    });
  }
};

export function EnviroTable({
  date,
  dataSource,
  loading,
  futureDate,
  isPublished,
  certificate,
}: EnviroTableProps) {
  const isAdmin = useUserIsAdmin();
  const columns = [
    {
      title: capitaliseEachAllWord("tenor"),
      key: "tradableProductName",
      dataIndex: "tradableProductName",
      editable: false,
    },
    {
      title: capitaliseEachAllWord("curve"),
      key: "curve",
      dataIndex: "curve",
      editable: true,
      render: (curve: number) => (
        <span style={{ color: "red" }}>
          {priceFormatter(curve, false) ?? ""}
        </span>
      ),
    },
    {
      title: (
        <Tooltip title="$ change in curve price since last published business day">
          {capitaliseEachAllWord("Change")}
        </Tooltip>
      ),
      key: "change",
      dataIndex: "change",
      editable: false,
      render: (change: number) => (
        <span>
          {change &&
            (change < 0 ? (
              <>
                <ArrowDownOutlined style={{ color: "#fa4d4a" }} />
                <span style={{ color: "#fa4d4a" }}>{change?.toFixed(2)}</span>
              </>
            ) : change === 0 ? (
              <span style={{ color: "#00b380" }}>{change?.toFixed(2)}</span>
            ) : (
              <>
                <ArrowUpOutlined style={{ color: "#00b380" }} />
                <span style={{ color: "#00b380" }}>{change?.toFixed(2)}</span>
              </>
            ))}
        </span>
      ),
    },
    {
      title: capitaliseEachAllWord("Last"),
      key: "last",
      dataIndex: "last",
      editable: false,
      render: (last: number, { isStrip, tradableProductName }: tableRow) => {
        const price = priceFormatter(last, false) ?? "";
        return isStrip ? (
          <Badge title={"Part of a strip trade"} color={"#23e9ae"}>
            {price}
          </Badge>
        ) : (
          price
        );
      },
    },
    {
      title: capitaliseEachAllWord("vol"),
      key: "vol",
      dataIndex: "vol",
      editable: false,
    },
    {
      title: capitaliseEachAllWord("trade date"),
      key: "tradeDate",
      dataIndex: "tradeDate",
      editable: false,
      render: (date: Moment | null) => date?.format(dateFormat) ?? "",
    },
  ];
  if (isAdmin) {
    columns.push(
      {
        editable: true,
        title: capitaliseEachAllWord("bid"),
        key: "bidPrice",
        dataIndex: "bidPrice",
        render: (value: number) => priceFormatter(value, false),
      },
      {
        editable: true,
        title: capitaliseEachAllWord("offer"),
        key: "offerPrice",
        dataIndex: "offerPrice",
        render: (value: number) => priceFormatter(value, false),
      }
    );
  }
  const styledColumns = columns.map((c) => ({
    ...c,
    className: "text-center",
  }));

  const saveMutation = useMutation(editColumn, {
    onSuccess: (successMessage: string) => {
      queryClient
        .invalidateQueries("getProductCurvePivots")
        .then(() => message.success(successMessage));
    },
    onError: ({ response: { data: err } }: any) => {
      message.error(`Error: ${err.message}`);
      console.error(err.message);
    },
  });

  return (
    <DEPRECATEDCORETable
      testId={generateTestId(
        `dailycurve`,
        `environmental-${certificate}-table`
      )}
      isNewTableDesign={true}
      rowKey={"tradableProduct"}
      theme={"w"}
      columns={styledColumns}
      loading={loading}
      dataSource={dataSource}
      editMode={isAdmin && !isPublished}
      onSave={(gridData, field) => {
        saveMutation.mutate({
          tableRow: gridData,
          field: field,
          curveDate: date,
          bidOfferDate: futureDate,
        });
      }}
    />
  );
}
