import React from "react";
import {
  CORESelectAPIProps,
  CORESelectWithPlaceHolderAPI,
} from "../../COREDesignSystem/Form/CORESelectAPI";
import { CertificateProject } from "./TradableProductTree";

type MethodSelectProps = Omit<
  // eslint-disable-next-line camelcase
  CORESelectAPIProps<CertificateProject, { product_type?: string }>,
  "endpoint"
>;

export const MethodSelect: React.FC<MethodSelectProps> = (props) => {
  return (
    <CORESelectWithPlaceHolderAPI
      endpoint={"certificateProjectTypesGreenMarket"}
      {...props}
      size={"lg"}
    />
  );
};
