import React from "react";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import {
  onSelectSetupMenu,
  SetupWidgetProps,
} from "../../dashboards/WidgetPanel";
import "./SetupWidget.less";
import { Col, Row, Space } from "antd";
import { capitalizeFirstLetter } from "../../../shared/global";
import {
  DailyCurveProductType,
  useAllProductTypes,
} from "../../../shared/customHoooks/useProductTypes";
import { MethodType } from "../carbonComponent/CertificateCurve";
import { COREMenuItem } from "../../../COREDesignSystem/Navigation/COREMenuItem";
import { generateTestId } from "../../../shared/testids/testids";
import { DropdownMenuItem } from "../../../COREDesignSystem/Form/COREDropdownMenu";

export type DailyCurveTableFilterType = {
  curveDate?: string | null;
  method?: MethodType["certificateProjectTypeName"][];
};

type SetupDailyCurveWidgetProps = SetupWidgetProps<
  DailyCurveProductType,
  DailyCurveTableFilterType
>;

export const useMenuGroupOfProducts = (
  widgetProps: SetupDailyCurveWidgetProps["widgetProps"]
): DropdownMenuItem[] => {
  const productTypes = useAllProductTypes();
  return productTypes.map((p) => {
    return {
      isGroupMenu: true,
      closeWhenClick: true,
      groupHeaderProps: {
        title: `${capitalizeFirstLetter(p.productClass)} market`,
      },
      component: p.productTypes.map((t) => {
        return (
          <COREMenuItem
            testID={generateTestId("company", "add-company-menu-item")}
            key={`${t}-item`}
            onClick={() => {
              onSelectSetupMenu(widgetProps, t);
            }}
          >
            {t.toUpperCase()}
          </COREMenuItem>
        );
      }),
    };
  });
};

export const SetupDailyCurveWidget: React.FC<SetupDailyCurveWidgetProps> = ({
  widgetProps,
  testID,
}) => {
  const productClass = useAllProductTypes();

  return (
    <div className={"setup-block-layout"}>
      <COREHeading
        level={3}
        testID={`${testID}-select-market`}
        marginBottom={false}
      >
        Select product type
      </COREHeading>
      <COREBody>
        Use the buttons below or the widget menu (
        {<COREIcon icon={icon({ name: "bars", style: "regular" })} size="xs" />}
        ) to configure your content preferences. You can have multiple widgets
        of this type and each can be configured separately.
      </COREBody>
      <Row gutter={[0, 24]}>
        {productClass.map((p) => {
          return (
            <Col span={24} key={`${p.productClass}-block`}>
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <COREHeading
                    testID={`${testID}-carbon-product-group`}
                    level={5}
                    marginBottom={false}
                  >
                    {capitalizeFirstLetter(p.productClass)} market products
                  </COREHeading>
                </Col>
                <Col span={24}>
                  <Space
                    size={8}
                    align={"center"}
                    wrap={true}
                    style={{ justifyContent: "center" }}
                  >
                    {p.productTypes.map((product) => (
                      <COREButton
                        key={`${product}-button`}
                        size="lg"
                        onClick={() => {
                          onSelectSetupMenu(widgetProps, product);
                        }}
                        testID={`${testID}-setup-menu-${product}-menu-item`}
                      >
                        {product.toUpperCase()}
                      </COREButton>
                    ))}
                  </Space>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
