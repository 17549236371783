import React from "react";
import { Divider, DividerProps } from "antd";
import classnames from "classnames";
import { FC } from "react";
import { TestID } from "../../shared/testids/testids";
import { COREBody } from "../Typography/COREBody";
import "./COREDivider.less";

type DividerSize = "xs" | "sm" | "md" | "lg" | "xl" | "none";
interface CommonProps {
  testID?: TestID;
  dashed?: boolean;
  space?: DividerSize;
  orientation?: orientation;
  noOrientationMargin?: boolean;
}
type DividerType =
  | {
      type?: "horizontal";
      height?: never;
    }
  | {
      type?: "vertical";
      height?: number | string;
    };

type orientation = "left" | "center" | "right";

type COREDividerProps = CommonProps &
  Omit<DividerProps, "type" | "plain"> &
  DividerType;
export const COREDivider: FC<COREDividerProps> = ({
  testID,
  className,
  children,
  orientation,
  noOrientationMargin = false,
  height = "100%",
  type = "horizontal",
  space = "lg",
  ...props
}) => {
  return (
    <Divider
      {...(type === "vertical" && height ? { style: { height } } : {})}
      {...(orientation !== undefined ? { orientation } : {})}
      {...(noOrientationMargin ? { orientationMargin: 0 } : {})}
      {...props}
      data-testid={testID}
      type={type}
      className={classnames("core-divider", className, space)}
    >
      {children && (
        <COREBody type="p3" marginBottom={false} className={"body-padding"}>
          {children}
        </COREBody>
      )}
    </Divider>
  );
};
