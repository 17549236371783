/* eslint-disable react/prop-types */
import React, { ForwardedRef, forwardRef } from "react";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import {
  generateTree,
  joinedDataType,
  Key,
  CORETree,
  useCORETree,
} from "../../../COREDesignSystem/Form/CORETree";
import { rowDataType } from "./FCASTimeSeriesTree";
import { TestID } from "../../../shared/testids/testids";

interface ElectricityTimeSeriesTreeWithDataProps {
  data: rowDataType[];
  multiple?: boolean;
  onChange?: (
    value: Key[],
    labelList: React.ReactNode[],
    extra: unknown
  ) => void;
  testID: TestID;
  initialValue?: Key[];
  ref: ForwardedRef<unknown>;
  checkedKeys?:
    | Key[]
    | {
        checked: Key[];
        halfChecked: Key[];
      };
  includeAgg: boolean;
}

interface ElectricityTimeSeriesTreeProps {
  onChange?: (
    value: Key[],
    labelList: React.ReactNode[],
    extra: unknown
  ) => void;
  testID: TestID;
  multiple?: boolean;
  activeProductOnly?: boolean;
  includeAgg?: boolean;
  checkedKeys?:
    | Key[]
    | {
        checked: Key[];
        halfChecked: Key[];
      };
}

const getJoinedData = (rawData: rowDataType[]): joinedDataType[] => {
  const joinedData: joinedDataType[] = [];
  const staticVar: string[] = [
    "Price",
    "Demand",
    "Generation",
    "Availability",
    "Rooftop",
  ];
  rawData.forEach((item) => {
    staticVar.forEach((variable) => {
      joinedData.push({
        id: item.id,
        name: item.name,
        shortName: item.short_name,
        aemoId: item.aemo_id,
        variable: variable,
        value: {
          id: item.id,
          aemoId: item.aemo_id,
          variable: variable,
          traceName: `${item.short_name} ${variable}`,
        },
      });
    });
  });
  return joinedData;
};

const ElectricityTimeSeriesTreeWithData: React.FC<
  ElectricityTimeSeriesTreeWithDataProps
> = ({
  data: d,
  multiple,
  onChange: parentOnChange,
  initialValue,
  ref,
  checkedKeys,
  includeAgg,
  testID,
  ...otherProps
}) => {
  const data = getJoinedData(d);
  const treeData = generateTree(
    data,
    ["shortName", "variable"],
    "value",
    includeAgg
  );

  const tree = useCORETree(treeData, {
    onChange: parentOnChange,
    initialRootValues: initialValue,
  });

  return (
    <CORETree
      ref={ref}
      {...tree}
      multiple={multiple}
      checkedKeys={checkedKeys}
      {...otherProps}
      testID={testID}
    />
  );
};

export const ElectricityTimeSeriesTree = React.memo(
  forwardRef(
    (
      {
        multiple = false,
        activeProductOnly = false,
        includeAgg = false,
        onChange,
        checkedKeys,
        testID,
        ...otherProps
      }: ElectricityTimeSeriesTreeProps,
      ref
    ) => {
      const { data, sync } = useAPIQuery("regions_data", { in_nem: `eq.true` }); // eslint-disable-line camelcase
      if (!sync) return <CORELoading size={"lg"} />;
      return (
        <ElectricityTimeSeriesTreeWithData
          multiple={multiple}
          includeAgg={includeAgg}
          onChange={onChange}
          checkedKeys={checkedKeys}
          ref={ref}
          data={data.data}
          testID={testID}
          {...otherProps}
        />
      );
    }
  )
);
