import React from "react";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import {
  CORETree,
  generateTree,
  useCORETree,
} from "../../../COREDesignSystem/Form/CORETree";
import { sortByRegions } from "../../../shared/globals";
import { generateTestId } from "../../../shared/testids/testids";

const DUIDTreeWithData = ({
  treeData,
  multiple,
  onChange: parentOnChange,
  initialValue,
  ref,
  checkedKeys,
  testID,
  ...otherProps
}) => {
  const tree = useCORETree(treeData, {
    onChange: parentOnChange,
    initialRootValues: initialValue,
  });

  return (
    <CORETree
      testID={testID}
      ref={ref}
      {...tree}
      multiple={multiple}
      checkedKeys={checkedKeys}
      {...otherProps}
    />
  );
};

export const DUIDTree = ({
  testModule = null,
  multiple = false,
  region = null,
  includeAgg = false,
  ...otherProps
}) => {
  const {
    data: { data },
    sync,
  } = useAPIQuery("duids");
  if (!sync) return <CORELoading size={"lg"} />;

  const mapData = data.map(
    ({ aemoRegion, co2eEnergySource, duid, stationnameDuid, ...rest }) => ({
      co2eEnergySource: co2eEnergySource ?? "Other",
      aemoRegion,
      stationnameDuid,
      ...rest,
      value: {
        id: duid,
        traceName: stationnameDuid,
        region: aemoRegion,
        co2eEnergySource: co2eEnergySource,
      },
    })
  );

  const regionFilter = region
    ? mapData.filter((i) => i.aemoRegion === region)
    : mapData;

  let treeData = generateTree(
    regionFilter,
    ["aemoRegion", "co2eEnergySource", "stationnameDuid"],
    "value",
    includeAgg
  );

  treeData = sortByRegions(treeData);

  return (
    <DUIDTreeWithData
      testID={generateTestId(testModule, `duid-tree`)}
      treeData={treeData}
      multiple={multiple}
      {...otherProps}
    />
  );
};
