/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  generateTree,
  Key,
  CORETreeData,
} from "../../../COREDesignSystem/Form/CORETree";
import { sortByProducts, sortByRegions } from "../../../shared/globals";
import { capitaliseEachWord } from "../../../shared/stringUtil";
import {
  cfdTreeType,
  COREAsyncTree,
  updateTreeData,
  useCOREAsyncTree,
} from "../../../COREDesignSystem/Form/COREAsyncTree";
import { Trace } from "./AggregatesChartV2";
import { datum } from "../../../shared/datum";
import { tradable_products as tradableProduct } from "../../../openapi-typescript/approved";

type tradeProductDataType = tradableProduct;

type ElectricityProductVWAPsTreeType = {
  datum?: datumReturnType;
  multiple: boolean;
  activeProductOnly?: boolean;
  onChange: (change: Trace[], value: Trace[], checkedKeys: string) => void;
  checkedKeys: string[];
  includeAgg?: boolean;
  topLevel?: cfdTreeType[];
};

type datumReturnType = {
  data: { data: tradeProductDataType[] };
  error: boolean;
  loading: boolean;
  sync: boolean;
};

const onLoadEvent = (filterTradeProduct, includeAgg) => {
  let cfdTree: cfdTreeType[] = generateTree(
    filterTradeProduct,
    ["region", "name"],
    "value",
    includeAgg
  );
  cfdTree = sortByRegions(cfdTree);

  return sortByProducts(cfdTree[0]?.children);
};

const ElectricityProductVWAPsTreeWithData = ({
  topLevel,
  tradeProductData,
  onChange: parentOnChange,
  includeAgg = false,
  ref,
  ...otherProps
}) => {
  const [treeData, setTreeData] = useState<CORETreeData<cfdTreeType>>(topLevel);

  const onLoad = ({ key }: { key: Key }) => {
    const filterTradeProduct = tradeProductData.filter((d) => d.region === key);

    if (!filterTradeProduct.length) {
      return;
    }

    setTreeData((origin) => {
      const childrenNodeData = onLoadEvent(filterTradeProduct, includeAgg);
      return updateTreeData(origin, key, childrenNodeData);
    });
  };

  const cfdTreeState = useCOREAsyncTree(treeData, {
    onChange: parentOnChange,
    initialRootValues: undefined,
  });

  return (
    <COREAsyncTree
      onLoad={onLoad}
      ref={ref}
      {...cfdTreeState}
      {...otherProps}
    />
  );
};

const setupTopLevelData = (dataTopLevel) => {
  let resultShapeTypes: cfdTreeType[] = dataTopLevel
    .filter((item) => item.short_name !== "Tas")
    .map((item) => {
      return {
        title: item?.short_name,
        key: item?.short_name,
        path: [item?.short_name],
        children: [],
        isLeaf: false,
        checkable: false,
      };
    });

  return resultShapeTypes;
};

const flatProduct = (tradeProductData) => {
  const cfdProducts = tradeProductData
    .filter(
      (d) =>
        Object.prototype.hasOwnProperty.call(d, "cfd_product") && d.cfd_product
    )
    .flatMap((cfdp) => {
      const name = cfdp.isCap
        ? cfdp.cfd_product.shape.type === "flat"
          ? "$300 Cap"
          : capitaliseEachWord(cfdp.cfd_product.shape.type) + " cap"
        : capitaliseEachWord(cfdp.cfd_product.shape.type);
      return {
        name: name,
        region: cfdp.cfd_product.shape.region.short_name,
        id: cfdp.id,
        value: {
          id: cfdp.id,
          variable: "VWAP",
          traceName: `${cfdp.cfd_product.shape.region.short_name} ${name} VWAP`,
        },
      };
    });

  const filterCfdProducts: tradeProductDataType[] = [];
  cfdProducts.forEach((cProduct) => {
    if (filterCfdProducts.length === 0) filterCfdProducts.push(cProduct);

    const checkDup = filterCfdProducts.find(
      (d) => d.name === cProduct.name && d.region === cProduct.region
    );
    if (!checkDup) filterCfdProducts.push(cProduct);
  });

  return filterCfdProducts;
};

const ElectricityProductWithData = ({
  datum,
  multiple,
  includeAgg,
  onChange,
  checkedKeys,
  ref,
  activeProductOnly,
  ...otherProps
}) => {
  const {
    data: { data: regions },
  } = datum[0];
  const topLevel: cfdTreeType[] = setupTopLevelData(regions);

  return activeProductOnly ? (
    <ActiveElectricityProductVWAPsTreeWithData
      multiple={multiple}
      includeAgg={includeAgg}
      onChange={onChange}
      checkedKeys={checkedKeys}
      topLevel={topLevel}
      {...otherProps}
    />
  ) : (
    <NotActiveElectricityProductVWAPsTreeWithData
      multiple={multiple}
      includeAgg={includeAgg}
      onChange={onChange}
      checkedKeys={checkedKeys}
      topLevel={topLevel}
      {...otherProps}
    />
  );
};

const LoadDataForElectricityProductVWAPsTree = ({
  datum,
  multiple,
  includeAgg,
  activeProductOnly,
  onChange,
  checkedKeys,
  topLevel,
  ...otherProps
}: ElectricityProductVWAPsTreeType) => {
  const tradeProductData: tradeProductDataType[] = datum
    ? datum[0].data.data
    : [];
  const cfdProducts: tradeProductDataType[] = flatProduct(tradeProductData);

  return (
    <ElectricityProductVWAPsTreeWithData
      topLevel={topLevel}
      tradeProductData={cfdProducts}
      multiple={multiple}
      includeAgg={includeAgg}
      checkedKeys={checkedKeys}
      onChange={onChange}
      ref={undefined}
      {...otherProps}
    />
  );
};

const ActiveElectricityProductVWAPsTreeWithData = datum(
  LoadDataForElectricityProductVWAPsTree,
  "tradable_products",
  [
    {
      params: { is_active: `eq.true` }, // eslint-disable-line camelcase
    },
  ],
  {
    loadingMessage: "Loading",
    loadingSize: "lg",
    loadingLayout: "horizontal",
  }
);

const NotActiveElectricityProductVWAPsTreeWithData = datum(
  LoadDataForElectricityProductVWAPsTree,
  "tradable_products",
  [{}],
  {
    loadingMessage: "Loading",
    loadingSize: "lg",
    loadingLayout: "horizontal",
  }
);

export const ElectricityProductVWAPsTree = React.memo(
  datum(
    ElectricityProductWithData,
    "regions_data",
    [
      {
        params: { in_nem: `eq.true` }, // eslint-disable-line camelcase
      },
    ],
    {
      loadingMessage: "Loading",
      loadingSize: "lg",
      loadingLayout: "horizontal",
    }
  )
);
