import React from "react";
import {
  OHLCPriceHistoryWidgetFilters,
  OHLCProductType,
} from "../../../dashboards/widgets/marketPrices/OHLCPriceHistoryWidget";
import {
  onSelectSetupMenu,
  SetupWidgetProps,
} from "../../../dashboards/WidgetPanel";
import { COREHeading } from "../../../../COREDesignSystem/Typography/COREHeading";
import { COREBody } from "../../../../COREDesignSystem/Typography/COREBody";
import "./OHLCPriceHistory.less";
import { COREIcon } from "../../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREButton } from "../../../../COREDesignSystem/Action/COREButton";
import { Col, Row } from "antd";

type SetupOHLCPriceHistoryWidgetProps = SetupWidgetProps<
  OHLCProductType,
  OHLCPriceHistoryWidgetFilters
>;

export const SetupOHLCPriceHistoryWidget = ({
  widgetProps,
  testID,
}: SetupOHLCPriceHistoryWidgetProps) => {
  return (
    <div className={"setup-block"}>
      <COREHeading
        level={3}
        testID={`${testID}-select-product-type`}
        marginBottom={false}
      >
        Select product type
      </COREHeading>
      <COREBody>
        Use the buttons below or the widget menu (
        {<COREIcon icon={icon({ name: "bars", style: "regular" })} size="xs" />}
        ) to configure your content preferences. You can have multiple widgets
        of this type and each can be configured separately.
      </COREBody>
      <Row justify="center" gutter={[8, 8]}>
        <Col>
          <COREButton
            size="lg"
            onClick={() => {
              onSelectSetupMenu(widgetProps, "accu");
            }}
            testID={`${testID}-setup-menu-accu-button`}
          >
            ACCU
          </COREButton>
        </Col>
        <Col>
          <COREButton
            size="lg"
            onClick={() => {
              onSelectSetupMenu(widgetProps, "lgc");
            }}
            testID={`${testID}-setup-menu-lgc-button`}
          >
            LGC
          </COREButton>
        </Col>
      </Row>
    </div>
  );
};
