import React from "react";
// import "leaflet/dist/leaflet.css";
import "../../../../../modules-css/leaflet.less";
import L from "leaflet";
import { TimelineSlider } from "./TimelineSlider";
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { iconTemperature, iconWeather, iconWind } from "./Icon";
import "./MapStick.less";
import { withContentRect } from "react-measure";

const { Overlay } = LayersControl;

// solve marker not showing icon by: https://github.com/PaulLeCam/react-leaflet/issues/453
delete L.Icon.Default.prototype._getIconUrl;

export const MapStick = () => {
  const positionTopRight = "topright";
  const positionTopLeft = "topleft";
  const isDisableOption = false;
  const isEnableOption = true;
  const zoomLevel = 9;
  const center = [-33.875053, 151.158651];

  // create a red polyline from an array of LatLng points
  let latlngsPolyline = [
    [-33.880169, 151.158007],
    [-33.886153, 151.158905],
    [-33.887726, 151.172051],
    [-33.893594, 151.181349],
    [-33.888543, 151.187128],
    [-33.876679, 151.19161],
  ];

  const MapToMeasure = withContentRect("bounds")(
    ({ measureRef, contentRect }) => (
      <div ref={measureRef}>
        <div className={"container_map"}>
          <MapContainer
            style={{ width: contentRect.width, height: contentRect.height }}
            trackResize={true}
            animate={isDisableOption}
            doubleClickZoom={isDisableOption}
            zoomControl={isDisableOption}
            center={center}
            className={"map"}
            dragging={isEnableOption}
            zoom={zoomLevel}
          >
            <TileLayer
              attribution=""
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <ZoomControl position={positionTopRight} />

            <LayersControl collapsed={false} position={positionTopLeft}>
              <Overlay checked name="NEM">
                <LayerGroup>
                  <Polyline positions={latlngsPolyline} color={"blue"}>
                    <Marker position={[-33.880169, 151.158007]} icon={iconWind}>
                      <Popup>
                        <span>Il Cugino Pizzeria</span>
                      </Popup>
                    </Marker>

                    <Marker position={[-33.886153, 151.158905]} icon={iconWind}>
                      <Popup>
                        <span>Norton Plaza</span>
                      </Popup>
                    </Marker>

                    <Marker position={[-33.887726, 151.172051]} icon={iconWind}>
                      <Popup>
                        <span>McDonald's Stanmore</span>
                      </Popup>
                    </Marker>
                  </Polyline>
                </LayerGroup>
              </Overlay>

              <Overlay checked name="Weather">
                <LayerGroup>
                  <Marker
                    position={[-33.893594, 151.181349]}
                    icon={iconWeather}
                  >
                    <Popup>
                      <span>Brewtown</span>
                    </Popup>
                  </Marker>

                  <Marker
                    position={[-33.888543, 151.187128]}
                    icon={iconWeather}
                  >
                    <Popup>
                      <span>The University of Sydney</span>
                    </Popup>
                  </Marker>

                  <Marker position={[-33.876679, 151.19161]} icon={iconWeather}>
                    <Popup>
                      <span>Wentworth Park Greyhounds</span>
                    </Popup>
                  </Marker>
                </LayerGroup>
              </Overlay>

              <Overlay checked name="Temperature">
                <LayerGroup>
                  <Marker
                    position={[-33.750972, 150.691572]}
                    icon={iconTemperature}
                  >
                    <Popup>
                      <span>Sydney</span>
                    </Popup>
                  </Marker>

                  <Marker
                    position={[-34.11328, 150.996296]}
                    icon={iconTemperature}
                  >
                    <Popup>
                      <span>Couridjah</span>
                    </Popup>
                  </Marker>

                  <Marker
                    position={[-34.207273, 150.5704]}
                    icon={iconTemperature}
                  >
                    <Popup>
                      <span>Thirlmere</span>
                    </Popup>
                  </Marker>
                </LayerGroup>
              </Overlay>
            </LayersControl>

            <TimelineSlider />
          </MapContainer>
        </div>
      </div>
    )
  );

  return (
    <>
      <MapToMeasure />
    </>
  );
};
