import { Col, Form, FormInstance, Row, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { generateTestId, TestID } from "../../shared/testids/testids";
import { COREButton } from "../Action/COREButton";
import { COREHeading } from "../Typography/COREHeading";
import { COREModal } from "../Overlay/COREModal";
import { debounce, sumArrayValues, useOpenClose } from "../../shared/global";
import { COREForm } from "./COREForm";
import { COREFormItem } from "./COREFormItem";
import { CORETypographyInput } from "../Typography/CORETypographyInput";
import { COREDivider } from "../Layout/COREDivider";
import "./COREFilters.less";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { WidthSize } from "./COREInput";
import {
  OnResize,
  SetSizeObject,
  defaultResizeValue,
} from "../../shared/OnResize";

const defaultDebounceTime = 200;

export type FilterItems = {
  label: string;
  dividerAfter?: boolean;
  widthSize: Exclude<WidthSize, "full">;
  key: string | number;
  input: React.ReactElement;
};

export type COREFiltersProps = {
  testID: TestID;
  items: FilterItems[];
  defaultValue?: Record<string, unknown>;
  value: Record<string, unknown>;
  modalTitle: string;
  filterHeader: string;
  onChange?: (values: Record<string, unknown>) => void;
  allowClear?: boolean;
  debounceTime?: number;
  liteMode?: boolean;
  onCancelModal?: () => void;
};

type FiltersProps = {
  form: FormInstance;
  testID: TestID;
  handleOnChange: () => void;
  filtered: boolean;
  opener: ReturnType<typeof useOpenClose>["opener"];
  containerSize?: SetSizeObject["width"];
} & Pick<COREFiltersProps, "items" | "value">;

type FilterModalProps = Pick<
  COREFiltersProps,
  "testID" | "modalTitle" | "value" | "allowClear"
> &
  Pick<FiltersProps, "handleOnChange"> & {
    isOpen: boolean;
    closer: ReturnType<typeof useOpenClose>["closer"];
    items: FilterItems[];
    modalForm: FormInstance;
    onClear: () => void;
    onCancelModal?: () => void;
  };

const clearIcon = (
  <COREIcon icon={icon({ name: "circle-xmark", style: "regular" })} />
);

const FilterModal = ({
  testID,
  items,
  modalTitle,
  isOpen,
  closer,
  onClear,
  modalForm,
  handleOnChange,
  value,
  allowClear,
  onCancelModal,
}: FilterModalProps) => {
  const formItems = items.map((item) => (
    <COREFormItem
      key={item.key}
      name={item.key}
      testID={`${testID}-filter-form-item-${item.key}`}
      label={
        <CORETypographyInput type={"label"}>{item.label}</CORETypographyInput>
      }
    >
      {React.cloneElement(item.input, { widthSize: "full" })}
    </COREFormItem>
  ));
  const handleOnCancel = () => {
    modalForm.setFieldsValue(value);
    closer();
    onCancelModal && onCancelModal();
  };
  return (
    <COREModal
      forceRender
      testID={`${testID}-filter-modal`}
      type={"basic"}
      title={modalTitle}
      visible={isOpen}
      onCancel={handleOnCancel}
      className={"filter-modal"}
      footer={[
        <div className={"button"} key={"submit-button-block"}>
          <COREButton
            key="submit"
            size={"lg"}
            type="primary"
            testID={generateTestId("emissionsmanager", "button-confirm-filter")}
            onClick={() => modalForm.submit()}
            icon={
              <COREIcon
                icon={icon({ name: "circle-check", style: "regular" })}
              />
            }
          >
            Apply
          </COREButton>
        </div>,
        <div className={"button"} key={"back-button-block"}>
          <COREButton
            key="back"
            size={"lg"}
            testID={generateTestId("emissionsmanager", "button-cancel-filter")}
            onClick={handleOnCancel}
          >
            Cancel
          </COREButton>
        </div>,
      ]}
    >
      <Row>
        <Col flex="auto">
          <COREHeading
            level={4}
            testID={generateTestId("emissionsmanager", "filter-modal-heading")}
          >
            <Space size={8}>
              <COREIcon
                icon={icon({ name: "filter", style: "regular" })}
                className={"filter-icon"}
              />
              <span>Filter options</span>
            </Space>
          </COREHeading>
        </Col>
        {allowClear && (
          <Col className={"clear-btn-col"}>
            <COREButton
              onClick={() => {
                onClear();
                closer();
              }}
              size={"sm"}
              icon={clearIcon}
            >
              Clear all
            </COREButton>
          </Col>
        )}
      </Row>
      <COREForm
        form={modalForm}
        initialValues={value}
        layout={"vertical"}
        labelSize={"lg"}
        testID={`${testID}-filter-modal-form`}
        onFinish={() => {
          handleOnChange();
          closer();
        }}
      >
        {formItems}
      </COREForm>
    </COREModal>
  );
};

const mergeFormValues = (
  modalForm: FormInstance,
  form: FormInstance,
  isModalMode: boolean
): Record<string, unknown> => {
  const modalFormValue = modalForm.getFieldsValue();
  const formValue = form.getFieldsValue();
  Object.keys(modalFormValue).forEach(
    (key) => modalFormValue[key] === undefined && delete modalFormValue[key]
  );
  Object.keys(formValue).forEach(
    (key) => formValue[key] === undefined && delete formValue[key]
  );
  if (isModalMode) {
    return modalFormValue;
  }
  return {
    ...modalFormValue,
    ...formValue,
  };
};

export const displayClearButton = (value: COREFiltersProps["value"]): boolean =>
  Object.values(value).reduce((acc: boolean, value) => {
    if (Array.isArray(value) && value.length === 0) return acc || false;
    return acc || (value !== undefined && value !== null && value !== "");
  }, false);

const calculateNumberOfRenderItem = (
  items: COREFiltersProps["items"],
  containerWidth: number,
  liteMode: COREFiltersProps["liteMode"],
  filtered: FiltersProps["filtered"],
  defaultValue: COREFiltersProps["defaultValue"],
  isChangeFromDefault?: boolean
) => {
  const dividerWidth = 26;
  const filterButtonWidth = 131;
  const clearButtonWidth = filtered ? 49 : 0;
  const restoreButtonWidth = defaultValue && isChangeFromDefault ? 41 : 0;
  const itemGap = 8;
  const widthSize = {
    xs: 64,
    sm: 128,
    md: 192,
    lg: 256,
    xl: 320,
    xxl: 384,
  };

  const itemsWidth: number[] = items.map((item) =>
    item.dividerAfter
      ? widthSize[item.widthSize] + dividerWidth
      : widthSize[item.widthSize]
  );

  for (let i = itemsWidth.length - 1; i >= 0; i--) {
    const allItemsWidth = sumArrayValues(itemsWidth, i) + (i + 1) * itemGap;
    const allItemsAndBtnWidth = liteMode
      ? allItemsWidth + clearButtonWidth + restoreButtonWidth
      : allItemsWidth;
    if (
      (i === itemsWidth.length - 1 && allItemsAndBtnWidth < containerWidth) ||
      (i < itemsWidth.length - 1 &&
        allItemsAndBtnWidth + filterButtonWidth < containerWidth)
    ) {
      return i + 1;
    }
  }
  return 1;
};

const FilterForm: React.FC<
  Pick<FiltersProps, "form" | "value" | "testID" | "handleOnChange">
> = ({ form, value, testID, handleOnChange, children }) => (
  <COREForm
    form={form}
    layout={"horizontal"}
    initialValues={value}
    testID={`${testID}-filter-form`}
    onValuesChange={() => {
      handleOnChange();
    }}
  >
    <div>{children}</div>
  </COREForm>
);

const ActionButtons: React.FC<
  Pick<FiltersProps, "testID" | "items" | "filtered" | "opener"> &
    Pick<COREFiltersProps, "liteMode" | "defaultValue"> & {
      onClear: () => void;
      isSmallScreen: boolean;
      numberOfRenderItem: number;
      onRestore: () => void;
      isChangeFromDefault?: boolean;
    }
> = ({
  testID,
  items,
  filtered,
  opener,
  onClear,
  isSmallScreen,
  numberOfRenderItem,
  liteMode,
  onRestore,
  defaultValue,
  isChangeFromDefault,
}) => {
  const paddingLeft = numberOfRenderItem === 1 ? { paddingLeft: 4 } : {};
  const isDisplayFilterBtn = numberOfRenderItem < items.length;
  const isDisplayClearBtn = filtered && liteMode;
  const isDisplayRestoreBtn = defaultValue && isChangeFromDefault && liteMode;
  const isDisplayActionBtn =
    isDisplayFilterBtn || isDisplayClearBtn || isDisplayRestoreBtn;

  const filterButton = (
    <COREButton
      type={"primary"}
      icon={
        filtered ? (
          <COREIcon icon={icon({ name: "filters", style: "solid" })} />
        ) : (
          <COREIcon icon={icon({ name: "filter", style: "regular" })} />
        )
      }
      size={"lg"}
      onClick={opener}
      testID={testID}
      block={isSmallScreen}
    >
      Filters
    </COREButton>
  );

  const clearButton = (
    <COREButton
      displayTooltip={true}
      tooltipWidth={"auto"}
      tooltipPosition={"topRight"}
      tooltipTitle={"Clear filters"}
      testID={`${testID}-clear-filter-button`}
      size={"lg"}
      type={"default"}
      icon={clearIcon}
      onClick={onClear}
    />
  );

  const restoreButton = (
    <COREButton
      displayTooltip={true}
      tooltipWidth={"auto"}
      tooltipPosition={"topRight"}
      tooltipTitle={"Restore preferences"}
      testID={`${testID}-restore-filter-button`}
      type={"default"}
      onClick={onRestore}
      size={"lg"}
      icon={<COREIcon icon={icon({ name: "redo", style: "regular" })} />}
    />
  );

  if (numberOfRenderItem === 1) {
    return (
      <div className={"button-block"}>
        {isDisplayFilterBtn && (
          <div
            className={"filter-button-block"}
            style={isSmallScreen ? { flex: "auto" } : {}}
          >
            {filterButton}
          </div>
        )}
        {isDisplayClearBtn && (
          <div className={"clear-button-block"} style={paddingLeft}>
            {clearButton}
          </div>
        )}
        {isDisplayRestoreBtn && (
          <div className={"restore-button-block"} style={paddingLeft}>
            {restoreButton}
          </div>
        )}
      </div>
    );
  }

  return isDisplayActionBtn ? (
    <Space wrap={false}>
      {isDisplayFilterBtn && (
        <div className={"filter-button-block"}>{filterButton}</div>
      )}
      {isDisplayClearBtn && (
        <div className={"clear-button-block"}>{clearButton}</div>
      )}
      {isDisplayRestoreBtn && (
        <div className={"restore-button-block"}>{restoreButton}</div>
      )}
    </Space>
  ) : null;
};

const FiltersAndActionButton: React.FC<
  FiltersProps &
    Pick<COREFiltersProps, "liteMode" | "defaultValue"> & {
      onClear: () => void;
      onRestore: () => void;
      isChangeFromDefault?: boolean;
    }
> = ({
  form,
  testID,
  handleOnChange,
  items,
  value,
  filtered,
  opener,
  containerSize,
  onClear,
  onRestore,
  liteMode,
  defaultValue,
  isChangeFromDefault,
}) => {
  if (!containerSize || items.length === 0) {
    return <></>;
  }
  const numberOfRenderItem = calculateNumberOfRenderItem(
    items,
    containerSize,
    liteMode,
    filtered,
    defaultValue,
    isChangeFromDefault
  );
  const isSmallScreen = numberOfRenderItem === 1;

  if (isSmallScreen) {
    const item = items[0];
    return (
      <FilterForm
        form={form}
        value={value}
        testID={testID}
        handleOnChange={handleOnChange}
      >
        <COREFormItem
          key={item.key}
          name={item.key}
          testID={`${testID}-filter-form-item-${item.key}`}
        >
          {React.cloneElement(item.input, {
            widthSize: "full",
          })}
        </COREFormItem>
        <ActionButtons
          testID={testID}
          filtered={filtered}
          opener={opener}
          onClear={onClear}
          isSmallScreen={true}
          numberOfRenderItem={numberOfRenderItem}
          items={items}
          liteMode={liteMode}
          onRestore={onRestore}
          defaultValue={defaultValue}
          isChangeFromDefault={isChangeFromDefault}
        />
      </FilterForm>
    );
  }
  return (
    <FilterForm
      form={form}
      value={value}
      testID={testID}
      handleOnChange={handleOnChange}
    >
      {items.slice(0, numberOfRenderItem).map((item) => (
        <div key={item.key} className={"filter-block"}>
          <div className={"form-item"}>
            <COREFormItem
              key={item.key}
              name={item.key}
              testID={`${testID}-filter-form-item-${item.key}`}
            >
              {React.cloneElement(item.input, {
                widthSize: item.widthSize,
              })}
            </COREFormItem>
          </div>
          {item.dividerAfter && numberOfRenderItem > 1 && (
            <div className={"divider"}>
              <COREDivider space={"xs"} height={"28px"} type={"vertical"} />
            </div>
          )}
        </div>
      ))}
      <ActionButtons
        testID={testID}
        filtered={filtered}
        opener={opener}
        onClear={onClear}
        isSmallScreen={false}
        numberOfRenderItem={numberOfRenderItem}
        items={items}
        liteMode={liteMode}
        onRestore={onRestore}
        defaultValue={defaultValue}
        isChangeFromDefault={isChangeFromDefault}
      />
    </FilterForm>
  );
};

export const COREFilters: React.FC<COREFiltersProps> = ({
  testID,
  items,
  modalTitle,
  filterHeader,
  defaultValue,
  value,
  onChange = () => {},
  allowClear = true,
  debounceTime = defaultDebounceTime,
  liteMode = false,
  onCancelModal,
}) => {
  const [form] = Form.useForm<Record<string, unknown>>();
  const [modalForm] = Form.useForm<Record<string, unknown>>();
  const { opener, closer, isOpen } = useOpenClose(false);
  const [containerSize, setContainerSize] =
    useState<SetSizeObject>(defaultResizeValue);
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const isDisplayClearButton = allowClear && displayClearButton(value);

  useEffect(() => {
    form.setFieldsValue(value);
    modalForm.setFieldsValue(value);
  }, [form, modalForm, value]);

  const handleOnChange = (isModal: boolean) => {
    const itemValues = mergeFormValues(modalForm, form, isModal);
    form.setFieldsValue(itemValues);
    modalForm.setFieldsValue(itemValues);
    debounce(debounceRef, () => onChange(itemValues), debounceTime);
  };
  const handleOnClear = () => {
    const fields = Object.keys(modalForm.getFieldsValue());
    const emptyValues: Record<string, unknown> = fields.reduce(
      (acc: Record<string, unknown>, field) => {
        acc[field] = undefined;
        return acc;
      },
      {}
    );
    form.setFieldsValue(emptyValues);
    modalForm.setFieldsValue(emptyValues);
    handleOnChange(false);
  };

  const isChangeFromDefault =
    defaultValue && JSON.stringify(defaultValue) !== JSON.stringify(value);
  const handleOnRestore = () => {
    if (!defaultValue) return;
    form.setFieldsValue(defaultValue);
    modalForm.setFieldsValue(defaultValue);
    onChange(defaultValue);
  };

  return (
    <div className={"core-filters"}>
      <OnResize onResize={setContainerSize}>
        {!liteMode && (
          <Row align={"middle"} className={"filter-title"}>
            <Col>
              <Space wrap size={[24, 12]}>
                <COREHeading
                  marginBottom={false}
                  level={4}
                  testID={`${testID}-filter-header`}
                >
                  <Space size={8}>
                    <COREIcon
                      icon={icon({ name: "filter", style: "regular" })}
                      size={"sm"}
                      className={"filter-icon"}
                    />
                    {filterHeader}
                  </Space>
                </COREHeading>
                {isDisplayClearButton && (
                  <COREButton
                    onClick={handleOnClear}
                    size={"sm"}
                    icon={
                      <COREIcon
                        icon={icon({ name: "circle-xmark", style: "regular" })}
                      />
                    }
                  >
                    Clear all
                  </COREButton>
                )}
                {defaultValue && isChangeFromDefault && (
                  <COREButton
                    type={"text"}
                    onClick={handleOnRestore}
                    size={"sm"}
                    icon={
                      <COREIcon icon={icon({ name: "redo", style: "solid" })} />
                    }
                  >
                    Restore preferences
                  </COREButton>
                )}
              </Space>
            </Col>
          </Row>
        )}
        <FiltersAndActionButton
          form={form}
          testID={testID}
          items={items}
          handleOnChange={() => handleOnChange(false)}
          opener={opener}
          defaultValue={defaultValue}
          isChangeFromDefault={isChangeFromDefault}
          value={value}
          filtered={isDisplayClearButton}
          containerSize={containerSize?.width}
          onClear={handleOnClear}
          onRestore={handleOnRestore}
          liteMode={liteMode}
        />
        <FilterModal
          value={value}
          modalForm={modalForm}
          onClear={handleOnClear}
          handleOnChange={() => handleOnChange(true)}
          testID={testID}
          items={items}
          modalTitle={modalTitle}
          isOpen={isOpen}
          closer={closer}
          allowClear={allowClear}
          onCancelModal={onCancelModal}
        />
      </OnResize>
    </div>
  );
};
