import React from "react";
import moment from "moment";
import { useAPIQueryRequest } from "../../../../shared/customHoooks/useAPI";
import { COREHeading } from "../../../../COREDesignSystem/Typography/COREHeading";
import "./LivePrice.less";

export const NemMarketTime = () => {
  const {
    sync: syncAPI,
    data: {
      data: { settlementdate: settlementdateAPI } = { settlementdate: null },
    },
  } = useAPIQueryRequest("livePrices");

  const apiDate = syncAPI ? settlementdateAPI : null;
  return (
    <React.Fragment>
      <COREHeading level={3} marginBottom={false}>
        NEM Market time {apiDate && moment(apiDate).format("HH:mm")}
      </COREHeading>
    </React.Fragment>
  );
};
