import React, { ReactElement, useEffect, useState } from "react";
import { Input, Popover, Tag, Badge } from "antd";
import "./CORETreeSelect.less";
import { FilterOutlined } from "@ant-design/icons";
import { COREButton } from "../Action/COREButton";

const defaultValuesRenderer = (values): Function =>
  values.map((value, i) => (
    <Tag key={`tag:${value}`}>
      <span>{value}</span>
    </Tag>
  ));

export const CORETreeSelect = React.forwardRef(
  (
    {
      children: ChildTree,
      onChange: parentOnChange,
      valuesRenderer = defaultValuesRenderer,
      forceVisiblePopOver = false,
    }: {
      children: JSX.Element;
      onChange: Function;
      valuesRenderer: Function;
      forceVisiblePopOver?: boolean;
    },
    ref: React.ForwardedRef<unknown>
  ) => {
    const {
      value,
      initialValue,
    }: {
      value?: number[];
      initialValue?: number[];
    } = ChildTree.props;

    const [rootValues, setRootValues] = useState<number[] | undefined>(
      ChildTree.props.initialValue
    );

    useEffect(() => {
      setRootValues(value ?? initialValue);
    }, [value, initialValue]);

    const onChange = (...args) => {
      const rootValues: number[] = args[0];
      setRootValues(rootValues);
      if (parentOnChange && parentOnChange instanceof Function) {
        parentOnChange(...args);
      }
    };

    const Tree: JSX.Element = React.cloneElement(
      ChildTree as React.ReactElement<ReactElement>,
      {
        onChange: onChange,
        ...ChildTree.props,
      }
    );

    const forceVisiblePopOverConfig: { visible?: boolean } = forceVisiblePopOver
      ? { visible: true }
      : {};

    return (
      <Popover
        placement={"bottomLeft"}
        content={Tree}
        overlayClassName={"tradable-product-tree-popover"}
        ref={ref}
        {...forceVisiblePopOverConfig}
      >
        <Input.Group className="core-tree-input-group">
          {rootValues &&
          rootValues.length !== 0 &&
          !ChildTree.props.filterMode ? (
            <div>{valuesRenderer(rootValues)}</div>
          ) : ChildTree.props.filterMode ? (
            <Badge
              count={rootValues && rootValues.length ? rootValues.length : 0}
            >
              <COREButton type={"primary"}>
                <FilterOutlined />
              </COREButton>
            </Badge>
          ) : (
            <COREButton type={"primary"}>
              {ChildTree.props.placeholder || "Select"}
            </COREButton>
          )}
        </Input.Group>
      </Popover>
    );
  }
);
