import React from "react";
import { useUserLoggedInStatus, useUserRole } from "../../../shared/state/user";

import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { PageLayout } from "./PageLayout";
import { Empty } from "antd";
import { useAPIQuery } from "../../../shared/customHoooks/useAPITypes";
import { uniqueByKey } from "../../../shared/global";
import { pathToFeature } from "../../../shared/customHoooks/useFeatureFlags";
import { UserAccessibleSections } from "../../../COREDesignSystem/Navigation/COREMainMenuBlock";
import {
  CURRENT_VERSION,
  fetchVersionFromIndexHtml,
  isVersionValid,
  useCheckFEVersionHasChanged,
} from "../../../shared/useCheckFEVersionHasChanged";
import { usePageChange } from "../../../shared/usePageChange";

export type DashboardLayoutRouteProps = Pick<
  RouteProps,
  "path" | "exact" | "component"
>;
const noPermissionMessage = "No permission to view this page.";
const redirectToLogin = (pathname: string) => {
  const to =
    Boolean(pathname) && pathname !== "/"
      ? `/login?next=${pathname}`
      : "/login";
  return <Redirect to={to} />;
};

export const DashboardLayoutRoute: React.FC<DashboardLayoutRouteProps> = ({
  component,
  path,
  exact,
}) => {
  const location = useLocation();
  const isPendingRole = useUserRole() === "pending";
  const isLoggedIn = useUserLoggedInStatus();
  const {
    sync,
    loading,
    error,
    data: userAccessibleSections,
  } = useAPIQuery<UserAccessibleSections[]>("userAccessibleSections", {});

  const hasETAGChanged = useCheckFEVersionHasChanged();

  usePageChange(async () => {
    if (hasETAGChanged) {
      window.location.reload();
      return;
    }
    const version = await fetchVersionFromIndexHtml();
    if (
      version !== CURRENT_VERSION &&
      isVersionValid(CURRENT_VERSION) &&
      isVersionValid(version)
    ) {
      // eslint-disable-next-line no-alert
      // alert("usePageChange2");
      window.location.reload();
    }
  });
  const { pathname } = location;

  if (!isLoggedIn) {
    return redirectToLogin(pathname);
  }

  if (isPendingRole) {
    return (
      <PageLayout>
        <COREError
          title={
            "No permission to view this page. This account is still pending."
          }
        />
      </PageLayout>
    );
  }

  const sections =
    userAccessibleSections &&
    uniqueByKey(userAccessibleSections, "section").map(
      (u) => `/${pathToFeature(u.callToActionUrl)[0]}`
    );
  const ignorePages = [
    "/website-terms-of-use",
    "/privacy-policy",
    "/terms-and-conditions",
    "/my-details",
    "/help",
    "/wholesale-inventory/registry-holdings", //TO-DO remove when feature flag is ready
    ...(sections ?? []),
  ];
  const hasPermission =
    userAccessibleSections?.filter(
      (u) =>
        (path && path.includes(u.callToActionUrl)) ||
        new RegExp(`${u.featureFlag}/.*`).test(path as string)
    ).length !== 0 ||
    ignorePages.includes(path as string) ||
    path?.includes("/dashboard");

  switch (true) {
    case loading && !sync && !error:
      return <CORELoading size={"lg"} />;
    case !!error:
      return (
        <COREError
          title={`Error loading feature list. ${
            error &&
            typeof error === "object" &&
            error.hasOwnProperty("message")
              ? error.message
              : ""
          }`}
        />
      );
    case !sync:
      return (
        <PageLayout>
          <Empty />
        </PageLayout>
      );
    case hasPermission:
      return <Route path={path} component={component} exact={exact} />;
    default:
      return (
        <PageLayout>
          <COREError title={noPermissionMessage} />
        </PageLayout>
      );
  }
};
