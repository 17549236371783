import React from "react";
import { DefaultOptionType } from "antd/lib/select";
import { uniqueByKey } from "../../shared/global";
import { ENDPOINTS } from "../../shared/state/rest";
import { CORELoading, CORELoadingProps } from "../Feedback/CORELoading";
import { COREError } from "../Content/COREError";
import { CORESelect, CORESelectProps } from "./CORESelect";
import {
  useAPIQuery,
  useAPIQueryWithPlaceHolder,
} from "../../shared/customHoooks/useAPI";

export type Endpoint = keyof typeof ENDPOINTS;

export type CORESelectAPIProps<
  T extends object,
  Input extends object = T
> = Omit<CORESelectProps, "options"> & {
  callback?: (data: T[]) => void;
  endpoint: Endpoint;
  params?: Input;
  transform: (arg: T[]) => CORESelectProps["options"];
  skip?: boolean;
  loadingLayout?: CORELoadingProps["layout"];
  loadingSize?: CORELoadingProps["size"];
};

export const groupOptions = <T extends object>(
  data: T[],
  label: DefaultOptionType["label"],
  value: DefaultOptionType["value"],
  key?: string
) => {
  const unique: T[] = key ? uniqueByKey(data, key) : data;

  return (
    unique?.map((item: T): DefaultOptionType => {
      return {
        label: item[label as keyof typeof label],
        value: item[value as keyof typeof value],
      };
    }) || []
  );
};

export const CORESelectWithPlaceHolderAPI = <T extends object>({
  endpoint,
  params,
  transform,
  callback,
  loadingLayout = "horizontal",
  ...props
}: CORESelectAPIProps<T>) => {
  const {
    sync,
    loading,
    error,
    data: { data },
  } = useAPIQueryWithPlaceHolder(
    endpoint,
    {
      ...params,
    },
    false,
    undefined,
    callback
  );

  if (loading && !sync) return <CORELoading layout={loadingLayout} />;
  if (error) return <COREError />;

  const options = transform(data);

  return <CORESelect {...props} options={options} />;
};

export const CORESelectAPI = <T extends object, Input extends object>({
  endpoint,
  params,
  transform,
  skip,
  loadingLayout = "horizontal",
  loadingSize = "sm",
  ...props
}: CORESelectAPIProps<T, Input>) => {
  const {
    sync,
    loading,
    error,
    data: { data },
  } = useAPIQuery(
    endpoint,
    {
      ...params,
    },
    skip
  );
  if (loading && !sync)
    return <CORELoading layout={loadingLayout} size={loadingSize} />;
  if (error) return <COREError />;

  const options = !skip ? transform(data) : [];

  return <CORESelect {...props} options={options} />;
};
