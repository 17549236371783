import { Col, Row } from "antd";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import {
  COREDatePicker,
  COREDatePickerProps,
} from "../../../COREDesignSystem/Form/COREDatePicker";
import {
  COREFilters,
  FilterItems,
} from "../../../COREDesignSystem/Form/COREFilters";
import {
  CORESelect,
  CORESelectProps,
} from "../../../COREDesignSystem/Form/CORESelect";
import { momentFormat } from "../../../shared/date/DateFormatContext";
import { keysToCamelCase } from "../../../shared/global";
import { TestID } from "../../../shared/testids/testids";
import {
  DailyCurvesWithSnapshot,
  disabledDate,
  useEnsureOnDateUserCanView,
} from "../CarbonDailyCurvePageWrapper";
import { CarbonTable, CarbonTableProps } from "./CarbonTable";
import { MethodType } from "./CertificateCurve";
import { useCarbonCurve } from "./useCarbonCurve";
import { DailyCurveTableFilterType } from "../widget/SetupDailyCurveWidget";
import { DailyCurveProductType } from "../../../shared/customHoooks/useProductTypes";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { useUserIsAdmin } from "../../../shared/state/user";
import { useDeepCompareEffect } from "react-use";
import "./CarbonTable.less";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";

type CarbonTableFilter = DailyCurveTableFilterType | null | undefined;

const CurveDatePicker: React.FC<
  {
    testID: TestID;
    value?: string | null;
    publishedDateArray?: (string | undefined)[];
  } & Pick<COREDatePickerProps, "onChange" | "disabledDate">
> = ({ publishedDateArray, testID, value, ...props }) => {
  const isAdmin = useUserIsAdmin();
  return (
    <COREDatePicker
      {...(!isAdmin &&
        publishedDateArray && {
          disabledDate: (current: moment.Moment) =>
            disabledDate(current, publishedDateArray),
        })}
      testID={`${testID}-end-of-day-selector`}
      size="lg"
      widthSize="lg"
      allowClear={false}
      value={moment(value)}
      {...props}
    />
  );
};

const MethodTypeSelector: React.FC<
  {
    testID: TestID;
    methodTypes: DailyCurvesWithSnapshot["certificateProductPremiumCurves"];
  } & Pick<CORESelectProps, "onChange" | "value" | "disabled">
> = ({ testID, methodTypes, value, ...props }) => {
  return (
    <CORESelect
      widthSize="lg"
      size="lg"
      mode="multiple"
      placeholder="Select methods"
      testID={`${testID}-methods-selector`}
      options={
        methodTypes?.map((m) => {
          return {
            label: m.certificateProjectTypeName,
            value: m.certificateProjectTypeName,
          };
        }) ?? []
      }
      value={value}
      {...props}
    />
  );
};

const serverDateFormat = momentFormat.server.format;
const DailyCurveTableFilter: React.FC<{
  isWidget?: boolean;
  testID: TestID;
  tableFilters?: CarbonTableFilter;
  onFilterChange: (filter: CarbonTableFilter) => void;
  methodTypes: DailyCurvesWithSnapshot["certificateProductPremiumCurves"];
  productType?: DailyCurveProductType;
  publishedDateArray?: (string | undefined)[];
}> = ({
  isWidget = false,
  testID,
  tableFilters,
  onFilterChange,
  methodTypes,
  productType,
  publishedDateArray,
}) => {
  if (!isWidget && (!methodTypes || (methodTypes && methodTypes.length === 0)))
    return null;
  if (isWidget && !methodTypes) {
    return (
      <CurveDatePicker
        publishedDateArray={publishedDateArray}
        testID={testID}
        onChange={(v) => {
          const dateSelected = v?.isValid()
            ? v.format(serverDateFormat)
            : moment().format(serverDateFormat);
          onFilterChange({
            ...tableFilters,
            curveDate: dateSelected,
          });
        }}
        value={tableFilters?.curveDate}
      />
    );
  }
  if (!isWidget && methodTypes && methodTypes.length > 0) {
    return (
      <MethodTypeSelector
        testID={testID}
        methodTypes={methodTypes}
        value={tableFilters?.method}
        onChange={(v: string[]) => {
          onFilterChange({
            ...tableFilters,
            method: v,
          });
        }}
      />
    );
  }

  const items: FilterItems[] = [];
  if (isWidget) {
    items.push({
      label: "Curve date",
      key: "curveDate",
      widthSize: "lg",
      dividerAfter: true,
      input: (
        <CurveDatePicker
          publishedDateArray={publishedDateArray}
          testID={testID}
        />
      ),
    });
  }
  if (methodTypes && methodTypes.length > 0) {
    items.push({
      label: "Method",
      key: "method",
      widthSize: "lg",
      input: <MethodTypeSelector testID={testID} methodTypes={methodTypes} />,
    });
  }

  return (
    <COREFilters
      liteMode={true}
      allowClear={false}
      testID={testID}
      items={items}
      onChange={(v: Record<string, unknown>) => {
        const dateSelected = moment(v.curveDate as Moment).isValid()
          ? moment(v.curveDate as Moment).format(serverDateFormat)
          : moment().format(serverDateFormat);
        onFilterChange({
          ...v,
          curveDate: dateSelected,
        });
      }}
      value={{
        ...tableFilters,
        method: tableFilters?.method ?? [],
      }}
      modalTitle="Price history filters"
      filterHeader="Price history filters"
      debounceTime={0}
    />
  );
};

export const getMethodBySelectedFilter = (
  methodTypes?: MethodType[],
  tableFilters?: CarbonTableFilter
) => {
  return methodTypes?.filter((v) => {
    const method: MethodType = keysToCamelCase(v);
    const methodFilters = tableFilters?.method;

    return methodFilters?.includes(method.certificateProjectTypeName);
  });
};

export const CarbonTableForWidget: React.FC<{
  productType: DailyCurveProductType;
  testID: TestID;
  widgetFilters?: DailyCurveTableFilterType;
  onWidgetFilterChange?: (filter?: CarbonTableFilter) => void;
}> = ({ productType, testID, widgetFilters, onWidgetFilterChange }) => {
  const curveDate: Moment = moment(widgetFilters?.curveDate);
  const { loading, sync, publishedDateArray, isPublished } = useCarbonCurve({
    curveDate,
    productType: productType,
  });
  const redirectToDate = useEnsureOnDateUserCanView(
    isPublished,
    sync,
    publishedDateArray ?? []
  );

  if (loading && !sync)
    return (
      <div className={"display-loading-center"}>
        <CORELoading delay={0} layout="horizontal" />
      </div>
    );

  const newCurveDate = widgetFilters?.curveDate ?? redirectToDate;

  return (
    <CarbonTableAfterCheckPublishDate
      curveDate={moment(newCurveDate)}
      productType={productType}
      testID={testID}
      widgetFilters={widgetFilters}
      onWidgetFilterChange={onWidgetFilterChange}
    />
  );
};

const CarbonTableAfterCheckPublishDate: React.FC<{
  productType: DailyCurveProductType;
  curveDate: Moment;
  testID: TestID;
  widgetFilters?: DailyCurveTableFilterType;
  onWidgetFilterChange?: (filter?: CarbonTableFilter) => void;
}> = ({
  productType,
  curveDate,
  testID,
  widgetFilters,
  onWidgetFilterChange,
}) => {
  const { loading, sync, publishedDateArray, certificateCurves, isPublished } =
    useCarbonCurve({
      curveDate,
      productType: productType,
    });

  const certificateCurve =
    certificateCurves && certificateCurves.length !== 0
      ? certificateCurves[0]
      : null;

  if (sync && !certificateCurve) return <COREError />;
  if (!certificateCurve || (loading && !sync))
    return (
      <div className={"display-loading-center"}>
        <CORELoading delay={0} layout="horizontal" />
      </div>
    );

  const methodTypes = certificateCurve?.dailyCurves.find(
    (d) =>
      d.certificateProductPremiumCurves &&
      d.certificateProductPremiumCurves.length !== 0
  )?.certificateProductPremiumCurves;

  const hasMethods: boolean = Array.isArray(methodTypes);
  const dailyCurve = certificateCurve?.dailyCurves?.find((d) => d) || {
    hasBaseCurve: false,
  };
  const hasBaseCurve: boolean = dailyCurve?.hasBaseCurve;

  return (
    <CarbonTableWithFilter
      testID={`${testID}-table`}
      isPublished={isPublished}
      dailyCurves={certificateCurve?.dailyCurves ?? []}
      currency={certificateCurve?.currency ?? ""}
      methodTypes={methodTypes}
      hasMethods={hasMethods}
      hasBaseCurve={hasBaseCurve}
      date={curveDate}
      productType={productType}
      initFilters={widgetFilters}
      isWidget={true}
      onFilterChange={onWidgetFilterChange}
      publishedDateArray={publishedDateArray}
    />
  );
};

const gettingAllMethods = (
  methodTypes: DailyCurvesWithSnapshot["certificateProductPremiumCurves"]
): string[] => {
  return methodTypes?.map((m) => m.certificateProjectTypeName) ?? [];
};

export const CarbonTableWithFilter: React.FC<
  Omit<CarbonTableProps, "testId"> & {
    testID: TestID;
    isWidget?: boolean;
    initFilters?: CarbonTableFilter;
    onFilterChange?: (filter?: CarbonTableFilter) => void;
    publishedDateArray?: (string | undefined)[];
  }
> = ({
  dailyCurves,
  currency,
  methodTypes,
  hasMethods,
  hasBaseCurve,
  isPublished,
  date,
  testID,
  productType,
  isWidget,
  initFilters,
  onFilterChange,
  publishedDateArray,
}) => {
  const dateFormatted = date.format(serverDateFormat);
  const defaultFilter = initFilters
    ? initFilters
    : {
        curveDate: dateFormatted,
        method: gettingAllMethods(keysToCamelCase(methodTypes)),
      };

  const [tableFilters, setTableFilters] =
    useState<CarbonTableFilter>(defaultFilter);

  const onChange = (filter?: CarbonTableFilter) => {
    setTableFilters(filter);
    onFilterChange && onFilterChange(filter);
  };

  useDeepCompareEffect(() => {
    setTableFilters(defaultFilter);
  }, [productType, defaultFilter]);

  const selectedMethod = getMethodBySelectedFilter(methodTypes, tableFilters);
  const isSelectedEmpty =
    methodTypes && selectedMethod?.length === 0 && productType !== "accu";

  return (
    <Row gutter={[0, 16]}>
      {isWidget || (methodTypes && methodTypes.length > 0) ? (
        <Col span={24}>
          <DailyCurveTableFilter
            testID={testID}
            isWidget={isWidget}
            tableFilters={tableFilters}
            onFilterChange={onChange}
            methodTypes={keysToCamelCase(methodTypes)}
            productType={productType as DailyCurveProductType}
            publishedDateArray={publishedDateArray}
          />
        </Col>
      ) : null}
      <Col span={24}>
        {isSelectedEmpty ? (
          <COREEmpty
            testID={`${testID}-empty-table`}
            description="Missing method type"
            hint={`Choose a method type from the options to display the table.`}
          />
        ) : (
          <CarbonTable
            testId={`${testID}-table`}
            isPublished={isPublished}
            dailyCurves={dailyCurves}
            currency={currency}
            methodTypes={selectedMethod}
            hasMethods={hasMethods}
            hasBaseCurve={hasBaseCurve}
            date={date}
            {...(isWidget && { productType: productType })}
          />
        )}
      </Col>
    </Row>
  );
};
