import React, { useState } from "react";
import "./AemoPriceMapOverview.less";
import { InterconnectorFlows } from "./InterconnectorFlows";
import { RegionValue } from "./RegionValue";
import { WeatherBox } from "./WeatherBox";
import { Col, Popover, Row, Tooltip } from "antd";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { LivePriceChartPopup } from "./LivePriceChartPopup";
import { InterconnectPopup } from "./InterconnectPopup";
import { NumberFormat } from "../../../../shared/globals";
import {
  useLiveInterconnectors,
  useLivePrices,
} from "../../../../shared/state/websocket";
import classNames from "classnames";
/* eslint-disable sonarjs/no-duplicate-string */

export const prefetchPriceMap = [
  { queryKey: "liveInterconnectors", action: "liveInterconnectors" },
  { queryKey: "livePrices", action: "livePrices" },
];

export const PriceMap = (): JSX.Element => {
  const {
    loading: loadingP,
    sync: syncP,
    data: { data: dataP },
  } = useLivePrices();

  const {
    sync: syncI,
    data: { data: dataI },
  } = useLiveInterconnectors();

  const LiveInterData =
    syncI && dataI.interconnector ? dataI.interconnector : {};

  const [visibleSa, setVisibleSa] = useState<boolean>(false);
  const [visibleQld, setVisibleQld] = useState<boolean>(false);
  const [visibleNsw, setVisibleNsw] = useState<boolean>(false);
  const [visibleVic, setVisibleVic] = useState<boolean>(false);
  const [visibleTas, setVisibleTas] = useState<boolean>(false);

  // Interconnector
  const [visibleNQMNSP1, setVisibleNQMNSP1] = useState<boolean>(false);
  const [visibleNSW1QLD1, setVisibleNSW1QLD1] = useState<boolean>(false);
  const [visibleTVMNSP1, setVisibleTVMNSP1] = useState<boolean>(false);
  const [visibleVIC1NSW1, setVisibleVIC1NSW1] = useState<boolean>(false);
  const [visibleVSA, setVisibleVSA] = useState<boolean>(false);
  const [visibleVSMNSP1, setVisibleVSMNSP1] = useState<boolean>(false);

  if (loadingP && !syncP) return <CORELoading size={"lg"} />;
  if (!syncP) return <></>;

  const regions = dataP.region ? dataP.region : {};

  const closePopover = (): void => {
    setVisibleSa(false);
    setVisibleQld(false);
    setVisibleNsw(false);
    setVisibleVic(false);
    setVisibleTas(false);
    // Interconnector
    setVisibleNQMNSP1(false);
    setVisibleNSW1QLD1(false);
    setVisibleTVMNSP1(false);
    setVisibleVIC1NSW1(false);
    setVisibleVSA(false);
    setVisibleVSMNSP1(false);
  };

  const regionGeneration = (regions, name, type) => {
    if (regions === undefined || regions[name] === undefined) return false;
    let label: string = "";
    let title: string = "";

    switch (type) {
      case "generation":
        label = "Total Gen";
        title =
          "Dispatchable Generation\n(DispatchRegionSum.DispatchableGeneration)";
        break;
      case "semi_generation":
        label = "Semi-sched";
        title =
          "Semi-scheduled Generation\n(DispatchRegionSum.Semischedule_ClearedMW)";
        break;
      default:
        label = "Availability";
        title = "Available Generation\n(DispatchRegionSum.AvailableGeneration)";
        break;
    }

    return (
      <Tooltip title={title}>
        <div className={"region-generation"}>
          <div className={"label"}>{label}:</div>
          <div className={"val"}>
            {NumberFormat(regions[name][type]) ?? "-"} MW
          </div>
        </div>
      </Tooltip>
    );
  };

  const popoverTitle = (name): JSX.Element => {
    return (
      <>
        <Row className={"popover-title"}>
          <Col span={22}>{name}</Col>
          <Col span={2} className={"popover-icon-col"}>
            <div className={"close-icon"} onClick={() => closePopover()}>
              ×
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const demandTitle = "Scheduled Demand\n(DispatchRegionSum.ClearedSupply)";
  const priceTitle = "Dispatch price\n(DispatchPrice.RRP)";
  return (
    <>
      <div className={classNames("live-price-card", "au-qld-card")}>
        <Popover
          destroyTooltipOnHide
          placement="topLeft"
          trigger="click"
          content={
            <LivePriceChartPopup
              region={"SA1"}
              regionColor={"SA"}
              rrp={regions["SA1"].price}
              demand={regions["SA1"].demand}
              date={regions.settlementdate}
            />
          }
          title={popoverTitle("SA")}
          visible={visibleSa}
          onVisibleChange={(v) => setVisibleSa(v)}
        >
          <div className={"live-price-inner"}>
            <Tooltip title={priceTitle}>
              <div className={"price-block"}>
                {/* Price */}
                <RegionValue
                  regions={regions}
                  name={"SA1"}
                  prefix={"$"}
                  type={"price"}
                />
              </div>
            </Tooltip>
            <div className={"label-block"}>
              {regionGeneration(regions, "SA1", "generation")}
              {regionGeneration(regions, "SA1", "semi_generation")}
              {regionGeneration(regions, "SA1", "availability")}
            </div>
            <Tooltip title={demandTitle}>
              <div className={"mw-block"}>
                {/* Demand */}
                <RegionValue
                  regions={regions}
                  name={"SA1"}
                  suffix={"MW"}
                  type={"demand"}
                />
              </div>
            </Tooltip>
            <div className={"region-block"}>SA</div>
            <div className={"temperature-block"}>
              {/* Weather SA */}
              <WeatherBox regions={regions} name={"SA1"} />
            </div>
            <div className={"arrow-btn"} />
          </div>
        </Popover>

        <div className={"graph-arrow-section"}>
          <Popover
            destroyTooltipOnHide
            placement="topLeft"
            trigger="click"
            content={<InterconnectPopup interconnector={"V-S-MNSP1"} />}
            title={popoverTitle("V-S-MNSP1")}
            visible={visibleVSMNSP1}
            onVisibleChange={(v) => setVisibleVSMNSP1(v)}
          >
            <div className={"graph-arrow-vertical-item"}>
              <InterconnectorFlows data={LiveInterData} name={"V-S-MNSP1"} />
            </div>
          </Popover>

          <Popover
            destroyTooltipOnHide
            placement="topLeft"
            trigger="click"
            content={<InterconnectPopup interconnector={"V-SA"} />}
            title={popoverTitle("V-SA")}
            visible={visibleVSA}
            onVisibleChange={(v) => setVisibleVSA(v)}
          >
            <div className={"graph-arrow-vertical-item"}>
              <InterconnectorFlows data={LiveInterData} name={"V-SA"} />
            </div>
          </Popover>
        </div>
      </div>

      <div className={"card-vertical-col"}>
        <div className={classNames("live-price-card", "au-qld-2-card")}>
          <Popover
            destroyTooltipOnHide
            placement="topLeft"
            trigger="click"
            content={
              <LivePriceChartPopup
                region={"QLD1"}
                regionColor={"QLD"}
                rrp={regions["QLD1"].price}
                demand={regions["QLD1"].demand}
                date={regions.settlementdate}
              />
            }
            title={popoverTitle("QLD")}
            visible={visibleQld}
            onVisibleChange={(v) => setVisibleQld(v)}
          >
            <div className={"live-price-inner"}>
              <Tooltip title={priceTitle}>
                <div className={"price-block"}>
                  <RegionValue
                    regions={regions}
                    name={"QLD1"}
                    prefix={"$"}
                    type={"price"}
                  />
                </div>
              </Tooltip>
              <div className={"label-block"}>
                {regionGeneration(regions, "QLD1", "generation")}
                {regionGeneration(regions, "QLD1", "semi_generation")}
                {regionGeneration(regions, "QLD1", "availability")}
              </div>
              <Tooltip title={demandTitle}>
                <div className={"mw-block"}>
                  <RegionValue
                    regions={regions}
                    name={"QLD1"}
                    suffix={"MW"}
                    type={"demand"}
                  />
                </div>
              </Tooltip>
              <div className={"region-block"}>QLD</div>
              <div className={"temperature-block"}>
                <WeatherBox regions={regions} name={"QLD1"} />
              </div>
              <div className={"arrow-btn"} />
            </div>
          </Popover>

          <div className={"graph-arrow-section"}>
            <Popover
              destroyTooltipOnHide
              placement="topLeft"
              trigger="click"
              content={<InterconnectPopup interconnector={"NSW1-QLD1"} />}
              title={popoverTitle("NSW1-QLD1")}
              visible={visibleNSW1QLD1}
              onVisibleChange={(v) => setVisibleNSW1QLD1(v)}
            >
              <div className={"graph-arrow-item"}>
                <InterconnectorFlows data={LiveInterData} name={"NSW1-QLD1"} />
              </div>
            </Popover>

            <Popover
              destroyTooltipOnHide
              placement="topLeft"
              trigger="click"
              content={<InterconnectPopup interconnector={"N-Q-MNSP1"} />}
              title={popoverTitle("N-Q-MNSP1")}
              visible={visibleNQMNSP1}
              onVisibleChange={(v) => setVisibleNQMNSP1(v)}
            >
              <div className={"graph-arrow-item"}>
                <InterconnectorFlows data={LiveInterData} name={"N-Q-MNSP1"} />
              </div>
            </Popover>
          </div>
        </div>

        <div className={classNames("live-price-card", "au-nsw-card")}>
          <Popover
            destroyTooltipOnHide
            placement="topLeft"
            trigger="click"
            content={
              <LivePriceChartPopup
                region={"NSW1"}
                regionColor={"NEW"}
                rrp={regions["NSW1"].price}
                demand={regions["NSW1"].demand}
                date={regions.settlementdate}
              />
            }
            title={popoverTitle("NSW")}
            visible={visibleNsw}
            onVisibleChange={(v) => setVisibleNsw(v)}
          >
            <div className={"live-price-inner"}>
              <Tooltip title={priceTitle}>
                <div className={"price-block"}>
                  <RegionValue
                    regions={regions}
                    name={"NSW1"}
                    prefix={"$"}
                    type={"price"}
                  />
                </div>
              </Tooltip>
              <div className={"label-block"}>
                {regionGeneration(regions, "NSW1", "generation")}
                {regionGeneration(regions, "NSW1", "semi_generation")}
                {regionGeneration(regions, "NSW1", "availability")}
              </div>
              <Tooltip title={demandTitle}>
                <div className={"mw-block"}>
                  {/*Demand*/}
                  <RegionValue
                    regions={regions}
                    name={"NSW1"}
                    suffix={"MW"}
                    type={"demand"}
                  />
                </div>
              </Tooltip>
              <div className={"region-block"}>NSW</div>
              <div className={"temperature-block"}>
                <WeatherBox regions={regions} name={"NSW1"} />
              </div>
              <div className={"arrow-btn"} />
            </div>
          </Popover>

          <div className={"graph-arrow-section"}>
            <Popover
              destroyTooltipOnHide
              placement="topLeft"
              trigger="click"
              content={<InterconnectPopup interconnector={"VIC1-NSW1"} />}
              title={popoverTitle("VIC1-NSW1")}
              visible={visibleVIC1NSW1}
              onVisibleChange={(v) => setVisibleVIC1NSW1(v)}
            >
              <div className={"graph-arrow-item"}>
                <InterconnectorFlows data={LiveInterData} name={"VIC1-NSW1"} />
              </div>
            </Popover>
          </div>
        </div>

        <div className={`${"live-price-card"} ${"au-vic-card"}`}>
          <Popover
            destroyTooltipOnHide
            placement="topLeft"
            trigger="click"
            content={
              <LivePriceChartPopup
                region={"VIC1"}
                regionColor={"VIC"}
                rrp={regions["VIC1"].price}
                demand={regions["VIC1"].demand}
                date={regions.settlementdate}
              />
            }
            title={popoverTitle("VIC")}
            visible={visibleVic}
            onVisibleChange={(v) => setVisibleVic(v)}
          >
            <div className={"live-price-inner"}>
              <Tooltip title={priceTitle}>
                <div className={"price-block"}>
                  <RegionValue
                    regions={regions}
                    name={"VIC1"}
                    prefix={"$"}
                    type={"price"}
                  />
                </div>
              </Tooltip>
              <div className={"label-block"}>
                {regionGeneration(regions, "VIC1", "generation")}
                {regionGeneration(regions, "VIC1", "semi_generation")}
                {regionGeneration(regions, "VIC1", "availability")}
              </div>
              <Tooltip title={demandTitle}>
                <div className={"mw-block"}>
                  <RegionValue
                    regions={regions}
                    name={"VIC1"}
                    suffix={"MW"}
                    type={"demand"}
                  />
                </div>
              </Tooltip>
              <div className={"region-block"}>VIC</div>
              <div className={"temperature-block"}>
                <WeatherBox regions={regions} name={"VIC1"} />
              </div>
              <div className={"arrow-btn"} />
            </div>
          </Popover>

          <div className={"graph-arrow-section"}>
            <Popover
              destroyTooltipOnHide
              placement="topLeft"
              trigger="click"
              content={<InterconnectPopup interconnector={"T-V-MNSP1"} />}
              title={popoverTitle("T-V-MNSP1")}
              visible={visibleTVMNSP1}
              onVisibleChange={(v) => setVisibleTVMNSP1(v)}
            >
              <div className={"graph-arrow-item"}>
                <InterconnectorFlows data={LiveInterData} name={"T-V-MNSP1"} />
              </div>
            </Popover>
          </div>
        </div>

        <Popover
          destroyTooltipOnHide
          placement="topLeft"
          trigger="click"
          content={
            <LivePriceChartPopup
              region={"TAS1"}
              regionColor={"TAS"}
              rrp={regions["TAS1"].price}
              demand={regions["TAS1"].demand}
              date={regions.settlementdate}
            />
          }
          title={popoverTitle("TAS")}
          visible={visibleTas}
          onVisibleChange={(v) => setVisibleTas(v)}
        >
          <div className={classNames("live-price-card", "au-tas-card")}>
            <Tooltip title={priceTitle}>
              <div className={"price-block"}>
                {/*TAS -> Price*/}
                <RegionValue
                  regions={regions}
                  name={"TAS1"}
                  prefix={"$"}
                  type={"price"}
                />
              </div>
            </Tooltip>
            <div className={"label-block"}>
              {regionGeneration(regions, "TAS1", "generation")}
              {regionGeneration(regions, "TAS1", "semi_generation")}
              {regionGeneration(regions, "TAS1", "availability")}
            </div>
            <Tooltip title={demandTitle}>
              <div className={"mw-block"}>
                {/*TAS ->Generation*/}
                <RegionValue
                  regions={regions}
                  name={"TAS1"}
                  suffix={"MW"}
                  type={"demand"}
                />
              </div>
            </Tooltip>
            <div className={"region-block"}>TAS</div>
            <div className={"temperature-block"}>
              <WeatherBox regions={regions} name={"TAS1"} />
            </div>
            <div className={"arrow-btn"} />
          </div>
        </Popover>
      </div>
    </>
  );
};
