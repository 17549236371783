import React from "react";
import { PriceMap } from "./PriceMap";
import "./AemoPriceMapOverview.less";
import LivePriceMapSVG from "../../../../assets/img/live-price-map-cut.svg";
import { NemMarketTime } from "./nemMarketTime.js";
import { Col, Row } from "antd";
import { COREDivider } from "../../../../COREDesignSystem/Layout/COREDivider";

export function AemoPriceMapOverview() {
  return (
    <Row className={"widget-padding"}>
      <Col span={24}>
        <NemMarketTime />
      </Col>
      <COREDivider space={"md"} type={"horizontal"}></COREDivider>
      <Col span={24}>
        <div className={"live-price-map-section"}>
          <div className={"live-price-map"}>
            <img src={LivePriceMapSVG} alt="Live NEM" />
          </div>

          <PriceMap />
        </div>
      </Col>
    </Row>
  );
}
