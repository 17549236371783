import {
  useUserCompanyId,
  useUserLoggedInStatus,
  useUserRole,
} from "../state/user";
import { hasDownloadFeatureForTrees } from "../../modules/tools/chartBuilder/AggregatesChart";
import { ChartSelect } from "../../modules/tools/chartBuilder/useChartBuilderApiV2";
import { useCompanies } from "./useCompanies";
import { UserScopes } from "../../openapi-typescript/common/user_scopes";
import { CompanyFeatures } from "../../openapi-typescript/common/company_features";

type NoFeatureFlag = "noFeatureFlagRequired";
export type FeatureFlag = UserScopes | CompanyFeatures["feature"];

type Link = string;
export interface Page {
  link: Link;
  feature: FeatureFlag | NoFeatureFlag;
  name: string;
  icon: string;
  child?: Page[];
}

export const useDownloadCsvButtonFeature = (
  allSelections: ChartSelect[] | undefined
) => {
  const { sync: featuresSync, features: featureList } = useFeatureFlags();
  const trees = allSelections?.map(
    ({ tree: treeName, selection: { variable } }) => ({
      treeName,
      variable,
    })
  );
  return featuresSync && allSelections
    ? hasDownloadFeatureForTrees(trees, featureList)
    : false;
};

export const pathToFeature = (path: string) => path.replace("/", "").split("/");

export const useFeatureFlags = (): {
  loading: boolean;
  sync: boolean;
  error?: Error | false;
  features: FeatureFlag[];
} => {
  const isLoggedIn = useUserLoggedInStatus();
  const isPendingRole = useUserRole() === "pending";
  const companyId = useUserCompanyId();

  const { loading, error, sync, updatable } = useCompanies(companyId);

  if (!isLoggedIn || updatable[0]?.data === undefined || isPendingRole) {
    return { sync: false, loading: false, features: [] };
  }

  const scopes = updatable[0]?.data.scopes.map((scope) => {
    return scope.scope as FeatureFlag;
  });

  const features = updatable[0]?.data.features.map((feature) => {
    return feature.feature as FeatureFlag;
  });

  const data = [...features, ...scopes];

  return {
    error: error,
    loading: loading,
    sync: sync,
    features: data,
  };
};
