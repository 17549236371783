import React from "react";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { generateTestId } from "../../../../shared/testids/testids";
import {
  DailyCurveTableFilterType,
  SetupDailyCurveWidget,
  useMenuGroupOfProducts,
} from "../../../dailyCurves/widget/SetupDailyCurveWidget";
import {
  currentWidgetFilters,
  WidgetPanel,
  WidgetProps,
} from "../../WidgetPanel";
import { DailyCurveTableByProductType } from "../../../dailyCurves/widget/DailyCurveTableByProductType";
import {
  DailyCurveProductType,
  getProductTypePermission,
  useDailyCurveFeaturePermissions,
} from "../../../../shared/customHoooks/useProductTypes";
import { useFeatureFlags } from "../../../../shared/customHoooks/useFeatureFlags";

type DailyCurveTableWidgetProps<T, WidgetFilterType extends object> = {
  props: WidgetProps<T, WidgetFilterType>;
};

export const DailyCurveTableWidget = ({
  props: widgetProps,
}: DailyCurveTableWidgetProps<
  DailyCurveProductType,
  DailyCurveTableFilterType
>) => {
  const { sync, loading, features } = useFeatureFlags();
  const testID = generateTestId("carbondailycurve", `carbon-table-widget`);
  const currentProps = currentWidgetFilters(widgetProps);
  const currentWidgetType = currentProps?.widgetType;
  const interactionsMenu = useMenuGroupOfProducts(widgetProps);

  const featurePermission = useDailyCurveFeaturePermissions(currentWidgetType);
  const productPermission = getProductTypePermission(
    features,
    currentWidgetType
  );

  const hasPermission = featurePermission ? productPermission : false;

  if (loading && !sync) return <CORELoading delay={0} />;

  return (
    <WidgetPanel
      interactionsMenu={interactionsMenu}
      testID={"dailycurve-table-widgets"}
      panelTitle={
        currentWidgetType
          ? `Daily curve - ${currentWidgetType.toUpperCase()}`
          : "Daily curve - widget setup"
      }
    >
      {!hasPermission ? (
        <COREEmpty
          testID={`${testID}-empty-content`}
          description="Missing permissions"
          hint={`You don’t have access to this content.`}
        />
      ) : currentWidgetType ? (
        <DailyCurveTableByProductType
          widgetProps={widgetProps}
          testID={testID}
          widgetType={currentWidgetType}
        />
      ) : (
        <SetupDailyCurveWidget testID={testID} widgetProps={widgetProps} />
      )}
    </WidgetPanel>
  );
};
