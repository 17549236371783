import React from "react";
import { TestID } from "../../shared/testids/testids";
import { CheckboxProps, Radio, RadioGroupProps } from "antd";
import "./CORERadioButton.less";
import { Merge } from "../../shared/TypeScriptHelpers";

export type RadioButtonGroupProps = Merge<
  RadioGroupProps,
  {
    testID: TestID;
    radioBtnValues: RadioBtnValueType[];
    tabIndex?: number;
  }
>;

type RadioButtonType = Merge<
  CheckboxProps,
  {
    testID: TestID;
  }
>;

export type RadioBtnValueType = Merge<
  CheckboxProps,
  {
    name: string;
    value: string | number | boolean;
    testID: TestID;
  }
>;

export const CORERadioButtonBasic: React.FC<RadioButtonType> = ({
  testID,
  name,
  ...props
}) => {
  return (
    <Radio data-testid={testID} {...props}>
      {name}
    </Radio>
  );
};

export const CORERadiobuttonGroup: React.FC<RadioButtonGroupProps> = ({
  radioBtnValues,
  testID,
  optionType,
  tabIndex,
  ...otherProps
}) => {
  return (
    <div data-testid={testID} className={"radio-button"}>
      <Radio.Group {...otherProps}>
        {radioBtnValues.map(({ name, value, ...props }) => {
          if (optionType === "button") {
            return (
              <Radio.Button
                key={`radio-${value}`}
                value={value}
                tabIndex={tabIndex}
                {...props}
              >
                {name}
              </Radio.Button>
            );
          }

          return (
            <CORERadioButtonBasic
              key={`radio-${value}`}
              name={name}
              value={value}
              tabIndex={tabIndex}
              {...props}
            />
          );
        })}
      </Radio.Group>
    </div>
  );
};
