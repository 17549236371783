import React from "react";
import { Col, Row } from "antd";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TestID } from "../../../shared/testids/testids";
import { useOpenClose } from "../../../shared/global";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { COREInput } from "../../../COREDesignSystem/Form/COREInput";
import { TradeTickerFilterModal } from "./TradeTickerFilterModal";

const { Search } = COREInput;

export type TradeTickerFilterType = {
  search?: string;
  onlyCurrent?: boolean;
  productType?: number[];
  showAll?: boolean;
};

export const TradeTickerFilter: React.FC<{
  tradeTickerFilter: TradeTickerFilterType;
  testID: TestID;
  onChangeFilter: (selectedFilter: TradeTickerFilterType) => void;
}> = ({ tradeTickerFilter, testID, onChangeFilter }) => {
  const { opener, closer, isOpen } = useOpenClose(false);
  const displayClearButton: boolean =
    !!tradeTickerFilter.search || !!tradeTickerFilter.productType;

  const handleOnClear = () => {
    onChangeFilter({});
  };

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Search
          placeholder={"Search trades"}
          testID={testID}
          size={"lg"}
          widthSize={"full"}
          value={tradeTickerFilter.search}
          onChange={(value) => {
            onChangeFilter({
              ...tradeTickerFilter,
              search: value.target.value,
            });
          }}
        />
      </Col>

      <Col span={24}>
        <Row wrap={false} gutter={[8, 8]}>
          <Col flex={"auto"}>
            <COREButton
              type={"primary"}
              icon={
                displayClearButton ? (
                  <COREIcon icon={icon({ name: "filters", style: "solid" })} />
                ) : (
                  <COREIcon icon={icon({ name: "filter", style: "regular" })} />
                )
              }
              size={"md"}
              onClick={opener}
              testID={`${testID}-open-filter-modal-button`}
              block={true}
            >
              Filters
            </COREButton>
          </Col>

          {displayClearButton && (
            <Col flex={"none"}>
              <COREButton
                displayTooltip={true}
                tooltipWidth={"auto"}
                tooltipPosition={"topRight"}
                tooltipTitle={"Clear filters"}
                testID={`${testID}-clear-filter-button`}
                size={"md"}
                type={"default"}
                icon={
                  <COREIcon
                    icon={icon({ name: "circle-xmark", style: "regular" })}
                  />
                }
                onClick={handleOnClear}
              />
            </Col>
          )}
        </Row>
      </Col>
      <TradeTickerFilterModal
        testID={testID}
        isOpen={isOpen}
        closer={closer}
        tradeTickerFilter={tradeTickerFilter}
        onChangeFilter={onChangeFilter}
      />
    </Row>
  );
};
