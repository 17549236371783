import { Space } from "antd";
import { COREBody } from "../Typography/COREBody";
import "./COREFlag.less";
import React from "react";

export type COREFlagType = {
  type: "indicative" | "firm" | "strip";
};
export type COREFlagNode = {
  gap?: number;
  children?: React.ReactNode;
};

export const COREIndicative: React.FC<COREFlagNode> = ({
  children,
  gap = 0,
}) => {
  const className = "row-price-indicative-colour";
  return (
    <div className={"core-flag-badge"} style={{ gap: gap }}>
      <COREBody
        type={"p2"}
        marginBottom={false}
        italic={true}
        className={className}
      >
        <Space direction={"horizontal"} size={gap}>
          <COREBody
            type={"p2"}
            marginBottom={false}
            italic={true}
            className={className}
          >
            {children}
          </COREBody>
          <COREFlag type={"indicative"} />
        </Space>
      </COREBody>
    </div>
  );
};

export const COREStrip: React.FC<COREFlagNode> = ({ children, gap = 0 }) => {
  return (
    <div className={"core-flag-badge"} style={{ gap: gap }}>
      <Space direction={"horizontal"} size={gap}>
        <span>{children}</span>
        <COREFlag type={"strip"} />
      </Space>
    </div>
  );
};

export const COREFlag: React.FC<COREFlagType> = ({ type }) => {
  if (!type || type === "firm") {
    return null;
  }

  return <span className={`indicator-${type}-flag`} />;
};
