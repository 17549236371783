import React from "react";
import {
  COREFilters,
  COREFiltersProps,
  FilterItems,
} from "../../../../COREDesignSystem/Form/COREFilters";
import { generateTestId } from "../../../../shared/testids/testids";
import {
  CORERangePickerV2,
  RelativeRangeValue,
} from "../../../../COREDesignSystem/Form/CORERangePicker";
import { InsightTypeSelect } from "../../../../shared/select/InsightTypeSelect";
import { InsightCategorySelect } from "../../../../shared/select/InsightCategorySelect";
import moment from "moment";
import { Insights } from "../../../../openapi-typescript/common/insights";
import { Order } from "../../../../shared/customHoooks/useUpdatable";

export type InsightsFilterValue = Partial<{
  publishedDate?: RelativeRangeValue;
  sortOrder: Order;
  type: Insights["fieldData"]["type"];
  categories: Insights["fieldData"]["categories"];
}>;

export const InsightsFilters: React.FC<
  Pick<COREFiltersProps, "onChange"> & { value: InsightsFilterValue }
> = ({ onChange, value }) => {
  const { sortOrder, ...formatedFilterValues }: InsightsFilterValue =
    value && Object.keys(value).length > 0
      ? {
          ...value,
          publishedDate: value.publishedDate
            ? ({
                ...value.publishedDate,
                from: moment(value.publishedDate.from),
                to: moment(value.publishedDate.to),
              } as RelativeRangeValue)
            : undefined,
        }
      : {};
  const filterItems: FilterItems[] = [
    {
      label: "Type",
      key: "type",
      widthSize: "lg",
      input: (
        <InsightTypeSelect
          size="lg"
          mode="multiple"
          placeholder="Select content type"
          testID={generateTestId("dashboard", "insight-type-filter")}
        />
      ),
      dividerAfter: true,
    },
    {
      label: "Categories",
      key: "categories",
      widthSize: "lg",
      input: (
        <InsightCategorySelect
          size="lg"
          mode="multiple"
          placeholder="Select categories"
          testID={generateTestId("dashboard", "insight-categories-filter")}
        />
      ),
    },
    {
      label: "Published date range",
      key: "publishedDate",
      widthSize: "lg",
      input: (
        <CORERangePickerV2
          allowClear
          widthSize="lg"
          size="lg"
          placeholder={["Select date from", "Select date to"]}
          testID={generateTestId("dashboard", "published-date-filter")}
        />
      ),
    },
  ];

  return (
    <COREFilters
      onChange={onChange}
      testID={generateTestId("dashboard", "insignt-filter")}
      items={filterItems}
      value={formatedFilterValues as InsightsFilterValue}
      modalTitle="Insights filters"
      filterHeader="Insights filters"
      liteMode
    />
  );
};
