import React from "react";

export const WeatherBox = ({ regions, name }) => {
  let w = "-";

  if (
    regions !== undefined &&
    regions[name] !== undefined &&
    regions[name]["weather"] &&
    typeof regions[name]["weather"] === "number"
  ) {
    w = regions[name]["weather"].toFixed(1);
  }

  return <>{w} °C</>;
};
