import React from "react";
import { MarketCommentaryFilters } from "../../../../shared/customHoooks/useProductCurveCommentaries";
import { ProductClassType } from "../../../../shared/customHoooks/useProductTypes";
import { MarketCommentaryByProductClass } from "../../../dailyCurves/widget/MarketCommentaryByProductClass";
import { SetupMarketCommentaryWidget } from "../../../dailyCurves/widget/SetupMarketCommentaryWidget";
import {
  useFeatureFlags,
  FeatureFlag,
} from "../../../../shared/customHoooks/useFeatureFlags";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import {
  currentWidgetFilters,
  onSelectSetupMenu,
  WidgetPanel,
  WidgetProps,
} from "../../WidgetPanel";
import { COREMenuItem } from "../../../../COREDesignSystem/Navigation/COREMenuItem";
import { capitalizeFirstLetter } from "../../../../shared/global";

type MarketCommentaryWidgetsType<T, WidgetFilterType extends object> = {
  props: WidgetProps<T, WidgetFilterType>;
};

const getMenuPermission = (
  selectedType: ProductClassType | undefined,
  features: FeatureFlag[]
): ProductClassType | "all" | "noPermission" => {
  if (selectedType && !features.includes(`daily-curves-${selectedType}`))
    return "noPermission";
  const enableCarbon = features.includes("daily-curves-carbon");
  const enableEnvironment = features.includes("daily-curves-environmental");
  const enableAlls = enableCarbon && enableEnvironment;

  if (enableAlls) return "all";
  if (enableCarbon) return "carbon";
  if (enableEnvironment) return "environmental";
  return "noPermission";
};

export const MarketCommentaryWidget = ({
  props: widgetProps,
}: MarketCommentaryWidgetsType<ProductClassType, MarketCommentaryFilters>) => {
  const { sync, loading, features } = useFeatureFlags();

  if (loading && !sync) {
    return <CORELoading />;
  }

  const currentProps = currentWidgetFilters(widgetProps);
  const permissionType = getMenuPermission(currentProps?.widgetType, features);

  if (permissionType === "noPermission")
    return (
      <COREEmpty
        testID={"dailycurve-market-commentary-widgets-empty"}
        description={"No content available."}
      />
    );

  const currentWidgetType =
    permissionType === "all"
      ? currentProps?.widgetType
      : permissionType ?? currentProps?.widgetType;

  const interactionsMenu = [
    {
      component: (
        <COREMenuItem
          testID={"dailycurve-market-commentary-widgets-1"}
          onClick={() => {
            onSelectSetupMenu(widgetProps, "carbon");
          }}
        >
          Carbon market
        </COREMenuItem>
      ),
      closeWhenClick: true,
    },
    {
      component: (
        <COREMenuItem
          testID={"dailycurve-market-commentary-widgets-2"}
          onClick={() => {
            onSelectSetupMenu(widgetProps, "environmental");
          }}
        >
          Environmental market
        </COREMenuItem>
      ),
      closeWhenClick: true,
    },
  ];

  return (
    <WidgetPanel
      interactionsMenu={[...(permissionType === "all" ? interactionsMenu : [])]}
      testID={"dailycurve-market-commentary-widgets"}
      panelTitle={
        currentWidgetType
          ? `${capitalizeFirstLetter(currentWidgetType)} market commentary`
          : "Market commentary - widget setup"
      }
    >
      {currentWidgetType ? (
        <MarketCommentaryByProductClass
          testID={"dailycurve-market-commentary-widgets-content"}
          widgetProps={widgetProps}
          widgetType={currentWidgetType}
        />
      ) : (
        <SetupMarketCommentaryWidget
          testID={"dailycurve-market-commentary-widgets-setup"}
          widgetProps={widgetProps}
        />
      )}
    </WidgetPanel>
  );
};
