import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import "./COREPageWrapper.less";
import { COREPageHeader } from "./COREPageHeader";
import { ReloadPageNotification } from "../../ReloadPageNotification";
import classNames from "classnames";
import React from "react";
import { COREPageFooter } from "./COREPageFooter";
import { PageLayout } from "../../modules/pages/layouts/PageLayout";
import { BreadcrumbItem } from "../Navigation/COREBreadcrumb";
import { Helmet } from "react-helmet";
import { PRODUCT_NAME } from "../../shared/global";

export interface COREPageWrapperProps {
  testID: TestID;
  header: React.ReactElement<typeof COREPageHeader>;
  className?: string;
  marginBottomHeader?: boolean;
  breadcrumbs: BreadcrumbItem[];
}

export const generatePageTitle = (breadcrumbs: BreadcrumbItem[]) => {
  const envName = [process.env.REACT_APP_ENV];
  const productTitle = PRODUCT_NAME;
  const reverseTitle = breadcrumbs
    .map((breadcrumb) => breadcrumb.title)
    .reverse()
    .join(" • ");
  if (envName[0] !== undefined && envName[0] !== "LIVE") {
    envName.push(` | ${reverseTitle} • ${productTitle}`);
  } else {
    return `${reverseTitle} • ${productTitle}`;
  }
  return envName.join(" ");
};
export const COREPageWrapper: React.FC<COREPageWrapperProps> = ({
  testID,
  children,
  header,
  className,
  marginBottomHeader = true,
  breadcrumbs,
}) => (
  <PageLayout breadcrumbs={breadcrumbs}>
    <Helmet>
      <title>{generatePageTitle(breadcrumbs)}</title>
    </Helmet>
    <TestIDWrapper testID={testID} className={"container"}>
      <div className={classNames("core-page-wrapper", className)}>
        <ReloadPageNotification />
        <div className={classNames({ header: marginBottomHeader })}>
          {header}
        </div>
        <div>{children}</div>
      </div>
      <COREPageFooter showDetails />
    </TestIDWrapper>
  </PageLayout>
);
