import React, { Dispatch, MutableRefObject, SetStateAction } from "react";
import { keysToSnake } from "../../../shared/global";
import { capitalizeWordString } from "../../../shared/globals";
import { generateTestId } from "../../../shared/testids/testids";
import {
  ChartProps,
  PlotRefType,
} from "../../../COREDesignSystem/Chart/ChartHOC";
import {
  COREWaterfallChart,
  COREWaterfallChartProps,
  Traces,
} from "../../../COREDesignSystem/Chart/COREWaterfallChart";
import {
  black,
  grey160,
  indigo100,
  lime100,
  orange100,
  orange20,
  purple100,
} from "../../../COREDesignSystem/Content/COREColour";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { TraceParamsBody } from "./useChartBuilderApiV2";
import {
  useAPIQueries,
  UseAPIQueriesRequest,
} from "../../../shared/customHoooks/useAPITypes";
import equal from "fast-deep-equal";

type ApiConfig = Pick<
  TraceParamsBody,
  Exclude<keyof TraceParamsBody, "columnName">
>;

type WaterfallStatus =
  | "increases"
  | "reductions"
  | "totals"
  | "differential"
  | "target";

export type WaterfallColumn = {
  apiColumnName: string;
  label: Traces["label"];
  measure: Traces["measure"];
  tagStyle?: Traces["tagStyle"];
  hovertemplate?: Traces["hovertemplate"];
} & ApiConfig;

type WaterfallReturn = { date: string; value: number | null };

type AggregatesWaterfallChartProps = Pick<COREWaterfallChartProps, "layout"> & {
  columns: WaterfallColumn[];
  chartTitle?: string;
  chartPanel?: boolean;
  plotRef?: MutableRefObject<PlotRefType>;
  titleRef?: MutableRefObject<HTMLDivElement>;
  setProgressPercent?: Dispatch<SetStateAction<number>>;
  empty: React.ReactElement;
} & ChartProps;

const generateRequestBody = (columnNames: WaterfallColumn[]) => {
  return columnNames.map(
    ({
      tableName,
      aggFunction,
      aggPeriod,
      dateRange,
      selectedItem,
      includeTimesWithoutData,
      apiColumnName,
    }) => ({
      body: keysToSnake({
        tableName: tableName,
        columnName: apiColumnName,
        aggFunction: aggFunction,
        aggPeriod: aggPeriod,
        dateRange: dateRange,
        includeTimesWithoutData: includeTimesWithoutData,
        selectedItem: selectedItem,
      }),
    })
  ) as UseAPIQueriesRequest["apiOptions"];
};

const generateTraceColorByStatus = (status: WaterfallStatus | undefined) => {
  switch (status) {
    case "increases":
      return purple100;
    case "reductions":
      return lime100;
    case "totals":
      return indigo100;
    case "differential":
      return [orange100, orange20];
    case "target":
      return grey160;
    default:
      return black;
  }
};

const generateTraceStatusByValue = (
  chartValue: number,
  value: WaterfallColumn
) => {
  if (chartValue === 0) {
    return undefined;
  }

  if (value.measure === "priorPeriod") {
    return "increases";
  }

  return value.measure === "relative"
    ? chartValue < 0
      ? "increases"
      : chartValue > 0
      ? "reductions"
      : undefined
    : value.measure;
};

const generateTraceByColumns = (
  datas: WaterfallReturn[],
  columns: WaterfallColumn[]
) => {
  return datas
    ? datas.map((data, index: number) => {
        const chartValue: number = data.value ?? 0;
        const status = generateTraceStatusByValue(chartValue, columns[index]);

        return {
          yaxis: chartValue,
          label: columns[index].label,
          measure: columns[index].measure,
          type: status && capitalizeWordString(status),
          tagStyle: columns[index].tagStyle,
          color: generateTraceColorByStatus(status),
          hovertemplate: columns[index].hovertemplate,
        };
      })
    : [];
};

const emptyDataCheck = (data: WaterfallReturn[]) => {
  if (data.length === 0) return true;
  return data.every(
    (datalist) => datalist.value === 0 || datalist.value === undefined
  );
};

const AggregatesWaterfallChartContent: React.FC<
  AggregatesWaterfallChartProps
> = ({
  layout,
  columns,
  chartTitle,
  chartPanel = false,
  plotRef,
  titleRef,
  empty,
  setProgressPercent,
  setOnDownloadCSV,
  setOnDownloadPNG,
  setOnResetChart,
}) => {
  const requestBody = generateRequestBody(columns);
  const { results, error } = useAPIQueries<WaterfallReturn[]>(
    "aggregates",
    requestBody
  );

  if (error) {
    return <COREError />;
  }

  const data = results
    .map((r) => {
      return r && r.data && r.data.length > 0
        ? r.data.reduce((prev, cur) => {
            prev.sumAndGroup = prev.sumAndGroup ?? {
              ...cur,
              value: 0,
            };
            if (cur.value !== null && prev.sumAndGroup.value !== null) {
              prev.sumAndGroup.value += cur.value;
            }
            return prev;
          }, {} as { sumAndGroup: WaterfallReturn }).sumAndGroup
        : undefined;
    })
    .filter((item) => item !== undefined) as WaterfallReturn[];
  setProgressPercent && setProgressPercent(1);
  const traces: COREWaterfallChartProps["traces"] = generateTraceByColumns(
    data,
    columns
  );
  const targetLine = traces.find((item) => item.measure === "target");

  if (emptyDataCheck(data)) return empty;

  return (
    <COREWaterfallChart
      testID={generateTestId(
        "emissionsmanager",
        "emission-scope-breakdown-chart"
      )}
      traces={traces}
      chartTitle={chartTitle}
      target={targetLine?.yaxis}
      displayTitle={!chartPanel}
      plotRef={plotRef}
      titleRef={titleRef}
      setOnDownloadCSV={setOnDownloadCSV}
      setOnDownloadPNG={setOnDownloadPNG}
      setOnResetChart={setOnResetChart}
      layout={layout}
    />
  );
};

export const AggregatesWaterfallChart = React.memo<
  React.ComponentType<AggregatesWaterfallChartProps>
>(
  AggregatesWaterfallChartContent,
  (prevProps, nextProps) =>
    prevProps && equal(prevProps.columns, nextProps.columns)
);
