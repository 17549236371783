import "./COREMenu.less";
import React, { useContext } from "react";
import { Menu, SubMenuProps } from "antd";
import { TestID } from "../../shared/testids/testids";
import { MenuModeContext } from "./COREMenu";
import { CORETypographyInput } from "../Typography/CORETypographyInput";
import { CaretDownOutlined } from "@ant-design/icons";
import classNames from "classnames";

type CORESubMenuProps = {
  testID: TestID;
  className?: string;
  title?: string;
  popupClassName?: string;
};

export const PopupOffsetContext = React.createContext<
  [number, number] | undefined
>(undefined);

const TitleWithCaretDownIcon: React.FC = ({ children }) => {
  return (
    <>
      {children}
      <CaretDownOutlined className={"horizontal-expand-icon"} />
    </>
  );
};

export const CORESubMenu: React.FC<CORESubMenuProps & SubMenuProps> = ({
  testID,
  className,
  popupClassName,
  title,
  ...props
}) => {
  let popupOffset: [number, number] = [0, 0];
  const popupClasses = [popupClassName];
  const memuMode = useContext(MenuModeContext);
  const popupOffsetContext = useContext(PopupOffsetContext);

  if (popupOffsetContext) {
    popupOffset = popupOffsetContext;
  } else if (!popupOffsetContext && memuMode === "horizontal") {
    popupOffset = [-20, 2];
  } else {
    popupOffset = [5, 0];
  }

  let titleMenu: React.ReactNode = (
    <CORETypographyInput type={"default"}>{title}</CORETypographyInput>
  );
  if (memuMode === "horizontal") {
    titleMenu = <TitleWithCaretDownIcon>{titleMenu}</TitleWithCaretDownIcon>;
    popupClasses.push("popup-horizontal");
  } else {
    popupClasses.push("popup-vertical");
  }

  return (
    <PopupOffsetContext.Provider value={[3, -3]}>
      <Menu.SubMenu
        {...props}
        title={titleMenu}
        data-testid={testID}
        className={"core-menu-submenu"}
        popupClassName={classNames(popupClasses, "core-menu-popup-container")}
        popupOffset={popupOffset}
      />
    </PopupOffsetContext.Provider>
  );
};
