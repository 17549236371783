import React from "react";
import {
  CORESelect,
  CORESelectProps,
} from "../../COREDesignSystem/Form/CORESelect";
import { useDashboardTemplateOptions } from "../customHoooks/useDashboardTemplate";

export const DashboardTemplateSelect: React.FC<CORESelectProps> = (props) => (
  <CORESelect
    size="lg"
    options={useDashboardTemplateOptions()}
    widthSize="xxl"
    mode="multiple"
    placeholder="Select dashboard widgets"
    {...props}
  />
);
