/* eslint-disable react/prop-types */
import React from "react";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import { ChartBuilderComponent } from "./ChartBuilderComponent";
import { chartConfig } from "../../../shared/globals";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { Col, Row } from "antd";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { generateTestId } from "../../../shared/testids/testids";

export const ChartBuilderWidget = ({ props }) => {
  const params: {
    type: string;
    me: string;
  } = {
    type: `eq.chart-builder`,
    me: `eq.true`,
  };

  const { data: userChart, loading } = useAPIQuery("user_configs", params);

  if (loading || !userChart.data) return <CORELoading size={"lg"} />;

  const chartItem = userChart.data.find((item) => item.id === props.props.id);

  return (
    <Row>
      <Col span={24}>
        <ChartBuilderComponent
          editMode={false}
          config={chartItem ? chartItem.config : chartConfig}
          chartCardMode={true}
          containerBordered={false}
          empty={
            <COREEmpty
              description={"No chart data available"}
              hint={
                "Try reloading the page or update your chart's configuration in Chart Builder."
              }
              testID={generateTestId(
                "chartbuilder",
                "chart-builder-widget-no-data"
              )}
            />
          }
        />
      </Col>
    </Row>
  );
};
