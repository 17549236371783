import { useHistory } from "react-router-dom";
import { Module } from "./testids";

const chartbuilderModule: Module = `chartbuilder`;
const traderviewModule: Module = `traderview`;

export const useTestModule: () => Module = () => {
  const history = useHistory();
  const pagePath = history.location.pathname.replace("/", "");
  switch (pagePath) {
    case "tools/chart-builder":
      return chartbuilderModule;
    case "tools/trader-view":
      return traderviewModule;
    default:
      throw new Error("unknown module");
  }
};
