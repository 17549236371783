import React, { useState } from "react";
import {
  COREFilters,
  COREFiltersProps,
  FilterItems,
} from "../../../COREDesignSystem/Form/COREFilters";
import { generateTestId } from "../../../shared/testids/testids";
import {
  CORERadiobuttonGroup,
  RadioButtonGroupProps,
} from "../../../COREDesignSystem/Form/CORERadioButton";
import {
  isCandleStickChart,
  PriceHistoryChartFilterValues,
  useTradableProductsForType,
  zoomButtons,
} from "./PriceHistoryChart";
import { PriceHistoryProductSelect } from "./PriceHistoryProductSelect";
import { MethodSelect } from "../../../shared/select/MethodSelect";
import { groupOptions } from "../../../COREDesignSystem/Form/CORESelectAPI";
import { AccreditationSelect } from "../../../shared/select/AccreditationSelect";
import { keysToSnake } from "../../../shared/global";

const ZoomRadioButton: React.FC<
  Omit<RadioButtonGroupProps, "radioBtnValues" | "optionType">
> = (props) => {
  return (
    <CORERadiobuttonGroup
      {...props}
      optionType="button"
      radioBtnValues={Object.keys(zoomButtons).map((key) => ({
        name: key,
        value: key,
        testID: generateTestId("projectmarketplace", `zoom-radio-btn-${key}`),
      }))}
    />
  );
};

export const PriceHistoryChartFilter: React.FC<{
  value: PriceHistoryChartFilterValues;
  setValue: (newValue: PriceHistoryChartFilterValues) => void;
  isWidget: boolean;
  onWidgetFilter?: COREFiltersProps["onChange"];
}> = ({ value, setValue, isWidget, onWidgetFilter }) => {
  const [inputState, setInputState] = useState<Pick<
    PriceHistoryChartFilterValues,
    "method" | "productType" | "vintages"
  > | null>(null);

  const { tradableProducts } = useTradableProductsForType(
    inputState?.productType ?? value.productType,
    inputState?.method !== "generic" ? inputState?.method : undefined
  );
  const vintages =
    inputState &&
    (isCandleStickChart(inputState.productType)
      ? tradableProducts?.[0]?.id
      : tradableProducts?.map((p) => p.id));
  const items = [
    ...(!isWidget
      ? [
          {
            label: `Accreditation`,
            key: `productType`,
            widthSize: "md",
            input: (
              <AccreditationSelect
                mode="basic"
                testID={generateTestId(
                  "projectmarketplace",
                  "filter-by-accreditation"
                )}
                widthSize="full"
                onChange={(v) => {
                  setInputState((cur) => ({
                    ...cur,
                    productType: v,
                    method: "generic",
                  }));
                }}
                allowClear={false}
                placeholder="Select accreditation"
                transform={(data) => groupOptions(data, "name", "code", "name")}
              />
            ),
            dividerAfter: true,
          },
        ]
      : []),
    {
      label: `Method`,
      key: `method`,
      widthSize: "lg",
      dividerAfter: isWidget,
      input: (
        <MethodSelect
          placeholder="Select method"
          mode="basic"
          testID={generateTestId("projectmarketplace", "method-filter")}
          transform={(d) => [
            { label: "Generic", value: "generic" },
            ...groupOptions(d, "name", "id", "name"),
          ]}
          {...(inputState?.productType
            ? {
                params: keysToSnake({
                  productType: `in.(${inputState.productType})`,
                }),
              }
            : {
                params: {
                  ...(value.productType &&
                    keysToSnake({
                      productType: `in.(${value.productType})`,
                    })),
                },
              })}
          widthSize="full"
          onChange={(v) => {
            setInputState(
              (cur) =>
                ({
                  ...cur,
                  method: v,
                } as PriceHistoryChartFilterValues)
            );
          }}
        />
      ),
    },
    {
      label: `Vintages`,
      key: `vintages`,
      widthSize: "lg",
      input: (
        <PriceHistoryProductSelect
          productType={inputState?.productType ?? value.productType}
          projectMethodType={
            inputState?.method
              ? inputState.method !== "generic"
                ? inputState.method
                : undefined
              : value.method !== "generic"
              ? value.method
              : undefined
          }
          testID={generateTestId(
            "projectmarketplace",
            "marketSnapshotPriceHistoryDropdown"
          )}
          placeholder="Vintages"
          size="lg"
          widthSize="lg"
        />
      ),
    },
    {
      label: `Zoom`,
      key: `zoomLevel`,
      widthSize: "lg",
      input: (
        <ZoomRadioButton
          size="large"
          buttonStyle="solid"
          testID={generateTestId("projectmarketplace", `zoom-radio-btn`)}
        />
      ),
    },
  ].filter(Boolean) as FilterItems[];

  return (
    <COREFilters
      liteMode={isWidget}
      allowClear={false}
      onChange={(v) => {
        setInputState(null);
        if (isWidget) {
          onWidgetFilter && onWidgetFilter(v);
        } else {
          if (value.productType !== v.productType) {
            setValue({
              ...value,
              productType: v.productType,
              method: "generic",
              vintages: undefined,
            } as PriceHistoryChartFilterValues);
          } else if (value.method !== v.method) {
            setValue({
              ...value,
              method: v.method,
              vintages: undefined,
            } as PriceHistoryChartFilterValues);
          } else {
            setValue(v as PriceHistoryChartFilterValues);
          }
        }
      }}
      testID={generateTestId(
        "projectmarketplace",
        "price-history-chart-filter"
      )}
      onCancelModal={() => setInputState(null)}
      items={items}
      value={{
        ...value,
        ...inputState,
        ...(vintages && { vintages: vintages }),
      }}
      modalTitle="Price history filters"
      filterHeader="Price history filters"
    />
  );
};
