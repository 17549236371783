import React, { useState } from "react";
import { Input, Tree } from "antd";
import "./CORETree.less";
import {
  getTreeDepth,
  Key,
  keysToRootValues,
  keysToValues,
  CORETreeData,
  renderTreeNodes,
  rootValuesToKeys,
  treeNodeRecursiveMatches,
  UseCORETreeProps,
} from "./CORETree";
import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";
import { Merge } from "../../shared/TypeScriptHelpers";
import { TreeProps } from "antd/es/tree";
import { useDeepCompareEffect } from "react-use";
import { COREButton } from "../Action/COREButton";

export type COREAsyncTreeProps<V> = Merge<
  TreeProps,
  {
    ref: React.ForwardedRef<any>;
    search?: boolean;
    limit?: number;
    filterMode?: boolean;
    onlyCurrent?: boolean;
    treeData: CORETreeData<V>;
    onCheck: (
      checked: Parameters<Exclude<TreeProps["onCheck"], undefined>>[0]
    ) => ReturnType<Exclude<TreeProps["onCheck"], undefined>>;
    onLoad: ({ key }: { key: string | number }) => void;
  }
>;

export type cfdTreeType = {
  path: string[];
  children: [];
  title: string;
  key: string;
  realValue?: string;
  value?: string;
  isLeaf?: boolean;
  checkable?: boolean;
};

export const updateTreeData = <V extends any>(
  list: CORETreeData<V>,
  key: React.Key,
  children: CORETreeData<V>
): CORETreeData<V> => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
};

export const useCOREAsyncTree = (
  treeData: CORETreeData<cfdTreeType>,
  {
    initialRootValues,
    value,
    onChange: parentOnChange,
  }: UseCORETreeProps<cfdTreeType> = {
    initialRootValues: undefined,
    value: undefined,
    onChange: undefined,
  }
) => {
  const [checkedKeys, setCheckedKeys] = useState(
    rootValuesToKeys(treeData, initialRootValues)
  );

  useDeepCompareEffect(() => {
    setCheckedKeys(rootValuesToKeys(treeData, value ?? initialRootValues));
  }, [value, setCheckedKeys]);

  return {
    onCheck: (currentKeyChecked) => {
      setCheckedKeys(currentKeyChecked);
      if (parentOnChange instanceof Function) {
        parentOnChange(
          keysToRootValues(treeData, currentKeyChecked),
          keysToValues(treeData, currentKeyChecked),
          currentKeyChecked
        );
      }
    },
    treeData: treeData,
    checkedKeys: checkedKeys,
  };
};

export const COREAsyncTree = <Value extends any>({
  treeData,
  onCheck,
  selectable = true,
  checkedKeys,
  multiple = false,
  limit = -1,
  search = false,
  filterMode = false,
  onLoad,
}: COREAsyncTreeProps<Value>) => {
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  function* flatten(array) {
    for (const el of array) {
      yield el;
      yield* flatten(el.children);
    }
  }

  const searchExpandedTree = (tree, searchValue): string[] => {
    let pathKey: string[] = [];
    for (const el of flatten(tree)) {
      const matchTreeNodes = treeNodeRecursiveMatches(el, searchValue);
      if (matchTreeNodes) {
        pathKey = [...pathKey, el.key];
      }
    }
    return [...new Set(pathKey)];
  };

  const onSearchChange = ({ target: { value: searchValue } }) => {
    setSearchValue(searchValue);
    const matchKey: string[] = searchExpandedTree(treeData, searchValue);
    setExpandedKeys(matchKey);
  };

  const onCheckFunc = (checkedKeysValue, checked, node) => {
    if (!onCheck) return;
    if (multiple) return onCheck(checkedKeysValue);
    if (checked) return onCheck([node.key]);
    return onCheck([]);
  };

  const onSelectFunc = (selectKeys, node) => {
    if (!selectable || !onCheck) {
      return false;
    }
    if (!node.isLeaf && !node.checkable && !node.children) {
      onLoadData({ key: node.key });
    } else if (!node.children) {
      setSelectedKeys(selectKeys);
      return onCheck(selectKeys);
    }
    const k: Key[] = node.expanded
      ? expandedKeys.filter((kFilter) => kFilter !== node.key)
      : expandedKeys.concat(node.key);
    setExpandedKeys(k);
  };

  const onLoadData = ({ key }: { key: Key }) =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        onLoad({ key: key });
        resolve();
      }, 1000);
    });

  if (Array.isArray(checkedKeys) && checkedKeys !== selectedKeys) {
    setSelectedKeys(checkedKeys);
  }

  const treeDepth: number = getTreeDepth(treeData);
  const checkAbleNodePosition: number =
    limit < 0 ? treeDepth + limit + 1 : limit > treeDepth ? treeDepth : limit;

  const treeResult = renderTreeNodes(
    treeData,
    multiple,
    checkAbleNodePosition,
    treeDepth,
    searchValue,
    selectable
  );

  return (
    <>
      {search && (
        <Input.Search
          allowClear
          onChange={onSearchChange}
          placeholder="Search"
          className={"input-search"}
        />
      )}
      {filterMode && (
        <COREButton
          type={"primary"}
          danger
          onClick={() => {
            if (!onCheck) return;
            onCheck([]);
          }}
        >
          <FilterOutlined />
        </COREButton>
      )}

      {treeData && (
        <div className={"selector"}>
          <Tree
            multiple={multiple}
            checkable
            loadData={onLoadData}
            treeData={treeResult}
            selectedKeys={selectedKeys}
            checkedKeys={checkedKeys}
            expandedKeys={expandedKeys}
            onExpand={(expandedKeysValue) => {
              setExpandedKeys(expandedKeysValue);
            }}
            onSelect={(selectKeys, { node }) => {
              onSelectFunc(selectKeys, node);
            }}
            onCheck={(checkedKeysValue, { checked, node }) => {
              onCheckFunc(checkedKeysValue, checked, node);
            }}
          />
        </div>
      )}
    </>
  );
};
