const normaliseGroup = (grp) => (grp.includes("Qtrs") ? "quarterly" : grp);
export const LiveMarketScreenGrouping = (dataSource) => {
  const sort = ["spot", "quarterly", "yearly", "half-yearly", "monthly"];

  let grouping = [];

  dataSource.forEach((d, i) => {
    const groupName =
      d.tradableProduct.tenor_type === "quarterly"
        ? `${d.tradableProduct.vintage} Qtrs`
        : d.tradableProduct.tenor_type ?? "spot";
    let groupIndex, sortIndex;
    let index = grouping.findIndex((x) => x.name === groupName);

    if (index > -1) {
      grouping[index].children.push({ ...d, sourceIndex: i });
    } else {
      if (sort) {
        sortIndex =
          sort.indexOf(normaliseGroup(groupName)) * 3000 +
          d.tradableProduct.vintage;

        if (sortIndex > -1) {
          groupIndex = sortIndex;
        } else {
          groupIndex = 0;
        }
      }

      grouping.push({
        index: groupIndex,
        name: groupName,
        tradableProductName: groupName,
        children: [{ ...d, sourceIndex: i }],
      });
    }
  });

  grouping.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

  dataSource = grouping;

  return dataSource;
};
