import { Space } from "antd";
import React from "react";
import { CORESwitch } from "../../../COREDesignSystem/Form/CORESwitch";
import { CORETypographyInput } from "../../../COREDesignSystem/Typography/CORETypographyInput";
import { ViewAsType } from "./ProjectMarketplaceContainer";
import "./ProjectMarketplaceFilter.less";
import { ProductType } from "../ProjectMarketplacePageWrapper";
import { generateTestId } from "../../../shared/testids/testids";
import { FilterType, ProjectMarketplaceFilters } from "../ProjectFiltersHOC";

type ProjectMarketplaceFilterProps = {
  defaultFilterValues?: FilterType;
  filterValues: FilterType;
  onChange: (value: FilterType) => void;
  viewAs: ViewAsType;
  setViewAs: (args: ViewAsType) => void;
  showMapSwitch: boolean;
};

export const params = (productType?: ProductType[]) => {
  return {
    ...(productType &&
      productType.length > 0 && {
        product_type: `in.(${productType})`, // eslint-disable-line camelcase
      }),
  };
};

export const ProjectMarketplaceFilter: React.FC<
  ProjectMarketplaceFilterProps
> = ({
  defaultFilterValues,
  filterValues,
  onChange,
  viewAs,
  setViewAs,
  showMapSwitch,
}) => {
  return (
    <div className={"project-marketplace-filter-block"}>
      <ProjectMarketplaceFilters
        testID={generateTestId("projectmarketplace", "filter-project-btn")}
        modalTitle={"Project filters"}
        filterHeaderText={"Project filters"}
        defaultFilterValues={defaultFilterValues}
        filterValues={filterValues}
        onChange={onChange}
      />

      {showMapSwitch && (
        <Space className={"card-view-block"}>
          <CORETypographyInput type={"label"}>Card view</CORETypographyInput>
          <CORESwitch
            size={"lg"}
            defaultChecked={false}
            checked={viewAs === "cards"}
            onChange={(value) => setViewAs(value ? "cards" : "list")}
            testID={generateTestId(
              "projectmarketplace",
              "switch-view-as-cards-in-filter-bar"
            )}
          />
        </Space>
      )}
    </div>
  );
};
