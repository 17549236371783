import React from "react";
import { COREBody } from "../../../../COREDesignSystem/Typography/COREBody";
import { CORETag } from "../../../../COREDesignSystem/Content/CORETag";
import { Space } from "antd";

export type BeZeroCarbonRatingType =
  | "AAA"
  | "AA"
  | "A"
  | "BBB"
  | "BB"
  | "B"
  | "C"
  | "D";

export type BezeroRatingType = {
  isOnWatch: boolean;
  rating: BeZeroCarbonRatingType;
};

export const BeZeroCarbonRatingTag: React.FC<
  BezeroRatingType & { className?: string }
> = ({ rating, isOnWatch, className }) => {
  return (
    <CORETag
      className={className}
      label={
        <Space size={2}>
          <COREBody type="p4" className={"rating-font-weight"}>
            <span>{rating} </span>
          </COREBody>
          <COREBody type="p4">
            BeZero Carbon Rating{isOnWatch ? " (rw)" : ""}
          </COREBody>
        </Space>
      }
      type={"basic"}
    />
  );
};
