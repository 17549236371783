import React, { useState, useEffect } from "react";
import { Input, Empty } from "antd";
import "./DashboardIFrame.less";

export const DashboardIFrame = ({ props }) => {
  const [iframeUrl, setIframeUrl] = useState(props.props);

  useEffect(() => {
    setIframeUrl(props.props);
  }, [props.props]);

  const setUrl = (widget) => {
    setIframeUrl(widget);
    props.setProps({ widget, i: props.name });
  };

  return (
    <div className={"dashboard-iframe-block"}>
      {props.editMode ? (
        <Input
          placeholder="Website url"
          value={iframeUrl}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
        />
      ) : null}

      {iframeUrl ? <iframe src={iframeUrl} title={"IFRAME"} /> : <Empty />}
    </div>
  );
};
