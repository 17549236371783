import React from "react";

import { product_types as productTypesApproved } from "../../openapi-typescript/approved";
import { product_types as productTypesAdmin } from "../../openapi-typescript/admin";
import { CORESelectProps } from "../../COREDesignSystem/Form/CORESelect";
import { ProductTypes } from "../../modules/dailyCurves/carbonComponent/EodEditor";
import {
  CORESelectAPI,
  CORESelectAPIProps,
} from "../../COREDesignSystem/Form/CORESelectAPI";
import { keysToSnake } from "../global";

export type ProductType = productTypesAdmin | productTypesApproved;
type AccreditationSelectProps = CORESelectProps & {
  productTypes?: ProductTypes["code"][];
  order?: string;
} & Pick<CORESelectAPIProps<ProductType>, "callback" | "transform">;

export const AccreditationSelect: React.FC<AccreditationSelectProps> = ({
  value,
  testID,
  productTypes,
  transform,
  order,
  ...props
}) => {
  return (
    <CORESelectAPI
      transform={transform}
      size={"lg"}
      allowClear
      value={value}
      testID={testID}
      endpoint={"getAllProductTypes"}
      params={{
        select: "code,name,earliestVintage:earliest_vintage,currency",
        ...keysToSnake({
          greenMarketplaceSortOrder: "not.is.null",
          ...(productTypes &&
            productTypes.length !== 0 && {
              code: `in.(${productTypes.join()})`,
            }),
        }),
        ...(order && { order: order }),
      }}
      {...props}
    />
  );
};
