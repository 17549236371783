import React from "react";
import "./AemoPriceMapOverview.less";
import moment from "moment";
import { useAPIQueryRequest } from "../../../../shared/customHoooks/useAPI";
import { NumberTooltip } from "./NumberTooltip";
import {
  ColorHex,
  COREElectricityChartColour,
} from "../../../../COREDesignSystem/Content/COREColour";
import { generateTestId } from "../../../../shared/testids/testids";
import {
  AggregatesChartProps,
  AggregatesChartV2,
  ChartFilterOptions,
  getTodayDate,
  Trace,
} from "../../../tools/chartBuilder/AggregatesChartV2";
import { RelativeRangeValue } from "../../../../COREDesignSystem/Form/CORERangePicker";
import { Alert } from "antd";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import classNames from "classnames";

type LivePriceChartPopupType = {
  region: string;
  regionColor: string;
  date: string;
  rrp: string;
  demand: string;
};

export const LivePriceChartPopup: React.FC<LivePriceChartPopupType> = ({
  region,
  regionColor,
  date,
  rrp: liveRRP,
  demand: liveDemand,
}) => {
  // variable
  const hrFormat: string = "HH:mm";

  // NEM Time
  const {
    sync: syncAPI,
    error,
    data: {
      data: { settlementdate: settlementdateAPI } = { settlementdate: null },
    },
  } = useAPIQueryRequest("livePrices");

  if (error)
    return <Alert type={"error"} message={`Unable to load ${region} time`} />;

  const nemTime: string | null = syncAPI ? settlementdateAPI : null;
  // map time range
  const todayDate: moment.Moment = moment(nemTime);
  const todayDateFormat: string = moment(nemTime).format();
  const lastNemTime: string = todayDate.format(hrFormat);

  const chartSpotColor: ColorHex = COREElectricityChartColour(
    regionColor,
    "price"
  );
  const chartDemandsColor: ColorHex = COREElectricityChartColour(
    regionColor,
    "demand"
  );

  const getChartTraces = (region: string): Trace[] => {
    const priceTitle: Trace["title"] = {
      text: "Spot Price",
      font: {},
      standoff: 0,
      position: "top left",
    };
    const demandTitle: Trace["title"] = {
      text: "Demand",
      font: {},
      standoff: 0,
      position: "top left",
    };
    const dateRange: RelativeRangeValue = {
      isRelative: false,
      from: moment(),
      to: moment(),
      bounds: "[)",
    };
    const spotTrace: Trace = {
      tableName: "dispatch",
      columnName: "rrp",
      title: priceTitle,
      name: "Spot Price",
      selectedValue: region,
      aggFunction: "none",
      aggPeriod: "none",
      dateRange: dateRange,
      type: "scatter",
      "line.color": chartSpotColor,
      axis: {
        side: "right",
        title: "Price ($/MWh)",
      },
      hovertemplate: "%{x}, %{y:,.2f}",
    };

    const demandTrace: Trace = {
      tableName: "dispatch",
      columnName: "clearedsupply",
      title: demandTitle,
      name: "Demand",
      selectedValue: region,
      aggFunction: "none",
      aggPeriod: "none",
      dateRange: dateRange,
      type: "scatter",
      "line.color": chartDemandsColor,
      axis: {
        side: "left",
        title: "Demand (MW)",
      },
      hovertemplate: "%{x}, %{y:,.0f}",
    };

    return [spotTrace, demandTrace];
  };

  const layoutChart: AggregatesChartProps["layout"] = {
    autosize: true,
    showlegend: false,
    margin: { l: 0, r: 0, t: 0, b: 25 },
    shapes: [
      {
        type: "rect",
        xref: "x",
        yref: "paper",
        x0: todayDateFormat,
        y0: 0,
        x1: todayDateFormat,
        y1: 1,
        opacity: 1,
        line: { dash: "dot", width: 1, color: "#FF0000" },
      },
    ],
    xaxis: {
      automargin: true,
      tickformat: "%H:%M",
      showline: true,
    },
    yaxis: {
      automargin: true,
      showgrid: false,
      showline: true,
      tickformat: ",.0f",
      autorange: true,
    },
    yaxis2: {
      automargin: true,
      showline: true,
      showgrid: false,
      overlaying: "y",
      side: "right",
      tickformat: ",.2f",
      autorange: true,
    },
    height: 210,
  };

  const todayDateChart: string = getTodayDate("none").format();
  const chartFilter: ChartFilterOptions = {
    hasTodayBreak: true,
    todayDate: todayDateChart,
  };

  return (
    <div className={"live-price-popover"}>
      <div className={"graph-date"}>{lastNemTime}</div>

      <div className={"content-block"}>
        <div className={"spot-price-block"}>
          <div className={"label-block"}>
            <span className={"icon"} style={{ background: chartSpotColor }} />
            Spot Price
          </div>
          <div className={"value-block"}>
            <NumberTooltip number={liveRRP} suffix={"$/MWh"} places={2} />
          </div>
        </div>
        <div className={"scheduled-demand-block"}>
          <div className={"label-block"}>
            <span
              className={"icon"}
              style={{ background: chartDemandsColor }}
            />
            Demand
          </div>
          <div className={"value-block"}>
            <NumberTooltip number={liveDemand} suffix={"MW"} places={0} />
          </div>
        </div>
      </div>

      <div className={classNames("graph-block", "with-axis-label")}>
        <div className={"axis-y-left-label"}>Demand (MW)</div>
        <div className={"axis-y-right-label"}>Price ($/MWh)</div>
        <AggregatesChartV2
          chartId={`${region} - ${regionColor}`}
          traces={getChartTraces(region)}
          chartFilter={chartFilter}
          downloadCsv={true}
          downloadPng={true}
          layout={layoutChart}
          testID={generateTestId("dashboard", "live-price-popup-chart")}
          showlegend={false}
          chartPanel={true}
          empty={
            <COREEmpty
              description={"No chart data available"}
              hint={
                "Try reloading the page or update your chart's configuration in Chart Builder."
              }
              testID={generateTestId(
                "dashboard",
                "live-price-popup-chart-no-data"
              )}
            />
          }
        />
      </div>
    </div>
  );
};
