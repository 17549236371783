import { Alert, AlertProps } from "antd";
import React from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { Merge } from "../../shared/TypeScriptHelpers";
import { COREBody } from "../Typography/COREBody";
import { COREHeading } from "../Typography/COREHeading";
import "./CORENotification.less";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREButton } from "../Action/COREButton";
import classNames from "classnames";

export type notificationType =
  | "default"
  | "info"
  | "success"
  | "error"
  | "warning";

export type CORENotificationType = Merge<
  AlertProps,
  {
    message: string;
    description: string;
    testID: TestID;
  }
>;

const iconPadding = "alert-icon-padding-top";

export const IconNotification: React.FC<{ type: string }> = ({ type }) => {
  switch (type) {
    case "info":
      return (
        <COREIcon
          icon={icon({ name: "circle-info", style: "solid" })}
          size={"sm"}
          className={iconPadding}
        />
      );
    case "success":
      return (
        <COREIcon
          icon={icon({ name: "circle-check", style: "solid" })}
          size={"sm"}
          className={iconPadding}
        />
      );
    case "warning":
      return (
        <COREIcon
          icon={icon({ name: "triangle-exclamation", style: "solid" })}
          size={"sm"}
          className={iconPadding}
        />
      );
    case "error":
      return (
        <COREIcon
          icon={icon({ name: "circle-xmark", style: "solid" })}
          size={"sm"}
          className={iconPadding}
        />
      );
    default:
      return (
        <COREIcon
          icon={icon({ name: "comment-dots", style: "solid" })}
          size={"sm"}
          className={iconPadding}
        />
      );
  }
};

const CustomDescription = (message: string, children: React.ReactNode) => {
  return (
    <>
      <COREBody type={"p2"} marginBottom={false}>
        {message}
      </COREBody>
      {children && <div className={"children-block"}>{children}</div>}
    </>
  );
};

const CustomMessage = (message: string, testId: TestID) => {
  return (
    <COREHeading level={3} testID={testId}>
      {message}
    </COREHeading>
  );
};

export const CORENotification: React.FC<CORENotificationType> = ({
  children,
  message,
  description,
  type,
  showIcon = true,
  testID,
  ...props
}) => {
  const showNotificationType = type ? type : "default";
  const mainBlockClass = classNames(
    "notification-default-block",
    showNotificationType
  );
  const titleMessage = CustomMessage(message, testID);
  const descriptionMessage = CustomDescription(description, children);

  return (
    <TestIDWrapper testID={testID}>
      <div className={mainBlockClass}>
        <Alert
          message={titleMessage}
          description={descriptionMessage}
          type={type === undefined ? "info" : type}
          showIcon={showIcon}
          icon={<IconNotification type={showNotificationType} />}
          closeIcon={
            <COREButton
              testID={`${testID}-close-btn`}
              type={"default"}
              size={"sm"}
              icon={<COREIcon icon={icon({ name: "xmark", style: "solid" })} />}
            />
          }
          {...props}
        />
      </div>
    </TestIDWrapper>
  );
};
