import { Col, Row } from "antd";
import React from "react";
import {
  ContentWidgetProps,
  currentWidgetFilters,
  onSelectFilters,
} from "../../dashboards/WidgetPanel";
import { CarbonTableForWidget } from "../carbonComponent/CarbonTableWithFilter";
import { DailyCurveTableFilterType } from "./SetupDailyCurveWidget";
import {
  carbonProductTypes,
  DailyCurveProductType,
} from "../../../shared/customHoooks/useProductTypes";
import { EnviroTableForWidget } from "../environComponent/EnviroTableWithFilter";

type DailyCurveTableByProductTypeProps = ContentWidgetProps<
  DailyCurveProductType,
  DailyCurveTableFilterType
>;

export const DailyCurveTableByProductType: React.FC<
  DailyCurveTableByProductTypeProps
> = ({ widgetProps, widgetType, testID }) => {
  const widgetFilters = currentWidgetFilters(widgetProps)?.filters;

  const onFilterChange = (filter?: DailyCurveTableFilterType | null) => {
    onSelectFilters(widgetProps, filter ?? {});
  };

  const isCarbon = carbonProductTypes.includes(widgetType);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        {isCarbon ? (
          <CarbonTableForWidget
            productType={widgetType}
            testID={testID}
            widgetFilters={widgetFilters}
            onWidgetFilterChange={onFilterChange}
          />
        ) : (
          <EnviroTableForWidget
            productType={widgetType}
            testID={testID}
            widgetFilters={widgetFilters}
            onWidgetFilterChange={onFilterChange}
          />
        )}
      </Col>
    </Row>
  );
};
